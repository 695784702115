export const scrollToTop = (): void => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const handleExportFile = (response: any): void => {
  const fileName = response?.headers?.['content-disposition']?.split("attachment; filename*=UTF-8''")?.[1];

  const data = new Blob([response?.dataFile], { type: response?.dataFile?.type });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', decodeURIComponent(fileName));
  tempLink.click();
};
