import React from 'react';

const PencilEditIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3999 5.46984L18.5299 2.59984C18.3433 2.40628 18.1196 2.25232 17.8722 2.14716C17.6248 2.04199 17.3587 1.98779 17.0899 1.98779C16.821 1.98779 16.555 2.04199 16.3075 2.14716C16.0601 2.25232 15.8365 2.40628 15.6499 2.59984L13.4899 4.74984L4.12989 14.1098L2.69989 18.4298L2.06989 20.3098C2.03223 20.4232 2.00872 20.5407 1.99989 20.6598C1.99989 20.6598 1.99989 20.7298 1.99989 20.7698C1.9945 20.8464 1.9945 20.9233 1.99989 20.9998C1.99989 20.9998 1.99989 21.0798 1.99989 21.1198C2.02056 21.1823 2.04736 21.2426 2.07989 21.2998L2.13989 21.4098L2.25989 21.5698L2.33989 21.6598L2.50989 21.7898L2.59989 21.8498L2.85989 21.9598H2.99989C3.10266 21.975 3.20711 21.975 3.30989 21.9598C3.44311 21.961 3.57538 21.9373 3.69989 21.8898L5.57989 21.2598L9.89989 19.8298L19.2599 10.4698L21.3999 8.34984C21.5935 8.16328 21.7474 7.9396 21.8526 7.69218C21.9577 7.44476 22.0119 7.17868 22.0119 6.90984C22.0119 6.64101 21.9577 6.37493 21.8526 6.12751C21.7474 5.88009 21.5935 5.65641 21.3999 5.46984ZM4.39989 20.1198L3.88989 19.6098L4.79989 16.8898L7.11989 19.2098L4.39989 20.1198ZM20.3999 7.28984L19.3099 8.38984L15.6099 4.74984L16.7099 3.65984C16.7586 3.60819 16.8176 3.56728 16.883 3.53973C16.9484 3.51218 17.0189 3.49859 17.0899 3.49984C17.1593 3.49938 17.228 3.51336 17.2917 3.5409C17.3554 3.56845 17.4127 3.60896 17.4599 3.65984L20.3399 6.52985C20.44 6.63096 20.4962 6.76753 20.4962 6.90984C20.4962 7.05216 20.44 7.18873 20.3399 7.28984H20.3999Z"
        fill="#ADAEC1"
      />
    </svg>
  );
};

export default PencilEditIcon;
