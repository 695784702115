import { ALERT_STATUS, TYPE_ALERT } from '../constants';

interface IUseCheckStatusAlert {
  checkStatus: (status: string) => string;
}

const useCheckStatusAlert = (): IUseCheckStatusAlert => {
  const checkStatus = (status: string): any => {
    switch (status) {
      case ALERT_STATUS.ALERT:
        return TYPE_ALERT.ALERT_PRIMARY;
      case ALERT_STATUS.ALERT_MUTED:
        return TYPE_ALERT.ALERT_MUTED;
      case ALERT_STATUS.NORMAL:
        return TYPE_ALERT.ALERT_NORMAL;
      default:
        return TYPE_ALERT.ALERT_NORMAL;
    }
  };

  return { checkStatus };
};

export default useCheckStatusAlert;
