import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import TERMINAL_API from './terminals.api';
import { MESSAGE_MASTER } from 'src/shared/constants/message';

export const getTerminalAction = createAsyncThunk<any, TerminalsStore.RequestData>(
  'terminal',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await TERMINAL_API.getTerminalAPI(data);
      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const createTerminalAction = createAsyncThunk<any, TerminalsStore.RequestData>(
  'create_terminal',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await TERMINAL_API.createTerminalAPI(data);
      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateTerminalAction = createAsyncThunk<any, TerminalsStore.RequestData>(
  'update_terminal',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await TERMINAL_API.updateTerminalAPI(data);
      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteTerminalActions = createAsyncThunk<any, TerminalsStore.RequestDeleteTerminal>(
  'delete_Terminal',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await TERMINAL_API.deleteTerminalAPI(id);
      callback();
      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
