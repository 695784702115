import React from 'react';

const SettingsV2Icon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M28.625 15.375V16.75C28.625 17.5 28.125 17.9999 27.5 18.1249L25.875 18.375C25.625 19.375 25.25 20.3749 24.625 21.2499L25.625 22.6249C26 23.1249 26 23.9999 25.5 24.4999L24.5 25.5C24 26 23.25 25.9999 22.625 25.6249L21.25 24.625C20.375 25.125 19.375 25.625 18.375 25.875L18.125 27.4999C18 28.1249 17.375 28.625 16.75 28.625H15.375C14.625 28.625 14.125 28.1249 14 27.4999L13.75 25.875C12.75 25.625 11.75 25.25 10.875 24.625L9.5 25.6249C9 25.9999 8.125 26 7.625 25.5L6.625 24.4999C6.125 23.9999 6.125 23.2499 6.5 22.6249L7.5 21.2499C7 20.3749 6.5 19.375 6.25 18.375L4.625 18.1249C4 17.9999 3.5 17.375 3.5 16.75V15.375C3.5 14.625 4 14.125 4.625 14L6.25 13.7499C6.5 12.7499 6.875 11.75 7.5 10.875L6.5 9.49991C6.125 8.99991 6.125 8.12491 6.625 7.62491L7.625 6.625C8.125 6.125 8.875 6.12503 9.5 6.50003L10.875 7.49994C11.75 6.99994 12.75 6.49994 13.75 6.24994L14 4.62503C14.125 4.00003 14.75 3.5 15.375 3.5H16.75C17.5 3.5 18 4.00003 18.125 4.62503L18.375 6.24994C19.375 6.49994 20.375 6.87494 21.25 7.49994L22.625 6.50003C23.125 6.12503 24 6.125 24.5 6.625L25.5 7.62491C26 8.12491 26 8.87491 25.625 9.49991L24.625 10.875C25.125 11.75 25.625 12.7499 25.875 13.7499L27.5 14C28.125 14.125 28.625 14.75 28.625 15.375ZM16.125 11.75C13.75 11.75 11.75 13.75 11.75 16.125C11.75 18.5 13.75 20.5 16.125 20.5C18.5 20.5 20.5 18.5 20.5 16.125C20.5 13.75 18.5 11.75 16.125 11.75Z"
        fill="#3699FF"
      />
      <path
        d="M22.3749 16.125C22.3749 19.625 19.6249 22.375 16.1249 22.375C12.6249 22.375 9.87491 19.625 9.87491 16.125C9.87491 12.625 12.6249 9.875 16.1249 9.875C19.6249 9.875 22.3749 12.625 22.3749 16.125ZM16.1249 13.625C14.7499 13.625 13.6249 14.75 13.6249 16.125C13.6249 17.5 14.7499 18.625 16.1249 18.625C17.4999 18.625 18.6249 17.5 18.6249 16.125C18.6249 14.75 17.4999 13.625 16.1249 13.625Z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default SettingsV2Icon;
