import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const USER_MANAGEMENT_API = {
  getListUserAPI: async (params: UserManagementStore.ParamsRequestGetListUser) => {
    !params.role && delete params.role;
    const response = await new AxiosClient().get('/users', { params });

    const data = get(response, 'data', null);

    return data;
  },
  createUserAPI: async (params: UserManagementStore.ParamsUser) => {
    const getParams = {
      username: params.username,
      staffNum: params.staffNum,
      email: params.email,
      terminalId: params.terminalId,
      role: params.role,
    };
    const response = await new AxiosClient().post('/auth/create', getParams);

    const data = get(response, 'message', null);

    return data;
  },

  updateUserAPI: async (params: any) => {
    const getParams = {
      username: params.username,
      email: params.email,
      terminalId: params.terminalId,
      role: params.role,
    };

    const response = await new AxiosClient().patch(`/users/${params?.id}`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  deleteUserAPI: async (id: number) => {
    const response = await new AxiosClient().delete(`/users/${id}`);

    const data = get(response, 'message', null);

    return data;
  },
};

export default USER_MANAGEMENT_API;
