import React from 'react';

const ItemInPageIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M15.9199 0H4.07994C1.82662 0 -6.10352e-05 1.82668 -6.10352e-05 4.08V11.59C-6.10352e-05 13.8433 1.82662 15.67 4.07994 15.67H15.9199C18.1733 15.67 19.9999 13.8433 19.9999 11.59V4.08C19.9999 1.82668 18.1733 0 15.9199 0Z"
        fill="#3E3F48"
      />
      <path d="M11.1699 15.67H8.81995V19.29H11.1699V15.67Z" fill="#3E3F48" />
      <path
        d="M14.3299 20H5.66992C5.47101 20 5.28024 19.921 5.13959 19.7803C4.99894 19.6397 4.91992 19.4489 4.91992 19.25C4.91992 19.0511 4.99894 18.8603 5.13959 18.7197C5.28024 18.579 5.47101 18.5 5.66992 18.5H14.3299C14.5288 18.5 14.7196 18.579 14.8603 18.7197C15.0009 18.8603 15.0799 19.0511 15.0799 19.25C15.0799 19.4489 15.0009 19.6397 14.8603 19.7803C14.7196 19.921 14.5288 20 14.3299 20Z"
        fill="#3E3F48"
      />
      <path
        d="M-7.25638e-05 11.34V11.56C-0.0053976 12.643 0.419049 13.6839 1.18017 14.4544C1.94128 15.2249 2.97693 15.662 4.05993 15.67H15.9399C17.0229 15.662 18.0586 15.2249 18.8197 14.4544C19.5808 13.6839 20.0053 12.643 19.9999 11.56V11.34H-7.25638e-05Z"
        fill="#3E3F48"
      />
    </svg>
  );
};

export default ItemInPageIcon;
