import { Row } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const WrapperStyled = styled(Row)`
  height: 100%;
  padding: 20px 0;
  justify-content: center;

  .col-form {
    padding: 30px;
    border-radius: 10px;
    height: 100%;
    background-color: ${COLOR.BACKGROUND_SECONDARY};
    padding-bottom: 5px;

    .row-btn {
      .ant-form-item {
        margin-right: 20px;
        min-width: 130px;
        margin-bottom: 0;
      }

      .title {
        color: ${COLOR.WHITE};
        font-size: 18px;
        font-weight: 700;
      }
      .col-left {
        display: flex;
        justify-content: end;

        .btn-create {
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .row-table {
      height: calc(100% - 34px);

      .ant-table-footer {
        background-color: transparent !important;
      }
    }
  }
`;

export const ModalContentWrap = styled(Row)`
  .field-title {
    margin-top: 20px;

    span {
      color: #adaec1;
    }

    .required {
      color: #f64e60;
    }
  }

  .field-select {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;
