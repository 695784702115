import React from 'react';

const OpenSiderIcon: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4465 23.1872C29.0113 26.4824 26.4824 29.0113 23.1872 29.4465C20.9219 29.7457 18.0881 30 15 30C11.9119 30 9.07812 29.7457 6.81276 29.4465C3.51756 29.0113 0.988688 26.4824 0.553488 23.1872C0.254304 20.9219 1.04137e-06 18.0881 1.31134e-06 15C1.58131e-06 11.9119 0.254305 9.07812 0.55349 6.81275C0.98869 3.51756 3.51757 0.988685 6.81276 0.553486C9.07813 0.254301 11.9119 -1.58131e-06 15 -1.31134e-06C18.0881 -1.04137e-06 20.9219 0.254302 23.1872 0.553487C26.4824 0.988687 29.0113 3.51756 29.4465 6.81276C29.7457 9.07812 30 11.9119 30 15C30 18.0881 29.7457 20.9219 29.4465 23.1872Z"
        fill="#222329"
      />
      <path
        d="M15 14.25H8.79435C8.46774 14.25 8.25 14.475 8.25 14.8125C8.25 15.15 8.46774 15.375 8.79435 15.375H15V14.25Z"
        fill="#3699FF"
      />
      <path
        d="M15.8612 17.85C15.5496 18.1757 15 17.9551 15 17.5043V11.7457C15 11.2949 15.5496 11.0743 15.8612 11.4L18.602 14.2641C18.7993 14.4703 18.7993 14.7797 18.602 14.9859L15.8612 17.85Z"
        fill="#3699FF"
      />
      <rect opacity="0.3" x="21" y="9" width="1.125" height="11.25" rx="0.5625" fill="#3699FF" />
    </svg>
  );
};

export default OpenSiderIcon;
