import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { handleExportFile } from 'src/shared/utils/common';
import { UserManagement } from 'src/interfaces/pages/userManagement';
import {
  downloadTemplateAction,
  downloadFileUsersAction,
  uploadFileUserAction,
} from 'src/stores/screens/publicScreens/auth/auth.action';
import { TYPE_SOCKET, ROUTERS } from 'src/shared/constants';
import { getListUserAction } from 'src/stores/screens/privateScreens/userManagement/userManagement.action';
import { MESSAGE_SUCCEESS } from 'src/shared/constants/message';
import socket from 'src/configs/socketIO/socketIO';

const MIN_RANDOM = 4;
const MAX_RANDOM = 7;
const DELAY_PROGRESS = 93;

function useInitUploadFile({ setCurrentPage }): UserManagement.UseInitUploadFile {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { meInfo } = useAppSelector(state => state.auth);
  const { roleId, searchUser, terminalId } = useAppSelector(state => state.userManagement);

  const [isShowModalDownloadFile, setIsShowModalDownloadFile] = useState(false);
  const [isShowModalUploadFile, setIsShowModalUploadFile] = useState(false);
  const [isShowModalTableValidate, setIsShowModalTableValidate] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [file, setFile] = useState<any>();
  const [dataTableValidate, setDataTableValidate] = useState<any>([]);
  const [secondProgress, setSecondProgress] = useState<any>(null);
  const [percentProgress, setPercentProgress] = useState(0);
  const [isShowProgress, setIsShowProgress] = useState<boolean>(false);
  const [isPercentProgressSuccess, setPercentProgressSuccess] = useState<boolean>(false);
  const [codeTitleTable, setCodeTitleTable] = useState<any>(0);

  const handleDownloadTemplate = async (): Promise<void> => {
    setIsFileLoading(true);
    const response = await dispatch(downloadTemplateAction({ data: {} }));
    if (response) {
      handleExportFile(response?.payload);
      setIsFileLoading(false);
    }
  };

  const handleClickUploadFile = (e): void => {
    setFile(e.target.files[0]);
  };

  const handleDownloadFile = async (data): Promise<void> => {
    setIsFileLoading(true);
    const response = await dispatch(downloadFileUsersAction({ data }));
    if (response?.payload) {
      handleExportFile(response.payload);
      setIsShowModalDownloadFile(false);
      setIsFileLoading(false);
      return;
    }
    setIsFileLoading(false);
  };

  const handleSubmitUploadFile = async (): Promise<void> => {
    setIsFileLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const res = await dispatch(
      uploadFileUserAction({
        data: formData,
        callback: () => {
          setIsShowModalUploadFile(false);
          setIsFileLoading(false);
          void getListUser();
          setCurrentPage(1);
        },
        callbackError: () => {
          setIsShowModalUploadFile(false);
          setIsFileLoading(false);
        },
      }),
    );

    if (res?.payload) {
      setIsFileLoading(false);
      setDataTableValidate(res?.payload?.message);
      setCodeTitleTable(res?.payload?.code);

      if (res?.payload.code === 31) {
        setIsShowProgress(true);
        setSecondProgress(res?.payload?.second);
      }

      if (res?.payload.code === 26) {
        setIsShowModalTableValidate(true);
        setIsShowModalUploadFile(false);
      }
    }
  };

  const handleSetProgressSuccess = (value: any): void => {
    if (value?.id === meInfo?.id) {
      setSecondProgress(null);
      setPercentProgressSuccess(true);
      socket().disconnect();
    }
  };

  useEffect(() => {
    if (isPercentProgressSuccess && pathname === ROUTERS.USER_MANAGEMENT.PATH) {
      setPercentProgress(100);

      setTimeout(() => {
        notification.success({ message: MESSAGE_SUCCEESS.PROGRESS_UPLOAD_FILE_SUCCESSFULLY });
        setIsShowModalUploadFile(false);
        setIsShowProgress(false);
        setPercentProgress(0);
        setPercentProgressSuccess(false);
      }, 1000);
    }

    if (isPercentProgressSuccess && dataTableValidate?.length > 0) {
      setIsShowModalTableValidate(true);
      setPercentProgressSuccess(false);
    }
  }, [pathname, isPercentProgressSuccess, dataTableValidate]);

  useEffect(() => {
    let interval: any;
    if (secondProgress !== null && secondProgress !== 0) {
      interval = setInterval(() => {
        if (percentProgress < DELAY_PROGRESS) {
          setPercentProgress(percentProgress + Math.floor(Math.random() * (MAX_RANDOM - MIN_RANDOM) + MIN_RANDOM));
        }
      }, secondProgress * 1000);

      if (percentProgress > DELAY_PROGRESS) {
        clearInterval(interval);
      }
    }
    if (secondProgress === 0) {
      setTimeout(() => {
        setPercentProgress(100);
        setIsShowModalUploadFile(false);
        setSecondProgress(null);
        setPercentProgressSuccess(true);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [secondProgress, percentProgress]);

  useEffect(() => {
    socket().on(TYPE_SOCKET.UPLOAD_FILE, value => {
      handleSetProgressSuccess(value);
    });
  }, []);

  const getListUser = async (): Promise<void> => {
    const data = {
      page: 1,
      search: searchUser,
      terminalId,
      role: roleId,
      size: 10,
    };
    await dispatch(getListUserAction({ data }));
  };

  return {
    isFileLoading,
    isShowModalDownloadFile,
    isShowModalUploadFile,
    isShowModalTableValidate,
    dataTableValidate,
    percentProgress,
    isShowProgress,
    codeTitleTable,
    setIsShowModalDownloadFile,
    setIsShowModalUploadFile,
    setIsShowModalTableValidate,
    handleDownloadFile,
    handleDownloadTemplate,
    handleClickUploadFile,
    handleSubmitUploadFile,
  };
}

export default useInitUploadFile;
