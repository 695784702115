import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAppDispatch } from 'src/stores';
import { loginAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import { ROUTERS } from 'src/shared/constants/routers';

import { LOCAL_STORAGE_KEY, STORAGE_KEY, USER_ROLE } from 'src/shared/constants';
import useWindowSize from 'src/shared/hooks/useWindowSize';

interface IUseInit {
  handleLogin: (param: any) => Promise<void>;
  loginSuccess: (param: any) => void;
}

const useInit = (): IUseInit => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();

  const loginSuccess = (userInfor: any): void => {
    Cookies.set(STORAGE_KEY.ACCESS_TOKEN, userInfor.token, { expires: STORAGE_KEY.EXPIRE_TOKEN });
    Cookies.set(STORAGE_KEY.ROLE, userInfor.role, { expires: STORAGE_KEY.EXPIRE_TOKEN });
    const role = userInfor.role;

    const isGeneralUser = role === USER_ROLE.GENERAL_USER;

    if (isGeneralUser || isMobile) {
      navigate(ROUTERS.SUBMIT_STREAMING.PATH);
      localStorage.setItem(LOCAL_STORAGE_KEY.IS_MOBILE, String(isMobile));
    } else {
      navigate(ROUTERS.DASHBOARD.PATH);

      window.open('dashboard-second', 'Fred', 'height=700,width=1024');
    }
    //
    Cookies.remove(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
  };

  const handleLogin = async (value): Promise<void> => {
    const user = await dispatch(
      loginAction({
        data: value,
      }),
    );

    const userInfor = user?.payload;
    const isResetPassword = userInfor.isResetPassword;
    if (isResetPassword) {
      isResetPassword === 1 &&
        Cookies.set(STORAGE_KEY.ACCESS_TOKEN_REGISTER, userInfor.token, { expires: STORAGE_KEY.EXPIRE_TOKEN });
      navigate({
        pathname: ROUTERS.REMIND_CHANGE_PASSWORD.PATH,
        search: `?username=${userInfor.username}&staffNum=${userInfor.staffNum}&type=${isResetPassword}&diffDays=${userInfor.diffDays}&email=${userInfor.email}&role=${userInfor.role}`,
      });
    } else {
      loginSuccess(userInfor);
    }
  };

  return { handleLogin, loginSuccess };
};

export default useInit;
