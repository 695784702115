import { useState, useEffect } from 'react';

function useCheckOrientation(): any {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkOrientation = (): void => {
      const mediaQuery = window.matchMedia('(orientation: landscape)');
      setIsLandscape(mediaQuery.matches);
    };

    checkOrientation();

    window.addEventListener('resize', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  return { isLandscape };
}
export default useCheckOrientation;
