export const LANGUAGE = {
  EN: 'en',
  JP: 'jp',
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: 'language',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  THEME: 'theme',
  ITEM_OF_PAGE: 'item_of_page',
  TYPE_DISPLAY_SCREEN: 'type_display_screen',
  SETTING_MONITOR: 'setting_monitor',
  SIZE_PAGE_DASHBOARD2_SWITCH: 'size_page_dashboard_2',
  TERMINAL_ID: 'terminal_id',
  ORDER: 'order',
  SEARCH: 'search',
  IS_MOBILE: 'is_mobile',
  QUANTITY_DISPLAY_SECOND: 'quality_display_second',
  PAGE_DASHBOARD_LIST: 'page_dashboard_list',
  IS_LOGOUT: 'is_logout',
};

export const SYSTEM_ERROR = {
  NETWORK_ERROR: {
    STATUS: 'Network Error',
    MESSAGE: 'Request has been cancelled',
  },

  TIMEOUT_ERROR: {
    STATUS: 'Request Timeout',
    MESSAGE: 'The Request Has Timed Out',
  },
};

export const HTTP_STATUS = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  PAYLOAD_TOO_LARGE: 413,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const THEME_TYPE = {
  LIGHT: 'light',
  DARK: 'dark',
  TYPE: 'THEME_TYPE',
};

export const ALERT_STATUS = {
  NORMAL: '0',
  ALERT: '2',
  ALERT_MUTED: '1',
};

export const USER_ROLE = {
  ADMIN: '3',
  ADVANCE_MONITOR: '2',
  GENERAL_MONITOR: '1',
  GENERAL_USER: '0',
};

export const SEARCH_ROLE_SELECTS = [
  {
    value: '',
    label: '全て',
  },
  {
    value: USER_ROLE.ADMIN,
    label: '管理者',
  },
  {
    value: USER_ROLE.ADVANCE_MONITOR,
    label: 'モニタ（MCT)',
  },
  {
    value: USER_ROLE.GENERAL_MONITOR,
    label: 'モニタ（KIOSK用)',
  },
  {
    value: USER_ROLE.GENERAL_USER,
    label: '作業員',
  },
];

export const USER_ROLE_SELECTS = [
  {
    value: USER_ROLE.ADMIN,
    label: '管理者',
  },
  {
    value: USER_ROLE.ADVANCE_MONITOR,
    label: 'モニタ（MCT)',
  },
  {
    value: USER_ROLE.GENERAL_MONITOR,
    label: 'モニタ（KIOSK用)',
  },
  {
    value: USER_ROLE.GENERAL_USER,
    label: '作業員',
  },
];

export const TYPE_ALERT = {
  ALERT_PRIMARY: 'alert-primary',
  ALERT_MUTED: 'alert-muted',
  ALERT_NORMAL: 'alert-normal',
};

export const CAMERA_STATUS = {
  ERROR: '0',
  STREAMING: '1',
};

export const TYPE_SOCKET = {
  ALERT_STREAM: 'stream.alert',
  DELETE_STREAM: 'stream.delete',
  ZOOM_STREAM: 'stream.zoom',
  CREATE_STREAM: 'stream.create',
  LOGOUT: 'auth.logout',
  MONITOR_CLOSE: 'monitor.switch',
  SETTING_CHANGE: 'setting.change',
  SWITCH_PLAY_PAUSE: 'playPause.switch',
  DASHBOARD_SEARCH: 'dashboardSecond.search',
  PIN_STREAM: 'stream.pin',
  CHANGE_THEME: 'monitor.mode',
  MONITOR_RELOAD: 'monitor.reload',
  UPLOAD_FILE: 'auth.done',
};

export const TYPE_CAMERA = {
  HUMAN: {
    LABEL: '作業員',
    VALUE: '0',
  },
  CAR: {
    LABEL: '車載',
    VALUE: '1',
  },
};

// 1s request to safie
export const TIME_REQUEST_THUMBNAIL = 1000;

export const TYPE_SELECT_ITEM_OF_PAGE = [
  { label: '6', value: 6 },
  { label: '8', value: 8 },
  { label: '10', value: 10 },
  { label: '12', value: 12 },
  { label: '18', value: 18 },
];

export const ITEM_OF_PAGE = {
  6: '6',
  8: '8',
  10: '10',
  12: '12',
  18: '18',
};

export const TYPE_DISPLAY_SCREEN = {
  TWO_SCREEN: 'two_screen',
  ONE_SCREEN: 'one_screen',
};

export const PROPERTY_TABLE_VALIDATE = {
  NO: 'No',
  USER_NAME: '名前',
  USER_NUMBER: '社員番号',
  EMAIL: 'メールアドレス',
  TERMINAL_ID: '拠点ID',
  ROLE: '権限',
  ACTION: '削除フラグ',
};

export const VALUE_EMPTY = {
  value: '',
  label: 'なし',
};

export const MAX_CARD_DISPLAY = 4;

export const OPTION_SELECT_TYPE_SORACOM = [
  { label: 'なし', value: '0' },
  { label: 'アラート通知', value: '1' },
];
