import { createSlice } from '@reduxjs/toolkit';
import { getSpotsAction, createSpotAction, updateSpotAction, deleteSpotActions } from './spots.action';

const initialState: SpotsStore.SpotsReducer = {
  isLoadingSpots: false,
  isUpdated: false,
  spots: [],
  pageSpots: 1,
  totalPageSpots: 0,
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'Spots_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSpotsAction.pending, (state, action) => {
        state.isLoadingSpots = true;
      })
      .addCase(getSpotsAction.fulfilled, (state, action) => {
        state.isLoadingSpots = false;
        state.isUpdated = false;
        state.pageSpots = action?.payload?.response?.page;
        state.totalPageSpots = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.spots = [...state.spots, ...action?.payload?.response?.rows];
        } else {
          state.spots = action?.payload?.response?.rows;
        }
      })
      .addCase(getSpotsAction.rejected, (state, action) => {
        state.isLoadingSpots = false;
        state.isUpdated = false;
        state.error = action.error;
      })
      .addCase(createSpotAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(updateSpotAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(deleteSpotActions.fulfilled, (state, action) => {
        state.isUpdated = true;
      });
  },
});

export { reducer };
export default actions;
