import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDataVideoAction,
  getManeuverSettingAction,
  maneuverSettingsAction,
  updatePostionStreamAction,
  fetchAllDataVideoAction,
} from './dashboard.action';

const initialState: DashboardStore.DashboardReducer = {
  isLoading: false,
  isLoadingFetchAll: false,
  error: null,
  dataVideo: [],
  dataVideoAll: [],
  dataSetting: null,
  page: 1,
  totalItem: 1,
  isLoadingSetting: false,
};

const { actions, reducer } = createSlice({
  name: 'dashboard_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDataVideoAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchDataVideoAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataVideo = action.payload?.rows;
        state.totalItem = action.payload?.total;
        state.page = action.payload?.page;
      })
      .addCase(fetchDataVideoAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getManeuverSettingAction.pending, (state, action) => {
        state.isLoadingSetting = true;
      })
      .addCase(getManeuverSettingAction.fulfilled, (state, action) => {
        state.isLoadingSetting = false;
        state.dataSetting = action.payload;
      })
      .addCase(getManeuverSettingAction.rejected, (state, action) => {
        state.isLoadingSetting = false;
        state.error = action.error;
      })
      .addCase(maneuverSettingsAction.pending, (state, action) => {
        state.isLoadingSetting = true;
      })
      .addCase(maneuverSettingsAction.fulfilled, (state, action) => {
        state.isLoadingSetting = false;
      })
      .addCase(maneuverSettingsAction.rejected, (state, action) => {
        state.isLoadingSetting = false;
        state.error = action.error;
      })
      .addCase(updatePostionStreamAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePostionStreamAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePostionStreamAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(fetchAllDataVideoAction.pending, (state, action) => {
        state.isLoadingFetchAll = true;
      })
      .addCase(fetchAllDataVideoAction.fulfilled, (state, action) => {
        state.isLoadingFetchAll = false;
        state.dataVideoAll = action.payload?.rows;
      })
      .addCase(fetchAllDataVideoAction.rejected, (state, action) => {
        state.isLoadingFetchAll = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
