import React from 'react';

const DragDropV2Icon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M15.51 2.12H6.81996C4.27393 2.12 2.20996 4.18396 2.20996 6.73V15.42C2.20996 17.966 4.27393 20.03 6.81996 20.03H15.51C18.056 20.03 20.12 17.966 20.12 15.42V6.73C20.12 4.18396 18.056 2.12 15.51 2.12Z"
        fill="#3699FF"
      />
      <path
        d="M15.4899 13.47L20.9999 15.21C21.3056 15.2894 21.577 15.4663 21.7729 15.714C21.9688 15.9617 22.0785 16.2666 22.0853 16.5823C22.092 16.8981 21.9956 17.2074 21.8105 17.4633C21.6254 17.7191 21.3619 17.9076 21.0599 18L19.4899 18.57C19.2919 18.6388 19.1123 18.7521 18.9649 18.9012C18.8176 19.0503 18.7064 19.2312 18.6399 19.43L18.0599 21C17.9634 21.2883 17.7787 21.539 17.532 21.7166C17.2853 21.8942 16.9889 21.9897 16.6849 21.9897C16.3809 21.9897 16.0846 21.8942 15.8379 21.7166C15.5911 21.539 15.4064 21.2883 15.3099 21L13.5899 15.38C13.5024 15.115 13.4899 14.831 13.5541 14.5593C13.6182 14.2877 13.7563 14.0392 13.9531 13.8413C14.15 13.6435 14.3978 13.504 14.6691 13.4385C14.9403 13.3729 15.2245 13.3839 15.4899 13.47Z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default DragDropV2Icon;
