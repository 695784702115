import * as yup from 'yup';

import { MESSAGE_REGISTER_VALIDATE } from 'src/shared/constants/message';
import { REGEX_PASSWORD } from 'src/shared/constants/regex';

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(MESSAGE_REGISTER_VALIDATE.REMIND_PASSWORD),
  newPassword: yup
    .string()
    .trim()
    .required(MESSAGE_REGISTER_VALIDATE.PASSWORD_NEW_IS_REQUIRED)
    .test('oneOfRequired', MESSAGE_REGISTER_VALIDATE.PASSWORD_NEW_VALIDATE, (value: any) => {
      return REGEX_PASSWORD.test(value);
    }),
  confirmPassword: yup
    .string()
    .trim()
    .required(MESSAGE_REGISTER_VALIDATE.PASSWORD_CONFIRM_IS_REQUIRED)
    .oneOf([yup.ref('newPassword')], MESSAGE_REGISTER_VALIDATE.PASSWORD_CONFIRM_FAIL),
});
