import React from 'react';

const WarningModalIcon: React.FC = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="25" stroke="#D9AA00" strokeWidth="2" />
      <path
        d="M26.25 30.66C26.5152 30.66 26.7696 30.5546 26.9571 30.3671C27.1446 30.1796 27.25 29.9252 27.25 29.66V20C27.25 19.7348 27.1446 19.4804 26.9571 19.2929C26.7696 19.1054 26.5152 19 26.25 19C25.9848 19 25.7304 19.1054 25.5429 19.2929C25.3554 19.4804 25.25 19.7348 25.25 20V29.66C25.25 29.9252 25.3554 30.1796 25.5429 30.3671C25.7304 30.5546 25.9848 30.66 26.25 30.66Z"
        fill="#D9AA00"
      />
      <path
        d="M26.25 34.5205C26.9404 34.5205 27.5 33.9609 27.5 33.2705C27.5 32.5802 26.9404 32.0205 26.25 32.0205C25.5596 32.0205 25 32.5802 25 33.2705C25 33.9609 25.5596 34.5205 26.25 34.5205Z"
        fill="#D9AA00"
      />
    </svg>
  );
};

export default WarningModalIcon;
