import { Modal } from 'antd';
import styled from 'styled-components';

export const WrapperStyled = styled(Modal)`
  .ant-modal-content {
    width: 100%;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    .ant-btn {
      &:first-child {
        color: #adaec1;
      }
    }
  }
`;
