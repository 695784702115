import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import SUBMIT_STREAMING_API from './submitStream.api';
import { CODE_RESPONSE_API } from 'src/shared/constants';

export const submitStreamAction = createAsyncThunk<any, SubmitStreamingStore.RequestSubmitStream>(
  'submit_stream',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SUBMIT_STREAMING_API.submitStreamAPI(data);
      callback();

      return response;
    } catch (err: any) {
      if (err?.code === CODE_RESPONSE_API.NOT_EXITSED_CAMERA.CODE) {
        notification.error({ message: t(`errorMsg.submit_stream.${err?.code}`) });
      } else notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getStreamDetailActions = createAsyncThunk<any>('get_stream', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await SUBMIT_STREAMING_API.getStreamDetailAPI();

    return response;
  } catch (err: any) {
    notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

    rejectWithValue(err?.message);
  }
});

export const alertStreamActions = createAsyncThunk<any, SubmitStreamingStore.AlertStreamRequest>(
  'alert_stream',
  async ({ id, typeAlert, isZoomFull, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SUBMIT_STREAMING_API.alertStreamAPI(id, typeAlert, isZoomFull);
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteStreamActions = createAsyncThunk<any, SubmitStreamingStore.DeleteStreamRequest>(
  'delete_stream',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SUBMIT_STREAMING_API.deleteStreamAPI(id);
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
