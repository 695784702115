import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { getCameraAction, getOneClickAction } from 'src/stores/screens/privateScreens/common/common.action';
import { Labels } from 'src/interfaces/pages/labels';
import { TYPE_CAMERA } from 'src/shared/constants/config';

function useInitModalForm(terminalId): Labels.UseInitModalForm {
  const dispatch = useAppDispatch();

  const { cameras, oneClick } = useAppSelector(state => state.common);
  const { meInfo } = useAppSelector(state => state.auth);

  const handleSetTerminalId = async (params: CommonStore.ParamsGetList): Promise<void> => {
    void fetchDataCameras({ ...params, terminalId: params.terminalId ?? meInfo?.terminalId });
    void fetchDataOneClick({ ...params, terminalId: params.terminalId ?? meInfo?.terminalId });
  };

  useEffect(() => {
    void fetchDataCameras({ terminalId: terminalId ?? meInfo?.terminalId, haveLabel: true });
    void fetchDataOneClick({ terminalId: terminalId ?? meInfo?.terminalId, haveLabel: true });
  }, [terminalId]);

  const fetchDataCameras = async (params: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(getCameraAction({ data: { ...params } }));
  };

  const fetchDataOneClick = async (params: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(getOneClickAction({ data: { ...params } }));
  };

  const cameraCarSelects = cameras
    ?.filter(item => item?.type === TYPE_CAMERA.CAR.VALUE)
    ?.map(item => {
      return {
        label: item.serialNum,
        value: item.id,
      };
    });

  const cameraHumanSelects = cameras
    ?.filter(item => item?.type === TYPE_CAMERA.HUMAN.VALUE)
    ?.map(item => {
      return {
        label: item.serialNum,
        value: item.id,
      };
    });

  const buttonSelects = oneClick?.map(item => {
    return {
      label: item.serialNum,
      value: item.id,
    };
  });

  return {
    cameraCarSelects,
    cameraHumanSelects,
    buttonSelects,
    handleSetTerminalId,
  };
}

export default useInitModalForm;
