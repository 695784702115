import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import USER_MANAGEMENT_API from './userManagement.api';
import { MESSAGE_MASTER } from 'src/shared/constants/message';

export const updateParamSearchUserActions = createAsyncThunk<any, UserManagementStore.RequestData>(
  'param_search_User',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      callback();
      return data;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getListUserAction = createAsyncThunk<any, UserManagementStore.RequestGetListUser>(
  'user_management',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await USER_MANAGEMENT_API.getListUserAPI(data);
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getTotalUserAction = createAsyncThunk<any, UserManagementStore.RequestGetListUser>(
  'total_user',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await USER_MANAGEMENT_API.getListUserAPI(data);

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const createUserAction = createAsyncThunk<any, UserManagementStore.RequestData>(
  'create_user_management',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      await USER_MANAGEMENT_API.createUserAPI(data);
      callback();
      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });
      return data;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateUserAction = createAsyncThunk<any, UserManagementStore.RequestData>(
  'update_user',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await USER_MANAGEMENT_API.updateUserAPI(data);
      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteUserActions = createAsyncThunk<any, UserManagementStore.RequestDeleteUser>(
  'delete_User',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await USER_MANAGEMENT_API.deleteUserAPI(id);
      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });
      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
