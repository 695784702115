import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { SYSTEM_ERROR, HTTP_STATUS } from 'src/shared/constants/config';
import { STORAGE_KEY } from 'src/shared/constants/storageKey';
import { ROUTERS, LOCAL_STORAGE_KEY } from 'src/shared/constants';

export class AxiosClient {
  instance: AxiosInstance;

  token: string = Cookies.get(STORAGE_KEY.ACCESS_TOKEN) ?? '';

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_VERSION}`,
      headers: {
        Authorization: this.getToken(),
      },
      timeout: 10000,
      timeoutErrorMessage: SYSTEM_ERROR.TIMEOUT_ERROR.MESSAGE,
    });

    this._initializeResponseInterceptor();
  }

  getToken(): string {
    return `Bearer ${this.token}`;
  }

  setToken(token: string): void {
    this.token = token;
  }

  _initializeResponseInterceptor = (): void => {
    this.instance.interceptors.request.use(this._handleRequestSuccess, this._handleRequestError);
    this.instance.interceptors.response.use(this._handleResponseSuccess, this._handleResponseError);
  };

  _handleRequestSuccess = (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    return config;
  };

  _handleRequestError = (error: AxiosError): unknown => {
    // eslint-disable-next-line
    console.error(`[request error] [${JSON.stringify(error)}]`);
    if (error.response) {
      return error?.response?.data;
    }

    return Promise.reject(error);
  };

  _handleResponseSuccess = (response: AxiosResponse): any => {
    return {
      data: response?.data?.data,
      headers: response?.headers,
      dataFile: response?.data,
    };
  };

  _handleResponseError = async (error: AxiosError & Error): Promise<never> => {
    const statusCode = error?.response && error?.response?.status;

    switch (statusCode) {
      case HTTP_STATUS.UNAUTHORIZED:
        window.location.href = ROUTERS.LOGIN.PATH;
        Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
        Cookies.remove(STORAGE_KEY.ROLE);
        Cookies.remove(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
        localStorage.removeItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);
        localStorage.removeItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN);
        localStorage.removeItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
        localStorage.removeItem(LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH);
        localStorage.removeItem(LOCAL_STORAGE_KEY.SETTING_MONITOR);
        localStorage.removeItem(LOCAL_STORAGE_KEY.ORDER);
        localStorage.removeItem(LOCAL_STORAGE_KEY.SEARCH);
        localStorage.removeItem(LOCAL_STORAGE_KEY.IS_MOBILE);
        break;
      case HTTP_STATUS.FORBIDEN:
        window.location.href = ROUTERS.ERROR.PATH;
        break;
      case HTTP_STATUS.NOT_FOUND:
        break;
      case HTTP_STATUS.INTERNAL_SERVER_ERROR:
      default:
        break;
    }
    return await Promise.reject(error?.response?.data);
  };

  async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
    return await this.instance.request<T, R>(config);
  }

  async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return await this.instance.get<T, R>(url, config);
  }

  async delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return await this.instance.delete<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return await this.instance.post<T, R>(url, data, config);
  }

  async put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return await this.instance.put<T, R>(url, data, config);
  }

  async patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return await this.instance.patch<T, R>(url, data, config);
  }
}
