import React from 'react';

const UserManagementIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M12.001 11.92C14.7403 11.92 16.961 9.69933 16.961 6.96C16.961 4.22067 14.7403 2 12.001 2C9.26168 2 7.04102 4.22067 7.04102 6.96C7.04102 9.69933 9.26168 11.92 12.001 11.92Z"
          fill="#3E3F48"
        />
      </g>
      <path
        d="M19.7003 21.9999C19.8637 22.0091 20.0273 21.9831 20.1798 21.9235C20.3323 21.8639 20.4702 21.7721 20.584 21.6545C20.6979 21.5368 20.7851 21.396 20.8396 21.2416C20.8942 21.0873 20.9149 20.9229 20.9003 20.7599C20.2603 16.9999 16.5203 14.1099 12.0003 14.1099C7.48026 14.1099 3.74026 16.9999 3.10026 20.7599C3.08563 20.9229 3.10631 21.0873 3.16088 21.2416C3.21545 21.396 3.30263 21.5368 3.4165 21.6545C3.53036 21.7721 3.66823 21.8639 3.82074 21.9235C3.97324 21.9831 4.13679 22.0091 4.30026 21.9999H19.7003Z"
        fill="#3E3F48"
      />
    </svg>
  );
};

export default UserManagementIcon;
