import { useFormik } from 'formik';

import { changePasswordSchema } from './shcema';
import { ChangePassword } from 'src/interfaces/pages/changePassword';
import { useAppDispatch } from 'src/stores';
import { changePasswordAction } from 'src/stores/screens/publicScreens/auth/auth.action';

const UseFormChangePassword = ({ setIsShowModal }): ChangePassword.useForm => {
  const dispatch = useAppDispatch();

  const initialValues: ChangePassword.initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const ChangePasswordSuccess = (): void => {
    setIsShowModal(false);
  };

  const handleChangePassword = async (value): Promise<void> => {
    const data = {
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
    };
    await dispatch(
      changePasswordAction({
        data,
        callback: ChangePasswordSuccess,
      }),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: changePasswordSchema,
    initialValues,
    onSubmit: async value => {
      await handleChangePassword(value);
    },
  });

  return { formik };
};

export default UseFormChangePassword;
