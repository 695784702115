import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';

import { ButtonField, InputField } from 'src/shared/components/form';
import TableCard from 'src/shared/components/table/Table';
import useInit from './hooks/useInit';
import { PlusIcon, SearchIcon } from 'src/assets/icons';
import ModalForm from './ModalForm';
import useDebouncedApiCall from 'src/shared/hooks/useDebounce';
import { WrapperStyled } from './styled';

const { Title } = Typography;

const TIMEOUT_DEBOUNCE = 700;

const PlaneNumber: React.FC = () => {
  const {
    columns,
    dataSource,
    totalPagePlaneNumber,
    isLoading,
    formik,
    dataEdit,
    isShowModal,
    initialLoad,
    currentPage,
    setIsShowModal,
    fetchDataAirPlane,
    setDataEdit,
    setCurrentPage,
  } = useInit();

  const { getFieldProps, setFieldValue } = formik;

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const debouncedSearchTerm = useDebouncedApiCall(searchTerm, TIMEOUT_DEBOUNCE);

  useEffect(() => {
    if (!initialLoad) {
      void fetchDataAirPlane({ page: currentPage, search: searchTerm });
    }
  }, [debouncedSearchTerm]);

  return (
    <React.Fragment>
      <WrapperStyled className="plane-number-screen">
        <Col span={20} className="col-form">
          <Row className="row-btn">
            <Col span={8}>
              <Title level={4} className="title color-title-table-light-theme">
                機番
              </Title>
            </Col>

            <Col span={16} className="col-left">
              <InputField
                placeholder="検索"
                inputProps={{
                  prefix: <Icon component={SearchIcon} />,
                }}
                field={getFieldProps('name')}
                setFieldValue={setFieldValue}
                callbackOnChange={e => setSearchTerm(e.target.value)}
              />

              <ButtonField
                content={<Icon component={PlusIcon} />}
                type="primary"
                className="btn-create"
                onClick={() => {
                  setIsShowModal(true);
                  setDataEdit(null);
                }}
              />
            </Col>
          </Row>

          <Row className="row-table">
            <TableCard
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              rowKey="id"
              totalPages={totalPagePlaneNumber}
              currentPage={currentPage}
              onChangePage={page => {
                setCurrentPage(page);
                void fetchDataAirPlane({ page });
              }}
            />
          </Row>
        </Col>
      </WrapperStyled>

      <ModalForm dataEdit={dataEdit} isOpen={isShowModal} setIsShowModal={setIsShowModal} />
    </React.Fragment>
  );
};

export default PlaneNumber;
