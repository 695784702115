import React from 'react';

const MutedIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_107_2079)">
        <path
          opacity="0.45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75207 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
          fill="white"
        />
        <path
          d="M19.2936 20.0006L22.1466 22.8546C22.1931 22.9011 22.2483 22.938 22.309 22.9632C22.3698 22.9883 22.4349 23.0013 22.5006 23.0013C22.5664 23.0013 22.6315 22.9883 22.6922 22.9632C22.753 22.938 22.8081 22.9011 22.8546 22.8546C22.9011 22.8081 22.938 22.753 22.9632 22.6922C22.9883 22.6315 23.0013 22.5664 23.0013 22.5006C23.0013 22.4349 22.9883 22.3698 22.9632 22.309C22.938 22.2483 22.9011 22.1931 22.8546 22.1466L9.85463 9.14663C9.80814 9.10014 9.75295 9.06327 9.69222 9.03811C9.63148 9.01295 9.56638 9 9.50063 9C9.43489 9 9.36979 9.01295 9.30905 9.03811C9.24831 9.06327 9.19312 9.10014 9.14663 9.14663C9.10014 9.19312 9.06327 9.24831 9.03811 9.30905C9.01295 9.36979 9 9.43489 9 9.50063C9 9.56638 9.01295 9.63148 9.03811 9.69222C9.06327 9.75295 9.10014 9.80814 9.14663 9.85463L11.9116 12.6186C11.6399 13.2088 11.4996 13.8509 11.5006 14.5006V16.9016L10.5366 19.3156C10.5064 19.3914 10.4953 19.4735 10.5041 19.5546C10.5129 19.6357 10.5414 19.7135 10.5872 19.781C10.6329 19.8486 10.6945 19.9039 10.7666 19.9422C10.8387 19.9805 10.919 20.0005 11.0006 20.0006H19.2936ZM21.4646 19.3156L21.4806 19.3586L13.1446 11.0236C13.8034 10.4826 14.6024 10.1398 15.4485 10.0352C16.2945 9.93057 17.1529 10.0684 17.9237 10.4328C18.6944 10.7971 19.3458 11.3728 19.802 12.093C20.2583 12.8132 20.5005 13.6481 20.5006 14.5006V16.9016L21.4646 19.3156ZM16.0006 22.5006C15.5572 22.5007 15.1262 22.3535 14.7756 22.0819C14.4249 21.8104 14.1745 21.43 14.0636 21.0006H17.9376C17.8268 21.43 17.5763 21.8104 17.2257 22.0819C16.875 22.3535 16.4441 22.5007 16.0006 22.5006Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_2079">
          <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MutedIcon;
