import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { MenuProps, Modal, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import {
  DashboardActiveIcon,
  DashboardIcon,
  MasterManagementActiveIcon,
  MasterManagementIcon,
  SubmitStreamActiveIcon,
  SubmitStreamIcon,
  SystemSettingsActiveIcon,
  UserManagementActiveIcon,
  UserManagementIcon,
  WarningModalIcon,
} from 'src/assets/icons';
import { LOCAL_STORAGE_KEY, ROUTERS, TYPE_SOCKET } from 'src/shared/constants';
import useRole from 'src/shared/hooks/useRole';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import SystemSettingsIcon from 'src/assets/icons/sytem-settings-icon';
import { Layout } from 'src/interfaces/common/layout';
import {
  fetchDataVideoAction,
  getManeuverSettingAction,
} from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';
import { paramSearchStreamAction } from 'src/stores/screens/privateScreens/paramSearchStream/paramSearchStream.action';
import { logoutAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import useHandleLocalStorage from 'src/shared/hooks/useHandleLocalStorage';
import { schemaSearch } from './schema';
import socket from 'src/configs/socketIO/socketIO';
import { getDataDashboardSecondAction } from 'src/stores/screens/privateScreens/dashboardSecond/dashboardSecond.action';

const { Text } = Typography;

const { confirm } = Modal;

function useInit(): Layout.LayoutPrivate {
  const navigate = useNavigate();

  const { isAdmin, isAdvanceAdmin, isGeneralUser, isGeneralMonitor } = useRole();

  const { terminals } = useAppSelector(state => state.terminals);

  const dataSettingMonitor = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '{}');

  const [itemSelectedMenu, setItemSelectedMenu] = useState('');
  const [isShowModalChangePassword, setIsShowModalChangePassword] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const { handleClearAllNotTheme } = useHandleLocalStorage();

  const { page } = useAppSelector(state => state.dashboard);

  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

  const handleChangeSetting = (value): void => {
    if (!isGeneralUser) {
      void dispatch(getManeuverSettingAction());
    }

    const convertData = JSON.stringify(dataSettingMonitor);

    localStorage.setItem(LOCAL_STORAGE_KEY.SETTING_MONITOR, convertData);
  };

  const pathNotCallTerminalApi = [
    ROUTERS.TERMINAL.PATH,
    ROUTERS.SPOTS.PATH,
    ROUTERS.LABEL.PATH,
    ROUTERS.CAMERA.PATH,
    ROUTERS.ONE_CLICK.PATH,
    ROUTERS.SYSTEM_SETTINGS.PATH,
  ].includes(pathname);

  useEffect(() => {
    if (!isGeneralUser && !pathNotCallTerminalApi) {
      void dispatch(getTerminalAction({ data: {} }));
    }

    socket().on(TYPE_SOCKET.SETTING_CHANGE, value => {
      handleChangeSetting(value);
    });
  }, []);

  const optionsSelectTerminal = terminals?.map(item => ({ label: item?.name, value: item?.id }));

  const optionsSelectOrder = [
    { label: 'なし', value: 'null' },
    { label: 'アラート中', value: 'alert' },
    { label: 'スポット', value: 'spot' },
    { label: '便名', value: 'flight' },
    { label: '機番', value: 'plane' },
  ];

  const activeIconMaster = useMemo(() => {
    switch (pathname) {
      case ROUTERS.TERMINAL.PATH:
        return true;
      case ROUTERS.SPOTS.PATH:
        return true;
      case ROUTERS.PLANE_NUMBER.PATH:
        return true;
      case ROUTERS.LABEL.PATH:
        return true;
      case ROUTERS.CAMERA.PATH:
        return true;
      case ROUTERS.ONE_CLICK.PATH:
        return true;
      default:
        return false;
    }
  }, [pathname]);

  const menuItems = [
    {
      key: ROUTERS.DASHBOARD.KEY,
      icon: <Icon component={itemSelectedMenu === ROUTERS.DASHBOARD.PATH ? DashboardActiveIcon : DashboardIcon} />,
      label: ROUTERS.DASHBOARD.LABEL,
      path: ROUTERS.DASHBOARD.PATH,
      isShow: isAdmin || isAdvanceAdmin || isGeneralMonitor,
    },
    {
      key: ROUTERS.USER_MANAGEMENT.KEY,
      icon: (
        <Icon
          component={itemSelectedMenu === ROUTERS.USER_MANAGEMENT.PATH ? UserManagementActiveIcon : UserManagementIcon}
        />
      ),
      label: ROUTERS.USER_MANAGEMENT.LABEL,
      path: ROUTERS.USER_MANAGEMENT.PATH,
      isShow: isAdmin,
    },
    {
      key: ROUTERS.MASTER_MANAGEMENT.KEY,
      icon: <Icon component={activeIconMaster ? MasterManagementActiveIcon : MasterManagementIcon} />,
      label: ROUTERS.MASTER_MANAGEMENT.LABEL,
      path: ROUTERS.MASTER_MANAGEMENT.PATH,
      isShow: isAdmin,
      children: [
        {
          key: ROUTERS.TERMINAL.KEY,
          label: ROUTERS.TERMINAL.LABEL,
          path: ROUTERS.TERMINAL.PATH,
        },
        {
          key: ROUTERS.SPOTS.KEY,
          label: ROUTERS.SPOTS.LABEL,
          path: ROUTERS.SPOTS.PATH,
        },
        {
          key: '/set-device',
          label: 'デバイス',
          path: '/set-device',
          children: [
            { key: ROUTERS.LABEL.KEY, label: ROUTERS.LABEL.LABEL, path: ROUTERS.LABEL.PATH },
            { key: ROUTERS.CAMERA.KEY, label: ROUTERS.CAMERA.LABEL, path: ROUTERS.CAMERA.PATH },
            { key: ROUTERS.ONE_CLICK.KEY, label: ROUTERS.ONE_CLICK.LABEL, path: ROUTERS.ONE_CLICK.PATH },
          ],
        },
        {
          key: ROUTERS.PLANE_NUMBER.KEY,
          label: ROUTERS.PLANE_NUMBER.LABEL,
          path: ROUTERS.PLANE_NUMBER.PATH,
        },
      ],
    },
    {
      key: ROUTERS.SYSTEM_SETTINGS.KEY,
      icon: (
        <Icon
          component={itemSelectedMenu === ROUTERS.SYSTEM_SETTINGS.PATH ? SystemSettingsActiveIcon : SystemSettingsIcon}
        />
      ),
      label: ROUTERS.SYSTEM_SETTINGS.LABEL,
      path: ROUTERS.SYSTEM_SETTINGS.PATH,
      isShow: isAdmin,
    },
    {
      key: ROUTERS.SUBMIT_STREAMING.KEY,
      icon: (
        <Icon
          component={itemSelectedMenu === ROUTERS.SUBMIT_STREAMING.PATH ? SubmitStreamActiveIcon : SubmitStreamIcon}
        />
      ),
      label: ROUTERS.SUBMIT_STREAMING.LABEL,
      path: ROUTERS.SUBMIT_STREAMING.PATH,
      isShow: isAdmin || isAdvanceAdmin || isGeneralUser,
    },
  ];

  const selectedMenu = useMemo(() => {
    const itemSelected = menuItems.find(item => item.path === location.pathname || item.path === '/')?.key ?? '';

    setItemSelectedMenu(itemSelected);

    return itemSelected;
  }, [location.pathname]);

  const itemsDropdown: MenuProps['items'] = [
    {
      label: <Text>パスワード設定</Text>,
      key: '0',
      onClick: () => setIsShowModalChangePassword(true),
    },
    {
      label: <Text>ログアウト</Text>,
      key: '0',
      onClick: () => handleLogout(),
    },
  ];

  const showConfirm = (): void => {
    confirm({
      title: MESSAGE_POPUP.DO_YOU_WANT_TO_LOGOUT,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        localStorage.setItem(LOCAL_STORAGE_KEY.IS_LOGOUT, 'true');

        void dispatch(
          logoutAction({
            callback: () => {
              navigate(ROUTERS.LOGIN.PATH);
              handleClearAllNotTheme();
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.LOGOUT,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  const handleLogout = (): any => {
    showConfirm();
  };

  const handleClickLogo = (): void => {
    if (isGeneralUser) {
      navigate(ROUTERS.SUBMIT_STREAMING.PATH);
    } else navigate(ROUTERS.DASHBOARD.PATH);
  };

  const formik = useFormik({
    initialValues: {
      terminalId: terminalId ?? null,
      order: localStorage.getItem(LOCAL_STORAGE_KEY.ORDER) ?? 'null',
      search: localStorage.getItem(LOCAL_STORAGE_KEY.SEARCH) ?? '',
      size: Number(localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE)),
      switch: dataSettingMonitor?.isPlayPause,
    },
    validationSchema: schemaSearch,
    onSubmit: value => {
      localStorage.setItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE, String(value?.size));
      localStorage.setItem(LOCAL_STORAGE_KEY.TERMINAL_ID, String(value?.terminalId));
      localStorage.setItem(LOCAL_STORAGE_KEY.ORDER, String(value?.order));
      localStorage.setItem(LOCAL_STORAGE_KEY.SEARCH, String(value?.search));

      socket().emit(TYPE_SOCKET.DASHBOARD_SEARCH, value);

      void dispatch(
        fetchDataVideoAction({
          data: { ...value, page },
          callback: () => {
            void dispatch(paramSearchStreamAction({ data: { ...value } }));
          },
        }),
      );
      void dispatch(
        getDataDashboardSecondAction({
          data: { terminalId: value?.terminalId },
        }),
      );
    },
  });

  return {
    menuItems,
    selectedMenu,
    itemsDropdown,
    formik,
    optionsSelectTerminal,
    optionsSelectOrder,
    isShowModalChangePassword,
    isGeneralUser,
    setIsShowModalChangePassword,
    handleClickLogo,
  };
}

export default useInit;
