import React from 'react';

const PinIconDefaultV2Light: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2967_12174)">
        <path
          opacity="0.45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75207 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
          fill="#EFEFF2"
        />
        <path
          d="M18.6347 10.3934C18.4063 10.1648 18.103 10.0263 17.7807 10.0034C17.4583 9.98046 17.1385 10.0746 16.88 10.2686L14.9254 11.7346C14.1134 12.3437 13.161 12.7383 12.1561 12.882L10.6954 13.09C10.2055 13.1604 9.84653 13.6878 10.0827 14.2051C10.3048 14.6903 11.0121 15.9691 13.0083 18.0431L10.2049 20.8464C10.1408 20.9083 10.0896 20.9823 10.0545 21.0642C10.0193 21.1461 10.0008 21.2341 10 21.3232C9.99925 21.4123 10.0162 21.5006 10.05 21.5831C10.0837 21.6656 10.1335 21.7405 10.1965 21.8035C10.2595 21.8665 10.3345 21.9163 10.4169 21.95C10.4994 21.9838 10.5877 22.0007 10.6768 22C10.7659 21.9992 10.854 21.9807 10.9359 21.9455C11.0177 21.9104 11.0918 21.8592 11.1537 21.7952L13.9571 18.9918C16.0312 20.988 17.3102 21.6952 17.7953 21.9173C18.312 22.1535 18.8401 21.7945 18.9098 21.3047L19.1185 19.844C19.2622 18.8392 19.6568 17.8868 20.2659 17.0749L21.7314 15.1203C21.9254 14.8619 22.0195 14.5421 21.9966 14.2198C21.9737 13.8974 21.8352 13.5942 21.6066 13.3658L18.6347 10.3934Z"
          fill="#3A75FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2967_12174">
          <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinIconDefaultV2Light;
