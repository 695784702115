import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from 'src/stores/app';
import {
  commonReducer,
  dashboardReducer,
  submitStreamReducer,
  userManagementReducer,
  settingsReducer,
  terminalsReducer,
  spotsReducer,
  labelsReducer,
  dashboardSecondReducer,
  paramSearchStreamReducer,
} from './screens/privateScreens';
import { authReducer } from './screens/publicScreens';

export const reducer = combineReducers({
  app: appReducer,

  auth: authReducer,
  dashboard: dashboardReducer,
  submitStream: submitStreamReducer,
  userManagement: userManagementReducer,
  common: commonReducer,
  settings: settingsReducer,
  terminals: terminalsReducer,
  spots: spotsReducer,
  labels: labelsReducer,
  dashboardSecond: dashboardSecondReducer,
  paramSearchStream: paramSearchStreamReducer,
});
