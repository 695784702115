const MESSAGE_POPUP = {
  DO_YOU_WANT_TO_MUTED_THESE_STREAM: 'アラート音を止めますか？',
  DO_YOU_WANT_TO_CLOSE_ALERT_OF_USER_THESE_STREAM: 'アラート通知を止めますか？',
  DO_YOU_WANT_TO_FINISH_THIS_STREAM: 'このデバイスグループの映像を終了しますか？',
  DO_YOU_WANT_TO_LOGOUT: 'ログアウトしますか？',
  DO_NOT_HAVE_CONNECT_INTERNET_PLEASE_CHECK: 'インターネット接続がありません。接続を確認してください。',
  CLICK_HERE_TO_PLAY_SOUND_WHEN_THERE_IS_AN_ALERT: 'アラート音が鳴ります。ご注意ください。',
  CANCEL: 'キャンセル',
  CLOSE_ALERT_SOUND: '音を止める',
  CLOSE_ALERT_NOTI: '通知を止める',
  FINISH: '終了 ',
  RETRY: 'リトライ',
  I_UNDERSTAND: '了解 ',
  LOGOUT: 'ログアウト',
  EXPIRE_STREAM: '利用デバイスは３時間以上経過したのでリセットされました。もう一度登録してください',
  EXPIRE_CAMERA: '利用デバイスは３分以上接続ができなかったためリセットされました。もう一度登録してください',
  ARE_YOU_SURE_DELETE: '削除しますか？',
  DELETE: '削除 ',
  EDIT: '更新 ',
  CREATE: '登録 ',
  MESSAGE_CONFIRM_ALERT: '本当に通知しますか？',
  YES: 'はい',
  NO: 'いいえ',
};

const MESSAGE_VALIDATE = {
  STAFF_NUM_IS_REQUIRED: '社員番号を入力してください',
  PASSWORD_IS_REQUIRED: 'パスワードを入力してください',
  LABEL_IS_REQUIRED: '利用デバイスグループを入力してください',
  FLIGHT_NUM_IS_REQUIRED: '便名を入力してください',
  SPOT_IS_REQUIRED: 'スポットを入力してください',
  PLANE_NUM_IS_REQUIRED: '機番を入力してください',
  FLIGHT_NUM_REQUIRED_4_CHARACTERS_NUMBER: '４桁まで入力してください',
};

const MESSAGE_REGISTER_VALIDATE = {
  REGISTER_PASSWORD: '初期パスワードを入力してください',
  RESET_PASSWORD: '一時パスワードを入力してください',
  REMIND_PASSWORD: '古いパスワードを入力してください',
  PASSWORD_NEW_IS_REQUIRED: '新規パスワードを入力してください',
  PASSWORD_NEW_VALIDATE: '9文字以上で文字種は英大文字、英小文字、数字、記号のうち3種類以上を含むのを入力してください',
  PASSWORD_CONFIRM_IS_REQUIRED: '確認用パスワードを入力してください',
  PASSWORD_CONFIRM_FAIL: '確認用パスワードが新規パスワードと一致しません',
};

const MESSAGE_NOTIFICATION = {
  PASSWORD_HAS_14_DAYS_REMAINING: '有効期限ぎれる残りは14日となり、そのうちにパスワードを再設定してください。',
  PASSWORD_HAS_14_DAYS_REMAINING_MOBILE_TOP: '有効期限ぎれる残りは14日となり、',
  PASSWORD_HAS_14_DAYS_REMAINING_MOBILE_BOTTOM: 'そのうちにパスワードを再設定してください。',
  PASSWORD_EXPIRED: '有効期限が切れたので、ログインできるようにパスワードを設定してください。',
  PASSWORD_EXPIRED_TOP: '有効期限が切れたので、ログインできるようにパ',
  PASSWORD_EXPIRED_BOTTOM: 'スワードを設定してください。',
  QUANTITY_PIN_FULL_DISPLAY: 'ペアがアラートしています。表示するには他のペアをクローズしてください。',
};

const MESSAGE_ERROR = {
  REGISTER_PASSWORD: '初期パスワードが間違っています',
  RESET_PASSWORD: '一時パスワードが間違っています',
  REMIND_PASSWORD: '古いパスワードが間違っています',
  FILE_EXTENSION_ERROR: 'Excelファイル拡張子（.xlsx）が正しくありません。',
  FILE_SIZE_ERROR: '0MBを超え5MB以下のファイルをアップロードしてください',
};

const MESSAGE_SUCCEESS = {
  SUBMIT_STREAM_SUCCESSFULLY: '利用デバイスを設定しました',
  SETTING_PASSWORD_SUCCESSFULLY: 'パスワードを設定しました',
  FORGOT_PASSWORD_SUCCESSFULLY: ' へパスワード再設定のご案内メールを送信しました',
  SETTING_SUCCESSFULLY: '設定しました',
  UPLOAD_FILE_SUCCESSFULLY:
    '一括登録が成功しました。多数の新しいユーザーを作成する場合は、処理が完了するまでもうしばらくお待ちください。',
  PROGRESS_UPLOAD_FILE_SUCCESSFULLY: 'ユーザ一括登録の処理が完了しました',
};

const MESSAGE_MASTER = {
  COMMON: {
    ALREADY_STREAM: '作業中のユーザが存在するため、編集できません',
    CREATE_SUCCESSFULLY: '登録しました',
    EDIT_SUCCESSFULLY: '編集しました',
    DELETE_SUCCESSFULLY: '削除しました',
    TERMINAL_DOES_NOT_EXIST: '拠点が存在しません。もう一度お試しください',
    DEVICE_GROUP_DOES_NOT_EXIST: 'デバイスグループが存在しません。もう一度お試しください',
    DO_YOU_WANT_DELETE: '削除しますか？',
    CANCEL: 'キャンセル',
    DELETE: '削除 ',
    KEY_DOES_NOT_EXIST: 'キーが存在しません。もう一度お試しください',
  },

  TERMINAL: {
    PLEASE_ENTER_NAME_TERMINAL: '拠点名を入力してください',
    DO_YOU_WANT_DELETE_TERMINAL:
      'この拠点を削除すると、関連するすべてのユーザとマスタも削除されます。本当に削除しますか？',
  },

  SPOT: {
    PLEASE_ENTER_NAME_SPOT: 'スポット名を入力してください',
  },

  CAMERA: {
    API_KEY_DOES_NOT_EXIST: 'APIキーが存在しません。もう一度お試しください',
    CAMERA_DOES_NOT_EXIST: 'カメラが存在しません。もう一度お試しください',
  },
};

export {
  MESSAGE_POPUP,
  MESSAGE_VALIDATE,
  MESSAGE_REGISTER_VALIDATE,
  MESSAGE_NOTIFICATION,
  MESSAGE_ERROR,
  MESSAGE_SUCCEESS,
  MESSAGE_MASTER,
};
