import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { ModalContentWrap } from '../terminal/styled';

interface Props {
  terminalSelected: any;
  isOpen: boolean;
  setIsShowModal: (param: boolean) => void;
  fetchDataTerminals: (params: CommonStore.ParamsGetList) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({ terminalSelected, isOpen, setIsShowModal, fetchDataTerminals }) => {
  const { formik } = useForm({ terminalSelected, setIsShowModal, fetchDataTerminals });
  const { errors, touched, isValid, setFieldValue, resetForm, getFieldProps, handleSubmit } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
  };

  useEffect(() => {
    if (terminalSelected?.id && isOpen) {
      setFieldValue('name', terminalSelected.name);
      return;
    }
    resetForm();
  }, [terminalSelected, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={terminalSelected ? '拠点情報を更新する' : '拠点情報を登録する'}
      width={494}
      className="modal-custom-antd modal-set-divice"
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField
            content={terminalSelected ? MESSAGE_POPUP.EDIT : MESSAGE_POPUP.CREATE}
            type="primary"
            onClick={handleSubmit}
            disabled={!isValid}
          />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  拠点名：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('name')}
                    error={errors.name}
                    touched={touched.name}
                    placeholder="入力してください"
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
