import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import DASHBOARD_API from './dashboardSecond.api';
import { LOCAL_STORAGE_KEY } from 'src/shared/constants';

export const getDataDashboardSecondAction = createAsyncThunk<any, DashboardSecondStore.RequestDataVideo>(
  'dashboard_second',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

    try {
      const getData = {
        size: data?.size === 0 ? null : data?.size,
        page: data?.page,
        order: data?.order,
        search: data?.search,
        terminalId: terminalId ?? data?.terminalId,
        listOldItem: data?.listOldItem,
      };

      const response = await DASHBOARD_API.getDataDashboardSecondAPI(getData);

      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
