import React from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';

import useInit from './hooks/useInit';
import TableCard from 'src/shared/components/table/Table';
import { ButtonField, SelectField } from 'src/shared/components/form';
import { PlusIcon } from 'src/assets/icons';
import ModalForm from './ModalForm';
import { WrapperStyled } from './styled';

const { Title } = Typography;

const OneClick: React.FC = () => {
  const {
    columns,
    dataSource,
    totalPageOneClicks,
    formik,
    isLoading,
    currentPage,
    isShowModal,
    dataEdit,
    dataSelectTerminals,
    terminalId,
    fetchDataOneClick,
    setCurrentPage,
    setTerminalId,
    setIsShowModal,
    setDataEdit,
  } = useInit();

  const { getFieldProps, setFieldValue } = formik;

  return (
    <React.Fragment>
      <WrapperStyled className="one-click-screen">
        <Col span={20} className="col-form">
          <Row className="row-btn">
            <Col span={8}>
              <Title level={4} className="title color-title-table-light-theme">
                タグボタン
              </Title>
            </Col>

            <Col span={16} className="col-left">
              <SelectField
                field={getFieldProps('terminalId')}
                optionsSelect={dataSelectTerminals}
                setFieldValue={setFieldValue}
                onChange={id => {
                  setTerminalId(id);
                  setCurrentPage(1);
                  void fetchDataOneClick({ terminalId: id, page: 1 });
                }}
              />
              <ButtonField
                content={<Icon component={PlusIcon} />}
                type="primary"
                className="btn-create"
                onClick={() => {
                  setIsShowModal(true);
                  setDataEdit(null);
                }}
              />
            </Col>
          </Row>

          <Row className="row-table">
            <TableCard
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              rowKey="id"
              totalPages={totalPageOneClicks}
              currentPage={currentPage}
              onChangePage={page => {
                setCurrentPage(page);
                void fetchDataOneClick({ page });
              }}
            />
          </Row>
        </Col>
      </WrapperStyled>

      <ModalForm
        terminalSelects={dataSelectTerminals}
        isOpen={isShowModal}
        setIsShowModal={setIsShowModal}
        dataEdit={dataEdit}
        terminalId={terminalId}
        fetchDataOneClick={fetchDataOneClick}
      />
    </React.Fragment>
  );
};

export default OneClick;
