import { Col, Row, Typography } from 'antd';
import React from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { PasswordField, ButtonField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { ModalContentWrap } from './styled';

interface Props {
  isOpen: boolean;
  setIsShowModal: (param: boolean) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({ isOpen, setIsShowModal }) => {
  const { formik } = useForm({ setIsShowModal });

  const { errors, touched, getFieldProps, handleSubmit } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="パスワードを設定する"
      width={494}
      className="modal-custom-antd"
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField content="更新" type="primary" onClick={handleSubmit} />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  古いパスワード：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <PasswordField
                  field={getFieldProps('oldPassword')}
                  className="form-control"
                  error={errors.oldPassword}
                  touched={touched.oldPassword}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  新規のパスワード：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <PasswordField
                  field={getFieldProps('newPassword')}
                  className="form-control"
                  error={errors.newPassword}
                  touched={touched.newPassword}
                />
              </Col>
            </Row>
            <Row className="mb-30">
              <Col span={24} className="field-title">
                <Text>
                  確認用のパスワード：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <PasswordField
                  field={getFieldProps('confirmPassword')}
                  className="form-control"
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                />
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
