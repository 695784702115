import React from 'react';
import { Row, Col, Typography, Tooltip } from 'antd';
import Icon from '@ant-design/icons';

import { CloseIconV3 } from 'src/assets/icons';
import { PROPERTY_TABLE_VALIDATE } from 'src/shared/constants';
import { ModalTableValidateWrap } from './styled';

interface Props {
  codeTitleTable: any;
  dataTableValidate: any;
  setIsShowModalTableValidate: (isShow: boolean) => void;
}

const { Title, Text } = Typography;

const ModalTableValidate: React.FC<Props> = ({ codeTitleTable, dataTableValidate, setIsShowModalTableValidate }) => {
  const elementData = (itemValue: any, itemError: any): any => {
    return (
      <div className="block-ellipsis">
        {itemError && (
          <Tooltip
            placement="bottomLeft"
            title={
              <p
                className="text-tooltip-error"
                dangerouslySetInnerHTML={{
                  __html: itemError.join('\n'),
                }}
              />
            }
            color="#0F1013"
          >
            {!itemValue && <span style={{ opacity: 0 }}>hiden</span>}
            {itemValue && <Text>{itemValue}</Text>}
          </Tooltip>
        )}
        {!itemError && <Text>{itemValue}</Text>}
      </div>
    );
  };

  return (
    <>
      <Row className="modal-background" onClick={() => setIsShowModalTableValidate(false)}></Row>
      <ModalTableValidateWrap className="modal-custom">
        <Col span={24} className="modal-header">
          {codeTitleTable === 26 && <Title className="title">アップロードしたファイルにエラーがあります。</Title>}
          {codeTitleTable === 31 && <Title className="title">作業中のユーザは編集できません</Title>}
          <Icon component={CloseIconV3} className="icon-close" onClick={() => setIsShowModalTableValidate(false)} />
        </Col>
        <Col span={24} className="modal-body">
          <div className="table-wrap">
            <table className="">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>{PROPERTY_TABLE_VALIDATE.NO}</th>
                  <th style={{ width: '10%' }}>{PROPERTY_TABLE_VALIDATE.USER_NAME}</th>
                  <th style={{ width: '10%' }}>{PROPERTY_TABLE_VALIDATE.USER_NUMBER}</th>
                  <th style={{ width: '25%' }}>{PROPERTY_TABLE_VALIDATE.EMAIL}</th>
                  <th style={{ width: '5%' }}>{PROPERTY_TABLE_VALIDATE.TERMINAL_ID}</th>
                  <th style={{ width: '5%' }}>{PROPERTY_TABLE_VALIDATE.ROLE}</th>
                  <th style={{ width: '10%' }}>{PROPERTY_TABLE_VALIDATE.ACTION}</th>
                </tr>
              </thead>
            </table>
            <div className="scroll">
              <table>
                <tbody>
                  {dataTableValidate?.length > 0 &&
                    dataTableValidate.map((item, index) => {
                      const userNo = item?.value?.[PROPERTY_TABLE_VALIDATE.NO];
                      const userNameValue = item?.value?.[PROPERTY_TABLE_VALIDATE.USER_NAME];
                      const userNumberValue = item?.value?.[PROPERTY_TABLE_VALIDATE.USER_NUMBER];
                      const emailValue = item?.value?.[PROPERTY_TABLE_VALIDATE.EMAIL];
                      const terminalIdValue = item?.value?.[PROPERTY_TABLE_VALIDATE.TERMINAL_ID];
                      const roleValue = item?.value?.[PROPERTY_TABLE_VALIDATE.ROLE];
                      const actionValue = item?.value?.[PROPERTY_TABLE_VALIDATE.ACTION];

                      const userNameError = item?.error?.[PROPERTY_TABLE_VALIDATE.USER_NAME];
                      const userNumberError = item?.error?.[PROPERTY_TABLE_VALIDATE.USER_NUMBER];
                      const emailError = item?.error?.[PROPERTY_TABLE_VALIDATE.EMAIL];
                      const terminalIdError = item?.error?.[PROPERTY_TABLE_VALIDATE.TERMINAL_ID];
                      const roleError = item?.error?.[PROPERTY_TABLE_VALIDATE.ROLE];
                      const actionError = item?.error?.[PROPERTY_TABLE_VALIDATE.ACTION];

                      return (
                        <tr key={index}>
                          <td style={{ width: '5%' }}>{userNo}</td>
                          <td style={{ width: '10%' }} className={userNameError && 'td-error'}>
                            {elementData(userNameValue, userNameError)}
                          </td>
                          <td style={{ width: '10%' }} className={userNumberError && 'td-error'}>
                            {elementData(userNumberValue, userNumberError)}
                          </td>
                          <td style={{ width: '25%' }} className={emailError && 'td-error'}>
                            {elementData(emailValue, emailError)}
                          </td>
                          <td style={{ width: '5%' }} className={terminalIdError && 'td-error'}>
                            {elementData(terminalIdValue, terminalIdError)}
                          </td>
                          <td style={{ width: '5%' }} className={roleError && 'td-error'}>
                            {elementData(roleValue, roleError)}
                          </td>
                          <td style={{ width: '10%' }} className={actionError && 'td-error'}>
                            {elementData(actionValue, actionError)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </ModalTableValidateWrap>
    </>
  );
};

export default ModalTableValidate;
