import React, { useEffect, useMemo, useState } from 'react';
import { Col, Dropdown, Layout, MenuProps, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';

import { ButtonField, InputField, SelectField, SwitchField } from 'src/shared/components/form';
import {
  AvatarDefaultIcon,
  DarkIcon,
  ItemInPageActiveIcon,
  ItemInPageIcon,
  LightIcon,
  LogoIcon,
  OneScreenIcon,
  OneScreenLightIcon,
  SearchHeaderIcon,
  SearchHeaderV2Icon,
  SearchIcon,
  SettingsIcon,
  SettingsV2Icon,
  TwoScreenIcon,
  TwoScreenLightIcon,
} from 'src/assets/icons';
import DragDropIcon from 'src/assets/icons/drag-drop-icon';
import DragDropV2Icon from 'src/assets/icons/drag-drop-v2-icon';
import useTitleHeader from 'src/shared/hooks/useTitleHeader';
import { useAppSelector, useAppDispatch } from 'src/stores';
import { setThemeTypeAction } from 'src/stores/screens/privateScreens/settings/settings.action';
import { ManeuverSettings, ModalChangePassword } from 'src/screens/privateScreens';
import {
  LOCAL_STORAGE_KEY,
  ROUTERS,
  THEME_TYPE,
  TYPE_DISPLAY_SCREEN,
  TYPE_SELECT_ITEM_OF_PAGE,
  TYPE_SOCKET,
} from 'src/shared/constants';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import useRole from 'src/shared/hooks/useRole';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { fetchDataVideoAction } from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import { paramSearchStreamAction } from 'src/stores/screens/privateScreens/paramSearchStream/paramSearchStream.action';
import { PopupBackground } from './styled';
import socket from 'src/configs/socketIO/socketIO';
import { getDataDashboardSecondAction } from 'src/stores/screens/privateScreens/dashboardSecond/dashboardSecond.action';

const { Title, Text } = Typography;
const { Header } = Layout;

interface IHeaderCard {
  itemsDropdown: MenuProps['items'];
  formik: FormikProps<any>;
  optionsSelectTerminal: DefaultOptionType[];
  optionsSelectOrder: DefaultOptionType[];
  isShowModalChangePassword: boolean;
  setIsShowModalChangePassword: (value: boolean) => void;
}

const HeaderCard: React.FC<IHeaderCard> = ({
  itemsDropdown,
  formik,
  optionsSelectTerminal,
  isShowModalChangePassword,
  setIsShowModalChangePassword,
  optionsSelectOrder,
}) => {
  const TYPE_SCREEN_DISPLAY = localStorage.getItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN);
  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

  const [isShowModalSetting, setIsShowModalSetting] = useState(false);
  const [isShowFormSearch, setIsShowFormSearch] = useState(false);
  const [isShowFormItemOfPage, setIsShowFormItemOfPage] = useState(false);
  const [typeSwitchDisplayScreen, setTypeSwitchDisplayScreen] = useState(TYPE_SCREEN_DISPLAY);
  const TYPE_THEME_STORAGE = localStorage.getItem(THEME_TYPE.TYPE);
  const dataSettingMonitor = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '{}');

  const { meInfo } = useAppSelector(state => state.auth);
  const { themeType } = useAppSelector(state => state.settings);
  const { page } = useAppSelector(state => state.dashboard);
  const { getTitleRole } = useRole();
  const { isMobile } = useWindowSize();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { getFieldProps, setFieldValue, handleSubmit, isValid, dirty, errors, touched, values } = formik;

  const title = useTitleHeader(pathname);
  const isScreenDashboard = pathname === ROUTERS.DASHBOARD.PATH;

  const onThemeTypeChange = async (): Promise<void> => {
    socket().emit(TYPE_SOCKET.CHANGE_THEME, {});
    await dispatch(setThemeTypeAction({ data: themeType === THEME_TYPE.DARK ? THEME_TYPE.LIGHT : THEME_TYPE.DARK }));
  };

  const handleDropDrapSetting = (): void => {
    navigate({
      pathname: ROUTERS.DASHBOARD_SETTING.PATH,
      search: `?page=${page}`,
    });
  };

  const roleTitle = getTitleRole(meInfo?.role ?? '');

  const handleClickCancelFormSearch = (): void => {
    if (dirty) {
      resetFormSearch();
    }

    setFieldValue('terminalId', meInfo?.terminalId);
    setFieldValue('order', 'null');
    setFieldValue('search', '');
    localStorage.setItem(LOCAL_STORAGE_KEY.TERMINAL_ID, String(meInfo?.terminalId));
    localStorage.setItem(LOCAL_STORAGE_KEY.ORDER, 'null');
    localStorage.setItem(LOCAL_STORAGE_KEY.SEARCH, '');

    socket().emit(TYPE_SOCKET.DASHBOARD_SEARCH, {
      terminalId: values?.terminalId,
    });

    void dispatch(
      fetchDataVideoAction({
        data: {
          terminalId: meInfo?.terminalId,
          size: Number(localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE)),
        },
      }),
    );

    void dispatch(
      getDataDashboardSecondAction({
        data: { terminalId: meInfo?.terminalId },
      }),
    );

    setIsShowFormSearch(false);
  };

  const content = (
    <div className="form-search">
      <Row>
        <Col span={24}>
          <InputField
            field={getFieldProps('search')}
            setFieldValue={setFieldValue}
            placeholder="検索"
            inputProps={{ prefix: <Icon component={SearchIcon} /> }}
            error={errors.search}
            touched={touched.search}
            maxLength={MAX_LENGTH}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <SelectField
            field={getFieldProps('terminalId')}
            optionsSelect={optionsSelectTerminal}
            setFieldValue={setFieldValue}
            selectProps={{
              placeholder: '拠点',
            }}
          />
        </Col>
        <Col span={12}>
          <SelectField
            field={getFieldProps('order')}
            optionsSelect={optionsSelectOrder}
            setFieldValue={setFieldValue}
            selectProps={{
              placeholder: 'なし',
            }}
          />
        </Col>
      </Row>

      <Row className="row-btn">
        <ButtonField
          content="クリア"
          type="default"
          className="btn-cancel"
          onClick={() => {
            handleClickCancelFormSearch();
          }}
        />
        <ButtonField disabled={!isValid} content="検索 " type="primary" className="btn-ok" onClick={handleSubmit} />
      </Row>
    </div>
  );

  const contentItemOfPage = (
    <Row className="form-item-of-page">
      <Col span={24}>
        <SelectField
          field={getFieldProps('size')}
          optionsSelect={TYPE_SELECT_ITEM_OF_PAGE}
          label="表示数"
          setFieldValue={setFieldValue}
          selectProps={{
            placeholder: '拠点',
          }}
          onChange={() => {
            handleSubmit();
            setIsShowFormItemOfPage(false);
          }}
        />
      </Col>
    </Row>
  );

  const switchTypeScreen = (): void => {
    if (typeSwitchDisplayScreen === TYPE_DISPLAY_SCREEN.ONE_SCREEN) {
      setTypeSwitchDisplayScreen(TYPE_DISPLAY_SCREEN.TWO_SCREEN);
      localStorage.setItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN, TYPE_DISPLAY_SCREEN.TWO_SCREEN ?? '');
      if (!isMobile) {
        window.open('dashboard-second', 'Fred', 'height=700,width=1024');
      }
    } else {
      setTypeSwitchDisplayScreen(TYPE_DISPLAY_SCREEN.ONE_SCREEN);
      localStorage.setItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN, TYPE_DISPLAY_SCREEN.ONE_SCREEN ?? '');
      socket().emit(TYPE_SOCKET.MONITOR_CLOSE);
    }
  };

  const handleTurnOffPopups = (): void => {
    setIsShowFormItemOfPage(false);
    setIsShowFormSearch(false);
    resetFormSearch();
  };

  const iconSwitchDisplayScreen = useMemo(() => {
    if (typeSwitchDisplayScreen === TYPE_DISPLAY_SCREEN.TWO_SCREEN && TYPE_THEME_STORAGE === THEME_TYPE.LIGHT) {
      return TwoScreenLightIcon;
    } else if (typeSwitchDisplayScreen === TYPE_DISPLAY_SCREEN.TWO_SCREEN && TYPE_THEME_STORAGE === THEME_TYPE.DARK) {
      return TwoScreenIcon;
    } else if (typeSwitchDisplayScreen === TYPE_DISPLAY_SCREEN.ONE_SCREEN && TYPE_THEME_STORAGE === THEME_TYPE.DARK) {
      return OneScreenIcon;
    } else {
      return OneScreenLightIcon;
    }
  }, [typeSwitchDisplayScreen, TYPE_THEME_STORAGE]);

  const resetFormSearch = (): void => {
    setFieldValue('terminalId', Number(terminalId) ?? meInfo?.terminalId);
    setFieldValue('search', localStorage.getItem(LOCAL_STORAGE_KEY.SEARCH) ?? null);
    setFieldValue('order', localStorage.getItem(LOCAL_STORAGE_KEY.ORDER) ?? 'null');
    void dispatch(paramSearchStreamAction({ data: { terminalId: meInfo?.terminalId } }));
  };

  useEffect(() => {
    resetFormSearch();
  }, []);

  const changeQuantityScreen = (quantityScreen: number): void => {
    if (quantityScreen === 1) {
      setTypeSwitchDisplayScreen(TYPE_DISPLAY_SCREEN.ONE_SCREEN);
      localStorage.setItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN, TYPE_DISPLAY_SCREEN.ONE_SCREEN ?? '');
    } else {
      setTypeSwitchDisplayScreen(TYPE_DISPLAY_SCREEN.TWO_SCREEN);
      localStorage.setItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN, TYPE_DISPLAY_SCREEN.TWO_SCREEN ?? '');
    }
  };

  useEffect(() => {
    socket().on(TYPE_SOCKET.MONITOR_RELOAD, value => {
      if (value?.id === meInfo?.id && value?.quantityMonitorDisplay === 2) {
        changeQuantityScreen(2);
      }
    });

    const handleWindow2Message = (event): void => {
      if (event.data === 'window2Closed') {
        changeQuantityScreen(1);
      }
    };

    window.addEventListener('message', handleWindow2Message);
  }, []);

  const handlePlayPause = (checked: boolean): void => {
    const settingMonitor = localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR);

    void setFieldValue('switch', checked);

    const convertData = JSON.stringify({
      ...JSON?.parse(settingMonitor ?? ''),
      isPlayPause: checked,
    });

    localStorage.setItem(LOCAL_STORAGE_KEY.SETTING_MONITOR, convertData);

    socket().emit(TYPE_SOCKET.SWITCH_PLAY_PAUSE);
  };

  return (
    <>
      {(isShowFormItemOfPage || isShowFormSearch) && <PopupBackground onClick={() => handleTurnOffPopups()} />}
      <Header className="body-header">
        <Row className="row-header">
          <Col xxl={6} xl={6} lg={6} md={10} sm={10} xs={10} className="col-left">
            {isMobile && <Icon component={LogoIcon} className="logo-icon" />}
            <Text>{title}</Text>
          </Col>

          <Col xxl={18} xl={18} lg={18} md={14} sm={14} xs={14} className="col-right">
            {isScreenDashboard && (
              <>
                <div className="div-item-of-page">
                  <Icon
                    component={isShowFormItemOfPage ? ItemInPageActiveIcon : ItemInPageIcon}
                    onClick={() => {
                      setIsShowFormItemOfPage(!isShowFormItemOfPage);
                      setFieldValue('size', Number(localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE)));
                    }}
                  />

                  {isShowFormItemOfPage && contentItemOfPage}
                </div>

                <div className="div-search">
                  <Icon
                    component={isShowFormSearch ? SearchHeaderIcon : SearchHeaderV2Icon}
                    onClick={() => setIsShowFormSearch(!isShowFormSearch)}
                  />
                  {isShowFormSearch && content}
                </div>

                <Icon component={DragDropIcon} onClick={handleDropDrapSetting} />
                <Icon
                  component={isShowModalSetting ? SettingsV2Icon : SettingsIcon}
                  onClick={() => setIsShowModalSetting(true)}
                />
                <Icon component={iconSwitchDisplayScreen} onClick={switchTypeScreen} />
              </>
            )}
            {pathname === ROUTERS.DASHBOARD_SETTING.PATH && <Icon component={DragDropV2Icon} />}
            {pathname !== ROUTERS.DASHBOARD_SECOND.PATH && (
              <Icon
                component={themeType === THEME_TYPE.DARK ? DarkIcon : LightIcon}
                onClick={() => {
                  void (async () => {
                    await onThemeTypeChange();
                  })();
                }}
              />
            )}
            {isScreenDashboard && (
              <SwitchField
                label="自動切替"
                field={getFieldProps('switch')}
                onChange={checked => {
                  handlePlayPause(checked);
                }}
                isChecked={dataSettingMonitor?.isPlayPause}
                className="switch-field"
                isDisabled={!dataSettingMonitor?.isSwitchList && !dataSettingMonitor?.isSwitchZoom}
              />
            )}

            <Row className="info-name">
              <Title level={5} className="first">
                {meInfo?.username}
              </Title>
              <Title level={5}>-</Title>
              <Text>{roleTitle}</Text>
            </Row>

            <Dropdown menu={{ items: itemsDropdown }}>
              <Icon component={AvatarDefaultIcon} style={{ marginRight: 0 }} />
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <ManeuverSettings isOpen={isShowModalSetting} setIsShowModalSetting={setIsShowModalSetting} />
      <ModalChangePassword isOpen={isShowModalChangePassword} setIsShowModal={setIsShowModalChangePassword} />
    </>
  );
};

export default HeaderCard;
