import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const SpinningTransparent: React.FC<{ isLoading: boolean; className?: string }> = ({ isLoading, className }) => {
  return (
    <SuspenseStyle className={className}>
      <Spin spinning={isLoading} />
    </SuspenseStyle>
  );
};

export default SpinningTransparent;

const SuspenseStyle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: #000000b8;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
