import { Layout } from 'antd';
import styled, { css } from 'styled-components';

import { COLOR } from 'src/shared/constants/color';
import ResponsiveUI, { SIZEH, SIZEW } from 'src/shared/constants/reponsive';

const responsiveLayoutUI = css`
  ${ResponsiveUI.lessThanH(SIZEH.PAD3)} {
    ${ResponsiveUI.lessThan(SIZEW.LG)} {
      .ant-layout-sider {
        width: 50px !important;

        .row-logo {
          padding-left: 12px !important;
        }
      }

      .layout-body {
        padding-left: 0;

        .body-header {
          padding: 0 15px;
        }
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.PAD2)} {
    ${ResponsiveUI.lessThan(SIZEW.MD)} {
      .layout-body {
        .body-header {
          .row-header {
            .col-left {
              .logo-icon {
                margin-right: 10px;
              }

              .ant-typography {
                font-size: 14px;
              }
            }

            .col-right {
              .anticon {
                margin-right: 5px;
              }

              .info-name {
                .ant-typography {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PrivateLayoutStyle = styled(Layout)<{ isDashboardSecondScreen: boolean }>`
  height: 100%;
  width: 100%;
  background-color: #0f1013;

  .layout-body {
    background-color: ${COLOR.BACKGROUND_PRIMARY};
    padding-left: ${props => (props.isDashboardSecondScreen ? '0' : '80px')};

    .body-header {
      padding: 0;
      background-color: ${COLOR.BACKGROUND_PRIMARY};
      padding: 0 30px;
      height: ${props => (props.isDashboardSecondScreen ? '44px' : '74px')};
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #3b3b3b;

      .row-header {
        height: 100%;
        width: 100%;

        .col-left,
        .col-right {
          display: flex;
          align-items: center;
          height: 100%;
        }

        .col-left {
          .ant-typography {
            color: ${COLOR.WHITE};
            font-size: 18px;
            font-weight: 700;
          }

          .logo-icon {
            margin-right: 52px;
          }
        }

        .col-right {
          justify-content: end;

          .ant-form-item {
            margin-bottom: 0;
            margin-right: 20px;
          }

          .anticon {
            margin-right: 20px;
          }

          .info-name {
            align-items: center;

            .ant-typography {
              margin: 0;
              margin-right: 6px;
              color: ${COLOR.WHITE};
            }

            .first {
              color: #3699ff;
            }
          }

          .div-search {
            display: flex;
            position: relative;

            .form-search {
              position: absolute;
              background-color: #1c1d22;
              left: 0;
              min-width: 328px;
              top: 40px;
              padding: 30px;
              z-index: 1000;
              border-radius: 12px;

              .ant-row {
                margin-bottom: 10px;

                .ant-form-item {
                  margin-right: 0;

                  .anticon {
                    margin-right: 0;
                  }

                  .ant-input-affix-wrapper {
                    &::before {
                      display: none;
                    }
                  }
                }
              }

              .row-btn {
                justify-content: end;
                padding-top: 20px;
                border-top: 1px dashed #383840;
                margin-bottom: 0;

                .btn-cancel,
                .btn-ok {
                  margin-left: 20px;
                  min-width: 100px;
                }
              }
            }
          }

          .div-item-of-page {
            display: flex;
            position: relative;

            .form-item-of-page {
              min-width: 295px;
              /* min-height: 173px; */
              background-color: #1c1d22;
              position: absolute;
              top: 40px;
              right: 20px;
              z-index: 1000;
              border-radius: 12px;
              padding: 30px;

              .ant-form-item,
              .anticon {
                margin-right: 0;
              }
            }
          }
        }
      }

      .logo-icon {
        margin-right: 30px;
      }
    }

    .body-content {
      overflow-y: auto;
      height: calc(100% - 74px);
    }

    transform: scale(1);
    transition: 0.3s all ease;
  }

  .layout-pin {
    padding-left: 275px !important;
    transform: scale(1);
    transition: 0.3s all ease;
  }

  // Sider
  .ant-layout-sider {
    background-color: #0f1013;
    position: absolute;
    z-index: 1000;
    height: 100%;
    min-width: auto !important;
    max-width: none !important;
    flex: auto !important;
    transition: width 0.3s ease;
    width: 275px !important;

    span {
      color: #9091a5;
    }

    .ant-menu {
      background-color: transparent;
      border-right: none;

      .ant-menu-submenu-arrow {
        color: #9091a5;
      }

      .ant-menu-item {
        margin: 30px 0;

        .ant-menu-title-content {
          font-weight: 700;
        }
      }

      .ant-menu-item-only-child {
        margin: 8px 0;
      }
    }

    .ant-layout-sider-children {
      border-right: 1px solid #1d1e25;

      .ant-menu-root {
        overflow-y: auto;
        height: calc(100% - 74px);
        padding: 0 20px;
        overflow-x: hidden;
        padding-right: 25px !important;

        .ant-menu-item-selected {
          .ant-menu-title-content {
            color: #ffffff;
          }
        }
      }

      .row-logo {
        height: 74px;
        border-bottom: 1px dashed #3b3b3b;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 23px;

        .collapsed-icon {
          position: absolute;
          right: -13px;
          background-color: #26272f;
          z-index: 100;
          border-radius: 5px;
        }

        .logo-text {
          position: absolute;
          opacity: 1;
          left: 55px;
          transition: opacity 1.3s ease;
        }
      }
    }

    .ant-menu-item-selected {
      background-color: transparent;
      border-radius: 8px;

      &::after {
        display: none !important;
      }
    }
  }

  .ant-layout-sider-collapsed {
    width: 80px !important;

    .logo-text {
      opacity: 0 !important;
      left: -150px !important;
      transition: opacity 0s ease !important;
    }

    .ant-menu-item-selected {
      display: flex !important;
      padding-left: 8px !important ;
    }
  }

  .background-item-selected {
    .ant-menu-item-selected {
      background-color: #1a1a1e;
    }
  }

  ${responsiveLayoutUI}
`;

export const PopupBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent;
`;
