import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const SPOTS_API = {
  getSpotsAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/spots', { params });

    const data = get(response, 'data', null);

    return data;
  },
  createSpotAPI: async (params: SpotsStore.ParamsSpots) => {
    const response = await new AxiosClient().post('spots', params);

    const data = get(response, 'message', null);

    return data;
  },
  updateSpotsAPI: async (params: SpotsStore.ParamsUpdateSpots) => {
    const getParams = { name: params.name, terminalId: params.terminalId };

    const response = await new AxiosClient().patch(`/spots/${params.id}`, getParams);

    const data = get(response, 'message', null);

    return data;
  },
  deleteSpotAPI: async (id: number) => {
    const response = await new AxiosClient().delete(`/spots/${id}`);

    const data = get(response, 'message', null);

    return data;
  },
};

export default SPOTS_API;
