import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEY } from 'src/shared/constants';

export const paramSearchStreamAction = createAsyncThunk<any, any>(
  'param_search_stream',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

    try {
      localStorage.setItem(LOCAL_STORAGE_KEY.TERMINAL_ID, terminalId ?? data?.terminalId);

      return data;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  },
);
