import { createSlice } from '@reduxjs/toolkit';

import { paramSearchStreamAction } from './paramSearchStream.action';
import { LOCAL_STORAGE_KEY } from 'src/shared/constants';

const initialState: any = {
  order: null,
  search: null,
  terminalId: Number(localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID)),
};

const { actions, reducer } = createSlice({
  name: 'search_stream_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(paramSearchStreamAction.fulfilled, (state, action) => {
      state.order = action.payload?.order;
      state.search = action.payload?.search;
      state.terminalId = action.payload?.terminalId;
    });
  },
});

export { reducer };
export default actions;
