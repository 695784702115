import { useFormik } from 'formik';
import * as yup from 'yup';

import { Labels } from 'src/interfaces/pages/labels';
import { useAppDispatch } from 'src/stores';
import { createLabelAction, updateLabelAction } from 'src/stores/screens/privateScreens/labels/labels.action';
import { TYPE_CAMERA } from 'src/shared/constants';

const useForm = ({ labelSelected, isValidateCamera, setIsShowModal }): Labels.useForm => {
  const dispatch = useAppDispatch();

  const initialValues: Labels.InitialValues = {
    name: '',
    terminalId: null,
    cameraCar: '',
    cameraHuman: '',
    buttonId: null,
  };

  const Labelschema = yup.object().shape({
    terminalId: yup.string().required('を入力してください 1'),
    name: yup.string().trim().required('グループ名を入力してください'),
    cameraCar: yup.string().when('validateCamera', {
      is: () => {
        return isValidateCamera;
      },
      then: yup.string().required('少なくとも 1 台のカメラを選択してください'),
    }),
    cameraHuman: yup.string().when('validateCamera', {
      is: () => {
        return isValidateCamera;
      },
      then: yup.string().required('少なくとも 1 台のカメラを選択してください'),
    }),
  });

  const submitForm = async (data: LabelsStore.ParamsLabels): Promise<void> => {
    const cameraList: any = [];

    if (data.cameraCar) {
      cameraList.push({
        id: data.cameraCar,
        type: TYPE_CAMERA.CAR.VALUE,
      });
    }

    if (data.cameraHuman) {
      cameraList.push({ id: data.cameraHuman, type: TYPE_CAMERA.HUMAN.VALUE });
    }

    const convertData = {
      terminalId: data.terminalId,
      name: data.name,
      cameraList,
      buttonId: data.buttonId,
    };

    if (labelSelected?.id) {
      const value = { id: labelSelected.id, ...convertData };
      await dispatch(updateLabelAction({ data: value, callback: () => setIsShowModal(false) }));
      return;
    }
    await dispatch(createLabelAction({ data: convertData, callback: () => setIsShowModal(false) }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Labelschema,
    initialValues,
    onSubmit: value => {
      void submitForm(value);
    },
    validateOnChange: true,
  });

  return {
    formik,
  };
};

export default useForm;
