import React, { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { deleteTerminalActions, getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';
import { Terminals } from 'src/interfaces/pages/terminals';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_MASTER, MESSAGE_POPUP } from 'src/shared/constants/message';

const { confirm } = Modal;

function useInit(): Terminals.UseInitTerminal {
  const dispatch = useAppDispatch();

  const [isShowModal, setIsShowModal] = useState(false);
  const [terminalSelected, setTerminalSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const { terminals, totalPageTerminals, isLoading } = useAppSelector(state => state.terminals);

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: '110px',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: '拠点名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '拠点ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'アクション',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon component={PencilEditIcon} onClick={() => handleShowModal(true, record)} />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirmFirst(record.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const handleShowModal = (isShow: boolean, data: any): void => {
    setIsShowModal(isShow);
    setTerminalSelected(data);
  };

  const fetchDataTerminals = async (params: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(getTerminalAction({ data: { ...params, size: 10 } }));
  };

  useEffect(() => {
    void fetchDataTerminals({ page: currentPage });
  }, []);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  const showConfirmFirst = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        showConfirmSecond(id);
      },
      onCancel() {},
      okText: MESSAGE_MASTER.COMMON.DELETE,
      cancelText: MESSAGE_MASTER.COMMON.CANCEL,
    });
  };

  const showConfirmSecond = (id: number): void => {
    confirm({
      title: MESSAGE_MASTER.TERMINAL.DO_YOU_WANT_DELETE_TERMINAL,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteTerminalActions({
            id,
            callback: () => {
              void setIsShowModal(false);
              void fetchDataTerminals({ page: currentPage });
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_MASTER.COMMON.DELETE,
      cancelText: MESSAGE_MASTER.COMMON.CANCEL,
    });
  };

  return {
    columns,
    dataSource: terminals,
    isLoading,
    totalPageTerminals,
    terminalSelected,
    currentPage,
    isShowModal,
    setIsShowModal,
    handleShowModal,
    fetchDataTerminals,
    setCurrentPage,
  };
}

export default useInit;
