import { ROUTERS } from 'src/shared/constants/routers';

interface IUseVerifyRouter {
  isCheckRouterPublic: (param: string) => boolean;
  isCheckRouterAdminPrivate: (param: string) => boolean;
  isCheckRouterAdvanceAdminPrivate: (param: string) => boolean;
  isCheckRouterGeneralMonitorPrivate: (param: string) => boolean;
  isCheckRouterUserPrivate: (param: string) => boolean;
}

const useVerifyRouter = (): IUseVerifyRouter => {
  const isCheckRouterPublic = (param: string): boolean => {
    return [
      ROUTERS.HOME.PATH,
      ROUTERS.LOGIN.PATH,
      ROUTERS.REGISTER.PATH,
      ROUTERS.RESET_PASSWORD.PATH,
      ROUTERS.REMIND_CHANGE_PASSWORD.PATH,
    ].includes(param);
  };

  const isCheckRouterAdminPrivate = (param: string): boolean => {
    return [
      ROUTERS.HOME.PATH,
      ROUTERS.DASHBOARD.PATH,
      ROUTERS.DASHBOARD_SECOND.PATH,
      ROUTERS.PROFILE.PATH,
      ROUTERS.SUBMIT_STREAMING.PATH,
      ROUTERS.USER_MANAGEMENT.PATH,
      ROUTERS.MASTER_MANAGEMENT.PATH,
      ROUTERS.LABEL.PATH,
      ROUTERS.CAMERA.PATH,
      ROUTERS.ONE_CLICK.PATH,
      ROUTERS.TERMINAL.PATH,
      ROUTERS.SPOTS.PATH,
      ROUTERS.PLANE_NUMBER.PATH,
      ROUTERS.SYSTEM_SETTINGS.PATH,
      ROUTERS.DASHBOARD_SETTING.PATH,
    ].includes(param);
  };

  const isCheckRouterAdvanceAdminPrivate = (param: string): boolean => {
    return [
      ROUTERS.HOME.PATH,
      ROUTERS.DASHBOARD.PATH,
      ROUTERS.DASHBOARD_SECOND.PATH,
      ROUTERS.SUBMIT_STREAMING.PATH,
      ROUTERS.DASHBOARD_SETTING.PATH,
    ].includes(param);
  };

  const isCheckRouterGeneralMonitorPrivate = (param: string): boolean => {
    return [
      ROUTERS.HOME.PATH,
      ROUTERS.DASHBOARD.PATH,
      ROUTERS.DASHBOARD_SECOND.PATH,
      ROUTERS.DASHBOARD_SETTING.PATH,
    ].includes(param);
  };

  const isCheckRouterUserPrivate = (param: string): boolean => {
    return [ROUTERS.SUBMIT_STREAMING.PATH].includes(param);
  };

  return {
    isCheckRouterPublic,
    isCheckRouterAdminPrivate,
    isCheckRouterAdvanceAdminPrivate,
    isCheckRouterGeneralMonitorPrivate,
    isCheckRouterUserPrivate,
  };
};

export default useVerifyRouter;
