import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const SUBMIT_STREAM_API = {
  submitStreamAPI: async (params: SubmitStreamingStore.ParamsSubmit) => {
    const response = await new AxiosClient().post('/streams', params);

    const data = get(response, 'data', null);

    return data;
  },

  getLabelsAPI: async (params: SubmitStreamingStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/labels', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getSpotsAPI: async (params: SubmitStreamingStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/spots', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getAirplaneAPI: async (params: SubmitStreamingStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/airplanes', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getStreamAPI: async () => {
    const response = await new AxiosClient().get('/streams');

    const data = get(response, 'data', null);

    return data;
  },

  getStreamDetailAPI: async () => {
    const response = await new AxiosClient().get('/streams/submit-detail');

    const data = get(response, 'data', null);

    return data;
  },

  alertStreamAPI: async (id: number, typeAlert: string, isZoomFull?: boolean) => {
    const response = await new AxiosClient().patch(`/streams/${id}/status`, { alertStatus: typeAlert, isZoomFull });

    const data = get(response, 'data', null);

    return data;
  },

  deleteStreamAPI: async (id: number) => {
    const response = await new AxiosClient().delete(`/streams/${id}`);

    const data = get(response, 'data', null);

    return data;
  },
};

export default SUBMIT_STREAM_API;
