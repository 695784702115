import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  createAirplaneAction,
  getPlaneNumerAction,
  updateAirplaneAction,
} from 'src/stores/screens/privateScreens/common/common.action';

interface IUseForm {
  formik: FormikProps<any>;
  setIsUpdate: (value: boolean) => void;
  setIdUpdate: (value: number) => void;
}

const useForm = ({ setIsShowModal }): IUseForm => {
  const dispatch = useAppDispatch();
  const { pagePlaneNum } = useAppSelector(state => state.common);

  const [isUpdate, setIsUpdate] = useState(false);
  const [idUpdate, setIdUpdate] = useState(1);

  const cameraSchema = yup.object().shape({
    name: yup.string().trim().required('機番を入力してください'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: cameraSchema,
    onSubmit: value => {
      handleSubmitForm(value);
    },
  });

  const handleSubmitForm = (value: CommonStore.InitValueCreateAirPlane): void => {
    if (!isUpdate) {
      void dispatch(
        createAirplaneAction({
          name: value.name,
          callback: () => {
            void dispatch(getPlaneNumerAction({ data: { haveLabel: true, size: 10, page: pagePlaneNum } }));
            setIsShowModal(false);
          },
        }),
      );
    } else {
      void dispatch(
        updateAirplaneAction({
          name: value.name,
          id: idUpdate,
          callback: () => {
            void dispatch(getPlaneNumerAction({ data: { haveLabel: true, size: 10, page: pagePlaneNum } }));
            setIsShowModal(false);
          },
        }),
      );
    }
  };

  return { formik, setIsUpdate, setIdUpdate };
};

export default useForm;
