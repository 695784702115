import React from 'react';

const AvatarDefaultIcon: React.FC = () => {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="28" height="28" rx="6" fill="#EFEFF2" />
      <g opacity="0.3">
        <path
          d="M13.9053 14.92C16.6446 14.92 18.8653 12.6993 18.8653 9.96C18.8653 7.22067 16.6446 5 13.9053 5C11.166 5 8.94531 7.22067 8.94531 9.96C8.94531 12.6993 11.166 14.92 13.9053 14.92Z"
          fill="#9096AE"
        />
      </g>
      <path
        d="M21.6046 25C21.768 25.0093 21.9316 24.9832 22.0841 24.9236C22.2366 24.864 22.3745 24.7723 22.4883 24.6546C22.6022 24.5369 22.6894 24.3961 22.7439 24.2418C22.7985 24.0874 22.8192 23.9231 22.8046 23.76C22.1646 20 18.4246 17.11 13.9046 17.11C9.38456 17.11 5.64456 20 5.00456 23.76C4.98993 23.9231 5.01061 24.0874 5.06518 24.2418C5.11975 24.3961 5.20693 24.5369 5.32079 24.6546C5.43466 24.7723 5.57253 24.864 5.72503 24.9236C5.87753 24.9832 6.04109 25.0093 6.20456 25H21.6046Z"
        fill="#9096AE"
      />
    </svg>
  );
};

export default AvatarDefaultIcon;
