import React from 'react';

const TwoScreenIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.45"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75206 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
        fill="#2C2F36"
      />
      <path
        opacity="0.3"
        d="M8.96999 20L14.46 20C16.1003 20 17.43 18.6703 17.43 17.03L17.43 8.97C17.43 7.32971 16.1003 6 14.46 6L8.96999 6C7.32971 6 5.99999 7.32971 5.99999 8.97L5.99999 17.03C5.99999 18.6703 7.32971 20 8.96999 20Z"
        fill="#3699FF"
      />
      <path
        d="M18.93 17C18.9277 18.165 18.4708 19.283 17.6564 20.1161C16.842 20.9491 15.7346 21.4313 14.57 21.46L14.57 23C14.57 23.7956 14.8861 24.5587 15.4487 25.1213C16.0113 25.6839 16.7744 26 17.57 26L23 26C23.7956 26 24.5587 25.6839 25.1213 25.1213C25.6839 24.5587 26 23.7956 26 23L26 15C26 14.2044 25.6839 13.4413 25.1213 12.8787C24.5587 12.3161 23.7957 12 23 12L18.9 12L18.93 17Z"
        fill="#3699FF"
      />
      <path
        d="M9.6856 17C9.13332 17 8.6856 16.5522 8.6856 16V15.6972C8.6856 15.4184 8.80196 15.1523 9.0066 14.9631L11.7216 12.452C11.9136 12.268 12.0536 12.108 12.1416 11.972C12.2376 11.836 12.3016 11.716 12.3336 11.612C12.3656 11.5 12.3816 11.396 12.3816 11.3C12.3816 11.092 12.3136 10.932 12.1776 10.82C12.0416 10.7 11.8376 10.64 11.5656 10.64C11.3176 10.64 11.0816 10.708 10.8576 10.844C10.4773 11.0613 10.012 11.2612 9.62023 11.0653L9.27334 10.8918C8.68089 10.5956 8.4406 9.84868 8.93334 9.40603C9.12074 9.23769 9.33016 9.085 9.5616 8.94796C10.1696 8.58796 10.9256 8.40796 11.8296 8.40796C12.4936 8.40796 13.0816 8.51596 13.5936 8.73196C14.1056 8.94796 14.5056 9.25196 14.7936 9.64396C15.0816 10.036 15.2256 10.5 15.2256 11.036C15.2256 11.308 15.1896 11.58 15.1176 11.852C15.0536 12.124 14.9176 12.412 14.7096 12.716C14.5016 13.012 14.1936 13.344 13.7856 13.712L11.5056 15.788L11.0616 14.804H14.4176C14.9699 14.804 15.4176 15.2517 15.4176 15.804V16C15.4176 16.5522 14.9699 17 14.4176 17H9.6856Z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default TwoScreenIcon;
