/* eslint-disable multiline-ternary */
import React from 'react';
import { Pagination, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { WrapperStyled } from './styled';

type Props = {
  columns: ColumnsType<any>;
  dataSource: any[];
  rowKey: string;
  totalPages: number;
  loading?: boolean;
  heightNotUse?: number;
  currentPage?: number;
  onChangePage: (page) => void;
} & Omit<TableProps<any>, 'children'>;

const TableCard: React.FC<Props> = ({
  columns,
  dataSource,
  rowKey,
  totalPages,
  currentPage,
  loading,
  onChangePage,
}) => {
  return (
    <WrapperStyled
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      pagination={false}
      scroll={{
        y: '100%',
      }}
      locale={{
        emptyText: <React.Fragment />,
      }}
      className={`${dataSource?.length === 0 && 'disabled-footer'}`}
      footer={() =>
        dataSource?.length > 0 ? (
          <Pagination
            showSizeChanger={false}
            total={totalPages}
            current={currentPage}
            showPrevNextJumpers={false}
            pageSizeOptions={[500]}
            onChange={page => {
              onChangePage(page);
            }}
          />
        ) : (
          <React.Fragment />
        )
      }
    />
  );
};

export default TableCard;
