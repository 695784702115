import { ROUTERS } from '../constants';

const useTitleHeader = (pathName: string): String => {
  let title: string = '';

  switch (pathName) {
    case ROUTERS.DASHBOARD.PATH:
      title = ROUTERS.DASHBOARD.LABEL;
      break;
    case ROUTERS.SUBMIT_STREAMING.PATH:
      title = ROUTERS.SUBMIT_STREAMING.LABEL;
      break;
    case ROUTERS.MASTER_MANAGEMENT.PATH:
      title = ROUTERS.MASTER_MANAGEMENT.LABEL;
      break;
    case ROUTERS.USER_MANAGEMENT.PATH:
      title = ROUTERS.USER_MANAGEMENT.LABEL;
      break;

    case ROUTERS.SYSTEM_SETTINGS.PATH:
      title = ROUTERS.SYSTEM_SETTINGS.LABEL;
      break;

    case ROUTERS.TERMINAL.PATH:
    case ROUTERS.SPOTS.PATH:
    case ROUTERS.LABEL.PATH:
    case ROUTERS.CAMERA.PATH:
    case ROUTERS.ONE_CLICK.PATH:
    case ROUTERS.PLANE_NUMBER.PATH:
      title = ROUTERS.MASTER_MANAGEMENT.LABEL;
      break;

    default:
      break;
  }

  return title;
};

export default useTitleHeader;
