import React from 'react';

const CloseAlertIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_366_2271)">
        <path
          opacity="0.45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75207 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
          fill="white"
        />
        <path
          d="M17.228 19.5871C17.228 17.6855 18.5838 16.0906 20.4224 15.6339L16.6652 9.41768C16.2759 8.77363 15.3416 8.77414 14.953 9.4186L8.91427 19.434C8.51242 20.1005 8.99239 20.9503 9.77065 20.9503H17.4765C17.3203 20.5209 17.228 20.0643 17.228 19.5871ZM16.5182 18.9056H15.0985V17.5424H16.5182V18.9056ZM16.5182 16.1792H15.0985V13.4528H16.5182V16.1792ZM23.4755 17.6386C23.7615 17.9131 23.7609 18.3706 23.4744 18.6445L22.488 19.5871L23.4744 20.5298C23.7609 20.8037 23.7615 21.2612 23.4755 21.5357C23.2056 21.795 22.779 21.7945 22.5096 21.5346L21.4871 20.5482L20.4653 21.534C20.1958 21.7939 19.7689 21.7937 19.4998 21.5334C19.2166 21.2596 19.2174 20.8055 19.5015 20.5327L20.4862 19.5871L19.5021 18.6422C19.218 18.3694 19.218 17.9149 19.5021 17.6421C19.7704 17.3845 20.1941 17.3845 20.4624 17.6421L21.4871 18.6261L22.5096 17.6397C22.779 17.3798 23.2056 17.3793 23.4755 17.6386Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_366_2271">
          <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseAlertIcon;
