import { createSlice } from '@reduxjs/toolkit';
import {
  getTerminalAction,
  createTerminalAction,
  updateTerminalAction,
  deleteTerminalActions,
} from './terminals.action';

const initialState: TerminalsStore.TerminalReducer = {
  isLoading: false,
  isUpdated: false,
  terminals: [],
  totalPageTerminals: 0,
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'terminal_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTerminalAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTerminalAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = false;
        state.terminals = action.payload?.rows;
        state.totalPageTerminals = action?.payload?.total;
      })
      .addCase(getTerminalAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(createTerminalAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(updateTerminalAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(deleteTerminalActions.fulfilled, (state, action) => {
        state.isUpdated = true;
      });
  },
});

export { reducer };
export default actions;
