import { FormikProps, useFormik } from 'formik';
import { DefaultOptionType } from 'antd/lib/select';
import * as yup from 'yup';
import { useState } from 'react';

import { TYPE_CAMERA } from 'src/shared/constants';
import { useAppDispatch } from 'src/stores';
import { createCameraAction, updateCameraAction } from 'src/stores/screens/privateScreens/common/common.action';

interface IUseForm {
  formik: FormikProps<any>;
  optionsSelectTypeCamera: DefaultOptionType[];
  setIsUpdate: (value: boolean) => void;
  setIdUpdate: (value: number) => void;
  fetchDataCameras?: (params?: CommonStore.ParamsGetList) => Promise<void>;
}

const useForm = ({ setIsShowModal, fetchDataCameras }): IUseForm => {
  const dispatch = useAppDispatch();

  const [isUpdate, setIsUpdate] = useState(false);
  const [idUpdate, setIdUpdate] = useState(1);

  const cameraSchema = yup.object().shape({
    terminalId: yup.string().required('を入力してください'),
    serialNum: yup.string().trim().required('カメラの端末番号（シリアル番号）が既に存在します'),
    type: yup.string().required('端末種類を入力してください ').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      terminalId: null,
      serialNum: '',
      type: '',
      name: '',
    },
    validationSchema: cameraSchema,
    onSubmit: value => {
      handleSubmitForm(value);
    },
  });

  const optionsSelectTypeCamera = [
    {
      label: TYPE_CAMERA.CAR.LABEL,
      value: TYPE_CAMERA.CAR.VALUE,
    },
    {
      label: TYPE_CAMERA.HUMAN.LABEL,
      value: TYPE_CAMERA.HUMAN.VALUE,
    },
  ];

  const handleSubmitForm = (value: CommonStore.InitValueCreateCamera): void => {
    if (!isUpdate) {
      void dispatch(
        createCameraAction({
          serialNum: value.serialNum,
          type: value.type,
          terminalId: value.terminalId,
          callback: () => {
            fetchDataCameras();
            setIsShowModal(false);
          },
        }),
      );
    } else {
      void dispatch(
        updateCameraAction({
          serialNum: value.serialNum,
          type: value.type,
          id: idUpdate,
          callback: () => {
            fetchDataCameras();
            setIsShowModal(false);
          },
        }),
      );
    }
  };

  return { formik, optionsSelectTypeCamera, setIsUpdate, setIdUpdate };
};

export default useForm;
