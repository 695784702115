/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react';
import { Col, Modal, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';

import Twelve from './TwelveCard';
import TenCard from './TenCard';
import useInit from './hooks/useInit';
import {
  ALERT_STATUS,
  LOCAL_STORAGE_KEY,
  MAX_CARD_DISPLAY,
  TIME_REQUEST_THUMBNAIL,
  TYPE_ALERT,
} from 'src/shared/constants';
import { DasboardSecondV2Screen } from 'src/screens/privateScreens/index';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { WarningModalIcon } from 'src/assets/icons';
import { useAppSelector } from 'src/stores';
import useSafieSDK from 'src/shared/hooks/useSafieSDK';
import { Spinning } from 'src/shared/components';
import { WrapperListVideo, WrapperStyle } from './styled';

const { Text } = Typography;
const { confirm } = Modal;

const DashboardScreen: React.FC = () => {
  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);

  const {
    totalItem,
    dataDashboardSecond,
    getItemOfPage,
    quantityCardSecond,
    page,
    terminalId,
    dataVideo,
    mergeVideo,
    isLoading,
    isLoadingDashboardSecond,
    totalPin,
    isPlayPause,
    fetchDataVideo,
    alertStream,
    updateStatusVideoZoom,
  } = useInit();

  const { order, search } = useAppSelector(state => state.paramSearchStream);
  const { meInfo } = useAppSelector(state => state.auth);
  const { dataSetting } = useAppSelector(state => state.dashboard);

  const { isMobile } = useWindowSize();
  const { addThumbnail } = useSafieSDK();

  const headerCard = (item): React.ReactNode => (
    <Row className="row-title">
      <Col className="col-title">
        <Text className="text-content">
          <span className="name">スポット</span>
          <Text className="value font-helvetica">{item?.spot?.name}</Text>
        </Text>
        <Text className="text-content">
          <span className="name">便名</span>
          <Text className="value font-helvetica">JL{item?.flightNum}</Text>
        </Text>
        <Text className="text-content">
          <span className="name">機番</span>
          <Text className="value font-helvetica">{item?.airplane?.name}</Text>
        </Text>
      </Col>
    </Row>
  );

  const handleOkConfirm = (id: number, isZoom: boolean, typeAlert?: string): void => {
    if (typeAlert === TYPE_ALERT.ALERT_PRIMARY) {
      void alertStream(id, ALERT_STATUS.ALERT_MUTED);
    } else {
      void alertStream(id, ALERT_STATUS.NORMAL);
    }
  };

  const showConfirm = (id: number, isZoom: boolean, typeAlert?: string): void => {
    if (typeAlert === TYPE_ALERT.ALERT_PRIMARY) {
      handleOkConfirm(id, isZoom, typeAlert);
    } else {
      confirm({
        title: MESSAGE_POPUP.DO_YOU_WANT_TO_CLOSE_ALERT_OF_USER_THESE_STREAM,
        icon: <Icon component={WarningModalIcon} />,
        onOk() {
          handleOkConfirm(id, isZoom, typeAlert);
        },
        onCancel() {},
        okText: MESSAGE_POPUP.CLOSE_ALERT_NOTI,
        cancelText: MESSAGE_POPUP.CANCEL,
      });
    }
  };

  const clickZoom = (id: number, isZoom: boolean): void => {
    if (!isZoom) {
      updateStatusVideoZoom(id, true);
    }
  };

  const isGroup18Item = Number(itemOfPage) === 6 || Number(itemOfPage) === 8 || Number(itemOfPage) === 18;

  useEffect(() => {
    const dataSettingMonitorStorage = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '');

    const TIME_OUT_CHANGE_PAGE = Number(dataSetting?.switchListSecond) * 1000;

    let intervalSwitchList: any;
    if (dataSettingMonitorStorage?.isSwitchList) {
      if (dataSettingMonitorStorage?.isPlayPause) {
        if (dataDashboardSecond?.length <= 2) {
          intervalSwitchList = setInterval(() => {
            const orderStorage = localStorage.getItem(LOCAL_STORAGE_KEY.ORDER);
            const searchStorage = localStorage.getItem(LOCAL_STORAGE_KEY.SEARCH);

            const endPage = totalItem / Number(itemOfPage);

            const newPage = page < endPage ? page + 1 : 1;

            if (endPage > 1) {
              void fetchDataVideo(
                {
                  size: Number(itemOfPage),
                  page: newPage,
                  order: orderStorage ?? order,
                  search: searchStorage ?? search,
                  terminalId: terminalId ?? meInfo?.terminalId,
                },
                () => {
                  localStorage.setItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST, `${newPage}`);
                },
              );
            }
          }, TIME_OUT_CHANGE_PAGE);
        }
      }
    }

    return () => {
      clearInterval(intervalSwitchList);
    };
  }, [dataVideo, dataSetting, dataDashboardSecond, isPlayPause, order, search]);

  useEffect(() => {
    if (dataDashboardSecond?.slice(2, 4)?.length === 0) {
      const addThumbnailInterVal = setInterval(() => {
        void (async () => {
          await addThumbnail(dataVideo);
        })();
      }, TIME_REQUEST_THUMBNAIL);
      return () => {
        clearInterval(addThumbnailInterVal);
      };
    }
  }, [dataVideo, dataDashboardSecond]);

  return (
    <React.Fragment>
      <Text className="quantity-wait-diplay">
        {totalPin > MAX_CARD_DISPLAY &&
          `表示されていない通知が
          ${totalPin - MAX_CARD_DISPLAY}
          個あります。表示するためには、現在表示されている通知をクローズしてください。`}
      </Text>

      {isLoading || isLoadingDashboardSecond ? (
        <Spinning isLoading={true} />
      ) : (
        <WrapperStyle>
          <WrapperListVideo
            itemOfPage={dataDashboardSecond?.length >= 3 ? 0 : getItemOfPage}
            isItem6Wrap={Number(itemOfPage) === 6}
            isItem18Wrap={dataDashboardSecond?.length >= 3 ? false : Number(itemOfPage) === 18}
            isShowTotalPin={totalPin > MAX_CARD_DISPLAY}
          >
            {quantityCardSecond >= 3 ? (
              <div className="list-alert">
                <DasboardSecondV2Screen
                  isDashboardList={true}
                  startIndex={2}
                  endIndex={4}
                  isMobileListScreen={isMobile}
                />
              </div>
            ) : (
              <div className={`${isGroup18Item && 'list-card-type-18'}`} style={{ height: '100%' }}>
                {getItemOfPage === 6 || isGroup18Item ? (
                  <Twelve
                    clickZoom={clickZoom}
                    currentPage={page}
                    fetchDataVideo={fetchDataVideo}
                    headerCard={headerCard}
                    itemOfPage={itemOfPage}
                    mergeVideo={mergeVideo}
                    order={order}
                    search={search}
                    showConfirm={showConfirm}
                    terminalId={terminalId}
                    totalItem={totalItem}
                    isGroup18Item={isGroup18Item}
                  />
                ) : (
                  <TenCard
                    clickZoom={clickZoom}
                    currentPage={page}
                    fetchDataVideo={fetchDataVideo}
                    headerCard={headerCard}
                    itemOfPage={itemOfPage}
                    mergeVideo={mergeVideo}
                    order={order}
                    search={search}
                    showConfirm={showConfirm}
                    terminalId={terminalId}
                    totalItem={totalItem}
                  />
                )}
              </div>
            )}
          </WrapperListVideo>
        </WrapperStyle>
      )}
    </React.Fragment>
  );
};

export default DashboardScreen;
