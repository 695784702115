import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import SETTINGS_API from './settings.api';
import { THEME_TYPE } from 'src/shared/constants';

export const getKeySafieAction = createAsyncThunk<any, { terminalId: number }>(
  'get_key',
  async ({ terminalId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SETTINGS_API.getKeySafieAPI(terminalId);

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const settingKeySafieAction = createAsyncThunk<any, SettingStore.RequestSettingKeySafie>(
  'setting_key',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SETTINGS_API.settingKeySafieAPI(data);
      callback();
      notification.success({ message: '設定しました' });
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const settingSoracomAction = createAsyncThunk<any, SettingStore.RequestSettingTerminal>(
  'setting_soracom',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      await SETTINGS_API.settingSoracomAPI(data);
      callback();
      notification.success({ message: 'SORACOMの設定をしました' });
      return data;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const settingSafieAction = createAsyncThunk<any, SettingStore.RequestSettingTerminal>(
  'setting_safie',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      await SETTINGS_API.settingSafieAPI(data);
      callback();
      notification.success({ message: 'Safieの設定をしました' });
      return data;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getSettingSoracomAction = createAsyncThunk<any, any>(
  'get_setting__sorcaom_terminal',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await SETTINGS_API.getSettingSoracomSystemAPI(id);

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getSettingSafieAction = createAsyncThunk<any, any>(
  'get_setting_safie_terminal',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await SETTINGS_API.getSettingSafieSystemAPI(id);

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const setThemeTypeAction = createAsyncThunk<any, SettingStore.RequestSettingTheme>(
  'setting_theme',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      localStorage.setItem(THEME_TYPE.TYPE, data);

      return data;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
