import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DefaultOptionType } from 'antd/lib/select';

import { Settings } from 'src/interfaces/pages/settings';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  getSettingSoracomAction,
  settingSoracomAction,
} from 'src/stores/screens/privateScreens/settings/settings.action';
import { schemaSoracom } from './schemaSoracom';

const useSoracom = (): Settings.useFormSystemSettings => {
  const dispatch = useAppDispatch();

  const { terminals } = useAppSelector(state => state.terminals);
  const { meInfo } = useAppSelector(state => state.auth);
  const { isLoading, dataSettingSoracomTerminal } = useAppSelector(state => state.settings);

  const [terminalId, setTerminalId] = useState(meInfo?.terminalId);

  const optionsSelectTerminal: DefaultOptionType[] = terminals?.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const initialValues: Settings.InitialValuesSystemSettings = {
    oneClickAction: dataSettingSoracomTerminal?.oneClickAction,
    doubleClickAction: dataSettingSoracomTerminal?.doubleClickAction,
    longClickAction: dataSettingSoracomTerminal?.longClickAction,
    authKey: dataSettingSoracomTerminal?.authKey,
    authKeyId: dataSettingSoracomTerminal?.authKeyId,
    terminalId,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schemaSoracom,
    onSubmit: values => {
      void changeSettingTerminal(values);
    },
  });

  const changeSettingTerminal = async (data): Promise<void> => {
    void dispatch(
      settingSoracomAction({
        data: {
          terminalId: meInfo?.terminalId,
          ...data,
        },
      }),
    );
  };

  useEffect(() => {
    void dispatch(getSettingSoracomAction(terminalId));
  }, [terminalId]);

  return {
    isLoading,
    optionsSelectTerminal,
    formik,
    setTerminalId,
    changeSettingTerminal,
  };
};

export default useSoracom;
