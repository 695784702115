import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const AUTH_API = {
  loginAPI: async (params: Auth.LoginRequestData) => {
    const response = await new AxiosClient().post('/auth/login', params);
    const data = get(response, 'data', null);
    return data;
  },

  verifyAuthAPI: async (params: Auth.VerifyAuthRequestData) => {
    const response = await new AxiosClient().post('/auth/verify', params);
    const data = get(response, 'data', null);
    return data;
  },

  registerAPI: async (params: Auth.RegisterRequestData) => {
    const response = await new AxiosClient().post('/auth/setting-password', params);
    const data = get(response, 'data', null);
    return data;
  },

  changePasswordAPI: async (params: Auth.ChangePasswordRequestData) => {
    const response = await new AxiosClient().post('/auth/change-password', params);
    const data = get(response, 'data', null);
    return data;
  },

  forgotPasswordAPI: async (params: Auth.ForgotPasswordRequestData) => {
    const response = await new AxiosClient().post('/auth/forgot-password', params);
    const data = get(response, 'data', null);
    return data;
  },

  getMeAPI: async () => {
    const response = await new AxiosClient().get('/auth/info');
    const data = get(response, 'data', null);
    return data;
  },

  downloadTemplateAPI: async () => {
    const response = await new AxiosClient().get('/auth/download-template', {
      responseType: 'blob',
    });

    return response;
  },

  downloadFileUsersAPI: async ({ terminalId, page }) => {
    const response = await new AxiosClient().get(`auth/download?terminalId=${terminalId}&page=${page}`, {
      responseType: 'blob',
    });

    return response;
  },

  uploadFileUserAPI: async (params: Auth.UploadFileUserRequestData) => {
    const config: any = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*',
      },
    };
    const response = await new AxiosClient().post('/auth/upload', params, config);
    const data = get(response, 'data', null);
    return data;
  },

  logoutAPI: async () => {
    const response = await new AxiosClient().post('/auth/logout');

    return response;
  },
};

export default AUTH_API;
