import { Content } from 'antd/lib/layout/layout';
import styled, { css } from 'styled-components';

import { SIZEW } from 'src/shared/constants';
import ResponsiveUI from 'src/shared/constants/reponsive';

const reponsiveNotFoundStyle = css`
  ${ResponsiveUI.lessThan(SIZEW.SM)} {
    .row-wrap {
      .en-error {
        font-size: 18px;
      }

      .ja-error {
        font-size: 18px;
      }

      .text-link {
        font-size: 16px;
      }
    }
  }
`;

export const NotFoundStyle = styled(Content)`
  .row-wrap {
    height: calc(100vh - 74px);
    justify-content: center;
    align-items: center;

    .screen-title {
      margin: 0;
      font-size: 72px;
      font-weight: 700;
      color: #fff;
      text-align: center;
    }

    .en-error {
      display: block;
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      text-align: center;
    }

    .ja-error {
      display: block;
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      text-align: center;
    }

    .text-link {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      text-align: center;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  ${reponsiveNotFoundStyle}
`;
