import Cookies from 'js-cookie';
import { Socket, io } from 'socket.io-client';
import { parse } from 'cookie';

import { STORAGE_KEY } from 'src/shared/constants';

function socket(): Socket {
  const token = Cookies.get(STORAGE_KEY.ACCESS_TOKEN);

  const socket: any = io(process.env.REACT_APP_SOCKET_URL ?? '', {
    auth: {
      token,
    },
    withCredentials: true,
    transports: ['websocket'],
  });

  const COOKIE_NAME = 'AWSALB';

  socket.io.on('open', () => {
    socket.io.engine.transport.on('pollComplete', () => {
      const request = socket.io.engine.transport.pollXhr.xhr;
      const cookieHeader = request.getResponseHeader('set-cookie');
      if (!cookieHeader) {
        return;
      }
      cookieHeader.forEach(cookieString => {
        if (cookieString.includes(`${COOKIE_NAME}=`)) {
          const cookie = parse(cookieString);
          socket.io.opts.extraHeaders = {
            cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`,
          };
        }
      });
    });
  });

  return socket;
}

export default socket;
