import { Button } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const ButtonStyle = styled(Button)`
  background-color: ${props => props.color};
  border-radius: 5px;
  color: ${COLOR.WHITE};
  border: none;

  &:hover,
  &:focus {
    color: ${COLOR.WHITE};
    background-color: ${props => props.color};
  }

  &:disabled {
    background-color: #26292f !important;
    color: #adaec1;

    &:hover,
    &:focus {
      color: ${COLOR.WHITE};
    }
  }
`;
