import React from 'react';

const LogoTextBlackIcon: React.FC = () => {
  return (
    <svg width="195" height="12" viewBox="0 0 195 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M193.592 7.94416C192.507 10.7661 188.761 11.64 184.716 11.64C180.671 11.64 178.074 10.762 178.679 7.94416H183.819C183.687 8.69522 184.142 9.17726 185.663 9.17726C187.183 9.17726 188.163 8.68499 188.449 7.94349C189.157 6.09999 178.984 7.90115 180.603 3.68363C181.509 1.3219 185.079 0.213745 189.106 0.213745C193.133 0.213745 195.418 1.08361 194.936 3.68159H189.893C189.973 2.98652 189.759 2.45872 188.244 2.45872C186.729 2.45872 185.915 2.99539 185.65 3.68363C184.927 5.56673 195.209 3.73415 193.592 7.94416Z"
        fill="black"
      />
      <path d="M103.594 11.3199H98.4534L102.592 0.540161H107.734L103.594 11.3199Z" fill="black" />
      <path d="M142.494 11.3199H137.353L141.491 0.540161H146.632L142.494 11.3199Z" fill="black" />
      <path
        d="M175.621 11.3199H163.121L167.261 0.540161H179.762L178.965 2.60898H171.607L170.773 4.78159H176.893L176.1 6.84905H169.978L169.14 9.03393H176.499L175.621 11.3199Z"
        fill="black"
      />
      <path
        d="M73.4386 11.3199H69.2791L65.7901 4.95366L63.3464 11.3199H59.1992L63.3382 0.540161H68.1682L71.2468 6.20724L73.4222 0.540161H77.581L73.4386 11.3199Z"
        fill="black"
      />
      <path
        d="M159.932 11.3199H155.773L152.282 4.95366L149.839 11.3199H145.688L149.826 0.540161H154.656L157.736 6.20724L159.91 0.540161H164.069L159.932 11.3199Z"
        fill="black"
      />
      <path
        d="M10.9929 7.71067C9.58504 11.3772 6.11379 11.6803 3.64076 11.6803C2.43981 11.6899 1.24135 11.5693 0.0664062 11.3205L0.944467 9.0332C3.30074 9.30631 5.31426 9.10148 6.10423 7.04769L8.60389 0.539429H13.7459L10.9922 7.70861"
        fill="black"
      />
      <path
        d="M40.1305 0.540116H32.1345L27.9968 11.3198H33.1381L34.6403 7.40614H37.4984C42.2505 7.40614 44.8089 6.13618 45.6391 3.97587C46.4694 1.81556 44.8881 0.539429 40.1312 0.539429M40.4964 3.97587C40.2954 4.38857 39.9813 4.73566 39.5906 4.9767C39.1999 5.21774 38.7487 5.34279 38.2897 5.33732H35.4343L36.4824 2.60621H39.3425C40.2793 2.60621 40.7989 3.18793 40.4964 3.97176"
        fill="black"
      />
      <path
        d="M118.923 0.540116H110.926L106.787 11.3198H111.928L113.43 7.40614H114.984L116 11.3198H121.613L120.239 7.01355C122.516 6.48508 123.869 5.43428 124.43 3.97587C125.259 1.81419 123.679 0.539429 118.921 0.539429M119.287 3.97587C119.085 4.38857 118.771 4.73563 118.38 4.97666C117.989 5.21768 117.538 5.34276 117.079 5.33732H114.224L115.272 2.60621H118.132C119.069 2.60621 119.588 3.18793 119.287 3.97176"
        fill="black"
      />
      <path
        d="M130.186 9.03531L133.455 0.540161H128.312L124.172 11.3199H135.848L136.725 9.03531H130.186Z"
        fill="black"
      />
      <path
        d="M25.6631 0.540161H21.0646C20.7603 0.558384 20.463 0.638795 20.1911 0.776429C19.9191 0.914063 19.6782 1.106 19.4833 1.34038L10.8694 11.3206H15.1163L16.828 9.19848H21.4709L21.5528 11.3206H26.6942L25.6638 0.540161M18.4994 7.12624L21.2674 3.68026L21.3951 7.12624H18.4994Z"
        fill="black"
      />
      <path
        d="M56.8601 0.540161H52.2616C51.9572 0.558224 51.6599 0.638575 51.3879 0.776221C51.1159 0.913866 50.875 1.10587 50.6803 1.34038L42.0649 11.3206H46.3118L48.0242 9.19848H52.6671L52.7491 11.3206H57.8904L56.8601 0.540161ZM49.6957 7.12624L52.4637 3.68026L52.592 7.12624H49.6957Z"
        fill="black"
      />
      <path
        d="M96.117 0.540161H91.5192C91.215 0.558475 90.9178 0.638944 90.646 0.776575C90.3741 0.914206 90.1334 1.10608 89.9386 1.34038L81.3232 11.3212H85.5708L87.2819 9.19917H91.9248L92.0067 11.3212H97.1473L96.117 0.540849M88.954 7.12693L91.7206 3.68095L91.8483 7.12693H88.954Z"
        fill="black"
      />
    </svg>
  );
};

export default LogoTextBlackIcon;
