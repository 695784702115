import { Row } from 'antd';
import styled, { css } from 'styled-components';

import { COLOR } from 'src/shared/constants';
import ResponsiveUI from 'src/shared/constants/reponsive';

const reponsiveWrapperStyled = css`
  ${ResponsiveUI.lessThan(1920)} {
    .list-alert {
      width: 1920px;
      height: 1080px;
      .col-item-alert {
        min-height: calc(1080px - 170px);
        min-width: calc(1920px / 2 - 200px);
      }
    }
  }

  ${ResponsiveUI.lessThan(1471)} {
    .list-alert {
      width: calc(1470px - 100px);
      height: calc(956px - 100px);

      .col-item-alert {
        min-height: calc(956px - 170px);
        min-width: calc(1470px / 2 - 100px);
      }
    }
  }
`;

export const WrapperStyle = styled.div`
  height: 100%;

  .griditemUI {
    padding: 10px;
    height: auto !important;
  }

  .griditemUI_grab {
    cursor: grab;
  }

  .pagination {
    display: flex;
    justify-content: end;
    margin-right: 10px;
  }
`;

export const WrapperFillterCard = styled(Row)`
  margin-bottom: 10px;
  height: 54px;
  background-color: #141414;
  box-shadow: 0px 5px 8px 0px rgba(70, 78, 95, 0.02);
  padding: 10px 30px;

  .ant-form-item {
    margin-bottom: 12px;
  }

  .col-search {
    display: flex;
    padding-right: 46px;

    .input-search {
      margin-right: 18px;
      width: 100%;
    }
  }

  .col-select {
    display: flex;

    .select-type {
      margin-right: 21px;
    }
  }

  .col-action {
    display: flex;
    justify-content: end;

    .btn_switch-display {
      margin-right: 20px;
      width: 123px;
    }

    .settings-icon {
      margin-left: 20px;
    }

    .switch-field {
      margin-right: 20px;
    }

    .btn-setting {
      margin-right: 20px;
    }
  }
`;

export const WrapperListVideo = styled.div<{
  itemOfPage?: number;
  isItem18Wrap?: boolean;
  isFullScreen?: boolean;
  isShowTotalPin?: boolean;
  isItem6Wrap?: boolean;
}>`
  width: 100%;
  padding-bottom: 10px;
  height: 100%;
  padding: ${props => (props.isItem18Wrap ? '3px 0' : props.itemOfPage === 5 ? '20px 60px' : '20px')};

  .ant-typography {
    margin: 0 !important;
  }

  .ant-list {
    height: 100%;

    .ant-spin-nested-loading {
      height: calc(100% - 40px);
      overflow-y: auto;
      padding: ${props => (props.isItem6Wrap ? '10px 200px' : '10px')};

      .ant-spin-container {
        .ant-row {
        }
      }
    }

    .ant-list-pagination {
      margin-top: 5px;
    }
  }

  .ant-list-item {
    border-radius: 6px;
    padding: ${props => (props.itemOfPage === 5 ? '15px 27px' : '15px')} !important;
    border: 1px solid #1f2126;
    background-color: ${COLOR.BACKGROUND_SECONDARY};
    margin: 0px ${props => (props.itemOfPage === 5 ? '10px' : '5px')};
    margin-bottom: ${props => (props.itemOfPage === 5 ? '10px' : '24px')} !important;

    .ant-card {
      background-color: transparent;

      * {
        color: ${COLOR.WHITE};
      }

      h2,
      h3,
      h4,
      h5,
      span {
        color: #9a9ab0;
      }
    }

    .ant-card-head {
      padding: 0;
      border-bottom: none;

      .ant-card-head-title {
        padding: 10px 0;
        margin-bottom: 4px;

        .row-title {
          justify-content: space-between;

          .col-title {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .ant-typography {
              border-radius: 8px;
              border: 1px dashed #383840;
              padding: 8px;
              font-size: 14px;
              min-width: calc(100% / 3 - 7px) !important;
              text-align: center;
            }

            .value {
              border: none;
              padding: 0;
              font-weight: 700;
              color: ${COLOR.WHITE};
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }

            .text-content {
              display: grid;

              .name {
                font-size: 8px;
                color: #595b69;
              }
            }
          }

          .no-alert,
          .has-alert {
            height: 10px;
            width: 10px;
            margin-top: 7px;
            border-radius: 50%;
          }

          .no-alert {
            background-color: ${COLOR.BACKGROUND_SECONDARY};
          }

          .has-alert {
            background-color: ${COLOR.BACKGROUND_PRIMARY};
          }
        }
      }
    }

    .ant-card-body {
      padding: 0;
    }
  }

  .ant-card {
    border: none;
  }

  .row-video {
    .col-video {
      height: 100%;
      position: relative;
      cursor: pointer;

      .id-video {
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 8px;
        background: rgba(22, 23, 28, 0.2);
        padding: 10px;
        color: ${COLOR.WHITE};
      }
    }

    .col-desc {
      padding-left: 14px;

      .col-info {
        display: grid;
        margin-bottom: 14px;

        .name-field {
          color: var(--Grey-Grey-600, #6d6d80);
          font-size: 8px;
          font-weight: 500;
        }

        .content {
          color: var(--Grey-Grey-800, #9a9ab0);
          font-size: 10px;
          font-weight: 700;
        }
      }
    }

    &:first-child {
      margin-bottom: 10px;
    }
  }

  .row-footer {
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    height: 24px;

    .right {
      display: flex;
      align-items: center;

      .icon-muted {
      }
    }

    .ant-typography {
      font-size: 10px;
      font-weight: 700;
      color: #adaec1 !important;
    }

    .card-stream-status {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #d9aa00;
    }
  }

  .list-alert {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    width: 100%;
    height: 100%;

    .col-item-alert {
      margin-right: 30px;
      min-height: calc(100vh - 150px);
      min-width: ${props => `calc(100vw / 2 - ${props.isShowTotalPin ? '200px' : '160px'})`};
      padding-top: ${props => `${props.isShowTotalPin ? '40px' : '0'}`};
    }
  }

  .border-dashed {
    margin-top: 10px;
    border: 3px dashed #3699ff;
    border-radius: 8px;
    background-color: #383840;
    height: ${props => `calc(100vh ${props.isFullScreen ? ' - 145px' : ' + 24px'})`};
  }

  .text-r {
    text-align: right;
  }

  .btn {
    width: 100px;
    height: 32px;
  }

  .btn-cancel {
    margin: 16px 0;
    background-color: #2c2f36;
    color: #adaec1;
  }

  .btn-save {
    margin: 10px;
  }

  .streaming-img {
    width: 100%;
  }

  .col-title-type-18 {
    padding-left: 10px;

    .text-group {
      background-color: #1f2126;
      margin-bottom: 12px;
      border-radius: 5px;
      padding: 4px;
      display: grid;

      .name {
        font-size: 8px;
      }

      .value {
        color: ${COLOR.WHITE};
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .list-card-type-18 {
    .ant-list-item {
      border-radius: 5px;
      padding: 10px !important;
      background-color: #1f2126;
    }

    .text-group {
      border-radius: 5px;
      padding: 6px;
      background-color: #15171c;

      .name {
        font-size: 8px;
        color: #595b69;
      }

      .value {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    .streaming-player {
      border-radius: 5px;
    }

    .row-footer .ant-typography {
      color: #adaec1 !important;
    }
  }

  .card-type-18 {
    .row-footer {
      display: flex;
    }
  }

  .alert-muted,
  .alert-primary {
    .row-footer {
      .ant-typography {
        color: ${COLOR.WHITE} !important;
      }
    }
  }

  .drop-drap-wrap {
    .griditemUI {
      padding: 10px;
      height: auto !important;
    }

    .griditemUI_grab {
      cursor: grab;
    }

    .ant-list-item {
      position: relative;
      margin: 0 !important;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
      }
    }
  }

  ${reponsiveWrapperStyled}
`;

export const ManeuverSettingWrap = styled(Row)`
  width: 100%;

  .ant-modal-content {
    background-color: #15171c;
  }

  .group-field {
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px dashed #383840;
    }
  }

  .group-title {
    font-size: 14px;
    font-weight: 700;
  }

  .field-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;

    .ant-typography {
      color: #adaec1;
      font-size: 14px;
    }
  }

  .field-list {
    display: flex;
    justify-content: space-between;

    span {
      color: #adaec1;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .input {
    width: 80px;
  }

  .input-key {
    margin-top: 20px;
  }

  .row-select {
    margin-bottom: 20px;
  }

  .field-select {
    width: 100%;

    .ant-form-item {
      margin: 0;
    }
  }
`;
