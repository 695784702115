import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { useLocation } from 'react-router-dom';

import socket from 'src/configs/socketIO/socketIO';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { SoundAlertFirst, SoundAlertSecond } from 'src/assets/audio';
import { ALERT_STATUS, LOCAL_STORAGE_KEY, ROUTERS, TYPE_SOCKET } from 'src/shared/constants';
import { fetchAllDataVideoAction } from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import useRole from './useRole';

const TIME_OUT = 3000;

const useSoundAlert = (): any => {
  const { dataVideoAll } = useAppSelector(state => state.dashboard);
  const { pathname } = useLocation();
  const { isGeneralUser } = useRole();
  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
  const { meInfo } = useAppSelector(state => state.auth);

  const dispatch = useAppDispatch();

  const [isNewAlert, setIsNewAlert] = useState<boolean>(false);

  const soundFirst = new Howl({
    src: [SoundAlertFirst],
    volume: 1,
    preload: true,
  });

  const soundSecond = new Howl({
    src: [SoundAlertSecond],
    volume: 0.3,
    preload: true,
  });

  useEffect(() => {
    if (!isGeneralUser) {
      void dispatch(fetchAllDataVideoAction({ data: { terminalId: meInfo?.terminalId, size: 30 } }));
    }

    let interval: any;

    const videoAlert = dataVideoAll?.filter(item => item?.alertStatus === ALERT_STATUS.ALERT);

    socket().on(TYPE_SOCKET.ALERT_STREAM, value => {
      if (!isGeneralUser) {
        void dispatch(fetchAllDataVideoAction({ data: { size: 30, terminalId: terminalId ?? meInfo?.terminalId } }));
      }

      if (value?.alertStatus === ALERT_STATUS.ALERT) {
        setIsNewAlert(true);
      } else {
        setIsNewAlert(false);
      }
    });

    socket().on(TYPE_SOCKET.DELETE_STREAM, _ => {
      const isLogout = localStorage.getItem(LOCAL_STORAGE_KEY.IS_LOGOUT);

      if (!isGeneralUser) {
        if (isLogout === 'false') {
          void dispatch(fetchAllDataVideoAction({ data: { size: 30, terminalId: terminalId ?? meInfo?.terminalId } }));
        }
      }
    });

    socket().on(TYPE_SOCKET.DASHBOARD_SEARCH, value => {
      if (value?.id === meInfo?.id) {
        void dispatch(fetchAllDataVideoAction({ data: { size: 30, terminalId: terminalId ?? meInfo?.terminalId } }));
      }
    });

    if (pathname !== ROUTERS.DASHBOARD_SECOND.PATH) {
      if (videoAlert.length > 0) {
        interval = setInterval(() => {
          soundSecond.play();
        }, TIME_OUT);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, []);

  useEffect(() => {
    let interval: any;

    const videoAlert = dataVideoAll?.filter(item => item?.alertStatus === ALERT_STATUS.ALERT);

    if (pathname !== ROUTERS.DASHBOARD_SECOND.PATH) {
      if (isNewAlert) {
        soundFirst.pause();
        if (videoAlert.length > 0) {
          soundFirst.play();
        }

        interval = setInterval(() => {
          if (videoAlert.length > 0) {
            setIsNewAlert(false);
            soundSecond.play();
          }
        }, TIME_OUT);

        return () => {
          clearInterval(interval);
        };
      } else {
        if (videoAlert.length > 0) {
          interval = setInterval(() => {
            soundSecond.play();
          }, TIME_OUT);

          return () => {
            clearInterval(interval);
          };
        } else {
          soundSecond.pause();

          return () => {
            clearInterval(interval);
          };
        }
      }
    }
  }, [dataVideoAll]);
};

export default useSoundAlert;
