import React from 'react';

const FileUploadIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M36.1067 47.7273H11.8933C8.73902 47.7273 5.71391 46.4885 3.48348 44.2834C1.25304 42.0783 0 39.0876 0 35.9691L8.41864e-06 11.7583C8.41864e-06 8.63981 1.25305 5.64907 3.48348 3.44399C5.71392 1.2389 8.73903 9.52952e-05 11.8933 9.52952e-05L23.1724 1.01774e-10C24.6959 -1.28722e-05 25.931 1.22103 25.931 2.72727V12.4246C25.931 17.5457 30.1303 21.6973 35.3103 21.6973L45.2414 21.6974C46.7649 21.6974 48 22.9184 48 24.4246L48 36.0218C47.9859 39.1311 46.7266 42.1083 44.4977 44.302C42.2688 46.4957 39.2518 47.7273 36.1067 47.7273Z"
        fill="white"
      />
      <path
        d="M22.6188 31.2965C22.6134 31.6849 22.4947 32.063 22.2776 32.3836C22.0605 32.7042 21.7547 32.9532 21.3982 33.0994C21.0418 33.2455 20.6505 33.2824 20.2734 33.2055C19.8963 33.1285 19.5501 32.9411 19.2779 32.6666L15.4932 28.7936L11.6564 32.6929C11.4713 32.878 11.2519 33.0245 11.0107 33.124C10.7695 33.2236 10.5113 33.2742 10.2507 33.2729C9.99017 33.2717 9.73241 33.2187 9.49216 33.1169C9.25191 33.0151 9.03387 32.8666 8.85049 32.6798C8.66711 32.4929 8.52198 32.2715 8.42339 32.028C8.32481 31.7846 8.27469 31.5239 8.2759 31.2609C8.27711 30.9979 8.32963 30.7377 8.43046 30.4952C8.53128 30.2527 8.67845 30.0326 8.86354 29.8475L14.0838 24.5781C14.4508 24.2081 14.9483 24.0002 15.4671 24.0002C15.9859 24.0002 16.4834 24.2081 16.8505 24.5781L22.0707 29.8475C22.2554 30.0383 22.3994 30.2654 22.4937 30.5146C22.5879 30.7639 22.6305 31.03 22.6188 31.2965Z"
        fill="white"
      />
      <rect x="13.2413" y="27.2729" width="3.86207" height="14.7273" rx="1.93103" fill="white" />
      <path
        d="M28.6897 12.1855V1.15321C28.6897 0.127867 29.9436 -0.385629 30.677 0.339398L47.6567 17.1262C48.3901 17.8512 47.8707 19.0909 46.8336 19.0909H35.6745C31.8169 19.0909 28.6897 15.9993 28.6897 12.1855Z"
        fill="white"
      />
    </svg>
  );
};

export default FileUploadIcon;
