import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField, SelectField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { useAppSelector } from 'src/stores';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { ModalContentWrap } from '../spots/styled';

interface Props {
  terminalSelects: any;
  spotSelected: any;
  terminalId: number | null;
  isOpen: boolean;
  setIsShowModal: (param: boolean) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({ terminalSelects, spotSelected, isOpen, terminalId, setIsShowModal }) => {
  const { meInfo } = useAppSelector(state => state.auth);

  const { formik } = useForm({ spotSelected, setIsShowModal });

  const { errors, touched, isValid, setFieldValue, resetForm, getFieldProps, handleSubmit } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
  };

  useEffect(() => {
    if (spotSelected?.id && isOpen) {
      setFieldValue('terminalId', terminalId ?? terminalId ?? meInfo?.terminalId);
      setFieldValue('name', spotSelected.name);
      return;
    }
    resetForm();
  }, [spotSelected, isOpen]);

  useEffect(() => {
    setFieldValue('terminalId', terminalId ?? meInfo?.terminalId);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={spotSelected ? 'スポット情報を更新する' : 'スポット情報を登録する'}
      width={494}
      className="modal-custom-antd modal-set-divice"
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField content={spotSelected ? MESSAGE_POPUP.EDIT : MESSAGE_POPUP.CREATE} type="primary" onClick={handleSubmit} disabled={!isValid} />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>拠点：</Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('terminalId')}
                  optionsSelect={terminalSelects}
                  setFieldValue={setFieldValue}
                  error={errors.terminalId}
                  touched={touched.terminalId}
                  selectProps={{
                    placeholder: '羽田国際線',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  スポット名：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('name')}
                    error={errors.name}
                    touched={touched.name}
                    placeholder="入力してください"
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
