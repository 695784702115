import React, { useState, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';

import { InputField, SelectField } from 'src/shared/components/form';
import { DownIcon, DownIconV2, PlusIcon, SearchIcon, UpIcon, UpIconV2 } from 'src/assets/icons';
import { THEME_TYPE } from 'src/shared/constants';
import { SEARCH_ROLE_SELECTS } from 'src/shared/constants/config';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { updateParamSearchUserActions } from 'src/stores/screens/privateScreens/userManagement/userManagement.action';

interface Props {
  terminalSelects: any;
  handleShowModal: (isShow: boolean, id: string | number | null) => void;
  setIsShowModalDownloadFile: (isShow: boolean) => void;
  setIsShowModalUploadFile: (isShow: boolean) => void;
}

const { Text } = Typography;

const FilterCard: React.FC<Props> = ({
  terminalSelects,
  handleShowModal,
  setIsShowModalDownloadFile,
  setIsShowModalUploadFile,
}) => {
  const dispatch = useAppDispatch();

  const [terminalId, setTerminalId] = useState<any>('');
  const [roleId, setRoleId] = useState<any>('');
  const [searchUser, setSearchUser] = useState<any>('');

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const { setFieldValue, getFieldProps } = formik;

  const { themeType } = useAppSelector(state => state.settings);
  const { meInfo } = useAppSelector(state => state.auth);

  useEffect(() => {
    void dispatch(
      updateParamSearchUserActions({
        data: {
          terminalId,
          roleId,
          searchUser,
        },
      }),
    );
  }, [terminalId, roleId, searchUser]);

  useEffect(() => {
    void setFieldValue('terminalId', meInfo?.terminalId);
    void setFieldValue('roleId', '');
    void setTerminalId(meInfo?.terminalId);
  }, []);

  return (
    <Row className="filter-card">
      <Col span={18} className="col-filter">
        <Row>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('search')}
            placeholder="検索"
            inputProps={{ prefix: <Icon component={SearchIcon} /> }}
            className="input-search"
            callbackOnChange={e => setSearchUser(e.target.value)}
          />
          <SelectField
            field={getFieldProps('terminalId')}
            optionsSelect={terminalSelects}
            setFieldValue={setFieldValue}
            className="field-select"
            onChange={e => setTerminalId(e)}
          />
          <SelectField
            field={getFieldProps('roleId')}
            setFieldValue={setFieldValue}
            optionsSelect={SEARCH_ROLE_SELECTS}
            className="field-select"
            onChange={e => setRoleId(e)}
          />
        </Row>

        <Row className="row-right">
          <Col className="col-action" onClick={() => setIsShowModalDownloadFile(true)}>
            <Icon component={themeType === THEME_TYPE.DARK ? DownIcon : DownIconV2} />
            <Text>ダウンロード</Text>
          </Col>
          <Col className="col-action" onClick={() => setIsShowModalUploadFile(true)}>
            <Icon component={themeType === THEME_TYPE.DARK ? UpIcon : UpIconV2} />
            <Text>アップロード</Text>
          </Col>
          <Icon component={PlusIcon} className="plus-icon" onClick={() => handleShowModal(true, null)} />
        </Row>
      </Col>
    </Row>
  );
};

export default FilterCard;
