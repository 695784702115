import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const TERMINAL_API = {
  getTerminalAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/terminals', { params });

    const data = get(response, 'data', null);

    return data;
  },
  createTerminalAPI: async (params: TerminalsStore.ParamsTerminal) => {
    const response = await new AxiosClient().post('terminals', { name: params?.name });

    const data = get(response, 'message', null);

    return data;
  },
  updateTerminalAPI: async (params: TerminalsStore.ParamsUpdateTerminal) => {
    const getParams = { name: params.name };

    const response = await new AxiosClient().patch(`/terminals/${params.id}`, getParams);

    const data = get(response, 'message', null);

    return data;
  },
  deleteTerminalAPI: async (id: number) => {
    const response = await new AxiosClient().delete(`/terminals/${id}`);

    const data = get(response, 'message', null);

    return data;
  },
};

export default TERMINAL_API;
