import React from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';

import { ButtonField, SelectField } from 'src/shared/components/form';
import TableCard from 'src/shared/components/table/Table';
import ModalForm from './ModalForm';
import useInit from './hooks/useInit';
import { PlusIcon } from 'src/assets/icons';
import { WrapperStyled } from './styled';

const { Title } = Typography;

const Spots: React.FC = () => {
  const {
    columns,
    dataSource,
    totalPageSpots,
    isLoading,
    isShowModal,
    spotSelected,
    currentPage,
    dataSelectTerminals,
    formik,
    terminalId,
    setIsShowModal,
    handleShowModal,
    setCurrentPage,
    setTerminalId,
    fetchDataSpots,
  } = useInit();

  const { getFieldProps, setFieldValue } = formik;

  return (
    <>
      <WrapperStyled className="spots-screen">
        <Col span={20} className="col-form">
          <Row className="row-btn">
            <Col span={8}>
              <Title level={4} className="title color-title-table-light-theme">
                スポット
              </Title>
            </Col>

            <Col span={16} className="col-left">
              <SelectField
                field={getFieldProps('terminalId')}
                optionsSelect={dataSelectTerminals}
                setFieldValue={setFieldValue}
                onChange={id => {
                  setTerminalId(id);
                  setCurrentPage(1);
                  void fetchDataSpots({ terminalId: id, page: 1 });
                }}
              />

              <ButtonField
                content={<Icon component={PlusIcon} />}
                type="primary"
                className="btn-create"
                onClick={() => handleShowModal(true, null)}
              />
            </Col>
          </Row>

          <Row className="row-table">
            <TableCard
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              rowKey="id"
              totalPages={totalPageSpots}
              currentPage={currentPage}
              onChangePage={page => {
                setCurrentPage(page);
                void fetchDataSpots({ page });
              }}
            />
          </Row>
        </Col>
      </WrapperStyled>
      <ModalForm
        terminalSelects={dataSelectTerminals}
        spotSelected={spotSelected}
        isOpen={isShowModal}
        terminalId={terminalId}
        setIsShowModal={setIsShowModal}
      />
    </>
  );
};

export default Spots;
