import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import DASHBOARD_API from './dashboard.api';
import { LOCAL_STORAGE_KEY } from 'src/shared/constants';
import { MESSAGE_SUCCEESS } from 'src/shared/constants/message';

export const fetchDataVideoAction = createAsyncThunk<any, DashboardStore.RequestDataVideo>(
  'dashboard',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

    try {
      const getData = {
        size: data?.size,
        page: data?.page,
        order: data?.order === 'null' ? null : data?.order,
        search: data?.search,
        terminalId: terminalId ?? data?.terminalId,
      };

      const response = await DASHBOARD_API.fetchDataVideoAPI(getData);
      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const fetchAllDataVideoAction = createAsyncThunk<any, DashboardStore.RequestDataVideo>(
  'dashboard_all',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

    try {
      const getData = {
        size: data?.size,
        page: data?.page,
        order: data?.order === 'null' ? null : data?.order,
        search: data?.search,
        terminalId: terminalId ?? data?.terminalId,
      };

      const response = await DASHBOARD_API.fetchDataVideoAPI(getData);
      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getManeuverSettingAction = createAsyncThunk<any>(
  'get_setting',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.getManeuverSettingsAPI();

      localStorage.setItem(LOCAL_STORAGE_KEY.SETTING_MONITOR, JSON.stringify({ ...response }));

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const maneuverSettingsAction = createAsyncThunk<any, DashboardStore.RequestManeuverSettings>(
  'settings',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.maneuverSettingsAPI(data);
      callback();
      notification.success({ message: MESSAGE_SUCCEESS.SETTING_SUCCESSFULLY });
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updatePostionStreamAction = createAsyncThunk<any, any>(
  'update_drop_drap',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      await DASHBOARD_API.updatePostionDropDrapStreamAPI(data);
      notification.success({ message: MESSAGE_SUCCEESS.SETTING_SUCCESSFULLY });
      callback();
      return data;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
