export const ROUTERS = {
  // PUBLIC
  HOME: {
    PATH: '/',
    LABEL: 'Home',
  },
  LOGIN: {
    PATH: '/login',
    LABEL: 'Login',
  },
  REGISTER: {
    PATH: '/register',
    LABEL: 'Register',
  },
  RESET_PASSWORD: {
    PATH: '/reset-password',
    LABEL: 'Reset Password',
  },
  REMIND_CHANGE_PASSWORD: {
    PATH: '/remind-change-password',
    LABEL: 'Remind Change Password',
  },
  VERIFY_ERROR: {
    PATH: '/verify-error',
    LABEL: 'Verify Error',
  },

  // PRIVATE
  DASHBOARD: {
    KEY: '/dashboard',
    PATH: '/dashboard',
    LABEL: 'ダッシュボード',
  },
  DASHBOARD_SECOND: {
    KEY: '/dashboard-second',
    PATH: '/dashboard-second',
    LABEL: 'Dashboard second',
  },
  PROFILE: {
    KEY: '/profile',
    PATH: '/profile',
    LABEL: 'Profile',
  },
  SUBMIT_STREAMING: {
    KEY: '/submit-streaming',
    PATH: '/submit-streaming',
    LABEL: '利用デバイス設定',
  },
  USER_MANAGEMENT: {
    KEY: '/user-management',
    PATH: '/user-management',
    LABEL: 'ユーザー管理',
  },
  MASTER_MANAGEMENT: {
    KEY: '/master-management',
    PATH: '/master-management',
    LABEL: 'マスタ管理',
  },
  LABEL: {
    KEY: '/label',
    PATH: '/label',
    LABEL: '利用デバイスグループ',
  },
  CAMERA: {
    KEY: '/camera',
    PATH: '/camera',
    LABEL: 'カメラ',
  },
  ONE_CLICK: {
    KEY: '/one-click',
    PATH: '/one-click',
    LABEL: 'タグボタン',
  },
  TERMINAL: {
    KEY: '/terminal',
    PATH: '/terminal',
    LABEL: '拠点',
  },
  SPOTS: {
    KEY: '/spots',
    PATH: '/spots',
    LABEL: 'スポット',
  },
  PLANE_NUMBER: {
    KEY: '/plane-number',
    PATH: '/plane-number',
    LABEL: '機番',
  },
  SYSTEM_SETTINGS: {
    KEY: '/system-settings',
    PATH: '/system-settings',
    LABEL: 'システム設定',
  },
  DASHBOARD_SETTING: {
    KEY: '/dashboard/setting-stream',
    PATH: '/dashboard/setting-stream',
    LABEL: '',
  },
  ERROR: {
    KEY: '/error',
    PATH: '/error',
    LABEL: 'error',
  },
};

export const PARAMS = {
  PAGE: 'page',
  TERMINAL_ID: 'terminalId',
  SEARCH: 'search',
};
