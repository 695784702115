import React from 'react';

const UpIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 10L11 19.1935C11 19.6774 11.4 20 12 20C12.6 20 13 19.6774 13 19.1935L13 10L11 10Z" fill="white" />
      <path
        d="M18.0344 9.12877C18.3744 9.43554 18.1574 10 17.6994 10L6.30056 10C5.84262 10 5.62561 9.43554 5.96561 9.12877L11.3875 4.23684C11.7375 3.92105 12.2625 3.92105 12.6125 4.23684L18.0344 9.12877Z"
        fill="white"
      />
    </svg>
  );
};

export default UpIcon;
