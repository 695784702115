import React from 'react';

const CloseIconV2: React.FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M5.50001 18.0584C5.22501 18.0584 5.04168 17.9667 4.85834 17.7834C4.49168 17.4167 4.49168 16.8667 4.85834 16.5001L16.5 4.85837C16.8667 4.49171 17.4167 4.49171 17.7833 4.85837C18.15 5.22504 18.15 5.77504 17.7833 6.1417L6.14167 17.7834C5.95834 17.9667 5.77501 18.0584 5.50001 18.0584Z"
        fill="#60646E"
      />
      <path
        d="M17.2334 18.0584C16.9584 18.0584 16.775 17.9667 16.5917 17.7834L4.95002 6.1417C4.58335 5.77504 4.58335 5.22504 4.95002 4.85837C5.31668 4.49171 5.86669 4.49171 6.23335 4.85837L17.875 16.5001C18.2417 16.8667 18.2417 17.4167 17.875 17.7834C17.6917 17.9667 17.4167 18.0584 17.2334 18.0584Z"
        fill="#60646E"
      />
    </svg>
  );
};

export default CloseIconV2;
