import React from 'react';

const SettingsIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        opacity="0.3"
        d="M27.625 14.375V15.75C27.625 16.5 27.125 16.9999 26.5 17.1249L24.875 17.375C24.625 18.375 24.25 19.3749 23.625 20.2499L24.625 21.6249C25 22.1249 25 22.9999 24.5 23.4999L23.5 24.5C23 25 22.25 24.9999 21.625 24.6249L20.25 23.625C19.375 24.125 18.375 24.625 17.375 24.875L17.125 26.4999C17 27.1249 16.375 27.625 15.75 27.625H14.375C13.625 27.625 13.125 27.1249 13 26.4999L12.75 24.875C11.75 24.625 10.75 24.25 9.875 23.625L8.5 24.6249C8 24.9999 7.125 25 6.625 24.5L5.625 23.4999C5.125 22.9999 5.125 22.2499 5.5 21.6249L6.5 20.2499C6 19.3749 5.5 18.375 5.25 17.375L3.625 17.1249C3 16.9999 2.5 16.375 2.5 15.75V14.375C2.5 13.625 3 13.125 3.625 13L5.25 12.7499C5.5 11.7499 5.875 10.75 6.5 9.87503L5.5 8.49991C5.125 7.99991 5.125 7.12491 5.625 6.62491L6.625 5.625C7.125 5.125 7.875 5.12503 8.5 5.50003L9.875 6.49994C10.75 5.99994 11.75 5.49994 12.75 5.24994L13 3.62503C13.125 3.00003 13.75 2.5 14.375 2.5H15.75C16.5 2.5 17 3.00003 17.125 3.62503L17.375 5.24994C18.375 5.49994 19.375 5.87494 20.25 6.49994L21.625 5.50003C22.125 5.12503 23 5.125 23.5 5.625L24.5 6.62491C25 7.12491 25 7.87491 24.625 8.49991L23.625 9.87503C24.125 10.75 24.625 11.7499 24.875 12.7499L26.5 13C27.125 13.125 27.625 13.75 27.625 14.375ZM15.125 10.75C12.75 10.75 10.75 12.75 10.75 15.125C10.75 17.5 12.75 19.5 15.125 19.5C17.5 19.5 19.5 17.5 19.5 15.125C19.5 12.75 17.5 10.75 15.125 10.75Z"
        fill="#A1A5B7"
      />
      <path
        d="M21.375 15.125C21.375 18.625 18.625 21.375 15.125 21.375C11.625 21.375 8.875 18.625 8.875 15.125C8.875 11.625 11.625 8.875 15.125 8.875C18.625 8.875 21.375 11.625 21.375 15.125ZM15.125 12.625C13.75 12.625 12.625 13.75 12.625 15.125C12.625 16.5 13.75 17.625 15.125 17.625C16.5 17.625 17.625 16.5 17.625 15.125C17.625 13.75 16.5 12.625 15.125 12.625Z"
        fill="#A1A5B7"
      />
    </svg>
  );
};

export default SettingsIcon;
