import { createSlice } from '@reduxjs/toolkit';

import {
  getPlaneNumerAction,
  getLabelsAction,
  getCameraAction,
  getOneClickAction,
  getDataVideoZoomAction,
} from './common.action';

const initialState: CommonStore.GetListReducer = {
  isLoading: false,
  error: null,
  labels: [],
  cameras: [],
  oneClick: [],
  planeNumber: [],
  videoZoom: [],
  pageLabels: 1,
  pagePlaneNum: 1,
  pageCamera: 1,
  totalPageLabels: 0,
  totalPagePlaneNumber: 0,
  totalPageCameras: 0,
  totalPageOneClicks: 0,
};

const { actions, reducer } = createSlice({
  name: 'submit_stream_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getLabelsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLabelsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pageLabels = action?.payload?.response?.page;
        state.totalPageLabels = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.labels = [...state.labels, ...action?.payload?.response?.rows];
        } else {
          state.labels = action?.payload?.response?.rows;
        }
      })
      .addCase(getLabelsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getPlaneNumerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPlaneNumerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pagePlaneNum = action?.payload?.response?.page;
        state.totalPagePlaneNumber = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.planeNumber = [...state.planeNumber, ...action?.payload?.response?.rows];
        } else {
          state.planeNumber = action?.payload?.response?.rows;
        }
      })
      .addCase(getPlaneNumerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getCameraAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCameraAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cameras = action.payload?.rows;
        state.pageCamera = action?.payload?.response?.page;
        state.totalPageCameras = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.cameras = [...state.cameras, ...action?.payload?.response?.rows];
        } else {
          state.cameras = action?.payload?.response?.rows;
        }
      })
      .addCase(getCameraAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getDataVideoZoomAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDataVideoZoomAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videoZoom = action.payload?.rows;
      })
      .addCase(getDataVideoZoomAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getOneClickAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOneClickAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.oneClick = action.payload?.rows;
        state.totalPageOneClicks = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.oneClick = [...state.oneClick, ...action?.payload?.response?.rows];
        } else {
          state.oneClick = action?.payload?.response?.rows;
        }
      })
      .addCase(getOneClickAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
