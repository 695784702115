import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField, SelectField } from 'src/shared/components/form';
import { useAppSelector, useAppDispatch } from 'src/stores';
import { USER_ROLE_SELECTS } from 'src/shared/constants/config';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { forgotPasswordAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import { ModalContentWrap } from './styled';

interface Props {
  isOpen: boolean;
  userSelected: any;
  currentPage: number;
  terminalSelects: any;
  setIsShowModal: (param: boolean) => void;
  setCurrentPage: (param: number) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({
  isOpen,
  userSelected,
  currentPage,
  terminalSelects,
  setIsShowModal,
  setCurrentPage,
}) => {
  const { roleId, searchUser, terminalId } = useAppSelector(state => state.userManagement);
  const { meInfo } = useAppSelector(state => state.auth);

  const { formik } = useForm({
    userSelected,
    roleId,
    searchUser,
    terminalId,
    currentPage,
    setIsShowModal,
    setCurrentPage,
  });
  const dispatch = useAppDispatch();

  const { errors, touched, setFieldValue, getFieldProps, handleSubmit, resetForm } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
  };

  const handleForgotPassword = (): void => {
    void dispatch(
      forgotPasswordAction({
        data: {
          email: userSelected.email,
        },
      }),
    );
  };

  useEffect(() => {
    if (userSelected?.id && isOpen) {
      setFieldValue('username', userSelected.username);
      setFieldValue('staffNum', userSelected.staffNum);
      setFieldValue('email', userSelected.email);
      setFieldValue('role', userSelected.role);
      return;
    }
    resetForm();
    setFieldValue('terminalId', terminalId || meInfo?.terminal?.id);
  }, [userSelected, isOpen, terminalId]);

  return (
    <Modal
      isOpen={isOpen}
      title={userSelected?.id ? 'ユーザー情報を更新する' : 'ユーザーを登録する'}
      width={494}
      className="modal-custom-antd modal-set-divice"
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      footer={
        <Row>
          <Col span={24} className="col-group-btn">
            <ButtonField className="btn-cancel" content="閉じる" type="default" onClick={handlecancel} />
            <ButtonField
              className="btn-submit"
              content={userSelected?.id ? '更新 ' : '登録 '}
              type="primary"
              onClick={handleSubmit}
            />
          </Col>
          {userSelected?.id && (
            <Col span={24} className="col-action">
              <Text onClick={handleForgotPassword}>このユーザのパスワードをリセットする</Text>
            </Col>
          )}
        </Row>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  名前：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('username')}
                    error={errors.username}
                    touched={touched.username}
                    placeholder="入力してください"
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>社員番号：{!userSelected?.id && <span className="required">*</span>}</Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('staffNum')}
                    error={errors.staffNum}
                    touched={touched.staffNum}
                    placeholder="入力してください"
                    disabled={userSelected?.id}
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  メールアドレス：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('email')}
                    error={errors.email}
                    touched={touched.email}
                    placeholder="入力してください"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>拠点：</Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('terminalId')}
                  optionsSelect={terminalSelects}
                  setFieldValue={setFieldValue}
                />
              </Col>
            </Row>
            <Row className="mb-30">
              <Col span={24} className="field-title">
                <Text>
                  権限：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('role')}
                  optionsSelect={USER_ROLE_SELECTS}
                  setFieldValue={setFieldValue}
                  error={errors.role}
                  touched={touched.role}
                  placeholder="選択してください"
                />
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
