import React from 'react';

const DashboardIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="7" height="7" rx="1.5" fill="#3E3F48" />
      <rect x="13" y="13" width="7" height="7" rx="1.5" fill="#3E3F48" />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 4C13.6716 4 13 4.67157 13 5.5V9.5C13 10.3284 13.6716 11 14.5 11H18.5C19.3284 11 20 10.3284 20 9.5V5.5C20 4.67157 19.3284 4 18.5 4H14.5ZM5.5 13C4.67157 13 4 13.6716 4 14.5V18.5C4 19.3284 4.67157 20 5.5 20H9.5C10.3284 20 11 19.3284 11 18.5V14.5C11 13.6716 10.3284 13 9.5 13H5.5Z"
        fill="#3E3F48"
      />
    </svg>
  );
};

export default DashboardIcon;
