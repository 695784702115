import React, { useState } from 'react';
import { Col, Row, Typography, Progress } from 'antd';
import Icon from '@ant-design/icons';

import { LIMIT_FILE_SIZE } from 'src/shared/constants/validate';
import { MESSAGE_ERROR } from 'src/shared/constants/message';
import { THEME_TYPE } from 'src/shared/constants';
import { CloseIconV3, FileUploadIcon, FileUploadLightIcon } from 'src/assets/icons';
import { ButtonField } from 'src/shared/components/form';
import { ModalUploadFileWrap } from './styled';

interface Props {
  isShowProgress: boolean;
  percentProgress: number;
  setIsShowModalUploadFile: (param: boolean) => void;
  handleDownloadTemplate: () => Promise<void>;
  handleClickUploadFile: (e: any) => void;
  handleSubmitUploadFile: () => Promise<void>;
}

const { Text, Title } = Typography;

const ModalUploadFile: React.FC<Props> = ({
  isShowProgress,
  percentProgress,
  handleClickUploadFile,
  handleSubmitUploadFile,
  setIsShowModalUploadFile,
  handleDownloadTemplate,
}) => {
  const [fileName, setFileName] = useState('');
  const [fileMessageError, setFileMessageError] = useState('');
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);

  const handleValidateFile = (e): void => {
    const file = e.target.files[0];
    setFileName(file.name);
    const isFileExtensionError = !['xlsx'].includes(getExt(file.name));
    const isFileSizeError = file.size === 0 || file.size > LIMIT_FILE_SIZE;
    if (isFileExtensionError) {
      setFileMessageError(MESSAGE_ERROR.FILE_EXTENSION_ERROR);
      setIsDisabledButton(true);
      return;
    }
    if (isFileSizeError) {
      setFileMessageError(MESSAGE_ERROR.FILE_SIZE_ERROR);
      setIsDisabledButton(true);
      return;
    }
    setFileMessageError('');
    setIsDisabledButton(false);
  };

  const getExt = (filename): string => {
    const ext = filename.split('.').pop();
    if (ext == filename) return '';
    return ext;
  };

  const handleCloseModal = (): void => {
    !isShowProgress && setIsShowModalUploadFile(false);
  };

  return (
    <>
      <Row className="modal-background" onClick={() => handleCloseModal()}></Row>
      <ModalUploadFileWrap className="modal-custom">
        <Col span={24} className="modal-header">
          <Title className="title">ユーザ一括登録</Title>
          <Icon component={CloseIconV3} className="icon-close" onClick={() => handleCloseModal()} />
        </Col>
        <Col span={24} className="modal-body">
          <Row className="row-title">
            <Col span={24}>
              <Title>指定のフォーマットを利用して、</Title>
            </Col>
            <Col span={24}>
              <Title>複数ユーザを一括で登録もしくは変更できます。</Title>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="field-title">
              <Text></Text>
            </Col>
            <Col span={24} className="col-upload-file">
              <div className={`upload-file-wrap ${fileMessageError && 'upload-file-wrap__error'}`}>
                <Icon
                  component={typeTheme === THEME_TYPE.DARK ? FileUploadIcon : FileUploadLightIcon}
                  className="icon-close"
                />
                {!fileMessageError && !fileName && <Text>ドラッグまたはドロップしてアップロードします</Text>}
                {!fileMessageError && fileName && <Text className="text-file-name">{fileName}</Text>}
                {fileMessageError && <Text className="file-message-error">{fileMessageError}</Text>}
                {isShowProgress && (
                  <div className="progress-upload-file">
                    <Progress type="circle" percent={percentProgress} size="small" status="active" width={80} />
                  </div>
                )}
              </div>
              <input
                type="file"
                name="myfile"
                title=""
                className="absolute inset-0 cursor-pointer opacity-0 upload-file-input"
                onChange={e => {
                  handleClickUploadFile(e);
                  handleValidateFile(e);
                }}
                accept=".xlsx"
              />
            </Col>
            <Col className="col-note">
              <Text className="text-note">
                一括で新規登録の場合、
                <span
                  className={!isShowProgress ? 'cursor-pointer' : ''}
                  onClick={() => {
                    void (async () => {
                      !isShowProgress && (await handleDownloadTemplate());
                    })();
                  }}
                >
                  こちらのテンプレート
                </span>
                をダウンロードする
              </Text>
            </Col>
          </Row>
        </Col>
        <Col className="modal-bottom">
          <ButtonField content="閉じる" type="default" onClick={() => handleCloseModal()} />
          <ButtonField
            content="登録"
            type="primary"
            onClick={() => {
              void (async () => {
                await handleSubmitUploadFile();
              })();
            }}
            disabled={isDisabledButton || isShowProgress}
          />
        </Col>
      </ModalUploadFileWrap>
    </>
  );
};

export default ModalUploadFile;
