import { Row } from 'antd';
import styled from 'styled-components';

export const ModalContentWrap = styled(Row)`
  .field-title {
    margin-top: 20px;

    span {
      color: #adaec1;
    }

    .required {
      color: #f64e60;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
`;
