import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const DASHBOARD_API = {
  fetchDataVideoAPI: async (params: DashboardStore.ParamsVideo) => {
    const response = await new AxiosClient().get('/streams/db1', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getManeuverSettingsAPI: async () => {
    const response = await new AxiosClient().get('/settings');

    const data = get(response, 'data', null);

    return data;
  },

  maneuverSettingsAPI: async (params: DashboardStore.ParamsManeuverSettings) => {
    const response = await new AxiosClient().patch('settings', params);

    const data = get(response, 'message', null);

    return data;
  },

  updatePostionDropDrapStreamAPI: async params => {
    const response = await new AxiosClient().patch('stream-users/position', params);

    const data = get(response, 'data', null);

    return data;
  },
};

export default DASHBOARD_API;
