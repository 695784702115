/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Col, Layout, Menu, Modal, Row, Typography } from 'antd';
import { useOutlet, useNavigate, useLocation } from 'react-router-dom';

import LogoIcon from 'src/assets/icons/logo-icon';
import useInit from './hooks/useInit';
import HeaderCard from './Header';
import {
  CloseSiderIcon,
  LogoTextIcon,
  OpenSiderIcon,
  OpenSiderLightIcon,
  CloseSiderLightIcon,
  CloseSiderHoverIcon,
  CloseSiderHoverLightIcon,
} from 'src/assets/icons';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { ROUTERS, THEME_TYPE } from 'src/shared/constants';
import useSoundAlert from 'src/shared/hooks/useSoundAlert';
import { MESSAGE_POPUP } from 'src/shared/constants/message';

import { PrivateLayoutStyle } from './styled';

const { Content, Sider } = Layout;

const { Text } = Typography;

const PrivateLayout: React.FC = () => {
  const [isCollapsedSider, setIsCollapsedSider] = useState<{ isOpen: boolean; type?: 'hover' | 'click' | null }>({
    isOpen: true,
    type: 'hover',
  });

  const [isShowModalConfirmSound, setIsShowModalConfirmSound] = useState(true);
  const [isHoverIconCollapsed, setIsHoverIconCollapsed] = useState(false);

  const { pathname } = useLocation();

  const isDashboardSecondScreen = pathname === ROUTERS.DASHBOARD_SECOND.PATH;

  const outlet = useOutlet();

  const navigate = useNavigate();

  const { isMobile, isMobileByWidth } = useWindowSize();

  const pathNameNotDisplay = [ROUTERS.DASHBOARD_SECOND.PATH, ROUTERS.SUBMIT_STREAMING.PATH, ROUTERS.LOGIN.PATH];

  useEffect(() => {
    if (isMobileByWidth && !pathNameNotDisplay.includes(pathname)) {
      navigate('/err');
    }
  }, [isMobileByWidth]);

  const {
    menuItems,
    selectedMenu,
    itemsDropdown,
    formik,
    optionsSelectTerminal,
    optionsSelectOrder,
    isShowModalChangePassword,
    isGeneralUser,
    setIsShowModalChangePassword,
    handleClickLogo,
  } = useInit();

  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);

  const dataMenuItems = menuItems?.map(menuItem => {
    if (menuItem.isShow) {
      return {
        key: menuItem.key,
        icon: menuItem.icon,
        label: menuItem.label,
        path: menuItem.path,
        children: menuItem.children,
      };
    }
    return null;
  });

  const openSider = (): void => {
    if (isCollapsedSider.type !== 'click') {
      setIsCollapsedSider({ isOpen: false, type: 'hover' });
    }
  };

  const collapsedSider = (): void => {
    if (isCollapsedSider.type !== 'click') {
      setIsCollapsedSider({ isOpen: true, type: 'hover' });
    }
  };

  const collapsed = useMemo(() => {
    if (isCollapsedSider.type === 'click') {
      return isCollapsedSider.isOpen;
    } else {
      if (isCollapsedSider.type === 'hover') {
        return isCollapsedSider.isOpen;
      }
    }
  }, [isCollapsedSider]);

  const iconPin = useMemo(() => {
    if (isCollapsedSider.type === 'hover') {
      return typeTheme === THEME_TYPE.LIGHT ? OpenSiderLightIcon : OpenSiderIcon;
    } else {
      if (isHoverIconCollapsed) {
        return typeTheme === THEME_TYPE.LIGHT ? CloseSiderHoverLightIcon : CloseSiderHoverIcon;
      } else return typeTheme === THEME_TYPE.LIGHT ? CloseSiderLightIcon : CloseSiderIcon;
    }
  }, [isCollapsedSider, typeTheme, isHoverIconCollapsed]);

  useSoundAlert();

  const isShowModalSound = (): boolean => {
    if (isShowModalConfirmSound && pathname !== ROUTERS.DASHBOARD_SECOND.PATH && !isGeneralUser && !isMobile) {
      return true;
    } else {
      return false;
    }
  };

  const onClickIconCollapsed = (): void => {
    if (isCollapsedSider.type === 'hover' && !isCollapsedSider.isOpen) {
      setIsCollapsedSider({ isOpen: false, type: 'click' });
    }

    if (isCollapsedSider.type === 'click' && !isCollapsedSider.isOpen) {
      setIsCollapsedSider({ isOpen: true, type: 'click' });

      setTimeout(() => {
        setIsCollapsedSider({ isOpen: true, type: 'hover' });
      }, 200);
    }
  };

  return (
    <PrivateLayoutStyle isDashboardSecondScreen={isDashboardSecondScreen}>
      <Modal
        open={isShowModalSound()}
        title={MESSAGE_POPUP.CLICK_HERE_TO_PLAY_SOUND_WHEN_THERE_IS_AN_ALERT}
        className="pop-up-confirm-audio"
        keyboard={false}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        closable={false}
      >
        <Row>
          <Col
            className="col-btn-ok"
            onClick={() => {
              setIsShowModalConfirmSound(false);
            }}
          >
            <Text>{MESSAGE_POPUP.I_UNDERSTAND}</Text>
          </Col>
        </Row>
      </Modal>

      {!isMobile && !isDashboardSecondScreen && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onMouseEnter={openSider}
          onMouseLeave={collapsedSider}
          className={`${!isCollapsedSider.isOpen ? 'background-item-selected' : ''}`}
        >
          <Row className="row-logo">
            <Icon
              component={iconPin}
              className="collapsed-icon"
              onClick={e => {
                onClickIconCollapsed();
              }}
              onMouseEnter={() => {
                setIsHoverIconCollapsed(true);
              }}
              onMouseLeave={() => {
                setIsHoverIconCollapsed(false);
              }}
            />
            <Icon className="logo-icon" component={LogoIcon} onClick={handleClickLogo} />
            <Icon component={LogoTextIcon} className="logo-text" />
          </Row>
          <Menu
            className={`layout-menu ${isCollapsedSider.isOpen && 'layout-menu-not-pin'}`}
            mode="inline"
            defaultSelectedKeys={[selectedMenu]}
            items={dataMenuItems}
            onClick={info => navigate(info.key)}
          />
        </Sider>
      )}

      <Layout
        className={`layout-body ${!isCollapsedSider.isOpen && isCollapsedSider.type === 'click' && 'layout-pin'}`}
      >
        <HeaderCard
          itemsDropdown={itemsDropdown}
          formik={formik}
          optionsSelectTerminal={optionsSelectTerminal}
          optionsSelectOrder={optionsSelectOrder}
          isShowModalChangePassword={isShowModalChangePassword}
          setIsShowModalChangePassword={setIsShowModalChangePassword}
        />

        <Content className="body-content">{outlet}</Content>
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;
