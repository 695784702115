import React from 'react';

const LightIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5601 12.07C11.5601 13.1232 11.8928 14.1494 12.5105 15.0024C13.1282 15.8553 13.9995 16.4914 15.0001 16.82C15.8071 16.3181 16.4753 15.6217 16.9434 14.7946C17.4116 13.9675 17.6647 13.0362 17.6797 12.0859C17.6946 11.1357 17.4708 10.1968 17.0289 9.35545C16.5869 8.51407 15.9409 7.79703 15.1501 7.27002C14.1133 7.57474 13.2032 8.20711 12.5561 9.07243C11.9089 9.93775 11.5594 10.9894 11.5601 12.07Z"
        fill="#60646E"
      />
      <g opacity="0.3">
        <path
          d="M10.1901 12.0701C10.1899 10.9359 10.4929 9.82235 11.0679 8.84475C11.6429 7.86715 12.4688 7.06108 13.4601 6.51006C12.9848 6.37724 12.4936 6.30995 12.0001 6.31006C10.491 6.31006 9.0437 6.90954 7.97662 7.97662C6.90954 9.0437 6.31006 10.491 6.31006 12.0001C6.31006 13.5091 6.90954 14.9564 7.97662 16.0235C9.0437 17.0906 10.491 17.6901 12.0001 17.6901C12.4382 17.6879 12.8745 17.6342 13.3001 17.5301C12.3532 16.9672 11.5688 16.1681 11.0237 15.211C10.4785 14.2538 10.1913 13.1715 10.1901 12.0701Z"
          fill="#60646E"
        />
      </g>
      <path
        d="M12 4.70002C11.8095 4.70044 11.6259 4.62833 11.4866 4.49831C11.3473 4.3683 11.2627 4.19014 11.25 4.00002V2.77002C11.25 2.57111 11.329 2.38034 11.4697 2.23969C11.6103 2.09904 11.8011 2.02002 12 2.02002C12.1989 2.02002 12.3897 2.09904 12.5303 2.23969C12.671 2.38034 12.75 2.57111 12.75 2.77002V4.00002C12.7373 4.19014 12.6527 4.3683 12.5134 4.49831C12.3741 4.62833 12.1905 4.70044 12 4.70002Z"
        fill="#60646E"
      />
      <path
        d="M12 22C11.8019 21.9975 11.6126 21.9176 11.4725 21.7775C11.3324 21.6374 11.2526 21.4482 11.25 21.25V20.05C11.25 19.8511 11.329 19.6604 11.4697 19.5197C11.6103 19.3791 11.8011 19.3 12 19.3C12.1989 19.3 12.3897 19.3791 12.5303 19.5197C12.671 19.6604 12.75 19.8511 12.75 20.05V21.23C12.7513 21.3299 12.733 21.4289 12.696 21.5217C12.659 21.6144 12.6041 21.6989 12.5345 21.7704C12.4648 21.8419 12.3818 21.899 12.2901 21.9384C12.1984 21.9778 12.0998 21.9987 12 22Z"
        fill="#60646E"
      />
      <path
        d="M6.30998 7.05998C6.21144 7.06044 6.11379 7.04122 6.02278 7.00344C5.93177 6.96566 5.84922 6.91009 5.77998 6.83998L4.93998 5.99998C4.85004 5.93587 4.77516 5.85294 4.72054 5.75695C4.66592 5.66096 4.63288 5.55422 4.62372 5.44416C4.61455 5.3341 4.62948 5.22336 4.66747 5.11966C4.70546 5.01596 4.7656 4.92179 4.84369 4.84369C4.92179 4.7656 5.01596 4.70546 5.11966 4.66747C5.22336 4.62948 5.3341 4.61455 5.44416 4.62372C5.55422 4.63288 5.66096 4.66592 5.75695 4.72054C5.85294 4.77516 5.93587 4.85004 5.99998 4.93998L6.82998 5.77998C6.97043 5.9206 7.04932 6.11123 7.04932 6.30998C7.04932 6.50873 6.97043 6.69935 6.82998 6.83998C6.69253 6.97915 6.50557 7.05825 6.30998 7.05998Z"
        fill="#60646E"
      />
      <path
        d="M18.5201 19.28C18.3221 19.2757 18.1329 19.1971 17.9901 19.06L17.1601 18.22C17.0864 18.1513 17.0273 18.0685 16.9863 17.9765C16.9453 17.8845 16.9233 17.7852 16.9215 17.6845C16.9197 17.5838 16.9383 17.4838 16.976 17.3904C17.0137 17.297 17.0698 17.2122 17.1411 17.1409C17.2123 17.0697 17.2971 17.0136 17.3905 16.9759C17.4839 16.9381 17.5839 16.9196 17.6846 16.9214C17.7853 16.9232 17.8846 16.9452 17.9766 16.9862C18.0686 17.0272 18.1514 17.0863 18.2201 17.16L19.0601 17.99C19.1988 18.1335 19.2763 18.3254 19.2763 18.525C19.2763 18.7246 19.1988 18.9164 19.0601 19.06C18.9147 19.1995 18.7216 19.2782 18.5201 19.28Z"
        fill="#60646E"
      />
      <path
        d="M4.00002 12.75H2.77002C2.57111 12.75 2.38034 12.671 2.23969 12.5303C2.09904 12.3897 2.02002 12.1989 2.02002 12C2.02002 11.8011 2.09904 11.6103 2.23969 11.4697C2.38034 11.329 2.57111 11.25 2.77002 11.25H4.00002C4.19893 11.25 4.3897 11.329 4.53035 11.4697C4.671 11.6103 4.75002 11.8011 4.75002 12C4.75002 12.1989 4.671 12.3897 4.53035 12.5303C4.3897 12.671 4.19893 12.75 4.00002 12.75Z"
        fill="#60646E"
      />
      <path
        d="M21.23 12.75H20.05C19.8511 12.75 19.6604 12.671 19.5197 12.5303C19.3791 12.3897 19.3 12.1989 19.3 12C19.3 11.8011 19.3791 11.6103 19.5197 11.4697C19.6604 11.329 19.8511 11.25 20.05 11.25H21.23C21.429 11.25 21.6197 11.329 21.7604 11.4697C21.901 11.6103 21.98 11.8011 21.98 12C21.98 12.1989 21.901 12.3897 21.7604 12.5303C21.6197 12.671 21.429 12.75 21.23 12.75Z"
        fill="#60646E"
      />
      <path
        d="M5.48009 19.28C5.27863 19.2782 5.08546 19.1995 4.94009 19.06C4.8014 18.9164 4.72388 18.7246 4.72388 18.525C4.72388 18.3254 4.8014 18.1335 4.94009 17.99L5.78009 17.16C5.84876 17.0863 5.93156 17.0272 6.02356 16.9862C6.11556 16.9452 6.21487 16.9232 6.31557 16.9214C6.41627 16.9196 6.5163 16.9381 6.60969 16.9759C6.70308 17.0136 6.78791 17.0697 6.85913 17.1409C6.93035 17.2122 6.9865 17.297 7.02422 17.3904C7.06194 17.4838 7.08046 17.5838 7.07869 17.6845C7.07691 17.7852 7.05487 17.8845 7.01388 17.9765C6.97288 18.0685 6.91378 18.1513 6.84009 18.22L6.00009 19.06C5.85978 19.1949 5.67461 19.2732 5.48009 19.28Z"
        fill="#60646E"
      />
      <path
        d="M17.69 7.05998C17.5915 7.06044 17.4938 7.04122 17.4028 7.00344C17.3118 6.96566 17.2293 6.91009 17.16 6.83998C17.0196 6.69935 16.9407 6.50873 16.9407 6.30998C16.9407 6.11123 17.0196 5.9206 17.16 5.77998L18 4.93998C18.0641 4.85004 18.147 4.77516 18.243 4.72054C18.339 4.66592 18.4458 4.63288 18.5558 4.62372C18.6659 4.61455 18.7766 4.62948 18.8803 4.66747C18.984 4.70546 19.0782 4.7656 19.1563 4.84369C19.2344 4.92179 19.2945 5.01596 19.3325 5.11966C19.3705 5.22336 19.3854 5.3341 19.3763 5.44416C19.3671 5.55422 19.3341 5.66096 19.2794 5.75695C19.2248 5.85294 19.1499 5.93587 19.06 5.99998L18.22 6.82998C18.1516 6.9019 18.0694 6.95932 17.9784 6.99884C17.8873 7.03835 17.7893 7.05914 17.69 7.05998Z"
        fill="#60646E"
      />
    </svg>
  );
};

export default LightIcon;
