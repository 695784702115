import React, { useEffect, useState, useMemo } from 'react';
import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
import { Card, Col, List, Row, Typography } from 'antd';

import { NoCamera } from 'src/assets/images';
import { ButtonField } from 'src/shared/components/form';
import useInit from './hooks/useInitDropDrapSettings';
import useCheckStatusAlert from 'src/shared/hooks/useCheckStatusAlert';
import { LOCAL_STORAGE_KEY } from 'src/shared/constants/config';
import EighteenCard from './EighteenCard';
import { Spinning } from 'src/shared/components';

import { WrapperListVideo } from './styled';

const { Text } = Typography;

const DragAndDropScreen: React.FC = () => {
  const { isLoading, currentItems, arrPositionStream, onChange, handleSave, handleCancel } = useInit();
  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);

  const { checkStatus } = useCheckStatusAlert();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const headerCard = (item): React.ReactNode => (
    <Row className="row-title">
      <Col className="col-title">
        <Text className="text-content">
          <span className="name">スポット</span>
          <Text className="value font-helvetica">{item?.spot?.name}</Text>
        </Text>
        <Text className="text-content">
          <span className="name">便名</span>
          <Text className="value font-helvetica">JL{item?.flightNum}</Text>
        </Text>
        <Text className="text-content">
          <span className="name">機番</span>
          <Text className="value font-helvetica">{item?.airplane?.name}</Text>
        </Text>
      </Col>
    </Row>
  );

  const handleCheckFullScreen = (): void => {
    const maxHeight = window.screen.height;
    const maxWidth = window.screen.width;
    const curHeight = window.innerHeight;
    const curWidth = window.innerWidth;

    if (maxWidth == curWidth && maxHeight == curHeight) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  const isGroup18Item = Number(itemOfPage) === 6 || Number(itemOfPage) === 8 || Number(itemOfPage) === 18;

  const getItemOfPage = useMemo(() => {
    switch (Number(itemOfPage)) {
      case 6:
        return 3;
      case 8:
        return 4;
      case 10:
        return 5;
      case 12:
      case 18:
        return 6;
      default:
        return 5;
    }
  }, [itemOfPage]);

  useEffect(() => {
    handleCheckFullScreen();
    window.onresize = function (event) {
      handleCheckFullScreen();
    };
  }, []);

  return (
    <>
      {isLoading && <Spinning isLoading={true} />}
      {!isLoading && (
        <WrapperListVideo
          style={{
            padding: `0 ${getItemOfPage === 5 ? '140px' : getItemOfPage === 3 ? '200px' : '20px'} 60px`,
            height: 'calc(100vh - 84px)',
          }}
          isFullScreen={isFullScreen}
        >
          <div className="border-dashed drop-drap-wrap">
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                boxesPerRow={getItemOfPage}
                rowHeight={Number(itemOfPage) === 18 ? 305 : 460}
                style={{ height: '100%' }}
              >
                {currentItems?.map(item => (
                  <GridItem
                    key={item.index}
                    className={`griditemUI griditemUI_grab ${isGroup18Item && 'list-card-type-18'}`}
                  >
                    <List.Item className={`${checkStatus(item?.alertStatus)}`}>
                      {isGroup18Item && (
                        <EighteenCard
                          item={item}
                          status={checkStatus(item?.alertStatus)}
                          clickZoom={() => {}}
                          showConfirm={() => {}}
                          isDrap={true}
                        />
                      )}
                      {!isGroup18Item && (
                        <Card title={headerCard(item)}>
                          <Row className="row-video">
                            <Col span={24} className="col-video">
                              <img
                                data-name="player"
                                className="streaming-player streaming-img"
                                id={`video-${String(item?.id)}-1`}
                                src={NoCamera}
                              />
                            </Col>
                          </Row>

                          <Row className="row-video">
                            <Col span={24} className="col-video">
                              <img
                                data-name="player"
                                className="streaming-player streaming-img"
                                id={`video-${String(item?.id)}-2`}
                                src={NoCamera}
                              />
                            </Col>
                          </Row>

                          <Row className="row-footer font-helvetica">
                            <Text>{item?.label?.name}</Text>
                          </Row>
                        </Card>
                      )}
                    </List.Item>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
            <Row>
              <Col span={4} />
              <Col span={20} className="text-r">
                <ButtonField onClick={handleCancel} type="primary" content="キャンセル" className="btn btn-cancel" />
                <ButtonField
                  onClick={handleSave}
                  type="primary"
                  content="保存 "
                  className="btn btn-save"
                  disabled={arrPositionStream.length === 0}
                />
              </Col>
            </Row>
          </div>
        </WrapperListVideo>
      )}
    </>
  );
};

export default DragAndDropScreen;
