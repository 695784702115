const NUMBER_REGEX = /^[0-9\b]+$/;
const NUMBER_SWITCH_SECONDS_REGEX = /^[1-9]\d*$/;
const REPLACE_ZERO = /^0+/;
const FOUR_NUMBER_CHARACTERS = /^(?!0{1,4}$)\d{1,4}$/;
const KEY_SAFIE_CHARACTERS = /^[0-9a-fA-F]$/;
const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{1,10}$/i;
const REGEX_PASSWORD =
  /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{9,}$/;
const ONLY_ALLOW_ENTER_CHARACTERS_NUMBER = /^[0-9０-９]+$/;
const NOT_ALLOW_ENTER_CHARACTERS_ALPHABET_FULLSIZE = /^[^a-zA-Z０-９]+$/;

export {
  NUMBER_REGEX,
  NUMBER_SWITCH_SECONDS_REGEX,
  REPLACE_ZERO,
  FOUR_NUMBER_CHARACTERS,
  KEY_SAFIE_CHARACTERS,
  REGEX_EMAIL,
  REGEX_PASSWORD,
  ONLY_ALLOW_ENTER_CHARACTERS_NUMBER,
  NOT_ALLOW_ENTER_CHARACTERS_ALPHABET_FULLSIZE,
};
