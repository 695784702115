import { Col, Row, Typography } from 'antd';
import React from 'react';

import { ButtonField, InputField, SelectField } from 'src/shared/components/form';
import { SubmitStreaming } from 'src/interfaces/pages/submitStreaming';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getLabelsAction, getPlaneNumerAction } from 'src/stores/screens/privateScreens/common/common.action';
import { getSpotsAction } from 'src/stores/screens/privateScreens/spots/spots.action';

const { Title, Text } = Typography;

const FormSubmit: React.FC<SubmitStreaming.FormSubmit> = ({
  formik,
  optionsSelectAirplane,
  optionsSelectLabels,
  optionsSelectSpots,
}) => {
  const { errors, touched, isValid, setFieldTouched, setFieldValue, getFieldProps, handleSubmit } = formik;

  const { isMobile } = useWindowSize();

  const { totalPageLabels, pageLabels, totalPagePlaneNumber, pagePlaneNum, isLoading } = useAppSelector(
    state => state.common,
  );
  const { totalPageSpots, pageSpots, isLoadingSpots } = useAppSelector(state => state.spots);
  const { meInfo } = useAppSelector(state => state.auth);

  const dispatch = useAppDispatch();

  const onScrollSelect = (e: any, type: 'labels' | 'spots' | 'planeNum'): void => {
    const target = e.target;
    const height = Number(target.scrollTop) + Number(target?.clientHeight);
    const maxPageLabel = totalPageLabels / 10;
    const maxPagePlaneNum = totalPagePlaneNumber / 10;
    const maxPageSpot = totalPageSpots / 10;

    if (type === 'labels') {
      if (height === target.scrollHeight && pageLabels < maxPageLabel && !isLoading) {
        void dispatch(
          getLabelsAction({ data: { terminalId: meInfo?.terminalId, page: Number(pageLabels) + 1, isSubmit: true } }),
        );
      }
    }

    if (type === 'planeNum') {
      if (height === target.scrollHeight && pagePlaneNum < maxPagePlaneNum && !isLoading) {
        void dispatch(
          getPlaneNumerAction({
            data: { page: Number(pagePlaneNum) + 1, isSubmit: true },
          }),
        );
      }
    }

    if (type === 'spots') {
      if (height === target.scrollHeight && pageSpots < maxPageSpot && !isLoadingSpots) {
        void dispatch(
          getSpotsAction({ data: { terminalId: meInfo?.terminalId, page: Number(pageSpots) + 1, isSubmit: true } }),
        );
      }
    }
  };

  return (
    <Col span={isMobile ? 20 : 15} className="col-form">
      <Col span={24}>
        <Title level={4} className="title">
          利用デバイス設定
        </Title>
      </Col>

      <Col span={24}>
        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('terminalId')}
            label={<Text className="color-primary">拠点：</Text>}
            disabled
            className="field-base form-item"
          />
        </Col>

        <Col span={24}>
          <SelectField
            optionsSelect={optionsSelectLabels}
            setFieldValue={setFieldValue}
            field={getFieldProps('labelId')}
            label={<Text className="required-field color-primary">利用デバイスグループ：</Text>}
            className="field-label form-item"
            selectProps={{
              onPopupScroll: (e: any) => {
                onScrollSelect(e, 'labels');
              },
              placeholder: '選択してください',
              onBlur: () => setFieldTouched('labelId', true),
              showSearch: true,
              filterOption: (input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
            }}
            error={errors.labelId}
            touched={touched.labelId}
          />
        </Col>

        <Col span={24} className="col-camera form-item">
          <Text className="field-name">カメラ：</Text>
          <Row gutter={10} className="row-camera">
            <Col span={12}>
              <InputField
                setFieldValue={setFieldValue}
                field={getFieldProps('cameraFirst')}
                disabled={true}
                className="field-camera"
                placeholder="車載の端末番号"
              />
            </Col>
            <Col span={12} className="col-camera">
              <InputField
                setFieldValue={setFieldValue}
                field={getFieldProps('cameraSecond')}
                disabled={true}
                className="field-camera"
                placeholder="作業員の端末番号"
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('tagButton')}
            label="タグボタン："
            disabled={true}
            className="field_tag-button form-item"
          />
        </Col>

        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('flightNum')}
            label={<Text className="required-field color-primary">便名：</Text>}
            error={errors.flightNum}
            touched={touched.flightNum}
            className="flight-num form-item"
            inputProps={{
              type: 'tel',
            }}
          />
        </Col>

        <Col span={24}>
          <SelectField
            optionsSelect={optionsSelectSpots}
            setFieldValue={setFieldValue}
            field={getFieldProps('spotId')}
            label={<Text className="required-field color-primary">スポット：</Text>}
            selectProps={{
              placeholder: '選択してください',
              showSearch: true,
              onPopupScroll: (e: any) => {
                onScrollSelect(e, 'spots');
              },
              onBlur: () => setFieldTouched('spotId', true),
              filterOption: (input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
            }}
            className="form-item"
            error={errors.spotId}
            touched={touched.spotId}
          />
        </Col>

        <Col span={24}>
          <SelectField
            optionsSelect={optionsSelectAirplane}
            setFieldValue={setFieldValue}
            field={getFieldProps('airplaneId')}
            label={<Text className="required-field color-primary">機番：</Text>}
            selectProps={{
              placeholder: '選択してください',
              showSearch: true,
              onPopupScroll: (e: any) => {
                onScrollSelect(e, 'planeNum');
              },
              onBlur: () => setFieldTouched('airplaneId', true),
              filterOption: (input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
            }}
            error={errors.airplaneId}
            touched={touched.airplaneId}
            className="form-item"
          />
        </Col>

        <Col span={24} className="col-submit">
          <ButtonField disabled={!isValid} content="登録 " type="primary" onClick={() => handleSubmit()} />
        </Col>
      </Col>
    </Col>
  );
};

export default FormSubmit;
