import React from 'react';

const CloseSiderHoverLightIcon: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4465 23.1872C29.0113 26.4824 26.4824 29.0113 23.1872 29.4465C20.9219 29.7457 18.0881 30 15 30C11.9119 30 9.07812 29.7457 6.81276 29.4465C3.51756 29.0113 0.988688 26.4824 0.553488 23.1872C0.254304 20.9219 1.04137e-06 18.0881 1.31134e-06 15C1.58131e-06 11.9119 0.254305 9.07812 0.55349 6.81275C0.98869 3.51756 3.51757 0.988685 6.81276 0.553486C9.07813 0.254301 11.9119 -1.58131e-06 15 -1.31134e-06C18.0881 -1.04137e-06 20.9219 0.254302 23.1872 0.553487C26.4824 0.988687 29.0113 3.51756 29.4465 6.81276C29.7457 9.07812 30 11.9119 30 15C30 18.0881 29.7457 20.9219 29.4465 23.1872Z"
        fill="#EFEFF2"
      />
      <path
        d="M15 15.75L21.2056 15.75C21.5323 15.75 21.75 15.525 21.75 15.1875C21.75 14.85 21.5323 14.625 21.2056 14.625L15 14.625L15 15.75Z"
        fill="#3699FF"
      />
      <path
        d="M14.1388 12.15C14.4504 11.8243 15 12.0449 15 12.4957L15 18.2543C15 18.7051 14.4504 18.9257 14.1388 18.6L11.398 15.7359C11.2007 15.5297 11.2007 15.2203 11.398 15.0141L14.1388 12.15Z"
        fill="#3699FF"
      />
      <rect
        opacity="0.3"
        x="9"
        y="21"
        width="1.125"
        height="11.25"
        rx="0.5625"
        transform="rotate(-180 9 21)"
        fill="#3699FF"
      />
    </svg>
  );
};

export default CloseSiderHoverLightIcon;
