import React from 'react';
import { Col, Row, Typography } from 'antd';

import { ButtonField, InputField, SelectField } from 'src/shared/components/form';
import useSafie from './hooks/useSafie';

const { Title, Text } = Typography;

const SafieSetting: React.FC = () => {
  const { formik, optionsSelectTerminal, setTerminalId } = useSafie();

  const { errors, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  return (
    <React.Fragment>
      <Row className="row-title">
        <Col span={18}>
          <Title level={4} className="title">
            Safie設定
          </Title>
        </Col>
        <Col span={6}>
          <SelectField
            field={getFieldProps('terminalId')}
            optionsSelect={optionsSelectTerminal}
            setFieldValue={setFieldValue}
            error={errors.terminalId}
            touched={touched.terminalId}
            selectProps={{
              placeholder: '羽田国際線',
            }}
            onChange={e => {
              setTerminalId(e);
            }}
          />
        </Col>
      </Row>

      <Row className="row-field-group">
        <Col span={24} className="col-text-first">
          <Text className="text-field-group color-title-table-light-theme">Safieカメラ設定</Text>
        </Col>

        <Col span={24}>
          <Text className="text-field-group color-title-table-light-theme">Safieに連携するAPIキー</Text>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('key')}
            className="input-key"
            error={errors.key}
            touched={touched.key}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24} className="col-btn-first">
          <ButtonField content="保存 " type="primary" onClick={handleSubmit} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SafieSetting;
