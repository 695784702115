import { Row } from 'antd';
import styled, { css } from 'styled-components';

import { COLOR, SIZEH, SIZEW } from 'src/shared/constants';
import ResponsiveUI from 'src/shared/constants/reponsive';

const responsiveForm = css`
  ${ResponsiveUI.lessThanH(SIZEH.PAD3)} {
    ${ResponsiveUI.lessThan(SIZEW.LG)} {
      .form-item {
        display: block;

        .ant-form-item-row {
          display: block;
        }

        .row-camera {
          width: 100%;
        }
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
    ${ResponsiveUI.lessThan(SIZEW.XS)} {
      &.submit-stream-screen {
        padding: 15px;

        .col-form {
          width: 100%;
          max-width: 100%;
          padding: 20px;
          flex: 100%;
        }

        .col-detail {
          .alert-normal,
          .alert-primary,
          .alert-muted {
            padding: 20px;
          }
        }
      }
    }
  }
`;

export const WrapperStyled = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 10px 0;

  &.submit-stream-screen {
    padding: 20px;

    .col-form {
      background-color: ${COLOR.BACKGROUND_SECONDARY};
      padding: 50px;
      border-radius: 10px;
      height: fit-content;

      .title {
        margin-bottom: 24px;
        color: ${COLOR.WHITE};
      }

      .col-submit {
        border-top: 1px solid #383840;
        display: flex;
        justify-content: center;

        .ant-btn {
          margin-top: 30px;
          width: 120px;
          height: 42px;
        }
      }
    }
  }

  .ant-form-item-label {
    width: 294px;
    display: flex;
  }

  .col-camera {
    display: flex;

    .field-name {
      width: 294px;
      color: #9096ae;
      font-family: 700;
    }

    .row-camera {
      width: calc(100% - 290px);

      :last-child {
        .col-camera {
          padding-right: 0 !important;
        }
      }

      .field-camera {
        width: 100%;
      }
    }
  }

  .flight-num {
    .ant-form-item-control-input-content {
      .ant-input {
        padding-left: 24px;
      }

      &::after {
        content: 'JL';
        position: absolute;
        color: #adaec1;
        top: 5px;
        left: 8px;
        opacity: 0.7;
      }
    }
  }

  .col-detail {
    display: flex;
    justify-content: center;
  }

  ${responsiveForm}
`;
