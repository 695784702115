import { Row } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const WrapperStyled = styled(Row)`
  height: 100%;
  padding: 20px 0;
  justify-content: center;

  .col-form {
    padding: 30px;
    border-radius: 10px;
    height: 100%;
    background-color: ${COLOR.BACKGROUND_SECONDARY};
    padding-bottom: 5px;

    .row-btn {
      justify-content: space-between;

      .title {
        color: ${COLOR.WHITE};
        font-size: 18px;
        font-weight: 700;
      }

      .btn-create {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .row-table {
      height: calc(100% - 34px);

      .ant-table-footer {
        background-color: transparent !important;
      }
    }
  }
`;

export const ModalContentWrap = styled(Row)`
  .field-title {
    margin-top: 20px;

    span {
      color: #adaec1;
    }

    .required {
      color: #f64e60;
    }
  }
`;
