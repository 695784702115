import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { deleteAirplaneAction, getPlaneNumerAction } from 'src/stores/screens/privateScreens/common/common.action';
import { MasterManagement } from 'src/interfaces/pages/masterManagement';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_POPUP } from 'src/shared/constants/message';

const { confirm } = Modal;

function useInit(): MasterManagement.UseInitPlaneNumber {
  const dispatch = useAppDispatch();

  const { planeNumber, totalPagePlaneNumber, isLoading, pagePlaneNum } = useAppSelector(state => state.common);

  const [currentPage, setCurrentPage] = useState(1);
  const [dataEdit, setDataEdit] = useState<DashboardStore.Airplane | null>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: '110px',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: '機番名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'アクション',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon
            component={PencilEditIcon}
            onClick={() => {
              setDataEdit(record);
              setIsShowModal(true);
            }}
          />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirm(record.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: () => {},
  });

  const fetchDataAirPlane = async (params?: CommonStore.ParamsGetList): Promise<void> => {
    const data = {
      page: params?.page ?? pagePlaneNum,
      search: params?.search,
      size: params?.size ?? 10,
      haveLabel: params?.haveLabel ?? true,
    };

    await dispatch(getPlaneNumerAction({ data, callback: () => setInitialLoad(false) }));
  };

  useEffect(() => {
    void fetchDataAirPlane({ haveLabel: true, size: 10 });
  }, []);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  useEffect(() => {
    if (!isShowModal) {
      setDataEdit(null);
    }
  }, [isShowModal]);

  const showConfirm = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteAirplaneAction({
            id,
            callback: () => {
              void fetchDataAirPlane({});
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.DELETE,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  return {
    columns,
    dataSource: planeNumber,
    isLoading,
    totalPagePlaneNumber,
    formik,
    dataEdit,
    isShowModal,
    initialLoad,
    currentPage,
    setDataEdit,
    setIsShowModal,
    fetchDataAirPlane,
    setCurrentPage,
  };
}

export default useInit;
