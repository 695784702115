import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Settings } from 'src/interfaces/pages/settings';
import { Dashboard } from 'src/interfaces/pages/dashboard';
import { shcemaManeuverSetting } from './shcemaManeuverSetting';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  fetchDataVideoAction,
  getManeuverSettingAction,
  maneuverSettingsAction,
} from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import { LOCAL_STORAGE_KEY, ROUTERS } from 'src/shared/constants';
import useRole from 'src/shared/hooks/useRole';

const useFormManeuverSettings = ({ isOpen, setIsShowModalSetting }): Settings.useFormManeuverSettings => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { dataSetting, isLoading, isLoadingSetting } = useAppSelector(state => state.dashboard);
  const { order, search } = useAppSelector(state => state.paramSearchStream);

  const { isGeneralUser } = useRole();

  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);
  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
  const { meInfo } = useAppSelector(state => state.auth);

  const page = Number(localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST));

  useEffect(() => {
    if (pathname !== ROUTERS.DASHBOARD.PATH) {
      setIsShowModalSetting(false);
    }
  }, [isOpen, pathname, dataSetting]);

  useEffect(() => {
    if (!isGeneralUser) {
      void dispatch(getManeuverSettingAction());
    }
  }, []);

  const initialValues: Dashboard.InitialValuesSetting = {
    switchListSecond: dataSetting?.switchListSecond ?? 1,
    switchZoomSecond: dataSetting?.switchZoomSecond ?? 1,
    isSwitchList: dataSetting?.isSwitchList ?? false,
    isSwitchZoom: dataSetting?.isSwitchZoom ?? false,
    isAutomaticZoom: dataSetting?.isAutomaticZoom ?? false,
  };

  const changeSettingSuccess = (): void => {
    const orderStorage = localStorage.getItem(LOCAL_STORAGE_KEY.ORDER);
    const searchStorage = localStorage.getItem(LOCAL_STORAGE_KEY.SEARCH);
    const pageStorage = Number(localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST));

    setIsShowModalSetting(false);
    if (!isGeneralUser) {
      void dispatch(getManeuverSettingAction());
    }
    void dispatch(
      fetchDataVideoAction({
        data: {
          size: Number(itemOfPage),
          terminalId: terminalId ?? meInfo?.terminalId,
          page: pageStorage ?? page,
          order: orderStorage ?? order,
          search: searchStorage ?? search,
        },
      }),
    );
    navigate({
      pathname: ROUTERS.DASHBOARD.PATH,
    });
  };

  const changeSetting = async (data: DashboardStore.ParamsManeuverSettings): Promise<void> => {
    await dispatch(
      maneuverSettingsAction({
        data,
        callback: () => {
          changeSettingSuccess();
        },
      }),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: shcemaManeuverSetting,
    initialValues,
    onSubmit: value => {
      void changeSetting({
        ...value,
        switchListSecond: Number(value.switchListSecond),
        switchZoomSecond: Number(value.switchZoomSecond),
      });
    },
    validateOnChange: true,
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    if (!values.isSwitchList) {
      void setFieldValue('switchListSecond', dataSetting?.switchListSecond);
    }

    if (!values.isSwitchZoom) {
      void setFieldValue('switchZoomSecond', dataSetting?.switchZoomSecond);
    }
  }, [values]);

  return {
    formik,
    isLoading,
    isLoadingSetting,
  };
};

export default useFormManeuverSettings;
