import React from 'react';

const FileUploadLightIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M36.1067 47.7273H11.8933C8.73902 47.7273 5.71391 46.4885 3.48348 44.2834C1.25304 42.0783 0 39.0876 0 35.9691L8.41864e-06 11.7583C8.41864e-06 8.63981 1.25305 5.64907 3.48348 3.44399C5.71392 1.2389 8.73903 9.52952e-05 11.8933 9.52952e-05L23.1724 1.01774e-10C24.6959 -1.28722e-05 25.931 1.22103 25.931 2.72727V12.4246C25.931 17.5457 30.1303 21.6973 35.3103 21.6973L45.2414 21.6974C46.7649 21.6974 48 22.9184 48 24.4246L48 36.0218C47.9859 39.1311 46.7266 42.1083 44.4977 44.302C42.2688 46.4957 39.2518 47.7273 36.1067 47.7273Z"
        fill="#9096AE"
      />
      <path
        d="M22.6183 31.2965C22.6129 31.6849 22.4942 32.063 22.2771 32.3836C22.06 32.7042 21.7542 32.9532 21.3977 33.0994C21.0413 33.2455 20.6501 33.2824 20.2729 33.2055C19.8958 33.1285 19.5496 32.9411 19.2774 32.6666L15.4927 28.7936L11.6559 32.6929C11.4708 32.878 11.2514 33.0245 11.0102 33.124C10.769 33.2236 10.5108 33.2742 10.2502 33.2729C9.98968 33.2717 9.73192 33.2187 9.49167 33.1169C9.25142 33.0151 9.03338 32.8666 8.85 32.6798C8.66662 32.4929 8.52149 32.2715 8.42291 32.028C8.32432 31.7846 8.2742 31.5239 8.27541 31.2609C8.27662 30.9979 8.32914 30.7377 8.42997 30.4952C8.5308 30.2527 8.67796 30.0326 8.86305 29.8475L14.0833 24.5781C14.4503 24.2081 14.9479 24.0002 15.4666 24.0002C15.9854 24.0002 16.4829 24.2081 16.85 24.5781L22.0702 29.8475C22.2549 30.0383 22.3989 30.2654 22.4932 30.5146C22.5874 30.7639 22.63 31.03 22.6183 31.2965Z"
        fill="#9096AE"
      />
      <rect x="13.2422" y="27.2729" width="3.86207" height="14.7273" rx="1.93103" fill="#9096AE" />
      <path
        d="M28.6895 12.1855V1.15321C28.6895 0.127867 29.9434 -0.385629 30.6767 0.339398L47.6565 17.1262C48.3899 17.8512 47.8705 19.0909 46.8333 19.0909H35.6742C31.8166 19.0909 28.6895 15.9993 28.6895 12.1855Z"
        fill="#9096AE"
      />
    </svg>
  );
};

export default FileUploadLightIcon;
