import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { deleteCameraAction, getCameraAction } from 'src/stores/screens/privateScreens/common/common.action';
import { MasterManagement } from 'src/interfaces/pages/masterManagement';
import { TYPE_CAMERA } from 'src/shared/constants';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';

const { confirm } = Modal;

function useInit(): MasterManagement.UseInitCamera {
  const dispatch = useAppDispatch();

  const { cameras, totalPageCameras, isLoading } = useAppSelector(state => state.common);
  const { terminals } = useAppSelector(state => state.terminals);
  const { meInfo } = useAppSelector(state => state.auth);

  const [dataEdit, setDataEdit] = useState<DashboardStore.Camera | null>(null);
  const [terminalId, setTerminalId] = useState<any>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: '110px',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: '端末番号',
      dataIndex: 'serialNum',
      key: 'serialNum',
    },
    {
      title: '端末種類',
      dataIndex: 'type',
      key: 'type',
      render: type => <>{type === TYPE_CAMERA.HUMAN.VALUE ? TYPE_CAMERA.HUMAN.LABEL : TYPE_CAMERA.CAR.LABEL}</>,
    },
    {
      title: 'グループ名',
      dataIndex: 'label',
      key: 'label',
      render: (_, record) => <>{record?.label?.name}</>,
    },
    {
      title: 'アクション',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon
            component={PencilEditIcon}
            onClick={() => {
              setDataEdit(record);
              setIsShowModal(true);
            }}
          />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirm(record?.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      terminalId: terminalId ?? meInfo?.terminalId,
    },
    onSubmit: () => {},
  });

  const fetchDataCameras = async (params?: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(
      getCameraAction({
        data: { size: 10, page: currentPage, terminalId: terminalId ?? meInfo?.terminalId, haveLabel: true, ...params },
      }),
    );
  };

  const dataSelectTerminals: DefaultOptionType[] = terminals?.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    void fetchDataCameras();
    void dispatch(getTerminalAction({ data: {} }));
  }, []);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  useEffect(() => {
    if (!isShowModal) {
      setDataEdit(null);
    }
  }, [isShowModal]);

  const showConfirm = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteCameraAction({
            id,
            callback: () => {
              void fetchDataCameras();
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.DELETE,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  return {
    columns,
    dataSource: cameras,
    totalPageCameras,
    isLoading,
    isShowModal,
    currentPage,
    dataSelectTerminals,
    formik,
    dataEdit,
    terminalId,
    setIsShowModal,
    setDataEdit,
    setTerminalId,
    setCurrentPage,
    fetchDataCameras,
  };
}

export default useInit;
