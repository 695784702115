import React from 'react';

const MasterManagementActiveIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M16.3295 2.93018H7.67953C4.5536 2.93018 2.01953 5.46424 2.01953 8.59018V15.4002C2.01953 18.5261 4.5536 21.0602 7.67953 21.0602H16.3295C19.4555 21.0602 21.9895 18.5261 21.9895 15.4002V8.59018C21.9895 5.46424 19.4555 2.93018 16.3295 2.93018Z"
        fill="white"
      />
      <path
        d="M8.19914 11.95C9.62404 11.95 10.7791 10.7904 10.7791 9.36002C10.7791 7.9296 9.62404 6.77002 8.19914 6.77002C6.77425 6.77002 5.61914 7.9296 5.61914 9.36002C5.61914 10.7904 6.77425 11.95 8.19914 11.95Z"
        fill="white"
      />
      <path
        d="M8.19891 17.6801C10.3086 17.6801 12.0189 16.5519 12.0189 15.1601C12.0189 13.7684 10.3086 12.6401 8.19891 12.6401C6.08918 12.6401 4.37891 13.7684 4.37891 15.1601C4.37891 16.5519 6.08918 17.6801 8.19891 17.6801Z"
        fill="white"
      />
      <path
        d="M18.52 14.9004H15C14.8011 14.9004 14.6103 14.8214 14.4697 14.6807C14.329 14.5401 14.25 14.3493 14.25 14.1504C14.25 13.9515 14.329 13.7607 14.4697 13.6201C14.6103 13.4794 14.8011 13.4004 15 13.4004H18.49C18.6889 13.4004 18.8797 13.4794 19.0203 13.6201C19.161 13.7607 19.24 13.9515 19.24 14.1504C19.24 14.3493 19.161 14.5401 19.0203 14.6807C18.8797 14.8214 18.6889 14.9004 18.49 14.9004H18.52Z"
        fill="white"
      />
      <path
        d="M18.8991 11.0601H13.6191C13.4202 11.0601 13.2295 10.981 13.0888 10.8404C12.9482 10.6997 12.8691 10.509 12.8691 10.3101C12.8691 10.1111 12.9482 9.92038 13.0888 9.77973C13.2295 9.63908 13.4202 9.56006 13.6191 9.56006H18.8991C19.0981 9.56006 19.2888 9.63908 19.4295 9.77973C19.5701 9.92038 19.6491 10.1111 19.6491 10.3101C19.6491 10.509 19.5701 10.6997 19.4295 10.8404C19.2888 10.981 19.0981 11.0601 18.8991 11.0601Z"
        fill="white"
      />
    </svg>
  );
};

export default MasterManagementActiveIcon;
