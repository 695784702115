import { Row } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const WrapperStyled = styled(Row)`
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: 100%;

  .filter-card {
    background-color: #141414;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    .col-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: 0;
        margin-right: 20px;
      }

      .field-select {
        min-width: 114px;
      }

      .row-right {
        align-items: center;

        .anticon {
          margin-left: 20px;
        }

        .plus-icon {
          border-radius: 5px;
          background-color: #3699ff;
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .col-action {
          background: #2c2f36;
          border-radius: 6px;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          margin-left: 20px;
          cursor: pointer;

          .ant-typography {
            color: #adaec1;
            font-size: 12px;
          }

          .anticon {
            margin-right: 5px;
            margin-left: 0;
          }
        }

        .icon-disable {
          cursor: not-allowed;
        }
      }
    }
  }

  .col-form {
    background-color: transparent;
    border-radius: 10px;
    height: calc(100% - 94px);
    padding: 0px;

    .ant-typography {
      color: ${COLOR.WHITE};
      margin-bottom: 24px;
    }
  }

  .ant-form-item-control .ant-select-selector .ant-select-selection-item {
    color: #adaec1;
  }
`;

export const ModalContentWrap = styled(Row)`
  .field-title {
    margin-top: 20px;

    span {
      color: #adaec1;
    }

    .required {
      color: #f64e60;
    }
  }

  .camera-group {
    display: flex;
    justify-content: space-between;
  }

  .camera-field {
    width: calc(50% - 5px);
    .text-note {
      color: #adaec1;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
`;

export const ModalUploadFileWrap = styled(Row)`
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  width: 494px;
  background-color: #15171c;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  .modal-header {
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;

    .title {
      font-size: 20px;
      text-align: center;
      color: #ffffff;
    }

    .icon-close {
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }

  .modal-body {
    padding: 30px;
    border-top: 1px solid #383840;
    border-bottom: 1px solid #383840;

    .row-title {
      text-align: center;
      margin-bottom: 20px;

      .ant-typography {
        line-height: 19.22px;
        font-size: 12px;
        font-weight: 400;
        color: #adaec1;
        margin: 0;
      }
    }

    .field-title {
      .ant-typography {
        display: block;
        margin-bottom: 5px;
        color: #adaec1;

        .required {
          color: #f64e60;
        }
      }
    }

    .col-upload-file {
      position: relative;

      .upload-file-wrap {
        display: flex;
        align-items: center;
        padding: 14px;
        border: 1px dashed #383840;
        border-radius: 6px;
        background-color: #26292f;

        .ant-typography {
          font-size: 14px;
          color: #595b69;
          margin: 0;
          margin-left: 10px;
        }

        .text-file-name {
          color: #fff;
        }

        .progress-upload-file {
          margin-left: auto;
        }
      }

      .upload-file-wrap__error {
        border: 1px dashed #f64e60;

        .file-message-error {
          font-size: 14px;
          color: #f64e60;
        }
      }

      .upload-file-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }

    .col-note {
      margin-top: 25px;
      width: 100%;
      text-align: center;

      .ant-typography {
        font-size: 12px;
        color: #adaec1;

        span {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }

  .modal-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      width: 140px;
      height: 48px;

      &:first-child {
        margin-right: 20px;
        color: #adaec1;
      }
    }
  }
`;

export const ModalTableValidateWrap = styled(Row)`
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  width: 730px;
  background-color: #15171c;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  .modal-header {
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;

    .title {
      font-size: 20px;
      text-align: center;
      color: #ffffff;
    }

    .icon-close {
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }

  .modal-body {
    height: 327px;
    padding: 20px;
    border-top: 1px solid #383840;

    .table-wrap {
      padding: 10px;
      background-color: #26292f;
      border-radius: 6px;
      height: 100%;
    }

    .scroll {
      height: 90%;
      overflow-y: auto;
      background-color: #fff;
    }

    table {
      position: relative;
      width: 100%;
      text-align: center;
      background-color: #fff;

      thead {
        background-color: #0bb783;
        color: #0f1013;
        font-size: 12px;
        font-weight: 700;
        padding: 5px;
        border-bottom: 1px solid #26292f;

        th {
          padding: 5px;
        }
      }

      tbody {
        font-weight: 500;
        font-size: 12px;
        color: #000000;

        tr {
          border-bottom: 1px solid #26292f;
        }

        td {
          padding: 5px;

          span {
            display: block;
          }
        }

        .td-error {
          background-color: #f64e60;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ModalDownloadFileWrap = styled(Row)`
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  width: 494px;
  background-color: #15171c;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  .modal-header {
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;

    .title {
      font-size: 20px;
      text-align: center;
      color: #ffffff;
    }

    .icon-close {
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }

  .modal-body {
    padding: 30px;
    border-top: 1px solid #383840;
    border-bottom: 1px solid #383840;

    .row-title {
      text-align: center;
      margin-bottom: 20px;

      .ant-typography {
        line-height: 19.22px;
        font-size: 12px;
        font-weight: 400;
        color: #adaec1;
        margin: 0;
      }
    }

    .field-title {
      .ant-typography {
        display: block;
        margin-bottom: 5px;
        color: #adaec1;

        .required {
          color: #f64e60;
        }
      }
    }
  }

  .modal-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      width: 140px;
      height: 48px;

      &:first-child {
        margin-right: 20px;
        color: #adaec1;
      }
    }
  }
`;
