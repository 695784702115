import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import COMMON_API from './common.api';
import { MESSAGE_MASTER } from 'src/shared/constants/message';

export const getLabelsAction = createAsyncThunk<any, CommonStore.RequestGetList>(
  'labels',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.getLabelsAPI({
        page: data?.page,
        size: data?.size,
        terminalId: data?.terminalId,
      });

      return { response, isSubmit: data?.isSubmit };
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getPlaneNumerAction = createAsyncThunk<any, CommonStore.RequestGetList>(
  'plane_number',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.getAirplaneAPI({
        page: data?.page,
        size: data?.size,
        search: data?.search ?? null,
        terminalId: data?.terminalId,
        haveLabel: data?.haveLabel,
        isSort: data?.isSort,
      });

      callback();

      return { response, isSubmit: data?.isSubmit };
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getCameraAction = createAsyncThunk<any, CommonStore.RequestGetList>(
  'camera',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.getCameraAPI({
        page: data?.page,
        size: data?.size,
        terminalId: data?.terminalId,
        haveLabel: data?.haveLabel,
      });

      callback();

      return { response, isSubmit: data?.isSubmit };
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getOneClickAction = createAsyncThunk<any, CommonStore.RequestGetList>(
  'one_click',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.getOneClickAPI({
        page: data?.page,
        size: data?.size,
        terminalId: data?.terminalId,
        haveLabel: data?.haveLabel,
      });

      callback();

      return { response, isSubmit: data?.isSubmit };
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const getDataVideoZoomAction = createAsyncThunk<any, CommonStore.RequestGetList>(
  'get_data_video_zoom',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.getDataVideoZoom(data);

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateStatusVideoZoomAction = createAsyncThunk<any, CommonStore.RequestUpdateStatusVideoZoom>(
  'update_status_video_zoom',
  async ({ id, isZoom, isZoomFull }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateStatusVideoZoom({ id, isZoom, isZoomFull });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateStatusIsPinAction = createAsyncThunk<any, CommonStore.RequestUpdateStatusIsPin>(
  'update_status_is_pin',
  async ({ id, isPin, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateStatusIsPin({ id, isPin });

      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateStatusCameraAction = createAsyncThunk<any, CommonStore.RequestUpdateStatusCamera>(
  'update_status_camera',
  async ({ id, status }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateStatusCamera({ id, status });

      return response;
    } catch (err: any) {
      rejectWithValue(err?.message);
    }
  },
);

export const createCameraAction = createAsyncThunk<any, CommonStore.InitValueCreateCamera>(
  'create_camera',
  async ({ terminalId, serialNum, type, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.createCameraAPI({ terminalId, serialNum, type });

      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateCameraAction = createAsyncThunk<any, CommonStore.InitValueUpdateCamera>(
  'update_camera',
  async ({ id, serialNum, type, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateCameraAPI({ id, serialNum, type });

      callback();
      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteCameraAction = createAsyncThunk<any, CommonStore.InitValueDeleteCamera>(
  'delete_camera',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.deleteCameraAPI({ id });

      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const createButtonAction = createAsyncThunk<any, CommonStore.InitValueCreateButton>(
  'create_button',
  async ({ terminalId, serialNum, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.createButtonAPI({ terminalId, serialNum });
      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateButtonAction = createAsyncThunk<any, CommonStore.InitValueUpdateButton>(
  'update_button',
  async ({ id, serialNum, terminalId, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateButtonAPI({ id, terminalId, serialNum });
      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteButtonAction = createAsyncThunk<any, CommonStore.InitValueDeleteButton>(
  'delete_button',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.deleteButtonAPI({ id });
      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });
      callback();

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const createAirplaneAction = createAsyncThunk<any, CommonStore.InitValueCreateAirPlane>(
  'create_Airplane',
  async ({ name, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.createAirplaneAPI({ name });

      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const updateAirplaneAction = createAsyncThunk<any, CommonStore.InitValueUpdateAirplane>(
  'update_airplane',
  async ({ id, name, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.updateAirplaneAPI({ id, name });

      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const deleteAirplaneAction = createAsyncThunk<any, CommonStore.InitValueDeleteButton>(
  'delete_airplane',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await COMMON_API.deleteAirplaneAPI({ id });

      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);
