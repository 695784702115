import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Dashboard } from 'src/interfaces/pages/dashboard';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { fetchDataVideoAction } from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import { alertStreamActions } from 'src/stores/screens/privateScreens/submitStream/submitStream.action';
import { updateStatusVideoZoomAction } from 'src/stores/screens/privateScreens/common/common.action';
import { LOCAL_STORAGE_KEY, ROUTERS, TYPE_SOCKET } from 'src/shared/constants';
import useRole from 'src/shared/hooks/useRole';
import socket from 'src/configs/socketIO/socketIO';
import { getDataDashboardSecondAction } from 'src/stores/screens/privateScreens/dashboardSecond/dashboardSecond.action';

const useInit = (): Dashboard.UseInit => {
  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);
  const page = Number(localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST));
  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
  const isPlayPauseStorage = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '')?.isPlayPause;

  const dispatch = useAppDispatch();
  const { dataVideo, isLoading, totalItem } = useAppSelector(state => state.dashboard);
  const {
    dataDashboardSecond,
    isLoading: isLoadingDashboardSecond,
    totalPin,
  } = useAppSelector(state => state.dashboardSecond);
  const { videoZoom } = useAppSelector(state => state.common);
  const { meInfo } = useAppSelector(state => state.auth);
  const { isGeneralUser } = useRole();
  const { pathname } = useLocation();

  const [quantityCardSecond, setQuanityCardSecond] = useState(dataDashboardSecond?.length);
  const [isPlayPause, setIsPlayPause] = useState(isPlayPauseStorage);
  const [newAlert, setNewAlert] = useState<DashboardStore.DataVideo[]>(dataVideo);
  const [mergeVideo, setMergeVideo] = useState<DashboardStore.DataVideo[]>(dataVideo);
  const [isSocket, setIsSocket] = useState<{ isSocket: boolean; type?: 'create' | 'delete' | null }>({
    isSocket: false,
    type: null,
  });

  const getItemOfPage = useMemo(() => {
    switch (Number(itemOfPage)) {
      case 6:
        return 3;
      case 8:
        return 4;
      case 10:
        return 5;
      case 12:
      case 18:
        return 6;
      default:
        return 5;
    }
  }, [itemOfPage]);

  const fetchDataVideo = async (value: DashboardStore.ParamsVideo, callback?: () => void): Promise<void> => {
    await dispatch(fetchDataVideoAction({ data: value, callback }));
  };

  const alertStream = async (id: number, typeAlert: string): Promise<void> => {
    await dispatch(
      alertStreamActions({
        id,
        typeAlert,
        callback: () => {},
      }),
    );
  };

  const updateStatusVideoZoom = (id: number, value: boolean): void => {
    void dispatch(updateStatusVideoZoomAction({ id, isZoom: value }));
  };

  useEffect(() => {
    setQuanityCardSecond(dataDashboardSecond.length);
  }, [dataDashboardSecond]);

  const handlePinStream = (value: any): void => {
    if (value?.userId === meInfo?.id) {
      void dispatch(
        getDataDashboardSecondAction({
          data: {
            terminalId: terminalId ?? meInfo?.terminalId,
          },
          callback: () => {
            socket().disconnect();
          },
        }),
      );
    }
  };

  const handleSwitchPlayPause = (): void => {
    const isPlayPause = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '')?.isPlayPause;

    setIsPlayPause(isPlayPause);
  };

  const getNewAlert = (newObj, action?: { clickZoom?: boolean; clickPin?: boolean }): void => {
    const quantity = Number(localStorage.getItem(LOCAL_STORAGE_KEY.QUANTITY_DISPLAY_SECOND));

    setNewAlert(prevData => {
      const newData = [...prevData];

      const existingObjIndex = newData.findIndex(obj => obj.id === newObj.id);

      if (existingObjIndex !== -1) {
        if (action?.clickZoom) {
          newData[existingObjIndex] = { ...newData[existingObjIndex], isZoom: newObj.isZoom };
        } else if (action?.clickPin) {
          newData[existingObjIndex] = { ...newData[existingObjIndex], isPin: newObj.isPin };
        } else {
          newData[existingObjIndex] = newObj;
        }
      } else {
        newData.push(newObj);
      }

      return newData;
    });

    if (pathname === ROUTERS.DASHBOARD.PATH) {
      void dispatch(
        getDataDashboardSecondAction({
          data: {
            terminalId: terminalId ?? meInfo?.terminalId,
          },
        }),
      );
    }

    if (newObj.isZoom) {
      setQuanityCardSecond(quantity + 1);
    } else {
      setQuanityCardSecond(quantity - 1);
    }
  };

  useEffect(() => {
    if (!isGeneralUser) {
      void dispatch(
        fetchDataVideoAction({
          data: { terminalId: meInfo?.terminalId, size: Number(itemOfPage), page },
        }),
      );

      if (pathname === ROUTERS.DASHBOARD.PATH) {
        void dispatch(getDataDashboardSecondAction({ data: { terminalId: terminalId ?? meInfo?.terminalId } }));
      }
    }
  }, []);

  const handleCreateStream = (): void => {
    const page = Number(localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST));
    void fetchDataVideo(
      {
        size: Number(itemOfPage),
        terminalId: terminalId ?? meInfo?.terminalId,
        page,
      },
      () => {},
    );

    setIsSocket({ isSocket: false });
  };

  const handleDeleteStream = (): void => {
    const page = Number(localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST));

    void fetchDataVideo(
      {
        size: Number(itemOfPage),
        terminalId: terminalId ?? meInfo?.terminalId,
        page,
      },
      () => {
        socket().disconnect();
      },
    );

    void dispatch(
      getDataDashboardSecondAction({
        data: { terminalId: terminalId ?? meInfo?.terminalId },
        callback: () => {
          socket().disconnect();
        },
      }),
    );

    setIsSocket({ isSocket: false });
  };

  useEffect(() => {
    if (isSocket.isSocket && isSocket.type === 'create') {
      handleCreateStream();
    }

    if (isSocket.isSocket && isSocket.type === 'delete') {
      handleDeleteStream();
    }
  }, [isSocket, itemOfPage, terminalId]);

  useEffect(() => {
    socket().on(TYPE_SOCKET.ALERT_STREAM, value => {
      getNewAlert(value);
    });

    socket().on(TYPE_SOCKET.CREATE_STREAM, value => {
      setIsSocket({ isSocket: true, type: 'create' });
    });

    socket().on(TYPE_SOCKET.DELETE_STREAM, _ => {
      setIsSocket({ isSocket: true, type: 'delete' });
    });

    socket().on(TYPE_SOCKET.ZOOM_STREAM, value => {
      getNewAlert(value, { clickZoom: true });
    });

    socket().on(TYPE_SOCKET.SWITCH_PLAY_PAUSE, _ => {
      handleSwitchPlayPause();
    });

    socket().on(TYPE_SOCKET.PIN_STREAM, value => {
      handlePinStream(value);
    });
  }, []);

  useEffect(() => {
    setMergeVideo(
      dataVideo?.map(itemA => {
        const videoExits = newAlert.find(itemB => itemB.id === itemA.id);

        if (videoExits) {
          return { ...itemA, alertStatus: videoExits.alertStatus };
        }
        return itemA;
      }),
    );
  }, [newAlert]);

  useEffect(() => {
    if (dataVideo.length === 0 && page !== 1) {
      void dispatch(
        fetchDataVideoAction({
          data: { terminalId: meInfo?.terminalId, size: Number(itemOfPage), page: 1 },
        }),
      );

      localStorage.setItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST, '1');
    }
  }, []);

  useEffect(() => {
    setNewAlert(dataVideo);
  }, [dataVideo]);

  return {
    dataVideo,
    dataDashboardSecond,
    isLoading,
    videoZoom,
    page,
    totalItem,
    isLoadingDashboardSecond,
    totalPin,
    quantityCardSecond,
    getItemOfPage,
    terminalId,
    isPlayPause,
    mergeVideo,
    setQuanityCardSecond,
    fetchDataVideo,
    alertStream,
    updateStatusVideoZoom,
    setNewAlert,
  };
};

export default useInit;
