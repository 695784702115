import { Col, Row, Typography } from 'antd';
import React from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField, SwitchField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useFormManeuverSettings from './hooks/useFormManeuverSettings';
import { ManeuverSettingWrap } from '../dashboard/styled';

interface Props {
  isOpen: boolean;
  setIsShowModalSetting: (param: boolean) => void;
}

const { Title, Text } = Typography;

const ManeuverSettings: React.FC<Props> = ({ isOpen, setIsShowModalSetting }) => {
  const { formik, isLoadingSetting } = useFormManeuverSettings({ isOpen, setIsShowModalSetting });

  const { errors, touched, values, isValid, setFieldValue, getFieldProps, handleSubmit, resetForm } = formik;

  const handllecancel = (): void => {
    setIsShowModalSetting(false);
    resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      title="ダッシュボード表示設定"
      width={494}
      iconClose={CloseIconV3}
      handleCancel={handllecancel}
      className="modal-setting modal-custom-antd"
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handllecancel} />
          <ButtonField
            content="保存 "
            type="primary"
            onClick={handleSubmit}
            isLoading={isLoadingSetting}
            disabled={!isValid}
          />
        </>
      }
      content={
        <ManeuverSettingWrap>
          <Col span={24} className="group-field">
            <Title level={1} className="group-title">
              デバイスグループ表示切替
            </Title>
            <Row>
              <Col span={14} className="field-title">
                <Text>オン・オフ</Text>
              </Col>
              <Col span={10} className="field-list">
                <div>
                  <Text>一覧画面</Text>
                  <SwitchField
                    field={getFieldProps('isSwitchList')}
                    onChange={checked => setFieldValue('isSwitchList', checked)}
                    className="switch-field"
                  />
                </div>
                <div>
                  <Text>拡大画面</Text>
                  <SwitchField
                    field={getFieldProps('isSwitchZoom')}
                    onChange={checked => setFieldValue('isSwitchZoom', checked)}
                    className="switch-field"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={14} className="field-title">
                <Text>切替秒間</Text>
              </Col>
              <Col span={10} className="field-list">
                <div>
                  <Text>一覧画面</Text>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('switchListSecond')}
                    inputProps={{ suffix: '秒' }}
                    className="input"
                    error={values.isSwitchList && errors.switchListSecond}
                    touched={values.isSwitchList && touched.switchListSecond}
                    disabled={!values.isSwitchList}
                  />
                </div>
                <div>
                  <Text>拡大画面</Text>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('switchZoomSecond')}
                    inputProps={{ suffix: '秒' }}
                    className="input"
                    error={values.isSwitchZoom && errors.switchZoomSecond}
                    touched={values.isSwitchZoom && touched.switchZoomSecond}
                    disabled={!values.isSwitchZoom}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="group-field">
            <Title level={1} className="group-title">
              アラート（共通）
            </Title>
            <Row className="row-select">
              <Col span={14} className="field-title">
                <Text>自動拡大表示</Text>
              </Col>
              <Col span={10} className="field-list">
                <div>
                  <Text>オン・オフ</Text>
                  <SwitchField
                    field={getFieldProps('isAutomaticZoom')}
                    onChange={checked => setFieldValue('isAutomaticZoom', checked)}
                    className="switch-field"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </ManeuverSettingWrap>
      }
    />
  );
};

export default ManeuverSettings;
