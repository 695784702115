import React from 'react';
import { ModalProps } from 'antd';
import Icon from '@ant-design/icons';

import { CloseIcon } from 'src/assets/icons';
import { WrapperStyled } from './styled';

type Props = {
  className?: string;
  title?: React.ReactNode;
  content: string | React.ReactNode;
  isOpen: boolean;
  footer?: React.ReactNode;
  okText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  width?: number;
  getContainer?: any;
  iconClose?: any;
  handleOk?: () => void;
  handleCancel?: () => void;
} & Omit<ModalProps, 'children'>;

const ModalCard: React.FC<Props> = ({
  className,
  title,
  content,
  isOpen,
  footer,
  okText,
  cancelText,
  width,
  getContainer,
  iconClose,
  handleOk,
  handleCancel,
}) => {
  return (
    <WrapperStyled
      className={className}
      title={title}
      open={isOpen}
      footer={footer}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      getContainer={getContainer}
      closeIcon={<Icon component={iconClose ?? CloseIcon} />}
    >
      {content}
    </WrapperStyled>
  );
};

export default ModalCard;
