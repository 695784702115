export const COLOR = {
  BACKGROUND_PRIMARY: '#0f1013',
  BACKGROUND_SECONDARY: '#15171c',

  PRIMARY: '#b5b5c3',

  WHITE: '#ffffff',
  BLUE: '#3699ff',
  BLACK_LIGHT: '#15171C',
  PURPLE_LIGHT: '#9a9ab0',
};
