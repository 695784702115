export const CODE_RESPONSE_API = {
  EXISTED_EMAIL: {
    MESSAGE: 'This email address or staffNum already exists',
    CODE: 1,
  },
  NOT_EXISTED_USER: {
    MESSAGE: 'ERROR_USER',
    CODE: 2,
  },
  NOT_TERMINAL: {
    MESSAGE: 'Not have terminal',
    CODE: 3,
  },
  LOCKED_USER: {
    MESSAGE: 'Locked user',
    CODE: 4,
  },
  NOT_SPOT: {
    MESSAGE: 'Not have spot',
    CODE: 5,
  },
  NOT_CAMERA: {
    MESSAGE: 'Not have camera',
    CODE: 6,
  },
  NOT_AIRPLANE: {
    MESSAGE: 'Not have airplane',
    CODE: 7,
  },
  ERROR_CAMERA: {
    MESSAGE: 'Error camera',
    CODE: 8,
  },
  EXISTED_CAMERA: {
    MESSAGE: 'Exited camera',
    CODE: 9,
  },
  EXISTED_BUTTON: {
    MESSAGE: 'Exited button',
    CODE: 9,
  },
  NOT_BUTTON: {
    MESSAGE: 'Not button',
    CODE: 10,
  },
  NOT_LABEL: {
    MESSAGE: 'Not label',
    CODE: 11,
  },
  NOT_SETTING: {
    MESSAGE: 'Not setting',
    CODE: 12,
  },
  NOT_STREAM: {
    MESSAGE: 'Not stream',
    CODE: 13,
  },
  NOT_PERMISSION: {
    MESSAGE: 'Not have permission',
    CODE: 14,
  },
  NOT_STREAM_USER: {
    MESSAGE: 'Not stream user',
    CODE: 15,
  },
  NOT_CAMERA_KEY: {
    MESSAGE: 'Not camera key',
    CODE: 16,
  },
  NOT_SIZE: {
    MESSAGE: 'Incorrect size',
    CODE: 17,
  },
  ERROR_TOKEN: {
    MESSAGE: 'Incorrect token',
    CODE: 18,
  },
  ALREADY_STREAM: {
    MESSAGE: '作業中のユーザが存在するため、編集できません',
    CODE: 19,
  },
  INVALID_CAMERA: {
    MESSAGE: 'Invalid camera',
    CODE: 20,
  },
  EXPIRE_STREAM: {
    MESSAGE: 'Expire stream',
    CODE: 21,
  },
  ERROR_SETTING_PASSWORD: {
    MESSAGE: 'Error setting password',
    CODE: 22,
  },
  EXPIRE_SETTING_PASSWORD: {
    MESSAGE: 'Expire setting password',
    CODE: 23,
  },
  ERROR_PASSWORD: {
    MESSAGE: 'Error password',
    CODE: 24,
  },
  EXPIRE_CAMERA: {
    MESSAGE: 'Expire camera',
    CODE: 25,
  },
  INPUT_UPLOAD: {
    MESSAGE: 'Invalid input upload',
    CODE: 26,
  },
  EXCEED_ROW: {
    MESSAGE: 'Exceed row',
    CODE: 27,
  },
  INVALID_KEY: {
    MESSAGE: 'Invalid key',
    CODE: 28,
  },
  ERROR_DUPLICATE: {
    MESSAGE: 'Duplicate error',
    CODE: 29,
  },
  ERROR_FILE: {
    MESSAGE: 'File error',
    CODE: 30,
  },
  HANDLE_UPLOAD: {
    MESSAGE: 'Invalid handle upload',
    CODE: 31,
  },
  INVALID_STREAM: {
    MESSAGE: 'Invalid stream',
    CODE: 32,
  },
  EXISTED_STAFFNUM: {
    MESSAGE: 'This email staffNum already exists',
    CODE: 33,
  },
  EXISTED_SPOT: {
    MESSAGE: 'Existed spot',
    CODE: 34,
  },
  EXISTED_TERMINAL: {
    MESSAGE: 'Existed terminal',
    CODE: 35,
  },
  EXISTED_AIRPLANE: {
    MESSAGE: 'Existed airplane',
    CODE: 36,
  },
  NOT_EXITSED_CAMERA: {
    MESSAGE: 'Not existed camera',
    CODE: 41,
  },
};
