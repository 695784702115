import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { Modal } from 'antd';

import { UserManagement } from 'src/interfaces/pages/userManagement';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  getListUserAction,
  deleteUserActions,
} from 'src/stores/screens/privateScreens/userManagement/userManagement.action';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';
import useRole from 'src/shared/hooks/useRole';
import useDebouncedApiCall from 'src/shared/hooks/useDebounce';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_POPUP } from 'src/shared/constants/message';

const { confirm } = Modal;

const TIMEOUT_DEBOUNCE = 700;

function useInit(): UserManagement.UseInit {
  const dispatch = useAppDispatch();

  const [userSelected, setUserSelected] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [terminalSelects, setTerminalSelects] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const { users, totalPage, roleId, searchUser, terminalId, isLoading } = useAppSelector(state => state.userManagement);
  const debouncedSearchUser = useDebouncedApiCall(searchUser, TIMEOUT_DEBOUNCE);
  const { getTitleRole } = useRole();

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: '名前',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '社員番号',
      dataIndex: 'staffNum',
      key: 'staffNum',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '拠点',
      dataIndex: 'base',
      key: 'base',
      render: (_, record) => <>{record?.terminal?.name}</>,
    },
    {
      title: '権限',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => {
        return getTitleRole(record?.role);
      },
    },
    {
      title: 'アクション',
      dataIndex: 'Action',
      key: 'Action',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon component={PencilEditIcon} onClick={() => handleShowModal(true, record)} />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirm(record.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const showConfirm = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteUserActions({
            id,
            callback: () => {
              void setIsShowModal(false);
              void getListUser();
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.DELETE,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  const handleShowModal = (isShow: boolean, data: any): void => {
    setIsShowModal(isShow);
    setUserSelected(data);
  };

  const getListUser = async (): Promise<void> => {
    const data = {
      page: currentPage,
      search: searchUser,
      terminalId,
      role: roleId,
      size: 10,
    };
    await dispatch(getListUserAction({ data }));
  };

  const fetchDataTerminals = async (params: CommonStore.ParamsGetList): Promise<void> => {
    const res = await dispatch(getTerminalAction({ data: params }));

    if (res) {
      const terminals = res.payload?.rows;
      const dataSelectTerminals = terminals?.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setTerminalSelects(dataSelectTerminals);
    }
  };

  useEffect(() => {
    void fetchDataTerminals({});
  }, []);

  useEffect(() => {
    if (terminalId) {
      void getListUser();
    }
  }, [debouncedSearchUser, currentPage, terminalId, roleId]);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  return {
    columns,
    dataSource: users,
    totalPage,
    isLoading,
    isShowModal,
    userSelected,
    terminalSelects,
    currentPage,
    getListUser,
    setIsShowModal,
    handleShowModal,
    setCurrentPage,
  };
}

export default useInit;
