import { createSlice } from '@reduxjs/toolkit';
import { getLabelsAction, createLabelAction, updateLabelAction, deleteLabelActions } from './labels.action';

const initialState: LabelsStore.LabelsReducer = {
  isLoadingLabels: false,
  isUpdated: false,
  labels: [],
  pageLabels: 1,
  totalPageLabels: 0,
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'Labels_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getLabelsAction.pending, (state, action) => {
        state.isLoadingLabels = true;
      })
      .addCase(getLabelsAction.fulfilled, (state, action) => {
        state.isLoadingLabels = false;
        state.isUpdated = false;
        state.pageLabels = action?.payload?.response?.page;
        state.totalPageLabels = action?.payload?.response?.total;
        if (action?.payload?.isSubmit) {
          state.labels = [...state.labels, ...action?.payload?.response?.rows];
        } else {
          state.labels = action?.payload?.response?.rows;
        }
      })
      .addCase(getLabelsAction.rejected, (state, action) => {
        state.isLoadingLabels = false;
        state.isUpdated = false;
        state.error = action.error;
      })
      .addCase(createLabelAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(updateLabelAction.fulfilled, (state, action) => {
        state.isUpdated = true;
      })
      .addCase(deleteLabelActions.fulfilled, (state, action) => {
        state.isUpdated = true;
      });
  },
});

export { reducer };
export default actions;
