import { createSlice } from '@reduxjs/toolkit';
import {
  getKeySafieAction,
  getSettingSafieAction,
  getSettingSoracomAction,
  settingKeySafieAction,
  setThemeTypeAction,
} from './settings.action';
import { THEME_TYPE } from 'src/shared/constants';

const initialState: SettingStore.SettingsReducer = {
  isLoading: false,
  isLoadingSoracom: false,
  isLoadingSafie: false,
  error: null,
  keySafie: '',
  dataSettingSoracomTerminal: null,
  dataSettingSafieTerminal: null,
  themeType: localStorage.getItem(THEME_TYPE.TYPE) ?? THEME_TYPE.DARK,
};

const { actions, reducer } = createSlice({
  name: 'settings_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getKeySafieAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getKeySafieAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.keySafie = action.payload.value;
      })
      .addCase(getKeySafieAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(settingKeySafieAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(settingKeySafieAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(settingKeySafieAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getSettingSoracomAction.pending, (state, action) => {
        state.isLoadingSoracom = true;
      })
      .addCase(getSettingSoracomAction.fulfilled, (state, action) => {
        state.isLoadingSoracom = false;
        state.dataSettingSoracomTerminal = action.payload;
      })
      .addCase(getSettingSoracomAction.rejected, (state, action) => {
        state.isLoadingSoracom = false;
        state.error = action.error;
      })

      .addCase(getSettingSafieAction.pending, (state, action) => {
        state.isLoadingSafie = true;
      })
      .addCase(getSettingSafieAction.fulfilled, (state, action) => {
        state.isLoadingSafie = false;
        state.dataSettingSafieTerminal = action.payload;
      })
      .addCase(getSettingSafieAction.rejected, (state, action) => {
        state.isLoadingSafie = false;
        state.error = action.error;
      })

      .addCase(setThemeTypeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setThemeTypeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.themeType = action.payload;
      })
      .addCase(setThemeTypeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
