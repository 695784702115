import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

import useForm from './hooks/useForm';
import UseInit from './hooks/useInit';
import FormSubmit from './FormSubmit';
import { ModeStandard, Spinning } from 'src/shared/components';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import useCheckStatusAlert from 'src/shared/hooks/useCheckStatusAlert';
import { WarningModalIcon } from 'src/assets/icons';
import { WrapperStyled } from './styled';
import socket from 'src/configs/socketIO/socketIO';
import { ALERT_STATUS, CODE_RESPONSE_API, TYPE_SOCKET } from 'src/shared/constants';
import { useAppSelector } from 'src/stores';

const { confirm } = Modal;

const SubmitStreaming: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    optionsSelectAirplane,
    optionsSelectLabels,
    optionsSelectSpots,
    streamDetail,
    isLoading,
    isLoadingDetail,
    alertStatus,
    alertStream,
    deleteStream,
    getStreamDetail,
    setAlertStatus,
  } = UseInit();

  useEffect(() => {
    setAlertStatus(streamDetail?.alertStatus);
  }, [streamDetail]);

  const { formik } = useForm();

  const statusStream = alertStatus ?? streamDetail?.alertStatus;

  const { checkStatus } = useCheckStatusAlert();

  const { meInfo } = useAppSelector(state => state.auth);

  const [isShowExpireStream, setIsShowExpireStream] = useState(false);
  const [isShowExpireCamera, setIsShowExpireCamera] = useState(false);

  const status = useMemo(() => {
    return checkStatus(statusStream);
  }, [statusStream]);

  const showConfirm = (): void => {
    confirm({
      title: MESSAGE_POPUP.DO_YOU_WANT_TO_FINISH_THIS_STREAM,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void deleteStream(streamDetail?.id);
        setAlertStatus(ALERT_STATUS.NORMAL);
      },
      onCancel() {},
      okText: MESSAGE_POPUP.FINISH,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  const showConfirmAlert = (): void => {
    confirm({
      title: MESSAGE_POPUP.MESSAGE_CONFIRM_ALERT,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void alertStream(streamDetail?.id, ALERT_STATUS.ALERT);
      },
      onCancel() {},
      okText: MESSAGE_POPUP.YES,
      cancelText: MESSAGE_POPUP.NO,
    });
  };

  const renderContent = useMemo(() => {
    if (streamDetail?.id) {
      return (
        <div className="col-detail">
          <ModeStandard
            id={streamDetail?.id}
            isShowAlert={true}
            typeAlert={status}
            hideIconZoom={true}
            onClickAlert={showConfirmAlert}
            title={streamDetail?.label?.name}
            terminalName={streamDetail?.terminal?.name}
            spotName={streamDetail?.spot?.name}
            airplaneName={streamDetail?.airplane?.name}
            flightNum={streamDetail?.flightNum}
            firstVideo={streamDetail?.label?.cameraList[0]}
            secondVideo={streamDetail?.label?.cameraList[1]}
            cameraList={[streamDetail]}
            onCloseZoom={showConfirm}
            isScreenSubmitStreaming={true}
          />
        </div>
      );
    } else {
      return (
        <FormSubmit
          formik={formik}
          optionsSelectAirplane={optionsSelectAirplane}
          optionsSelectLabels={optionsSelectLabels}
          optionsSelectSpots={optionsSelectSpots}
        />
      );
    }
  }, [formik]);

  const showConfirmExpireStream = (): void => {
    confirm({
      title: MESSAGE_POPUP.EXPIRE_STREAM,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        getStreamDetail();
        setIsShowExpireStream(false);
      },
      okText: 'OK',
      okCancel: false,
    });
  };

  const showConfirmExpireCamera = (): void => {
    confirm({
      title: MESSAGE_POPUP.EXPIRE_CAMERA,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        getStreamDetail();
        setIsShowExpireCamera(false);
      },
      okText: 'OK',
      okCancel: false,
    });
  };

  const handleExpireStream = (code: number): void => {
    if (code === CODE_RESPONSE_API.EXPIRE_STREAM.CODE) {
      setIsShowExpireStream(true);
    }
    if (code === CODE_RESPONSE_API.EXPIRE_CAMERA.CODE) {
      setIsShowExpireCamera(true);
    }
  };

  useEffect(() => {
    socket().on(TYPE_SOCKET.DELETE_STREAM, value => {
      if (value?.userId === meInfo?.id) {
        handleExpireStream(value?.code);
      }
    });
  }, []);

  useEffect(() => {
    if (isShowExpireStream) {
      showConfirmExpireStream();
    }
    if (isShowExpireCamera) {
      showConfirmExpireCamera();
    }
  }, [isShowExpireStream, isShowExpireCamera]);

  useEffect(() => {
    setTimeout(() => {
      if (!streamDetail?.id && searchParams.has('type')) {
        searchParams.delete('type');
        setSearchParams(searchParams);
      }
    }, 4000);
  }, [streamDetail]);

  return (
    <WrapperStyled className="submit-stream-screen">
      {isLoading || isLoadingDetail ? <Spinning isLoading={isLoading || isLoadingDetail} /> : renderContent}
    </WrapperStyled>
  );
};

export default SubmitStreaming;
