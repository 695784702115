import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ROUTERS } from 'src/shared/constants/routers';
import { STORAGE_KEY } from 'src/shared/constants/storageKey';
import useRole from 'src/shared//hooks/useRole';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { NotFoundStyle } from './styled';

const { Title, Text } = Typography;

const NotFoundScreen: React.FC = () => {
  const navigate = useNavigate();

  const token = Cookies.get(STORAGE_KEY.ACCESS_TOKEN);
  const { isGeneralUser } = useRole();

  const { isMobile } = useWindowSize();

  const handleRedirectPage = (): void => {
    if (token) {
      if (isMobile) {
        navigate(ROUTERS.SUBMIT_STREAMING.PATH);
        return;
      }
      if (isGeneralUser) {
        navigate(ROUTERS.SUBMIT_STREAMING.PATH);
        return;
      }
      navigate(ROUTERS.DASHBOARD.PATH);
      return;
    }
    navigate(ROUTERS.LOGIN.PATH);
  };

  return (
    <NotFoundStyle>
      <Row className="row-wrap">
        <Col span={24}>
          <Title className="screen-title font-helvetica">404</Title>
          <Text className="en-error font-helvetica">Sorry, we were unable to find that page</Text>
          <Text className="ja-error ">お探しのページがありません</Text>
          <Text className="text-link font-helvetica">
            Return to <span onClick={handleRedirectPage}>Top Page</span>
          </Text>
        </Col>
      </Row>
    </NotFoundStyle>
  );
};

export default NotFoundScreen;
