import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField, SelectField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import useInitModalForm from './hooks/useInitModalForm';
import { TYPE_CAMERA, VALUE_EMPTY } from 'src/shared/constants/config';
import { useAppSelector } from 'src/stores';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { ModalContentWrap } from '../label/styled';

interface Props {
  terminalSelects: any;
  labelSelected: any;
  isOpen: boolean;
  terminalId: number;
  setIsShowModal: (param: boolean, notHaveLabel?: boolean) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({ terminalSelects, labelSelected, isOpen, terminalId, setIsShowModal }) => {
  const [isValidateCamera, setIsValidateCamera] = useState(true);
  const [newTerminalId, setNewTerminlId] = useState(terminalId);

  const { meInfo } = useAppSelector(state => state.auth);

  const { cameraCarSelects, cameraHumanSelects, buttonSelects, handleSetTerminalId } = useInitModalForm(terminalId);
  const { formik } = useForm({ labelSelected, isValidateCamera, setIsShowModal });

  const { errors, touched, values, setFieldValue, resetForm, getFieldProps, handleSubmit } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
  };

  const handleResetForm = (): void => {
    setFieldValue('cameraCar', '');
    setFieldValue('cameraHuman', '');
    setFieldValue('buttonId', null);
  };

  const handleSetField = (labelSelected: any): void => {
    setFieldValue('name', labelSelected?.name);
    setFieldValue('buttonId', labelSelected?.button?.id);

    labelSelected?.cameraList.forEach(element => {
      if (element?.type === TYPE_CAMERA.CAR.VALUE) {
        setFieldValue('cameraCar', element?.id);
      }
      if (element?.type === TYPE_CAMERA.HUMAN.VALUE) {
        setFieldValue('cameraHuman', element?.id);
      }
    });
  };

  useEffect(() => {
    if (labelSelected?.id && isOpen) {
      handleSetField(labelSelected);
      return;
    }
    resetForm();
  }, [labelSelected, isOpen]);

  useEffect(() => {
    setFieldValue('terminalId', terminalId ?? meInfo?.terminalId);
  }, [isOpen]);

  useEffect(() => {
    if (!values.cameraCar && !values.cameraHuman) {
      setIsValidateCamera(true);
    } else {
      setIsValidateCamera(false);
    }
  }, [values]);

  useEffect(() => {
    if (isOpen) {
      void handleSetTerminalId({ terminalId, haveLabel: false });
    } else {
      resetForm();
    }
  }, [isOpen]);

  const findCameraHumanDefault = labelSelected?.cameraList?.filter(item => item?.type === TYPE_CAMERA.HUMAN.VALUE);
  const findCameraCarDefault = labelSelected?.cameraList?.filter(item => item?.type === TYPE_CAMERA.CAR.VALUE);

  const cameraHumanDefault =
    findCameraHumanDefault?.length > 0
      ? {
          label: findCameraHumanDefault[0]?.serialNum,
          value: findCameraHumanDefault[0]?.id,
        }
      : null;

  const cameraCarDefault =
    findCameraCarDefault?.length > 0
      ? {
          label: findCameraCarDefault[0]?.serialNum,
          value: findCameraCarDefault[0]?.id,
        }
      : null;

  useEffect(() => {
    if (newTerminalId === terminalId) {
      if (labelSelected?.button) {
        setFieldValue('buttonId', labelSelected?.button?.id);
      }

      if (cameraCarDefault?.value) {
        setFieldValue('cameraCar', cameraCarDefault?.value);
      }

      if (cameraHumanDefault?.value) {
        setFieldValue('cameraHuman', cameraHumanDefault?.value);
      }
    }
  }, [newTerminalId]);

  return (
    <Modal
      isOpen={isOpen}
      title={labelSelected ? 'デバイスグループ情報を更新する' : 'デバイスグループ情報を登録する'}
      width={494}
      className="modal-custom-antd modal-set-divice"
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField
            content={labelSelected ? MESSAGE_POPUP.EDIT : MESSAGE_POPUP.CREATE}
            type="primary"
            onClick={handleSubmit}
          />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  拠点：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('terminalId')}
                  optionsSelect={terminalSelects}
                  setFieldValue={setFieldValue}
                  error={errors.terminalId}
                  touched={touched.terminalId}
                  onChange={terminalId => {
                    void (async () => {
                      await handleSetTerminalId({
                        terminalId,
                      });
                      setNewTerminlId(terminalId);
                      handleResetForm();
                    })();
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  グループ名：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('name')}
                    error={errors.name}
                    touched={touched.name}
                    placeholder="入力してください"
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  カメラ：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="camera-group">
                <div className="camera-field">
                  <SelectField
                    field={getFieldProps('cameraCar')}
                    optionsSelect={
                      cameraCarDefault && newTerminalId === terminalId
                        ? [VALUE_EMPTY, cameraCarDefault, ...cameraCarSelects]
                        : [VALUE_EMPTY, ...cameraCarSelects]
                    }
                    setFieldValue={setFieldValue}
                    error={isValidateCamera ? errors.cameraCar : ''}
                    touched={touched.cameraCar}
                  />
                  <Text className="text-note">車載の端末番号</Text>
                </div>

                <div className="camera-field">
                  <SelectField
                    field={getFieldProps('cameraHuman')}
                    optionsSelect={
                      cameraHumanDefault && newTerminalId === terminalId
                        ? [VALUE_EMPTY, cameraHumanDefault, ...cameraHumanSelects]
                        : [VALUE_EMPTY, ...cameraHumanSelects]
                    }
                    setFieldValue={setFieldValue}
                    error={isValidateCamera ? errors.cameraHuman : ''}
                    touched={touched.cameraHuman}
                  />
                  <Text className="text-note">作業員の端末番号</Text>
                </div>
              </Col>
            </Row>
            <Row className="mb-30">
              <Col span={24} className="field-title">
                <Text>タグボタン：</Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('buttonId')}
                  optionsSelect={
                    labelSelected?.button && newTerminalId === terminalId
                      ? [
                          { label: labelSelected?.button?.serialNum, value: labelSelected?.button?.id },
                          ...buttonSelects,
                        ]
                      : buttonSelects
                  }
                  setFieldValue={setFieldValue}
                  error={errors.buttonId}
                  touched={touched.buttonId}
                />
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
