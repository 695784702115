import { useFormik } from 'formik';

import { Spots } from 'src/interfaces/pages/spots';
import { SpotSchema } from './shcema';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { createSpotAction, updateSpotAction } from 'src/stores/screens/privateScreens/spots/spots.action';

const useForm = ({ spotSelected, setIsShowModal }): Spots.useForm => {
  const dispatch = useAppDispatch();
  const { meInfo } = useAppSelector(state => state.auth);

  const initialValues: Spots.InitialValues = {
    name: '',
    terminalId: meInfo?.terminalId ?? null,
  };

  const submitForm = async (data: SpotsStore.ParamsSpots): Promise<void> => {
    if (spotSelected?.id) {
      const value = { id: spotSelected.id, ...data };
      await dispatch(updateSpotAction({ data: value, callback: () => setIsShowModal(false) }));
      return;
    }
    await dispatch(createSpotAction({ data, callback: () => setIsShowModal(false) }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: SpotSchema,
    initialValues,
    onSubmit: value => {
      void submitForm(value);
    },
  });

  return {
    formik,
  };
};

export default useForm;
