import React from 'react';
import { Col, Row, Typography } from 'antd';

import { ButtonField, InputField, SelectField } from 'src/shared/components/form';
import useSoracom from './hooks/useSoracom';
import { OPTION_SELECT_TYPE_SORACOM } from 'src/shared/constants';

const { Title, Text } = Typography;

const SoracomSetting: React.FC = () => {
  const { formik, optionsSelectTerminal, setTerminalId } = useSoracom();

  const { errors, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  return (
    <React.Fragment>
      <Row className="row-title">
        <Col span={18}>
          <Title level={4} className="title">
            SORACOMボタン設定
          </Title>
        </Col>
        <Col span={6}>
          <SelectField
            field={getFieldProps('terminalId')}
            optionsSelect={optionsSelectTerminal}
            setFieldValue={setFieldValue}
            error={errors.terminalId}
            touched={touched.terminalId}
            selectProps={{
              placeholder: '羽田国際線',
            }}
            onChange={e => {
              setTerminalId(e);
            }}
          />
        </Col>
      </Row>

      <Row className="row-field-group">
        <Col span={24} className="col-text-first">
          <Text className="text-field-group color-title-table-light-theme">SORACOMボタン設定</Text>
        </Col>

        <Col span={24}>
          <Text className="text-field-group color-title-table-light-theme">アラート通知の動作</Text>
        </Col>
      </Row>

      <Row className="row-field">
        <Col span={10}>
          <Text className="text-field">シングルクリック</Text>
        </Col>
        <Col span={14}>
          <SelectField
            setFieldValue={setFieldValue}
            optionsSelect={OPTION_SELECT_TYPE_SORACOM}
            field={getFieldProps('oneClickAction')}
            className="one-click-field"
            selectProps={{
              placeholder: 'アラート通知',
            }}
          />
        </Col>

        <Col span={10}>
          <Text className="text-field">ダブルクリック</Text>
        </Col>
        <Col span={14}>
          <div>
            <SelectField
              setFieldValue={setFieldValue}
              optionsSelect={OPTION_SELECT_TYPE_SORACOM}
              field={getFieldProps('doubleClickAction')}
            />
          </div>
        </Col>

        <Col span={10}>
          <Text className="text-field">長押し（2s~5s）</Text>
        </Col>
        <Col span={14}>
          <div>
            <SelectField
              setFieldValue={setFieldValue}
              optionsSelect={OPTION_SELECT_TYPE_SORACOM}
              field={getFieldProps('longClickAction')}
            />
          </div>
        </Col>
      </Row>

      <Row className="row-field-group">
        <Text className="text-field-group color-title-table-light-theme">SORACOMに連携するAPIキー</Text>
      </Row>

      <Row>
        <Col span={24}>
          <Text className="text-auth-key">authkey</Text>
        </Col>
        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('authKey')}
            className="input-key"
            error={errors.authKey}
            touched={touched.authKey}
          />
        </Col>

        <Col span={24}>
          <Text className="text-auth-key">authkeyId</Text>
        </Col>
        <Col span={24}>
          <InputField
            setFieldValue={setFieldValue}
            field={getFieldProps('authKeyId')}
            className="input-key"
            error={errors.authKeyId}
            touched={touched.authKeyId}
          />
        </Col>

        <Col span={24} className="col-btn-first">
          <ButtonField content="保存 " type="primary" onClick={handleSubmit} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SoracomSetting;
