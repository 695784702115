import { Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';

import socket from 'src/configs/socketIO/socketIO';
import { Dashboard } from 'src/interfaces/pages/dashboard';
import { ALERT_STATUS, LOCAL_STORAGE_KEY, ROUTERS, TYPE_ALERT, TYPE_SOCKET } from 'src/shared/constants';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  updateStatusIsPinAction,
  updateStatusVideoZoomAction,
} from 'src/stores/screens/privateScreens/common/common.action';
import { getDataDashboardSecondAction } from 'src/stores/screens/privateScreens/dashboardSecond/dashboardSecond.action';
import { alertStreamActions } from 'src/stores/screens/privateScreens/submitStream/submitStream.action';
import { WarningModalIcon } from 'src/assets/icons';
import { useLocation } from 'react-router-dom';

const { confirm } = Modal;

const useInit = (): Dashboard.DashboardSecondV2 => {
  const terminalIdStorage = Number(localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID));

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { dataDashboardSecond, page, totalItem, isLoading, totalPin, totalPageSwitch } = useAppSelector(
    state => state.dashboardSecond,
  );
  const { meInfo } = useAppSelector(state => state.auth);
  const { dataSetting } = useAppSelector(state => state.dashboard);

  const [terminalId, setTerminalId] = useState<number>(terminalIdStorage);
  const [newTypeAlert, setNewTypeAlert] = useState<any | null>(null);

  const fetchDataDashboardSecond = async (
    params: DashboardSecondStore.ParamsVideo,
    callback?: () => void,
  ): Promise<void> => {
    await dispatch(getDataDashboardSecondAction({ data: params, callback }));
  };

  const emitSocket = (): void => {
    socket().emit(TYPE_SOCKET.MONITOR_RELOAD, { quantityMonitorDisplay: 2 });
  };

  const [newAlert, setNewAlert] = useState<DashboardStore.DataVideo[]>(dataDashboardSecond);

  const getNewAlert = (newObj): void => {
    setNewAlert(prevData => {
      const newData = [...prevData];

      const existingObjIndex = newData.findIndex(obj => obj.id === newObj.id);

      if (existingObjIndex !== -1) {
        newData[existingObjIndex] = {
          ...newObj,
          isPin: newObj.streamUserList.some(streamUser => streamUser.userId === newObj.terminal.id && streamUser.isPin),
          isZoom: newObj.streamUserList.some(
            streamUser => streamUser.userId === newObj.terminal.id && streamUser.isZoom,
          ),
        };
      }

      return newData;
    });
  };

  const mergeList = useMemo(() => {
    return newAlert?.map(itemA => {
      return itemA;
    });
  }, [newAlert]);

  useEffect(() => {
    if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
      void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId });
      emitSocket();
    }

    socket().on(TYPE_SOCKET.ZOOM_STREAM, value => {
      if (value?.userId === meInfo?.id) {
        if (!value?.isZoomFull) {
          if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
            void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId }, () => {
              socket().disconnect();
            });
          }
        }
      }
    });

    socket().on(TYPE_SOCKET.ALERT_STREAM, value => {
      if (value?.isZoomFull) {
        setNewTypeAlert({ alertStatus: value?.alertStatus, idStream: value?.id });
      } else {
        setNewTypeAlert({ alertStatus: value?.alertStatus, idStream: value?.id });
        if (value?.alertStatus === ALERT_STATUS.ALERT) {
          if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
            void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId }, () => {
              socket().disconnect();
            });
          }
        } else getNewAlert(value);
      }
    });

    socket().on(TYPE_SOCKET.DELETE_STREAM, _ => {
      if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
        void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId }, () => {
          socket().disconnect();
        });
      }
    });

    socket().on(TYPE_SOCKET.PIN_STREAM, value => {
      if (value?.userId === meInfo?.id) {
        if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
          void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId }, () => {
            socket().disconnect();
          });
        }
      }
    });

    socket().on(TYPE_SOCKET.CREATE_STREAM, value => {
      if (value.cardTotal <= 2) {
        if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
          void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId }, () => {
            socket().disconnect();
          });
        }
      }
    });

    socket().on(TYPE_SOCKET.DASHBOARD_SEARCH, value => {
      if (value?.id === meInfo?.id) {
        getNewTerminalID();
      }
    });

    const handleBeforeUnload = (): void => {
      window.opener.postMessage('window2Closed', '*');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useEffect(() => {
    if (pathname === ROUTERS.DASHBOARD_SECOND.PATH) {
      void fetchDataDashboardSecond({ terminalId: terminalId ?? meInfo?.terminalId });
    }
  }, [terminalId]);

  useEffect(() => {
    const quantityItemSwitch = dataDashboardSecond.filter(item => !item.isPin);

    localStorage.setItem(
      LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH,
      JSON.stringify({ page, size: quantityItemSwitch?.length }),
    );

    localStorage.setItem(LOCAL_STORAGE_KEY.QUANTITY_DISPLAY_SECOND, `${dataDashboardSecond.length}`);

    setNewAlert(dataDashboardSecond);
  }, [dataDashboardSecond]);

  const onCloseZoom = (item: DashboardSecondStore.DataVideo): void => {
    if (item?.isZoom) {
      void dispatch(updateStatusVideoZoomAction({ id: item?.id, isZoom: false }));

      if (item?.isPin) {
        void dispatch(
          updateStatusIsPinAction({
            id: item?.id,
            isPin: false,
          }),
        );
      }
    } else {
      if (item?.isPin) {
        void dispatch(
          updateStatusIsPinAction({
            id: item?.id,
            isPin: false,
          }),
        );
      }
    }
  };

  const onClickPin = (item: DashboardSecondStore.DataVideo): void => {
    void dispatch(
      updateStatusIsPinAction({
        id: item?.id,
        isPin: !item?.isPin,
        callback: () => {
          if (item?.isPin) {
            if (!item?.isZoom) {
              void dispatch(updateStatusVideoZoomAction({ id: item?.id, isZoom: true }));
            }
          }

          void dispatch(
            getDataDashboardSecondAction({
              data: { terminalId: terminalId ?? meInfo?.terminalId },
            }),
          );
        },
      }),
    );
  };

  const updateStatusVideoZoom = (id: number, isZoom: boolean, isZoomFull?: boolean): void => {
    void dispatch(updateStatusVideoZoomAction({ id, isZoom, isZoomFull }));
  };

  const onClickAlert = (item: DashboardSecondStore.DataVideo, typeAlert?: string, isZoomFull?: boolean): void => {
    showConfirm(item, typeAlert, isZoomFull);
  };

  const alertStream = async (id: number, typeAlert: string, isZoomFull?: boolean): Promise<void> => {
    await dispatch(
      alertStreamActions({
        id,
        typeAlert,
        isZoomFull,
        callback: () => {},
      }),
    );
  };

  const handleOkConfirm = (item: DashboardSecondStore.DataVideo, typeAlert?: string, isZoomFull?: boolean): void => {
    if (typeAlert === TYPE_ALERT.ALERT_PRIMARY) {
      void alertStream(item?.id, ALERT_STATUS.ALERT_MUTED, isZoomFull);
    } else {
      void alertStream(item?.id, ALERT_STATUS.NORMAL, isZoomFull);
      if (!item?.isZoom) {
        void updateStatusVideoZoom(item?.id, true, isZoomFull);
      }
    }
  };

  const showConfirm = (item: DashboardSecondStore.DataVideo, typeAlert?: string, isZoomFull?: boolean): void => {
    if (typeAlert === TYPE_ALERT.ALERT_PRIMARY) {
      handleOkConfirm(item, typeAlert, isZoomFull);
    } else {
      confirm({
        title: MESSAGE_POPUP.DO_YOU_WANT_TO_CLOSE_ALERT_OF_USER_THESE_STREAM,
        icon: <Icon component={WarningModalIcon} />,
        onOk() {
          handleOkConfirm(item, typeAlert, isZoomFull);
        },
        onCancel() {},
        okText:
          typeAlert === TYPE_ALERT.ALERT_PRIMARY ? MESSAGE_POPUP.CLOSE_ALERT_SOUND : MESSAGE_POPUP.CLOSE_ALERT_NOTI,
        cancelText: MESSAGE_POPUP.CANCEL,
      });
    }
  };

  const getNewTerminalID = (): void => {
    const terminalIdStorage = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

    setTerminalId(Number(terminalIdStorage));
  };

  return {
    dataDashboardSecond,
    page,
    totalItem,
    isLoading,
    dataSetting,
    terminalId,
    meInfo,
    totalPin,
    newTypeAlert,
    totalPageSwitch,
    newAlert: mergeList,
    onCloseZoom,
    onClickPin,
    onClickAlert,
    fetchDataDashboardSecond,
    setNewAlert,
  };
};

export default useInit;
