import Cookies from 'js-cookie';

import { STORAGE_KEY, USER_ROLE } from '../constants';

interface IUseRole {
  isAdmin: boolean;
  isAdvanceAdmin: boolean;
  isGeneralUser: boolean;
  isGeneralMonitor: boolean;
  getTitleRole: (role: string) => string;
}

const useRole = (): IUseRole => {
  const role = Cookies.get(STORAGE_KEY.ROLE);

  const isAdmin = role === USER_ROLE.ADMIN;
  const isAdvanceAdmin = role === USER_ROLE.ADVANCE_MONITOR;
  const isGeneralUser = role === USER_ROLE.GENERAL_USER;
  const isGeneralMonitor = role === USER_ROLE.GENERAL_MONITOR;

  const getTitleRole = (role: string): string => {
    let roleTitle = '';

    switch (role) {
      case USER_ROLE.ADMIN:
        roleTitle = '管理者';
        break;

      case USER_ROLE.ADVANCE_MONITOR:
        roleTitle = 'モニタ（MCT)';
        break;

      case USER_ROLE.GENERAL_MONITOR:
        roleTitle = 'モニタ（KIOSK用)';
        break;

      case USER_ROLE.GENERAL_USER:
        roleTitle = '作業員';
        break;

      default:
        break;
    }

    return roleTitle;
  };

  return { isAdmin, isAdvanceAdmin, isGeneralUser, isGeneralMonitor, getTitleRole };
};

export default useRole;
