import React from 'react';

const CloseIcon: React.FC = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_302_8616)">
        <path
          opacity="0.45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4096 25.2331C30.9454 28.7479 28.2479 31.4454 24.7331 31.9096C22.3167 32.2287 19.294 32.5 16 32.5C12.706 32.5 9.68333 32.2287 7.26694 31.9096C3.75207 31.4454 1.0546 28.7479 0.590388 25.2331C0.271256 22.8167 1.1108e-06 19.794 1.39876e-06 16.5C1.68673e-06 13.206 0.271257 10.1833 0.59039 7.76694C1.0546 4.25207 3.75207 1.5546 7.26695 1.09039C9.68334 0.771254 12.706 0.499998 16 0.499999C19.294 0.499999 22.3167 0.771255 24.7331 1.09039C28.2479 1.5546 30.9454 4.25207 31.4096 7.76694C31.7287 10.1833 32 13.206 32 16.5C32 19.794 31.7287 22.8167 31.4096 25.2331Z"
          fill="#2C2F36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3661 11.8661C11.8543 11.378 12.6457 11.378 13.1339 11.8661L16 14.7322L18.8661 11.8661C19.3543 11.378 20.1457 11.378 20.6339 11.8661C21.122 12.3543 21.122 13.1457 20.6339 13.6339L17.7678 16.5L20.6339 19.3661C21.122 19.8543 21.122 20.6457 20.6339 21.1339C20.1457 21.622 19.3543 21.622 18.8661 21.1339L16 18.2678L13.1339 21.1339C12.6457 21.622 11.8543 21.622 11.3661 21.1339C10.878 20.6457 10.878 19.8543 11.3661 19.3661L14.2322 16.5L11.3661 13.6339C10.878 13.1457 10.878 12.3543 11.3661 11.8661Z"
          fill="#F64E60"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_8616">
          <rect width="32" height="32" fill="white" transform="translate(32 32.5) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
