import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import { InputField, ButtonField, PasswordField } from 'src/shared/components/form';
import UseFormRegister from './hooks/useForm';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import useCheckOrientation from 'src/shared/hooks/useCheckOrientation';
import useRole from 'src/shared/hooks/useRole';
import { verifyAuthAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import { THEME_TYPE } from 'src/shared/constants';
import { MESSAGE_NOTIFICATION } from 'src/shared/constants/message';
import { ROUTERS } from 'src/shared/constants/routers';
import { STORAGE_KEY } from 'src/shared/constants/storageKey';
import { useAppDispatch } from 'src/stores';
import useInit from '../hooks/useInit';
import { LogoIcon, LogoTextBlackIcon, LogoTextIcon } from 'src/assets/icons';
import { RegisterScreenStyle } from './styled';

const { Title, Text } = Typography;

const RegisterScreen: React.FC = () => {
  const { formik } = UseFormRegister();
  const { isMobile, isMobileByWidth, isIpad } = useWindowSize();
  const { isLandscape } = useCheckOrientation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getTitleRole } = useRole();
  const { loginSuccess } = useInit();
  const { pathname } = useLocation();

  const { setFieldValue, handleSubmit, getFieldProps, errors, touched } = formik;

  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);
  const tokenVerify = searchParams.get('token');
  const type = searchParams.get('type');
  const diffDays = searchParams.get('diffDays');
  const token = Cookies.get(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
  const isRemindChangePassWord = pathname === ROUTERS.REMIND_CHANGE_PASSWORD.PATH;

  const getInforAuth = (): any => {
    const username = searchParams.get('username');
    const staffNum = searchParams.get('staffNum');
    const email = searchParams.get('email');
    const role = searchParams.get('role');

    return {
      username,
      staffNum,
      email,
      role,
    };
  };

  const setFieldValueForm = (inforAuth: any): void => {
    setFieldValue('username', inforAuth.username);
    setFieldValue('staffNum', inforAuth.staffNum);
    setFieldValue('email', inforAuth.email);
    setFieldValue('role', inforAuth.role);
    setFieldValue('roleName', getTitleRole(inforAuth.role ?? ''));
  };

  const verifyAuth = async (): Promise<void> => {
    const res = await dispatch(
      verifyAuthAction({
        data: {
          token: tokenVerify ?? '',
        },
        callback: () => {
          navigate(ROUTERS.VERIFY_ERROR.PATH);
        },
      }),
    );

    if (res?.payload) {
      setFieldValueForm(res?.payload);
    }
  };

  const handleLoginByScreenRegister = (): void => {
    if (token) {
      const inforAuth = getInforAuth();
      loginSuccess({
        token,
        role: isRemindChangePassWord ? inforAuth.role : getFieldProps('roleName'),
      });
    }
  };

  useEffect(() => {
    if (isRemindChangePassWord) {
      const inforAuth = getInforAuth();
      setFieldValueForm(inforAuth);
      return;
    }
    void verifyAuth();
  }, []);

  return (
    <RegisterScreenStyle className="wrapper_screen-register wrapper_screen-auth">
      <Row className={`row-wrap ${isIpad && 'is-ipad'} ${isIpad && isLandscape && 'is-ipad-horizontal'}`}>
        {isMobile && (
          <Col span={24} className="header-mobile">
            <Icon component={LogoIcon} className="logo" />
            <Icon component={typeTheme === THEME_TYPE.LIGHT ? LogoTextBlackIcon : LogoTextIcon} />
          </Col>
        )}
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} className="col-left">
          <Row>
            <Col span={24}>
              <Title className="register-title">パスワード設定</Title>
            </Col>
            {type && (
              <Col span={24}>
                {type === '1' && (
                  <>
                    {!isMobileByWidth && (
                      <Text className="text-notification">
                        {`'有効期限ぎれる残りは${diffDays}日となり、そのうちにパスワードを再設定してください。'`}
                      </Text>
                    )}
                    {isMobileByWidth && (
                      <Text className="text-notification">
                        <span>{`有効期限ぎれる残りは${diffDays}日となり、`}</span>
                        <span>{MESSAGE_NOTIFICATION.PASSWORD_HAS_14_DAYS_REMAINING_MOBILE_BOTTOM}</span>
                      </Text>
                    )}
                  </>
                )}
                {type === '2' && (
                  <>
                    {!isMobileByWidth && (
                      <Text className="text-notification">
                        <span className="red">{MESSAGE_NOTIFICATION.PASSWORD_EXPIRED}</span>
                      </Text>
                    )}
                    {isMobileByWidth && (
                      <Text className="text-notification">
                        <span className="red">{MESSAGE_NOTIFICATION.PASSWORD_EXPIRED_TOP}</span>
                        <span className="red">{MESSAGE_NOTIFICATION.PASSWORD_EXPIRED_BOTTOM}</span>
                      </Text>
                    )}
                  </>
                )}
              </Col>
            )}
            <Col className="form-wrap">
              <div className="input-group">
                <Text className="input-label">名前：</Text>
                <InputField
                  field={getFieldProps('username')}
                  setFieldValue={setFieldValue}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="input-group">
                <Text className="input-label">社員番号：</Text>
                <InputField
                  field={getFieldProps('staffNum')}
                  setFieldValue={setFieldValue}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="input-group">
                <Text className="input-label">メールアドレス：</Text>
                <InputField
                  field={getFieldProps('email')}
                  setFieldValue={setFieldValue}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="input-group">
                <Text className="input-label">権限：</Text>
                <InputField
                  field={getFieldProps('roleName')}
                  setFieldValue={setFieldValue}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="input-group">
                <Text className="input-label required-field">
                  {pathname === ROUTERS.RESET_PASSWORD.PATH && '一時パスワード'}
                  {pathname !== ROUTERS.RESET_PASSWORD.PATH && (type ? '古いパスワード：' : '初期パスワード：')}
                </Text>
                <PasswordField
                  field={getFieldProps('oldPassword')}
                  className="form-control"
                  error={errors.oldPassword}
                  touched={touched.oldPassword}
                />
              </div>
              <div className="input-group">
                <Text className="input-label required-field">新規パスワード：</Text>
                <PasswordField
                  field={getFieldProps('newPassword')}
                  className="form-control"
                  error={errors.newPassword}
                  touched={touched.newPassword}
                />
              </div>
              <div className="input-group">
                <Text className="input-label required-field">確認用パスワード：</Text>
                <PasswordField
                  field={getFieldProps('confirmPassword')}
                  className="form-control"
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                />
              </div>
              <div className="btn-submit">
                <ButtonField onClick={handleSubmit} type="primary" content="設定 " color="#3D7EA3" />
              </div>
              {type === '1' && (
                <Text className="text-skip" onClick={handleLoginByScreenRegister}>
                  スクップ
                </Text>
              )}
            </Col>
          </Row>
          <Text className="text-footer  font-helvetica">HANDLING MONITOR CAMERA SYSTEM</Text>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={0} sm={0} xs={0} className="row-right" />
      </Row>
    </RegisterScreenStyle>
  );
};

export default RegisterScreen;
