import { useEffect } from 'react';
import { notification } from 'antd';
import { useFormik } from 'formik';

import { SubmitStreaming } from 'src/interfaces/pages/submitStreaming';
import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  getStreamDetailActions,
  submitStreamAction,
} from 'src/stores/screens/privateScreens/submitStream/submitStream.action';
import { submitStreamSchema } from './schema';
import { REPLACE_ZERO } from 'src/shared/constants';
import { MESSAGE_SUCCEESS } from 'src/shared/constants/message';

function useForm(): SubmitStreaming.UseForm {
  const { meInfo } = useAppSelector(state => state.auth);

  const { labels } = useAppSelector(state => state.common);

  const dispatch = useAppDispatch();

  const initialValues: SubmitStreaming.InitialValues = {
    terminalId: meInfo?.terminal?.name,
    labelId: null,
    cameraFirst: null,
    cameraSecond: null,
    tagButton: null,
    flightNum: null,
    spotId: null,
    airplaneId: null,
  };

  const getStreamDetail = (): void => {
    void dispatch(getStreamDetailActions());
  };

  const submitStreamSuccess = async (): Promise<void> => {
    await notification.success({ message: MESSAGE_SUCCEESS.SUBMIT_STREAM_SUCCESSFULLY });
    await getStreamDetail();
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: submitStreamSchema,
    initialValues,
    onSubmit: value => {
      const convertData = {
        terminalId: meInfo?.terminalId,
        labelId: value.labelId,
        flightNum: value.flightNum?.replace(REPLACE_ZERO, ''),
        spotId: value.spotId,
        airplaneId: value.airplaneId,
      };

      void dispatch(
        submitStreamAction({
          data: convertData,
          callback: () => {
            void submitStreamSuccess();
          },
        }),
      );
    },
  });

  useEffect(() => {
    const selectLabel = labels?.filter(item => item.id === formik.values.labelId);
    const cameraFirst = selectLabel ? selectLabel[0]?.cameraList[0]?.serialNum : null;
    const cameraSecond = selectLabel ? selectLabel[0]?.cameraList[1]?.serialNum : null;
    const tagButton = selectLabel ? selectLabel[0]?.button?.serialNum : null;

    void formik.setFieldValue('cameraFirst', cameraFirst);
    void formik.setFieldValue('cameraSecond', cameraSecond);
    void formik.setFieldValue('tagButton', tagButton);
  }, [formik.values.labelId]);

  return { formik };
}

export default useForm;
