import { useFormik } from 'formik';

import { UserManagement } from 'src/interfaces/pages/userManagement';
import { UserSchema } from './shcema';
import { useAppDispatch } from 'src/stores';
import {
  createUserAction,
  updateUserAction,
  getListUserAction,
} from 'src/stores/screens/privateScreens/userManagement/userManagement.action';

function useForm({
  userSelected,
  roleId,
  searchUser,
  terminalId,
  currentPage,
  setIsShowModal,
  setCurrentPage,
}): UserManagement.UseForm {
  const dispatch = useAppDispatch();

  const initialValues: UserManagement.InitialValues = {
    terminalId: '',
    username: '',
    staffNum: '',
    email: '',
    role: undefined,
  };

  const submitForm = async (data: UserManagementStore.ParamsUser): Promise<void> => {
    if (!userSelected?.id) {
      await dispatch(createUserAction({ data, callback: () => getListUser(1) }));
      setCurrentPage(1);
    } else {
      const dataUpdate = {
        id: userSelected.id,
        terminalId: data.terminalId,
        username: data.username,
        email: data.email,
        role: data.role,
      };
      await dispatch(updateUserAction({ data: dataUpdate, callback: () => getListUser(currentPage) }));
    }
  };

  const getListUser = (currentPage): void => {
    const data = {
      page: currentPage,
      search: searchUser,
      terminalId,
      role: roleId,
      size: 10,
    };
    void dispatch(getListUserAction({ data }));
    setIsShowModal(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: UserSchema,
    initialValues,
    onSubmit: value => {
      void submitForm(value);
    },
    validateOnChange: true,
  });

  return {
    formik,
  };
}

export default useForm;
