import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores';

import { getKeySafieAction } from 'src/stores/screens/privateScreens/settings/settings.action';
import { updateStatusCameraAction } from 'src/stores/screens/privateScreens/common/common.action';
import { CAMERA_STATUS, LOCAL_STORAGE_KEY } from 'src/shared/constants';
import { NoCamera } from 'src/assets/images';

declare global {
  interface Window {
    Safie?: any;
  }
}

interface IUseSafie {
  addPlayer: (dataVideo: any, isScreenSubmitStreaming?: boolean) => Promise<void>;
  addThumbnail: (dataVideo: any) => Promise<void>;
  addPlayerVideoZoom: (camera: any) => Promise<void>;
}

const useSafie = (): IUseSafie => {
  const dispatch = useAppDispatch();
  const { keySafie } = useAppSelector(state => state.settings);
  const { meInfo } = useAppSelector(state => state.auth);

  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);

  const setToken = async (): Promise<void> => {
    const token = keySafie;
    const tokenType = 'apiKey';

    try {
      await window.Safie.Auth.setToken(token, tokenType);
    } catch (error) {}
  };

  const addThumbnail = async (dataVideo: any): Promise<void> => {
    for (const video of dataVideo) {
      const imgElemFirst: any = window.document.getElementById(`video-${video?.id}-1`);
      const imgElemSecond: any = window.document.getElementById(`video-${video?.id}-2`);

      const iconCardStreamStatus: any = window.document.getElementById(`card-stream-status-${video?.id}`);
      let isPlayStreamFirst = false;
      let isPlayStreamSecond = false;

      try {
        const imgFirst = await window.Safie.Devices.queryThumbnail({
          deviceId: video.label.cameraList[0].streamingUrl,
        });
        imgElemFirst.src = URL.createObjectURL(imgFirst);
        isPlayStreamFirst = true;
      } catch (error) {
        isPlayStreamFirst = false;
        imgElemFirst.src = NoCamera;
      }

      try {
        const imgSecond = await window.Safie.Devices.queryThumbnail({
          deviceId: video.label.cameraList[1].streamingUrl,
        });
        imgElemSecond.src = URL.createObjectURL(imgSecond);
        isPlayStreamSecond = true;
      } catch (error) {
        isPlayStreamSecond = false;
        imgElemSecond.src = NoCamera;
      }

      if (iconCardStreamStatus) {
        if (isPlayStreamFirst || isPlayStreamSecond) {
          iconCardStreamStatus.style.backgroundColor = '#0BB783';
        } else {
          iconCardStreamStatus.style.backgroundColor = '#d9aa00';
        }
      }
    }
  };

  const addPlayer = async (dataVideo: any, isScreenSubmitStreaming?: boolean): Promise<void> => {
    dataVideo.forEach(video => {
      const playerElemFirst: any = window.document.getElementById(`video-${video?.id}-1`);
      const playerElemSecond: any = window.document.getElementById(`video-${video?.id}-2`);

      const cameraStatusElemFirst: any = window.document.getElementById(`video-status-${video?.id}-1`);
      const cameraStatusElemSecond: any = window.document.getElementById(`video-status-${video?.id}-2`);

      const noCameraElemFirst: any = window.document.getElementById(`no-video-${video?.id}-1`);
      const noCameraElemSecond: any = window.document.getElementById(`no-video-${video?.id}-2`);

      playerElemFirst.innerHTML = '';
      playerElemSecond.innerHTML = '';
      cameraStatusElemFirst.style.backgroundColor = '';
      cameraStatusElemSecond.style.backgroundColor = '';
      noCameraElemFirst.style.display = 'none';
      noCameraElemSecond.style.display = 'none';

      try {
        const playerFirst = new window.Safie.Player.StreamingPlayer(playerElemFirst);
        if (playerFirst) {
          let isLoadingCameraFirst = true;
          let currentFirst = 0;
          playerFirst.on(window.Safie.Player.PlayerEvent.STATUS_CHANGE, ({ status, context }) => {
            void updateStatusCamera(
              video.label.cameraList[0].id,
              status,
              context,
              cameraStatusElemFirst,
              noCameraElemFirst,
              isLoadingCameraFirst,
            );
            isLoadingCameraFirst = false;

            //
            if (isScreenSubmitStreaming) {
              const isLive = context?.streamingMode === window.Safie.Player.StreamingMode.Live;
              void handleUpdateStatusCamera(video.label.cameraList[0].id, isLive, currentFirst);

              if (isLive) {
                currentFirst = 0;
              } else {
                currentFirst++;
              }
            }
          });

          playerFirst.deviceId = video.label.cameraList[0].streamingUrl;
          playerFirst.muted = true;
          playerFirst.play();
        }
      } catch (error) {
        cameraStatusElemFirst.style.backgroundColor = '#595b69';
        noCameraElemFirst.style.display = 'block';
      }

      try {
        const playerSecond = new window.Safie.Player.StreamingPlayer(playerElemSecond);
        if (playerSecond) {
          let isLoadingCameraSecond = true;
          let currentSecond = 0;
          playerSecond.on(window.Safie.Player.PlayerEvent.STATUS_CHANGE, ({ status, context }) => {
            void updateStatusCamera(
              video.label.cameraList[1].id,
              status,
              context,
              cameraStatusElemSecond,
              noCameraElemSecond,
              isLoadingCameraSecond,
            );
            isLoadingCameraSecond = false;

            //
            if (isScreenSubmitStreaming) {
              const isLive = context?.streamingMode === window.Safie.Player.StreamingMode.Live;
              void handleUpdateStatusCamera(video.label.cameraList[1].id, isLive, currentSecond);
              if (isLive) {
                currentSecond = 0;
              } else {
                currentSecond++;
              }
            }
          });

          playerSecond.deviceId = video.label.cameraList[1].streamingUrl;
          playerSecond.muted = true;
          playerSecond.play();
        }
      } catch (error) {
        cameraStatusElemSecond.style.backgroundColor = '#595b69';
        noCameraElemSecond.style.display = 'block';
      }
    });
  };

  const addPlayerVideoZoom = async (camera: any): Promise<void> => {
    const playerElemZoom: any = window.document.getElementById(`video-zoom-${camera?.id}`);
    const cameraStatusElemZoom: any = window.document.getElementById(`video-zoom-status-${camera?.id}`);
    const noCameraElemZoom: any = window.document.getElementById(`no-video-zoom-${camera?.id}`);

    playerElemZoom.innerHTML = '';
    cameraStatusElemZoom.style.backgroundColor = '';
    noCameraElemZoom.style.display = 'none';

    try {
      const playerZoom = new window.Safie.Player.StreamingPlayer(playerElemZoom);
      if (playerZoom) {
        let isLoadingCameraZoom = true;
        playerZoom.on(window.Safie.Player.PlayerEvent.STATUS_CHANGE, ({ status, context }) => {
          void updateStatusCamera(
            camera?.id,
            status,
            context,
            cameraStatusElemZoom,
            noCameraElemZoom,
            isLoadingCameraZoom,
          );
          isLoadingCameraZoom = false;
        });

        playerZoom.deviceId = camera?.streamingUrl;
        playerZoom.muted = true;
        playerZoom.play();

        if (!playerZoom.deviceId) {
          cameraStatusElemZoom.style.backgroundColor = '#595b69';
          noCameraElemZoom.style.display = 'block';
        }
      }
    } catch (error) {
      cameraStatusElemZoom.style.backgroundColor = '#595b69';
      noCameraElemZoom.style.display = 'block';
    }
  };

  const handleUpdateStatusCamera = async (id: number, isLive: boolean, current: number): Promise<void> => {
    if (isLive) {
      await dispatch(updateStatusCameraAction({ id, status: CAMERA_STATUS.STREAMING }));
    } else {
      current === 0 && (await dispatch(updateStatusCameraAction({ id, status: CAMERA_STATUS.ERROR })));
    }
  };

  const updateStatusCamera = async (
    id: number,
    statusSafie: string,
    context: any,
    elementStatusCamera: any,
    elementNoCamera: any,
    isFirstLoadingCamera,
  ): Promise<void> => {
    if (context?.streamingMode !== window.Safie.Player.StreamingMode.Live) {
      elementStatusCamera.style.backgroundColor = '#595b69';
      elementNoCamera.style.display = 'block';
    }
    switch (statusSafie) {
      case window.Safie.Player.PlayerStatus.Loading:
        elementStatusCamera.style.backgroundColor = '#595b69';
        elementNoCamera.style.display = isFirstLoadingCamera ? 'none' : 'block';
        break;
      case window.Safie.Player.PlayerStatus.Streaming:
        elementStatusCamera.style.backgroundColor = '#0bb783';
        elementNoCamera.style.display = 'none';
        break;
      case window.Safie.Player.PlayerStatus.Error:
        elementStatusCamera.style.backgroundColor = '#595b69';
        elementNoCamera.style.display = 'block';
        break;
      case window.Safie.Player.PlayerStatus.NotLoaded:
        elementStatusCamera.style.backgroundColor = '#595b69';
        elementNoCamera.style.display = 'block';
        break;
      default:
        break;
    }
  };

  const getManeuverSetting = async (): Promise<void> => {
    await dispatch(getKeySafieAction({ terminalId: Number(terminalId) ?? meInfo?.terminalId }));
  };

  useEffect(() => {
    if (keySafie) {
      void (async () => {
        await setToken();
      })();
    }
  }, [keySafie]);

  useEffect(() => {
    void getManeuverSetting();
  }, [terminalId]);

  return { addPlayer, addThumbnail, addPlayerVideoZoom };
};

export default useSafie;
