import React from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';

import { ButtonField } from 'src/shared/components/form';
import TableCard from 'src/shared/components/table/Table';
import ModalForm from './ModalForm';
import useInit from './hooks/useInit';
import { PlusIcon } from 'src/assets/icons';
import { WrapperStyled } from './styled';

const { Title } = Typography;

const Terminal: React.FC = () => {
  const {
    columns,
    dataSource,
    totalPageTerminals,
    isLoading,
    terminalSelected,
    currentPage,
    isShowModal,
    setIsShowModal,
    handleShowModal,
    fetchDataTerminals,
    setCurrentPage,
  } = useInit();

  return (
    <>
      <WrapperStyled className="terminal-screen">
        <Col span={20} className="col-form">
          <Row className="row-btn">
            <Title level={4} className="title color-title-table-light-theme">
              拠点
            </Title>

            <ButtonField
              content={<Icon component={PlusIcon} />}
              type="primary"
              className="btn-create"
              onClick={() => handleShowModal(true, null)}
            />
          </Row>

          <Row className="row-table">
            <TableCard
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              rowKey="id"
              totalPages={totalPageTerminals}
              currentPage={currentPage}
              onChangePage={page => {
                setCurrentPage(page);
                void fetchDataTerminals({ page });
              }}
            />
          </Row>
        </Col>
      </WrapperStyled>
      <ModalForm
        terminalSelected={terminalSelected}
        isOpen={isShowModal}
        setIsShowModal={setIsShowModal}
        fetchDataTerminals={() => {
          void fetchDataTerminals({ page: currentPage });
        }}
      />
    </>
  );
};

export default Terminal;
