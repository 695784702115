import React from 'react';

const SearchHeaderIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M21.5001 19.42L18.3301 16.42C17.7274 17.196 17.005 17.8711 16.1901 18.42L19.4501 21.52C19.5871 21.6801 19.7566 21.8093 19.9473 21.8991C20.138 21.9889 20.3456 22.0371 20.5563 22.0408C20.7671 22.0444 20.9762 22.0032 21.1699 21.9201C21.3635 21.8369 21.5373 21.7136 21.6798 21.5583C21.8223 21.403 21.9302 21.2192 21.9964 21.0191C22.0627 20.819 22.0857 20.6071 22.0639 20.3975C22.0422 20.1878 21.9763 19.9852 21.8705 19.8029C21.7646 19.6206 21.6214 19.4629 21.4501 19.34L21.5001 19.42Z"
        fill="#3699FF"
      />
      <path
        d="M11.14 2C9.35999 2 7.61993 2.52784 6.13989 3.51677C4.65984 4.50571 3.50629 5.91131 2.8251 7.55585C2.14391 9.20038 1.96568 11.01 2.31295 12.7558C2.66022 14.5016 3.51739 16.1053 4.77606 17.364C6.03473 18.6226 7.63838 19.4798 9.38421 19.8271C11.13 20.1743 12.9396 19.9961 14.5842 19.3149C16.2287 18.6337 17.6343 17.4802 18.6232 16.0001C19.6122 14.5201 20.14 12.78 20.14 11C20.14 8.61305 19.1918 6.32387 17.504 4.63604C15.8162 2.94821 13.527 2 11.14 2ZM11.14 17.36C9.88453 17.36 8.65722 16.9878 7.61319 16.2905C6.56915 15.5932 5.75526 14.6021 5.27435 13.4423C4.79344 12.2826 4.6671 11.0064 4.91131 9.77485C5.15552 8.54334 5.7593 7.41187 6.64637 6.52341C7.53343 5.63495 8.66396 5.02938 9.89508 4.78324C11.1262 4.53709 12.4027 4.66142 13.5631 5.1405C14.7236 5.61958 15.716 6.43192 16.415 7.47486C17.1139 8.51779 17.488 9.74452 17.49 11C17.4927 11.8351 17.3303 12.6625 17.0122 13.4347C16.6942 14.2068 16.2267 14.9086 15.6366 15.4995C15.0466 16.0905 14.3456 16.5591 13.5739 16.8784C12.8023 17.1976 11.9751 17.3613 11.14 17.36Z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default SearchHeaderIcon;
