import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import styled from 'styled-components';
import { Spin } from 'antd';
import Cookies from 'js-cookie';

import _publicRoutes from './_public';
import {
  _adminPrivateRoutes,
  _advanceAdminPrivateRoutes,
  _generalMonitorPrivateRoutes,
  _userPrivateRouters,
} from './_private';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { ROUTERS } from 'src/shared/constants/routers';
import { STORAGE_KEY } from 'src/shared/constants/storageKey';
import { THEME_TYPE, USER_ROLE } from 'src/shared/constants';
import { getMeAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import useVerifyRouter from 'src/shared/hooks/useVerifyRouter';
import useHandleLocalStorage from 'src/shared/hooks/useHandleLocalStorage';

const styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const RootRouter: React.FC = () => {
  const navigate = useNavigate();
  const token = Cookies.get(STORAGE_KEY.ACCESS_TOKEN);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { initialLocalStorage } = useHandleLocalStorage();
  const role = Cookies.get(STORAGE_KEY.ROLE);

  const { isLoading, error, meInfo } = useAppSelector(state => state.auth);
  const { themeType } = useAppSelector(state => state.settings);
  const [routes, setRoutes] = useState<RouteObject[]>([]);

  const {
    isCheckRouterPublic,
    isCheckRouterAdminPrivate,
    isCheckRouterAdvanceAdminPrivate,
    isCheckRouterGeneralMonitorPrivate,
    isCheckRouterUserPrivate,
  } = useVerifyRouter();

  const addRouterByRole = (): void => {
    const isRouterPublic = isCheckRouterPublic(pathname);
    const isRouterAdvanceAdminPrivate = isCheckRouterAdvanceAdminPrivate(pathname);
    const isRouterGeneralMonitorPrivate = isCheckRouterGeneralMonitorPrivate(pathname);
    const isRouterUserPrivate = isCheckRouterUserPrivate(pathname);
    switch (role) {
      case USER_ROLE.ADMIN:
        isRouterPublic && navigate(ROUTERS.DASHBOARD.PATH);
        setRoutes([..._adminPrivateRoutes]);
        break;
      case USER_ROLE.ADVANCE_MONITOR:
        !isRouterAdvanceAdminPrivate && navigate(ROUTERS.ERROR.PATH);
        isRouterPublic && navigate(ROUTERS.DASHBOARD.PATH);
        setRoutes([..._advanceAdminPrivateRoutes]);
        break;
      case USER_ROLE.GENERAL_MONITOR:
        !isRouterGeneralMonitorPrivate && navigate(ROUTERS.ERROR.PATH);
        isRouterPublic && navigate(ROUTERS.DASHBOARD.PATH);
        setRoutes([..._generalMonitorPrivateRoutes]);
        break;
      case USER_ROLE.GENERAL_USER:
        !isRouterUserPrivate && navigate(ROUTERS.ERROR.PATH);
        isRouterPublic && navigate(ROUTERS.SUBMIT_STREAMING.PATH);
        setRoutes([..._userPrivateRouters]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (pathname !== ROUTERS.DASHBOARD_SECOND.PATH) {
      const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);

      if (typeTheme === THEME_TYPE.LIGHT) {
        document.body.classList.add('light-theme');
        styleSheetLink.href = '/css/light_theme.css';
      } else if (document.body.classList.contains('light-theme')) {
        document.body.classList.remove('light-theme');
        styleSheetLink.href = '';
      }
    }
  }, [pathname, themeType]);

  useEffect(() => {
    if (token) {
      if (!meInfo) {
        void dispatch(getMeAction());
      } else {
        addRouterByRole();
        initialLocalStorage();
      }
    } else {
      const isRouterPrivate = isCheckRouterAdminPrivate(pathname);
      setRoutes([..._publicRoutes]);
      isRouterPrivate && navigate(ROUTERS.LOGIN.PATH);
    }
  }, [pathname, meInfo]);

  useEffect(() => {
    if (error) {
      Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
      navigate(ROUTERS.LOGIN.PATH);
    }
  }, [error]);

  return (
    <SpinStyle spinning={isLoading} wrapperClassName="root-spin" className="root-spin-component" tip="Loading...">
      {!isLoading && useRoutes(routes)}
    </SpinStyle>
  );
};

export default RootRouter;

const SpinStyle = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
