import React from 'react';

const DarkIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.34 10.93C10.8734 9.97252 10.6128 8.92782 10.5749 7.86336C10.537 6.79889 10.7227 5.73831 11.12 4.75004C11.2109 4.51508 11.231 4.25866 11.1778 4.01241C11.1247 3.76616 11.0006 3.54086 10.8208 3.36433C10.6411 3.1878 10.4136 3.06775 10.1665 3.01901C9.91931 2.97026 9.66329 2.99496 9.43 3.09004C8.32465 3.51223 7.30738 4.13615 6.43 4.93004C5.29737 5.98041 4.4455 7.29744 3.95196 8.76119C3.45843 10.2249 3.33891 11.7889 3.6043 13.3106C3.86968 14.8324 4.51154 16.2636 5.47141 17.4739C6.43129 18.6841 7.67868 19.6351 9.1 20.24C8.89028 19.4028 8.78283 18.5432 8.78 17.68C8.75627 15.1885 9.67004 12.7792 11.34 10.93Z"
        fill="#60646E"
      />
      <g opacity="0.3">
        <path
          d="M18.31 19.1601C19.0449 18.6148 19.6923 17.9606 20.23 17.2201C20.378 17.0201 20.4656 16.7818 20.4822 16.5335C20.4989 16.2852 20.4439 16.0374 20.3239 15.8194C20.2038 15.6015 20.0238 15.4225 19.805 15.3039C19.5863 15.1852 19.3382 15.1318 19.09 15.1501C17.5173 15.3624 15.9179 15.0678 14.5241 14.3088C13.1304 13.5499 12.015 12.3663 11.34 10.9301C9.67004 12.7792 8.75626 15.1886 8.77999 17.6801C8.78282 18.5432 8.89027 19.4028 9.09999 20.2401C10.5944 20.8866 12.2329 21.1272 13.8501 20.9375C15.4673 20.7479 17.0057 20.1347 18.31 19.1601Z"
          fill="#60646E"
        />
      </g>
    </svg>
  );
};

export default DarkIcon;
