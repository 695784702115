import React from 'react';

const PlusIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 7.5H10.5V3C10.5 2.80109 10.421 2.61032 10.2803 2.46967C10.1397 2.32902 9.94891 2.25 9.75 2.25H8.25C8.05109 2.25 7.86032 2.32902 7.71967 2.46967C7.57902 2.61032 7.5 2.80109 7.5 3V7.5H3C2.80109 7.5 2.61032 7.57902 2.46967 7.71967C2.32902 7.86032 2.25 8.05109 2.25 8.25V9.75C2.25 9.94891 2.32902 10.1397 2.46967 10.2803C2.61032 10.421 2.80109 10.5 3 10.5H7.5V15C7.5 15.1989 7.57902 15.3897 7.71967 15.5303C7.86032 15.671 8.05109 15.75 8.25 15.75H9.75C9.94891 15.75 10.1397 15.671 10.2803 15.5303C10.421 15.3897 10.5 15.1989 10.5 15V10.5H15C15.1989 10.5 15.3897 10.421 15.5303 10.2803C15.671 10.1397 15.75 9.94891 15.75 9.75V8.25C15.75 8.05109 15.671 7.86032 15.5303 7.71967C15.3897 7.57902 15.1989 7.5 15 7.5Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
