import React from 'react';

const SubmitStreamIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M16.8501 2H5.78005C4.77131 2.00793 3.80689 2.41558 3.09828 3.13356C2.38967 3.85154 1.99473 4.82124 2.00005 5.83V12.83C1.99473 13.8388 2.38967 14.8085 3.09828 15.5264C3.80689 16.2444 4.77131 16.6521 5.78005 16.66H10.2201V19.29H7.28005C7.08114 19.29 6.89038 19.369 6.74972 19.5097C6.60907 19.6503 6.53005 19.8411 6.53005 20.04C6.53005 20.2389 6.60907 20.4297 6.74972 20.5703C6.89038 20.711 7.08114 20.79 7.28005 20.79H15.3501C15.549 20.79 15.7397 20.711 15.8804 20.5703C16.021 20.4297 16.1001 20.2389 16.1001 20.04C16.1001 19.8411 16.021 19.6503 15.8804 19.5097C15.7397 19.369 15.549 19.29 15.3501 19.29H12.4101V16.63H16.8501C17.8571 16.6194 18.8189 16.2106 19.5254 15.4929C20.2318 14.7752 20.6254 13.807 20.6201 12.8V5.8C20.6175 4.79815 20.2204 3.83763 19.5148 3.12641C18.8092 2.41519 17.8519 2.01051 16.8501 2Z"
        fill="#3E3F48"
      />
      <path
        d="M20.0709 9.5H14.0709C13.5509 9.51561 13.0575 9.73319 12.6953 10.1066C12.3331 10.48 12.1306 10.9798 12.1309 11.5V20C12.1306 20.5202 12.3331 21.02 12.6953 21.3934C13.0575 21.7668 13.5509 21.9844 14.0709 22H20.0709C20.5891 21.9819 21.08 21.7632 21.44 21.39C21.8001 21.0169 22.0012 20.5185 22.0009 20V11.47C21.9935 10.9567 21.789 10.4659 21.4297 10.0991C21.0704 9.73243 20.5839 9.51791 20.0709 9.5ZM17.0709 20.33C16.8501 20.334 16.6332 20.272 16.4478 20.152C16.2624 20.0321 16.117 19.8595 16.0302 19.6565C15.9435 19.4535 15.9192 19.2292 15.9606 19.0123C16.0019 18.7954 16.107 18.5957 16.2624 18.4389C16.4179 18.282 16.6165 18.1751 16.833 18.1318C17.0495 18.0884 17.2741 18.1107 17.4779 18.1956C17.6817 18.2805 17.8555 18.4243 17.9772 18.6086C18.0989 18.7928 18.1628 19.0092 18.1609 19.23C18.1622 19.374 18.135 19.5168 18.0808 19.6502C18.0266 19.7836 17.9465 19.9049 17.8452 20.0072C17.7438 20.1095 17.6232 20.1907 17.4903 20.2461C17.3574 20.3015 17.2148 20.33 17.0709 20.33Z"
        fill="#3E3F48"
      />
    </svg>
  );
};

export default SubmitStreamIcon;
