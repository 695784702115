import React, { useEffect } from 'react';
import { Col } from 'antd';

import SoracomSetting from './SoracomSetting';
import SafieSetting from './SafieSetting';
import { useAppDispatch } from 'src/stores';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';
import { WrapperStyled } from './styled';

const SystemSettings: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getTerminalAction({ data: {} }));
  }, []);

  return (
    <WrapperStyled className="system-setting-screen">
      <React.Fragment>
        <Col className="col-form">
          <SoracomSetting />
        </Col>

        <Col className="col-form">
          <SafieSetting />
        </Col>
      </React.Fragment>
    </WrapperStyled>
  );
};

export default SystemSettings;
