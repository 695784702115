/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  AlertSubmitStreamIcon,
  CloseAlertIcon,
  CloseAlertNormalLight,
  CloseAlertNotNormalLight,
  CloseIcon,
  CloseIconV2,
  MutedIcon,
  PinAndNormalIcon,
  PinAndNotNormalIcon,
  PinIconDefaultV1,
  PinIconDefaultV2,
  PinIconV1,
  PinIconV2,
  SwitchVideoIcon,
  UnPinAndNormalIcon,
  UnPinAndNotNormalIcon,
  ZoomIcon,
} from 'src/assets/icons';
import { ModalCard } from 'src/shared/components';
import useSafieSDK from 'src/shared/hooks/useSafieSDK';
import { ROUTERS, THEME_TYPE, TYPE_ALERT } from 'src/shared/constants';
import { NoCamera } from 'src/assets/images';
import { WrapperStyled } from './styled';

const { Title, Text } = Typography;

interface ModeNormalProps {
  id?: string | number;
  title?: string;
  terminalName?: number | string | null;
  spotName?: number | string | null;
  flightNum?: string | null;
  airplaneName?: number | string | null;
  hideIconZoom?: boolean;
  className?: string;
  typeAlert?: 'alert-css' | 'alert-muted-css' | 'normal-css' | string;
  firstVideo?: any;
  secondVideo?: any;
  showPinIcon?: boolean;
  isShowMuted?: boolean;
  isShowAlert?: boolean;
  cameraList?: any;
  dataVideo?: any;
  isPin?: boolean;
  isScreenSubmitStreaming?: boolean;
  onCloseZoom?: () => void;
  onClickAlert?: () => void;
  onClickIsPin?: () => void;
  onClickAlertModeZoomFull?: () => void;
  setIsZoomFull?: any;
}

const ModeNormal: React.FC<ModeNormalProps> = ({
  id,
  title,
  spotName,
  flightNum,
  airplaneName,
  className,
  typeAlert,
  firstVideo,
  secondVideo,
  isShowMuted,
  isShowAlert,
  cameraList,
  hideIconZoom,
  dataVideo,
  isPin,
  isScreenSubmitStreaming,
  setIsZoomFull,
  onCloseZoom,
  onClickAlert,
  onClickIsPin,
  onClickAlertModeZoomFull,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);
  const typeAlertAuto = searchParams.get('type');

  const [openModal, setOpenModal] = useState<{ isOpen: boolean; dataVideo?: any }>({
    isOpen: false,
    dataVideo: null,
  });

  const { addPlayer, addPlayerVideoZoom } = useSafieSDK();

  useEffect(() => {
    const getListCameraByPath = pathname === ROUTERS.DASHBOARD.PATH ? cameraList?.slice(2, 4) : cameraList?.slice(0, 2);

    void (async () => {
      !openModal.isOpen && (await addPlayer(getListCameraByPath, isScreenSubmitStreaming));
      openModal.isOpen && (await addPlayerVideoZoom(openModal.dataVideo));
    })();
  }, [openModal, cameraList, isScreenSubmitStreaming]);

  useEffect(() => {
    setOpenModal({ isOpen: false });
  }, [dataVideo]);

  const renderIconPin = (): any => {
    // Dark
    if (typeTheme === THEME_TYPE.DARK && isPin && typeAlert === TYPE_ALERT.ALERT_NORMAL) {
      return PinIconV1;
    }
    if (typeTheme === THEME_TYPE.DARK && isPin && typeAlert !== TYPE_ALERT.ALERT_NORMAL) {
      return PinIconV2;
    }
    if (typeTheme === THEME_TYPE.DARK && !isPin && typeAlert === TYPE_ALERT.ALERT_NORMAL) {
      return PinIconDefaultV1;
    }
    if (typeTheme === THEME_TYPE.DARK && !isPin && typeAlert !== TYPE_ALERT.ALERT_NORMAL) {
      return PinIconDefaultV2;
    }

    // Light
    if (typeTheme === THEME_TYPE.LIGHT && isPin && typeAlert === TYPE_ALERT.ALERT_NORMAL) {
      return PinAndNormalIcon;
    }
    if (typeTheme === THEME_TYPE.LIGHT && isPin && typeAlert !== TYPE_ALERT.ALERT_NORMAL) {
      return PinAndNotNormalIcon;
    }
    if (typeTheme === THEME_TYPE.LIGHT && !isPin && typeAlert === TYPE_ALERT.ALERT_NORMAL) {
      return UnPinAndNormalIcon;
    }
    if (typeTheme === THEME_TYPE.LIGHT && !isPin && typeAlert !== TYPE_ALERT.ALERT_NORMAL) {
      return UnPinAndNotNormalIcon;
    }
  };

  useEffect(() => {
    if (setIsZoomFull) {
      setIsZoomFull(openModal?.isOpen);
    }
  }, [openModal]);

  useEffect(() => {
    if (isShowAlert && typeAlert === TYPE_ALERT.ALERT_NORMAL && onClickAlert && typeAlertAuto === '1') {
      void onClickAlert();
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [isShowAlert, typeAlert]);

  return (
    <WrapperStyled id={`${id}`} className={`${className ?? ''} ${typeAlert}`}>
      <Row className="row-header">
        <Col span={24} className="col-info">
          <Title level={5} className="title">
            <Text className="text">スポット</Text>
            <Text className="text font-helvetica">{spotName}</Text>
          </Title>
          <Title level={5} className="title title-center">
            <Text className="text">便名</Text>
            <Text className="text font-helvetica">JL{flightNum}</Text>
          </Title>
          <Title level={5} className="title">
            <Text className="text">機番</Text>
            <Text className="text font-helvetica">{airplaneName}</Text>
          </Title>
        </Col>
      </Row>

      <Row className="row-video">
        <Col span={24} className="col-video-1">
          <div className="video-item">
            <span className="streaming-status" id={`video-status-${id as string}-1`}></span>
            <div data-name="player" id={`video-${id as string}-1`} className="streaming-player"></div>
            <img
              data-name="player"
              className="streaming-player no-camera"
              id={`no-video-${String(id)}-1`}
              src={NoCamera}
            />
            {!hideIconZoom && (
              <Icon
                component={ZoomIcon}
                className="zoom-icon"
                onClick={() => {
                  setOpenModal({ isOpen: true, dataVideo: firstVideo ?? null });
                }}
              />
            )}
          </div>
        </Col>

        <Col span={24} className="col-video-2">
          <div className="video-item">
            <span className="streaming-status" id={`video-status-${id as string}-2`}></span>
            <div data-name="player" id={`video-${id as string}-2`} className="streaming-player"></div>
            <img
              data-name="player"
              className="streaming-player no-camera"
              id={`no-video-${String(id)}-2`}
              src={NoCamera}
            />
            {!hideIconZoom && (
              <Icon
                component={ZoomIcon}
                className="zoom-icon"
                onClick={() => {
                  setOpenModal({ isOpen: true, dataVideo: secondVideo ?? null });
                }}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row className="row-footer">
        <Col span={18} className="col-left">
          <Title level={4} className="title font-helvetica color-white-type-theme">
            {title}
          </Title>
        </Col>
        <Col span={6} className="col-right">
          {pathname !== ROUTERS.SUBMIT_STREAMING.PATH && <Icon component={renderIconPin()} onClick={onClickIsPin} />}

          {isShowMuted && typeAlert === TYPE_ALERT.ALERT_PRIMARY && (
            <Icon component={MutedIcon} onClick={onClickAlert} />
          )}

          {typeAlert === TYPE_ALERT.ALERT_MUTED && pathname !== ROUTERS.SUBMIT_STREAMING.PATH && (
            <Icon component={CloseAlertIcon} onClick={onClickAlert} />
          )}

          {typeTheme === THEME_TYPE.DARK && (
            <Icon component={typeAlert === TYPE_ALERT.ALERT_NORMAL ? CloseIcon : CloseIconV2} onClick={onCloseZoom} />
          )}

          {typeTheme === THEME_TYPE.LIGHT && (
            <Icon
              component={typeAlert === TYPE_ALERT.ALERT_NORMAL ? CloseAlertNormalLight : CloseAlertNotNormalLight}
              onClick={onCloseZoom}
            />
          )}
        </Col>
      </Row>

      {openModal.isOpen && (
        <ModalCard
          className={`mode_zoom-video ${typeAlert} ${
            pathname === ROUTERS.DASHBOARD.PATH && 'mode_zoom-video-dashboard'
          }`}
          title={
            <Row>
              <Col span={5} className="title">
                {title}
              </Col>

              <Col span={15} className="col-info">
                <Text className="text-info">
                  スポット<Text>{spotName}</Text>
                </Text>

                <Text className="text-info">
                  便名<Text>{flightNum}</Text>
                </Text>

                <Text className="text-info">
                  機番
                  <Text>{airplaneName}</Text>
                </Text>
              </Col>

              <Icon
                component={typeAlert === TYPE_ALERT.ALERT_NORMAL ? CloseIcon : CloseIconV2}
                onClick={() => setOpenModal({ isOpen: false })}
                className="icon-close-modal"
              />

              {isShowMuted && typeAlert === TYPE_ALERT.ALERT_PRIMARY && (
                <Icon component={MutedIcon} onClick={onClickAlertModeZoomFull} className="icon-muted" />
              )}

              {typeAlert === TYPE_ALERT.ALERT_MUTED && (
                <Icon component={CloseAlertIcon} onClick={onClickAlertModeZoomFull} className="icon-close" />
              )}
            </Row>
          }
          content={
            <React.Fragment>
              <div className="video-zoom__wrap">
                <Icon
                  className="switch-icon"
                  component={SwitchVideoIcon}
                  onClick={() => {
                    if (openModal?.dataVideo?.id === firstVideo?.id) {
                      setOpenModal({ isOpen: true, dataVideo: secondVideo });
                    } else {
                      setOpenModal({ isOpen: true, dataVideo: firstVideo });
                    }
                  }}
                />
                <span className="streaming-status" id={`video-zoom-status-${openModal?.dataVideo?.id}`}></span>
                <div
                  data-name="player"
                  className="streaming-player"
                  id={`video-zoom-${openModal?.dataVideo?.id}`}
                ></div>
                <img
                  data-name="player"
                  className="streaming-player no-camera"
                  id={`no-video-zoom-${String(openModal?.dataVideo?.id)}`}
                  src={NoCamera}
                />
              </div>
            </React.Fragment>
          }
          isOpen={openModal.isOpen}
          handleOk={() => setOpenModal({ isOpen: false })}
          handleCancel={() => setOpenModal({ isOpen: false })}
          footer={null}
        />
      )}

      {isShowAlert && typeAlert !== TYPE_ALERT.ALERT_PRIMARY && typeAlert !== TYPE_ALERT.ALERT_MUTED && (
        <div className="row-action-alert" onClick={onClickAlert}>
          <Icon component={AlertSubmitStreamIcon} />
          <Text className="text-alert">アラート通知</Text>
        </div>
      )}
    </WrapperStyled>
  );
};

export default ModeNormal;
