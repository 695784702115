import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const LABELS_API = {
  getLabelsAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/labels', { params });

    const data = get(response, 'data', null);

    return data;
  },
  createLabelAPI: async (params: LabelsStore.ParamsLabels) => {
    const response = await new AxiosClient().post('labels', params);

    const data = get(response, 'message', null);

    return data;
  },
  updateLabelsAPI: async (params: LabelsStore.ParamsUpdateLabels) => {
    const getParams = {
      terminalId: params.terminalId,
      name: params.name,
      cameraList: params.cameraList,
      buttonId: params.buttonId,
    };

    const response = await new AxiosClient().patch(`/labels/${params.id}`, getParams);

    const data = get(response, 'message', null);

    return data;
  },
  deleteLabelAPI: async (id: number) => {
    const response = await new AxiosClient().delete(`/labels/${id}`);

    const data = get(response, 'message', null);

    return data;
  },
};

export default LABELS_API;
