import React from 'react';

const DownIconV2: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 14.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V14.4H13Z" fill="#E43B53" />
      <path
        opacity="0.3"
        d="M4 14.3999H20L12.7 21.6999C12.3 22.0999 11.7 22.0999 11.3 21.6999L4 14.3999Z"
        fill="#E43B53"
      />
    </svg>
  );
};

export default DownIconV2;
