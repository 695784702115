import { createSlice } from '@reduxjs/toolkit';

import { getDataDashboardSecondAction } from './dashboardSecond.action';

const initialState: DashboardSecondStore.DashboardSecondReducer = {
  isLoading: false,
  error: null,
  dataDashboardSecond: [],
  page: 1,
  totalItem: 1,
  totalPin: 0,
  totalPageSwitch: 0,
};

const { actions, reducer } = createSlice({
  name: 'dashboard_second_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDataDashboardSecondAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDataDashboardSecondAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataDashboardSecond = action.payload?.rows;
        state.totalItem = action.payload?.total;
        state.page = action.payload?.page;
        state.totalPin = action.payload?.totalPin;
        state.totalPageSwitch = action.payload?.totalPageSwitch;
      })
      .addCase(getDataDashboardSecondAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
