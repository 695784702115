import * as yup from 'yup';

import { REGEX_EMAIL } from 'src/shared/constants/regex';

export const UserSchema = yup.object().shape({
  username: yup.string().required('名前を入力してください'),
  staffNum: yup.string().required('社員番号を入力してください'),
  email: yup
    .string()
    .nullable()
    .required('メールアドレスを入力してください')
    .matches(REGEX_EMAIL, 'メールアドレスの形式が正しくありません'),
  role: yup.string().required('権限を入力してください'),
});
