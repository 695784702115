import { COLOR, SIZEH, SIZEW } from 'src/shared/constants';
import ResponsiveUI from 'src/shared/constants/reponsive';

import styled, { css } from 'styled-components';

const reponsiveWrapperStyled = css`
  ${ResponsiveUI.lessThanH(SIZEH.PAD3)} {
    ${ResponsiveUI.lessThan(SIZEW.LG)} {
      .row-header {
        .col-info {
          .title {
            display: flex !important;
            align-items: center;
            padding: 6px 14px !important;
            min-width: calc(100% / 3 - 14px) !important;
            justify-content: center;
          }
        }
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.PAD2)} {
    ${ResponsiveUI.lessThan(SIZEW.XL1)} {
      .row-header {
        .col-info {
          .title {
            display: grid !important;
            align-items: center;
            padding: 6px 8px !important;
            min-width: calc(100% / 3 - 14px) !important;
            text-align: center;
            justify-content: center;

            .text {
              &:last-child {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    ${ResponsiveUI.lessThan(SIZEW.LG)} {
      width: 90%;

      .list-alert {
        padding: 20px 10px;
      }

      .row-action-alert {
        bottom: -60px;
        height: 40px;

        .text-alert {
          color: ${COLOR.WHITE};
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
`;

export const WrapperStyled = styled.div`
  padding: 20px 30px;
  width: 95%;
  border-radius: 12px;
  position: relative;

  .row-header {
    .col-info {
      display: flex;
      justify-content: space-between;
      height: 55px;

      .title {
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 14px;
        height: 100%;
        min-width: calc(100% / 3 - 14px);
        background-color: #1f2126;
        justify-content: space-between;
      }

      .title,
      .text {
        margin: 0;
        color: ${COLOR.WHITE};
        font-size: 14px;
        font-weight: 700;
      }

      .text {
        &:first-child {
          color: #595b69;
        }
      }

      .text {
        &:last-child {
          font-size: 18px;
        }
      }

      .title-center {
        margin: 0 5px;
      }
    }
  }

  .row-footer {
    margin-top: 15px;
    .col-left {
      display: flex;
      align-items: center;
      justify-content: start;

      .title {
        color: #595b69;
        margin: 0;
      }
    }

    .col-right {
      display: flex;
      align-items: center;
      justify-content: end;

      .anticon {
        margin-left: 14px;
      }
    }
  }

  .row-video {
    .col-video-1,
    .col-video-2 {
      margin-top: 20px;

      .video-item {
        position: relative;
      }

      .zoom-icon {
        position: absolute;
        bottom: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 2;
      }

      .serial {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 8px;
        background: rgba(22, 23, 28, 0.2);
        padding: 10px;
      }
    }
  }

  .row-action-alert {
    position: absolute;
    bottom: -110px;
    background-color: #f64e60;
    width: 100%;
    left: 0;
    height: 80px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .text-alert {
      color: ${COLOR.WHITE};
      font-size: 28px;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  ${reponsiveWrapperStyled}
`;
