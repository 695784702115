import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { notification } from 'antd';

import { Register } from 'src/interfaces/pages/register';
import { useAppDispatch } from 'src/stores';
import { registerAction } from 'src/stores/screens/publicScreens/auth/auth.action';
import { ROUTERS } from 'src/shared/constants/routers';
import { STORAGE_KEY } from 'src/shared/constants';
import { MESSAGE_REGISTER_VALIDATE, MESSAGE_ERROR } from 'src/shared/constants/message';
import { REGEX_PASSWORD } from 'src/shared/constants/regex';

const UseFormRegister = (): Register.useForm => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const initialValues: Register.initialValues = {
    username: '',
    staffNum: '',
    email: '',
    role: '',
    roleName: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const keyMessageSettingPasswordByScreen = (): string => {
    switch (pathname) {
      case ROUTERS.REGISTER.PATH:
        return 'REGISTER_PASSWORD';
      case ROUTERS.RESET_PASSWORD.PATH:
        return 'RESET_PASSWORD';
      case ROUTERS.REMIND_CHANGE_PASSWORD.PATH:
        return 'REMIND_PASSWORD';
      default:
        return '';
    }
  };

  const registerSuccess = (): void => {
    navigate(ROUTERS.LOGIN.PATH);
    Cookies.remove(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
  };

  const settingPasswordError = (): void => {
    notification.error({ message: MESSAGE_ERROR[keyMessageSettingPasswordByScreen()] });
  };

  const handleRegister = async (value): Promise<void> => {
    const data = {
      username: value.username,
      staffNum: value.staffNum,
      email: value.email,
      role: value.role,
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
    };
    await dispatch(
      registerAction({
        data,
        callback: registerSuccess,
        callbackError: settingPasswordError,
      }),
    );
  };

  const registerSchema = yup.object().shape({
    oldPassword: yup.string().required(MESSAGE_REGISTER_VALIDATE[keyMessageSettingPasswordByScreen()]),
    newPassword: yup
      .string()
      .trim()
      .required(MESSAGE_REGISTER_VALIDATE.PASSWORD_NEW_IS_REQUIRED)
      .test('oneOfRequired', MESSAGE_REGISTER_VALIDATE.PASSWORD_NEW_VALIDATE, (value: any) => {
        return REGEX_PASSWORD.test(value);
      }),
    confirmPassword: yup
      .string()
      .trim()
      .required(MESSAGE_REGISTER_VALIDATE.PASSWORD_CONFIRM_IS_REQUIRED)
      .oneOf([yup.ref('newPassword')], MESSAGE_REGISTER_VALIDATE.PASSWORD_CONFIRM_FAIL),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: registerSchema,
    initialValues,
    onSubmit: async value => {
      await handleRegister(value);
    },
  });

  return { formik };
};

export default UseFormRegister;
