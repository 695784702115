import React from 'react';

const CloseAlertNormalLight: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3488_10214)">
        <path
          opacity="0.45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75207 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
          fill="#EFEFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3661 11.3661C11.8543 10.878 12.6457 10.878 13.1339 11.3661L16 14.2322L18.8661 11.3661C19.3543 10.878 20.1457 10.878 20.6339 11.3661C21.122 11.8543 21.122 12.6457 20.6339 13.1339L17.7678 16L20.6339 18.8661C21.122 19.3543 21.122 20.1457 20.6339 20.6339C20.1457 21.122 19.3543 21.122 18.8661 20.6339L16 17.7678L13.1339 20.6339C12.6457 21.122 11.8543 21.122 11.3661 20.6339C10.878 20.1457 10.878 19.3543 11.3661 18.8661L14.2322 16L11.3661 13.1339C10.878 12.6457 10.878 11.8543 11.3661 11.3661Z"
          fill="#E43B53"
        />
      </g>
      <defs>
        <clipPath id="clip0_3488_10214">
          <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseAlertNormalLight;
