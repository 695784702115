import React from 'react';

const AlertSubmitStreamIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.35782 20.9233L15.6391 20.9233C15.4937 21.7836 15.0482 22.5646 14.3818 23.1278C13.7153 23.691 12.871 24 11.9985 24C11.126 24 10.2816 23.691 9.61519 23.1278C8.94876 22.5646 8.50331 21.7836 8.35782 20.9233ZM11.9985 -1.93631e-06C14.4467 -2.36436e-06 16.7946 0.972533 18.5257 2.70366C20.2568 4.43478 21.2294 6.7827 21.2294 9.23088L21.2294 14.154L22.9746 18.0433C23.054 18.2212 23.0876 18.4161 23.0725 18.6102C23.0574 18.8044 22.994 18.9918 22.8882 19.1553C22.7823 19.3187 22.6372 19.4532 22.4662 19.5464C22.2951 19.6395 22.1035 19.6885 21.9088 19.6889L2.09313 19.6889C1.89809 19.6888 1.70616 19.64 1.53481 19.5468C1.36346 19.4537 1.21813 19.3191 1.11207 19.1554C1.00601 18.9918 0.942577 18.8041 0.927551 18.6097C0.912525 18.4152 0.946383 18.2201 1.02604 18.0421L2.7676 14.1528L2.7676 9.21488L2.77375 8.90718C2.85795 6.51682 3.86669 4.25246 5.58745 2.59116C7.30821 0.929859 9.60663 0.00011079 11.9985 -1.93631e-06Z"
        fill="white"
      />
    </svg>
  );
};

export default AlertSubmitStreamIcon;
