import Cookies from 'js-cookie';

import { useAppSelector } from 'src/stores';
import { ITEM_OF_PAGE, LOCAL_STORAGE_KEY, STORAGE_KEY, THEME_TYPE, TYPE_DISPLAY_SCREEN } from '../constants';

interface IUseHandleLocalStorage {
  initialLocalStorage: () => void;
  clearStorage: () => void;
  handleClearAllNotTheme: () => void;
}

const useHandleLocalStorage = (): IUseHandleLocalStorage => {
  const { meInfo } = useAppSelector(state => state.auth);

  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);
  const typeDisplayScreen = localStorage.getItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN);
  const themeType = localStorage.getItem(THEME_TYPE.TYPE);
  const settingMonitor = localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR);
  const pageAndSize = localStorage.getItem(LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH);
  const terminalId = localStorage.getItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
  const quantityDisplaySecond = localStorage.getItem(LOCAL_STORAGE_KEY.QUANTITY_DISPLAY_SECOND);
  const pageDashboardList = localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST);

  const initialLocalStorage = (): void => {
    if (!itemOfPage) {
      localStorage.setItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE, ITEM_OF_PAGE[12]);
    }

    if (!typeDisplayScreen) {
      localStorage.setItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN, TYPE_DISPLAY_SCREEN.TWO_SCREEN);
    }

    if (!themeType) {
      localStorage.setItem(THEME_TYPE.TYPE, THEME_TYPE.DARK);
    }

    if (!settingMonitor) {
      localStorage.setItem(LOCAL_STORAGE_KEY.SETTING_MONITOR, JSON.stringify({}));
    }

    if (!pageAndSize) {
      localStorage.setItem(LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH, JSON.stringify({ page: 1, size: 0 }));
    }

    if (!quantityDisplaySecond) {
      localStorage.setItem(LOCAL_STORAGE_KEY.QUANTITY_DISPLAY_SECOND, '0');
    }

    if (!pageDashboardList) {
      localStorage.setItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST, '1');
    }

    localStorage.setItem(LOCAL_STORAGE_KEY.TERMINAL_ID, terminalId ?? String(meInfo?.terminalId));
    localStorage.setItem(LOCAL_STORAGE_KEY.ORDER, 'null');
    localStorage.setItem(LOCAL_STORAGE_KEY.SEARCH, '');
    localStorage.setItem(LOCAL_STORAGE_KEY.IS_LOGOUT, 'false');
  };

  const clearStorage = (): void => {
    localStorage.clear();
  };

  const handleClearAllNotTheme = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);
    localStorage.removeItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.TERMINAL_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SETTING_MONITOR);
    localStorage.removeItem(LOCAL_STORAGE_KEY.ORDER);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SEARCH);
    localStorage.removeItem(LOCAL_STORAGE_KEY.IS_MOBILE);
    localStorage.removeItem(LOCAL_STORAGE_KEY.QUANTITY_DISPLAY_SECOND);
    Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
    Cookies.remove(STORAGE_KEY.ROLE);
    Cookies.remove(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
  };

  return { initialLocalStorage, clearStorage, handleClearAllNotTheme };
};

export default useHandleLocalStorage;
