import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';

import { useAppDispatch } from 'src/stores';
import { createButtonAction, updateButtonAction } from 'src/stores/screens/privateScreens/common/common.action';

interface IUseForm {
  formik: FormikProps<any>;
  setIsUpdate: (value: boolean) => void;
  setIdUpdate: (value: number) => void;
}

const useForm = ({ setIsShowModal, fetchDataOneClick }): IUseForm => {
  const dispatch = useAppDispatch();

  const [isUpdate, setIsUpdate] = useState(false);
  const [idUpdate, setIdUpdate] = useState(1);

  const cameraSchema = yup.object().shape({
    terminalId: yup.string().required('を入力してください'),
    serialNum: yup.string().trim().required('タグボタンの端末番号（IMSI）が既に存在します'),
  });

  const formik = useFormik({
    initialValues: {
      terminalId: null,
      serialNum: '',
    },
    validationSchema: cameraSchema,
    onSubmit: value => {
      handleSubmitForm(value);
    },
  });

  const handleSubmitForm = (value: CommonStore.InitValueCreateButton): void => {
    if (!isUpdate) {
      void dispatch(
        createButtonAction({
          terminalId: value.terminalId,
          serialNum: value.serialNum,
          callback: () => {
            fetchDataOneClick();

            setIsShowModal(false);
          },
        }),
      );
    } else {
      void dispatch(
        updateButtonAction({
          serialNum: value.serialNum,
          terminalId: value.terminalId,
          id: idUpdate,
          callback: () => {
            fetchDataOneClick();

            setIsShowModal(false);
          },
        }),
      );
    }
  };

  return { formik, setIsUpdate, setIdUpdate };
};

export default useForm;
