import React from 'react';
import { Col } from 'antd';

import useInit from './hooks/useInit';
import useInitUploadFile from './hooks/useInitUploadFile';
import FilterCard from './FilterCard';
import TableCard from 'src/shared/components/table/Table';
import ModalForm from './ModalForm';
import ModalDownloadFile from './ModalDownloadFile';
import ModalUploadFile from './ModalUploadFile';
import ModalTableValidate from './ModalTableValidate';
import { SpinningTransparent } from 'src/shared/components';
import { WrapperStyled } from './styled';

const UserManagement: React.FC = () => {
  const {
    columns,
    dataSource,
    totalPage,
    isLoading,
    userSelected,
    isShowModal,
    terminalSelects,
    currentPage,
    setIsShowModal,
    handleShowModal,
    setCurrentPage,
  } = useInit();

  const {
    isFileLoading,
    isShowModalDownloadFile,
    isShowModalUploadFile,
    isShowModalTableValidate,
    dataTableValidate,
    percentProgress,
    isShowProgress,
    codeTitleTable,
    setIsShowModalDownloadFile,
    setIsShowModalUploadFile,
    setIsShowModalTableValidate,
    handleDownloadFile,
    handleDownloadTemplate,
    handleClickUploadFile,
    handleSubmitUploadFile,
  } = useInitUploadFile({ setCurrentPage });

  return (
    <React.Fragment>
      {isFileLoading && <SpinningTransparent isLoading={isFileLoading} />}
      <WrapperStyled className="user-management-screen">
        <FilterCard
          terminalSelects={terminalSelects}
          handleShowModal={handleShowModal}
          setIsShowModalUploadFile={setIsShowModalUploadFile}
          setIsShowModalDownloadFile={setIsShowModalDownloadFile}
        />

        <Col span={18} className="col-form">
          <TableCard
            loading={isLoading}
            columns={columns}
            dataSource={dataSource ?? []}
            rowKey="id"
            totalPages={totalPage}
            currentPage={currentPage}
            onChangePage={page => {
              setCurrentPage(page);
            }}
          />
        </Col>
      </WrapperStyled>
      <ModalForm
        isOpen={isShowModal}
        setIsShowModal={setIsShowModal}
        userSelected={userSelected}
        currentPage={currentPage}
        terminalSelects={terminalSelects}
        setCurrentPage={setCurrentPage}
      />
      {isShowModalDownloadFile && (
        <ModalDownloadFile
          terminalSelects={terminalSelects}
          setIsShowModalDownloadFile={setIsShowModalDownloadFile}
          handleDownloadFile={handleDownloadFile}
        />
      )}
      {isShowModalUploadFile && (
        <ModalUploadFile
          isShowProgress={isShowProgress}
          percentProgress={percentProgress}
          setIsShowModalUploadFile={setIsShowModalUploadFile}
          handleDownloadTemplate={handleDownloadTemplate}
          handleClickUploadFile={handleClickUploadFile}
          handleSubmitUploadFile={handleSubmitUploadFile}
        />
      )}
      {isShowModalTableValidate && (
        <ModalTableValidate
          codeTitleTable={codeTitleTable}
          setIsShowModalTableValidate={setIsShowModalTableValidate}
          dataTableValidate={dataTableValidate}
        />
      )}
    </React.Fragment>
  );
};

export default UserManagement;
