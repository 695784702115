import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import { t } from 'i18next';

import AUTH_API from './auth.api';
import { STORAGE_KEY } from 'src/shared/constants/storageKey';
import { MESSAGE_SUCCEESS } from 'src/shared/constants/message';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.loginAPI(data);
      return user;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });
      rejectWithValue(err?.message);
    }
  },
);

export const registerAction = createAsyncThunk<any, Auth.RegisterRequestPayload>(
  'register_auth',
  async ({ data, callback = () => {}, callbackError = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const res = await AUTH_API.registerAPI(data);
      notification.success({ message: MESSAGE_SUCCEESS.SETTING_PASSWORD_SUCCESSFULLY });
      callback();
      return res;
    } catch (err: any) {
      callbackError();
      return rejectWithValue(err?.message);
    }
  },
);

export const verifyAuthAction = createAsyncThunk<any, Auth.VerifyAuthRequestPayload>(
  'verify_auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const res = await AUTH_API.verifyAuthAPI(data);
      return res;
    } catch (err: any) {
      callback();
      return rejectWithValue(err?.message);
    }
  },
);

export const changePasswordAction = createAsyncThunk<any, Auth.ChangePasswordRequestPayload>(
  'change_password_auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.changePasswordAPI(data);
      notification.success({ message: MESSAGE_SUCCEESS.SETTING_PASSWORD_SUCCESSFULLY });
      if (user?.token) {
        Cookies.set(STORAGE_KEY.ACCESS_TOKEN, user.token, { expires: STORAGE_KEY.EXPIRE_TOKEN });
        Cookies.set(STORAGE_KEY.ROLE, user.role, { expires: STORAGE_KEY.EXPIRE_TOKEN });
      }

      callback();
      return user;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });
      rejectWithValue(err?.message);
    }
  },
);

export const forgotPasswordAction = createAsyncThunk<any, Auth.ForgotPasswordRequestPayload>(
  'forgot_password_auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const res = await AUTH_API.forgotPasswordAPI(data);
      notification.success({ message: `${data.email}${MESSAGE_SUCCEESS.FORGOT_PASSWORD_SUCCESSFULLY}` });

      callback();
      return res;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });
      rejectWithValue(err?.message);
    }
  },
);

export const getMeAction = createAsyncThunk<any>('me', async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await AUTH_API.getMeAPI();

    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const downloadTemplateAction = createAsyncThunk<any, TerminalsStore.RequestData>(
  'download_template',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.downloadTemplateAPI();

      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const downloadFileUsersAction = createAsyncThunk<any, TerminalsStore.RequestData>(
  'download_file_users',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.downloadFileUsersAPI(data);

      callback();
      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const uploadFileUserAction = createAsyncThunk<any, any>(
  'upload_file_user',
  async ({ data, callback = () => {}, callbackError = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const res = await AUTH_API.uploadFileUserAPI(data);

      if (!res) {
        callback();
      }
      return res;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });
      callbackError();
      rejectWithValue(err?.message);
    }
  },
);

export const logoutAction = createAsyncThunk<any, Auth.LogoutRequest>(
  'alert',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const res: any = await AUTH_API.logoutAPI();

      if (res) {
        Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
        Cookies.remove(STORAGE_KEY.ROLE);
        Cookies.remove(STORAGE_KEY.ACCESS_TOKEN_REGISTER);
      }

      callback();

      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
