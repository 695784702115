import React from 'react';

const TwoScreenLightIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.45"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75206 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
        fill="#EFEFF2"
      />
      <path
        opacity="0.3"
        d="M8.96969 20L14.4597 20C16.1 20 17.4297 18.6703 17.4297 17.03L17.4297 8.97C17.4297 7.32971 16.1 6 14.4597 6L8.96969 6C7.3294 6 5.99969 7.32971 5.99969 8.97L5.99969 17.03C5.99969 18.6703 7.3294 20 8.96969 20Z"
        fill="#3A75FF"
      />
      <path
        d="M18.93 17C18.9277 18.165 18.4708 19.283 17.6564 20.1161C16.842 20.9491 15.7346 21.4313 14.57 21.46L14.57 23C14.57 23.7956 14.8861 24.5587 15.4487 25.1213C16.0113 25.6839 16.7744 26 17.57 26L23 26C23.7956 26 24.5587 25.6839 25.1213 25.1213C25.6839 24.5587 26 23.7956 26 23L26 15C26 14.2044 25.6839 13.4413 25.1213 12.8787C24.5587 12.3161 23.7957 12 23 12L18.9 12L18.93 17Z"
        fill="#3A75FF"
      />
      <path
        d="M9.68536 17C9.13308 17 8.68536 16.5522 8.68536 16V15.6972C8.68536 15.4184 8.80171 15.1523 9.00636 14.9631L11.7214 12.452C11.9134 12.268 12.0534 12.108 12.1414 11.972C12.2374 11.836 12.3014 11.716 12.3334 11.612C12.3654 11.5 12.3814 11.396 12.3814 11.3C12.3814 11.092 12.3134 10.932 12.1774 10.82C12.0414 10.7 11.8374 10.64 11.5654 10.64C11.3174 10.64 11.0814 10.708 10.8574 10.844C10.4771 11.0613 10.0118 11.2612 9.61999 11.0653L9.27309 10.8918C8.68065 10.5956 8.44035 9.84868 8.9331 9.40603C9.12049 9.23769 9.32991 9.085 9.56136 8.94796C10.1694 8.58796 10.9254 8.40796 11.8294 8.40796C12.4934 8.40796 13.0814 8.51596 13.5934 8.73196C14.1054 8.94796 14.5054 9.25196 14.7934 9.64396C15.0814 10.036 15.2254 10.5 15.2254 11.036C15.2254 11.308 15.1894 11.58 15.1174 11.852C15.0534 12.124 14.9174 12.412 14.7094 12.716C14.5014 13.012 14.1934 13.344 13.7854 13.712L11.5054 15.788L11.0614 14.804H14.4174C14.9696 14.804 15.4174 15.2517 15.4174 15.804V16C15.4174 16.5522 14.9696 17 14.4174 17H9.68536Z"
        fill="#3A75FF"
      />
    </svg>
  );
};

export default TwoScreenLightIcon;
