import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField, SelectField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { useAppSelector } from 'src/stores';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { ModalContentWrap } from './styled';

interface Props {
  terminalSelects: any;
  isOpen: boolean;
  dataEdit: DashboardStore.Camera | null;
  terminalId: number;
  setIsShowModal: (param: boolean) => void;
  fetchDataCameras: (params?: CommonStore.ParamsGetList) => Promise<void>;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({
  terminalSelects,
  isOpen,
  dataEdit,
  terminalId,
  setIsShowModal,
  fetchDataCameras,
}) => {
  const { meInfo } = useAppSelector(state => state.auth);

  const { formik, optionsSelectTypeCamera, setIsUpdate, setIdUpdate } = useForm({ setIsShowModal, fetchDataCameras });

  const { errors, touched, setFieldValue, getFieldProps, handleSubmit, resetForm } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
    resetForm();
  };

  useEffect(() => {
    setFieldValue('terminalId', terminalId ?? meInfo?.terminalId);

    if (dataEdit) {
      setIsUpdate(true);
      setIdUpdate(dataEdit?.id);
      setFieldValue('serialNum', dataEdit.serialNum);
      setFieldValue('type', dataEdit.type);
      setFieldValue('name', dataEdit?.label?.name);
    } else {
      setIsUpdate(false);
    }
  }, [dataEdit]);

  useEffect(() => {
    setFieldValue('terminalId', terminalId ?? meInfo?.terminalId);

    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={dataEdit ? 'カメラ情報を更新する' : 'カメラ情報を登録する'}
      width={494}
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      className="modal-create-camera modal-custom-antd modal-set-divice"
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField
            content={dataEdit ? MESSAGE_POPUP.EDIT : MESSAGE_POPUP.CREATE}
            type="primary"
            onClick={handleSubmit}
          />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  拠点：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('terminalId')}
                  optionsSelect={terminalSelects}
                  setFieldValue={setFieldValue}
                  error={errors.terminalId}
                  touched={touched.terminalId}
                  disabled={!!dataEdit}
                />
              </Col>
            </Row>
            {dataEdit && (
              <Row>
                <Col span={24} className="field-title">
                  <Text>
                    グループ名：<span className="required">*</span>
                  </Text>
                </Col>
                <Col span={24} className="field-list">
                  <div>
                    <InputField
                      setFieldValue={setFieldValue}
                      field={getFieldProps('name')}
                      error={errors.name}
                      touched={touched.name}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  端末番号：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('serialNum')}
                    error={errors.serialNum}
                    touched={touched.serialNum}
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-30">
              <Col span={24} className="field-title">
                <Text>
                  端末種類：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-select">
                <SelectField
                  field={getFieldProps('type')}
                  optionsSelect={optionsSelectTypeCamera}
                  setFieldValue={setFieldValue}
                  error={errors.type}
                  touched={touched.type}
                />
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
