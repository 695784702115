import { useFormik } from 'formik';

import { Terminals } from 'src/interfaces/pages/terminals';
import { TerminalSchema } from './shcema';
import { useAppDispatch } from 'src/stores';
import {
  createTerminalAction,
  updateTerminalAction,
} from 'src/stores/screens/privateScreens/terminals/terminals.action';

const useForm = ({ terminalSelected, setIsShowModal, fetchDataTerminals }): Terminals.useForm => {
  const dispatch = useAppDispatch();

  const initialValues: Terminals.InitialValues = {
    name: null,
  };

  const submitForm = async (data: TerminalsStore.ParamsTerminal): Promise<void> => {
    if (terminalSelected?.id) {
      const value = { id: terminalSelected.id, name: data.name };
      await dispatch(
        updateTerminalAction({
          data: value,
          callback: () => {
            setIsShowModal(false);
            fetchDataTerminals();
          },
        }),
      );
      return;
    }
    await dispatch(
      createTerminalAction({
        data,
        callback: () => {
          setIsShowModal(false);
          fetchDataTerminals();
        },
      }),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: TerminalSchema,
    initialValues,
    onSubmit: value => {
      void submitForm(value);
    },
    validateOnChange: true,
  });

  return {
    formik,
  };
};

export default useForm;
