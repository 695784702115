import { Table } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const WrapperStyled = styled(Table)`
  &.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-table {
      background-color: transparent;
      height: 100%;
      position: relative;
      color: #adaec1 !important;

      .ant-table-cell {
        background-color: transparent;
      }

      .ant-table-cell-scrollbar {
        display: none;
      }

      .ant-table-body {
        overflow-y: auto !important;
        padding-bottom: 53px;

        .ant-table-placeholder {
          &:hover {
            background-color: transparent;

            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }

      .ant-table-row {
        &:hover {
          background-color: transparent;

          .ant-table-cell {
            background-color: #0f1013;
          }
        }
      }

      .ant-table-container {
        height: calc(100% - 64px);
        border-radius: 12px;
        background: #15171c;
        overflow: hidden;

        .ant-table-content {
          height: 100%;

          table {
            height: 100% !important;
            table-layout: fixed !important;
          }
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          border-bottom: 1px dashed #383840;
        }

        .ant-table-tbody {
          .ant-table-cell {
            font-size: 16px;
            font-weight: 400;

            .anticon {
              margin-left: 10px;
            }
          }
        }

        .ant-table-thead {
          .ant-table-cell {
            color: #adaec1;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .ant-table-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: ${COLOR.BACKGROUND_PRIMARY};
        display: flex;
        justify-content: end;
        padding-right: 0;
      }
    }
  }

  .ant-pagination-jump-next {
    .ant-pagination-item-container {
      height: 100%;
      width: 100%;

      .ant-pagination-item-ellipsis {
        color: ${COLOR.PRIMARY};
      }
    }
  }
`;
