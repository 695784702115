import React, { useState, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';

import { CloseIconV3 } from 'src/assets/icons';
import { ButtonField, SelectField } from 'src/shared/components/form';
import { useAppSelector, useAppDispatch } from 'src/stores';
import { getTotalUserAction } from 'src/stores/screens/privateScreens/userManagement/userManagement.action';
import { ModalDownloadFileWrap } from './styled';

interface Props {
  terminalSelects: any;
  setIsShowModalDownloadFile: (param: boolean) => void;
  handleDownloadFile: (value: any) => Promise<void>;
}

const LIMIT_PAGE = 1000;

const { Text, Title } = Typography;

const ModalDownloadFile: React.FC<Props> = ({ terminalSelects, handleDownloadFile, setIsShowModalDownloadFile }) => {
  const dispatch = useAppDispatch();

  const { meInfo } = useAppSelector(state => state.auth);
  const { terminalId } = useAppSelector(state => state.userManagement);

  const [selectPageSize, setSelectPageSize] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const formik = useFormik({
    initialValues: {
      terminalId: '',
    },
    onSubmit: value => {
      void handleDownloadFile(value);
    },
  });

  const getListUser = async (terminalId): Promise<void> => {
    const data = {
      page: 1,
      terminalId,
    };
    const res = await dispatch(getTotalUserAction({ data }));
    if (res) {
      setTotalPage(res.payload?.total);
    }
  };

  const dataPageSize = (totalPage: number): any => {
    const resulst: any = [];
    let pageSizeTemp = 0;
    let page = 0;

    for (let i = 0; i <= totalPage; i++) {
      const pageSize = i + LIMIT_PAGE;
      page++;
      if (i < totalPage - 1) {
        resulst.push({
          label: `${i + 1} ~ ${pageSize}`,
          value: page,
          valueStart: i + 1,
          valueEnd: pageSize,
        });

        i = pageSize - 1;
        pageSizeTemp = pageSize;
      } else {
        pageSizeTemp !== totalPage &&
          resulst.push({
            label: `${totalPage}`,
            value: page,
          });
        break;
      }
    }

    const valueLast = resulst[resulst?.length - 1];
    if (valueLast?.valueEnd && valueLast?.valueEnd !== totalPage) {
      resulst[resulst?.length - 1] = {
        label: `${valueLast.valueStart} ~ ${totalPage}`,
        value: valueLast.value,
      };
    }

    setSelectPageSize(resulst);
    void setFieldValue('page', resulst[0]?.value);
  };

  const { setFieldValue, getFieldProps, handleSubmit } = formik;

  useEffect(() => {
    void setFieldValue('terminalId', terminalId || meInfo?.terminal?.id);
    void getListUser(terminalId);
  }, [terminalId]);

  useEffect(() => {
    dataPageSize(totalPage);
  }, [totalPage]);

  return (
    <>
      <Row className="modal-background" onClick={() => setIsShowModalDownloadFile(false)}></Row>
      <ModalDownloadFileWrap className="modal-custom">
        <Col span={24} className="modal-header">
          <Title className="title">ユーザ一覧ダウンロード</Title>
          <Icon component={CloseIconV3} className="icon-close" onClick={() => setIsShowModalDownloadFile(false)} />
        </Col>
        <Col span={24} className="modal-body">
          <Row className="row-title">
            <Col span={24}>
              <Title>ユーザ一覧をダウンロードして1000件単位で一括編集できます。</Title>
            </Col>
            <Col span={24}>
              <Title>編集結果はまた一括登録後に反映されます。</Title>
            </Col>
          </Row>
          <Row className="row-field">
            <Col span={24} className="field-title">
              <Text>拠点：</Text>
            </Col>
            <Col span={24} className="field-select">
              <SelectField
                field={getFieldProps('terminalId')}
                optionsSelect={terminalSelects}
                setFieldValue={setFieldValue}
                onChange={e => {
                  void (async e => {
                    await getListUser(e);
                  })(e);
                }}
              />
            </Col>
          </Row>
          <Row className="row-field">
            <Col span={24} className="field-title">
              <Text>ダウンロード単位：</Text>
            </Col>
            <Col span={24} className="field-select">
              <SelectField field={getFieldProps('page')} optionsSelect={selectPageSize} setFieldValue={setFieldValue} />
            </Col>
          </Row>
        </Col>
        <Col className="modal-bottom">
          <ButtonField content="閉じる" type="default" onClick={() => setIsShowModalDownloadFile(false)} />
          <ButtonField content="出力 " type="primary" onClick={handleSubmit} disabled={totalPage === 0} />
        </Col>
      </ModalDownloadFileWrap>
    </>
  );
};

export default ModalDownloadFile;
