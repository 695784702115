import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { ROUTERS } from 'src/shared/constants/routers';
import { PrivateLayout } from 'src/layouts';
import {
  ProfileScreen,
  DashboardScreen,
  SubmitStreamingScreen,
  UserManagementScreen,
  LabelScreen,
  CameraScreen,
  OneClickScreen,
  TerminalScreen,
  SpotScreen,
  PlaneNumberScreen,
  SystemSettingScreen,
  DasboardSecondV2Screen,
  DropDrapSetting,
} from 'src/screens/privateScreens';
import NotFoundScreen from 'src/screens/errorPage/notFound';

const _adminPrivateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: ROUTERS.HOME.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.DASHBOARD.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.PROFILE.PATH, element: <ProfileScreen /> },
      { path: ROUTERS.SUBMIT_STREAMING.PATH, element: <SubmitStreamingScreen /> },
      { path: ROUTERS.USER_MANAGEMENT.PATH, element: <UserManagementScreen /> },
      { path: ROUTERS.LABEL.PATH, element: <LabelScreen /> },
      { path: ROUTERS.CAMERA.PATH, element: <CameraScreen /> },
      { path: ROUTERS.ONE_CLICK.PATH, element: <OneClickScreen /> },
      { path: ROUTERS.TERMINAL.PATH, element: <TerminalScreen /> },
      { path: ROUTERS.SPOTS.PATH, element: <SpotScreen /> },
      { path: ROUTERS.PLANE_NUMBER.PATH, element: <PlaneNumberScreen /> },
      { path: ROUTERS.SYSTEM_SETTINGS.PATH, element: <SystemSettingScreen /> },
      { path: ROUTERS.DASHBOARD_SECOND.PATH, element: <DasboardSecondV2Screen /> },
      { path: ROUTERS.DASHBOARD_SETTING.PATH, element: <DropDrapSetting /> },
      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

const _advanceAdminPrivateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: ROUTERS.HOME.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.DASHBOARD.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.SUBMIT_STREAMING.PATH, element: <SubmitStreamingScreen /> },
      { path: ROUTERS.DASHBOARD_SECOND.PATH, element: <DasboardSecondV2Screen /> },
      { path: ROUTERS.DASHBOARD_SETTING.PATH, element: <DropDrapSetting /> },
      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

const _generalMonitorPrivateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: ROUTERS.HOME.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.DASHBOARD.PATH, element: <DashboardScreen /> },
      { path: ROUTERS.DASHBOARD_SECOND.PATH, element: <DasboardSecondV2Screen /> },
      { path: ROUTERS.DASHBOARD_SETTING.PATH, element: <DropDrapSetting /> },
      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

const _userPrivateRouters: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: ROUTERS.SUBMIT_STREAMING.PATH, element: <SubmitStreamingScreen /> },
      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

export { _adminPrivateRoutes, _advanceAdminPrivateRoutes, _generalMonitorPrivateRoutes, _userPrivateRouters };
