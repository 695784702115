import { useFormik } from 'formik';

import { loginSchema } from './shcema';
import { Login } from 'src/interfaces/pages/login';
import useInit from '../../hooks/useInit';

const UseFormLogin = (): Login.useForm => {
  const { handleLogin } = useInit();

  const initialValues: Login.initialValues = {
    staffNum: '',
    password: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: loginSchema,
    initialValues,
    onSubmit: async value => {
      await handleLogin(value);
    },
  });

  return { formik };
};

export default UseFormLogin;
