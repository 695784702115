import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const DASHBOARD_SECOND_API = {
  getDataDashboardSecondAPI: async (params: DashboardSecondStore.ParamsVideo) => {
    const response = await new AxiosClient().get('/streams/db2', { params });

    const data = get(response, 'data', null);

    return data;
  },
};

export default DASHBOARD_SECOND_API;
