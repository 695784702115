import { useEffect, useState } from 'react';
import { swap } from 'react-grid-dnd';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/stores';
import {
  fetchDataVideoAction,
  updatePostionStreamAction,
} from 'src/stores/screens/privateScreens/dashboard/dashboard.action';
import useSafieSDK from 'src/shared/hooks/useSafieSDK';
import { LOCAL_STORAGE_KEY, ROUTERS, TYPE_SOCKET } from 'src/shared/constants';
import useRole from 'src/shared/hooks/useRole';
import socket from 'src/configs/socketIO/socketIO';

const useInitDropDrapSettings = (): any => {
  const { addThumbnail } = useSafieSDK();
  const { meInfo } = useAppSelector(state => state.auth);
  const { isGeneralUser } = useRole();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const page = localStorage.getItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST);
  const itemOfPage = localStorage.getItem(LOCAL_STORAGE_KEY.ITEM_OF_PAGE);

  const [dataCameras, setDataCameras] = useState<any>([]);
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [arrPositionStream, setArrPositionStream] = useState<any>([]);
  const [listDataDrapDrop, setListDataDrapDrop] = useState<any>([]);
  const [isShowThumbnailFirst, setIsShowThumbnailFirst] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isHasCreateStream, setIsHasCreateStream] = useState(false);
  const [indexCreateStream, setIndexCreateStream] = useState(0);

  const onChange = (sourceid: number, sourceIndex: number, targetIndex: number, targetId?: string): void => {
    const index = arrPositionStream.findIndex(item => item?.id === currentItems[sourceIndex].id);

    if (index === -1) {
      arrPositionStream.push(currentItems[sourceIndex]);
    } else {
      arrPositionStream[index] = currentItems[sourceIndex];
    }

    const nextState = swap(currentItems, sourceIndex, targetIndex);

    setListDataDrapDrop(nextState);
    setCurrentItems(nextState);
    setArrPositionStream([...arrPositionStream]);
  };

  const handleSave = async (): Promise<void> => {
    const result = listDataDrapDrop.map(item => {
      return {
        streamId: item.id,
        index:
          Number(currentItems.findIndex(item1 => item1.id === item.id)) +
          (Number(page) - 1) * Number(itemOfPage) +
          1 +
          indexCreateStream,
      };
    });

    await dispatch(
      updatePostionStreamAction({
        data: { positionList: result, page: Number(page) },
        callback: () => {
          navigate({
            pathname: ROUTERS.DASHBOARD.PATH,
          });
        },
      }),
    );
  };

  const handleCancel = async (): Promise<void> => {
    navigate({
      pathname: ROUTERS.DASHBOARD.PATH,
    });
  };

  const handShowThumbnails = (): void => {
    if (currentItems?.length === 0) return;
    if (isShowThumbnailFirst) {
      void addThumbnail(currentItems);
      setIsShowThumbnailFirst(false);
    }
  };

  const handSetDataDropDrap = (): void => {
    if (dataCameras) {
      const data = dataCameras?.map((item, index) => {
        return {
          ...item,
          index,
        };
      });

      setCurrentItems(data);
    }
  };

  const fetchDataCamera = async (): Promise<void> => {
    setIsLoading(true);
    const res = await dispatch(
      fetchDataVideoAction({
        data: { terminalId: meInfo?.terminalId, size: Number(itemOfPage), page: Number(page) },
      }),
    );

    if (res) {
      const dataCameras = res.payload?.rows;
      setDataCameras(dataCameras);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handSetDataDropDrap();
    handShowThumbnails();
  }, [dataCameras, isShowThumbnailFirst]);

  useEffect(() => {
    if (isHasCreateStream) {
      setIndexCreateStream(indexCreateStream + 1);
      setIsHasCreateStream(false);
    }
  }, [isHasCreateStream, indexCreateStream]);

  useEffect(() => {
    if (!isGeneralUser) {
      void fetchDataCamera();
    }

    socket().on(TYPE_SOCKET.CREATE_STREAM, value => {
      if (value?.id) {
        setIsHasCreateStream(true);
      }
    });
  }, []);

  return {
    isLoading,
    currentItems,
    arrPositionStream,
    onChange,
    handleSave,
    handleCancel,
  };
};

export default useInitDropDrapSettings;
