import { useState } from 'react';

import { SIZEW } from '../constants/reponsive';
import useEventListener from './useEventListener';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

interface WindowSize {
  width: number;
  height: number;
  isMobile: boolean;
  isMobileByWidth: boolean;
  isIpad: boolean;
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    isMobile: false,
    isMobileByWidth: false,
    isIpad: false,
  });

  const handleSize = (): void => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const isMobile =
      Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) ||
      window.innerWidth < SIZEW.LG;
    const isMobileByWidth = window.innerWidth < SIZEW.SM;
    const isIpad = Boolean(userAgent.match(/iPad/i));

    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile,
      isMobileByWidth,
      isIpad,
    });
  };

  useEventListener('resize', handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, []);

  return windowSize;
}
export default useWindowSize;
