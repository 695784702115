import { useEffect, useState } from 'react';
import socket from 'src/configs/socketIO/socketIO';

import { SubmitStreaming } from 'src/interfaces/pages/submitStreaming';
import { TYPE_SOCKET } from 'src/shared/constants';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { getPlaneNumerAction, getLabelsAction } from 'src/stores/screens/privateScreens/common/common.action';
import { getSpotsAction } from 'src/stores/screens/privateScreens/spots/spots.action';
import {
  alertStreamActions,
  deleteStreamActions,
  getStreamDetailActions,
} from 'src/stores/screens/privateScreens/submitStream/submitStream.action';

const UseInit = (): SubmitStreaming.UseInit => {
  const { meInfo } = useAppSelector(state => state.auth);

  const { labels, planeNumber } = useAppSelector(state => state.common);
  const { spots } = useAppSelector(state => state.spots);
  const { streamDetail, isLoading, isLoadingDetail } = useAppSelector(state => state.submitStream);

  const dispatch = useAppDispatch();

  const optionsSelectLabels = labels?.map(item => ({ label: item?.name, value: item?.id, title: '' }));
  const optionsSelectSpots = spots?.map(item => ({ label: item?.name, value: item?.id, title: '' }));
  const optionsSelectAirplane = planeNumber?.map(item => ({ label: item?.name, value: item?.id, title: '' }));

  const [alertStatus, setAlertStatus] = useState<string | null>(streamDetail?.alertStatus);

  const handleNewAlert = (value, streamDetail): void => {
    if (value?.id === streamDetail?.id) {
      setAlertStatus(value?.alertStatus);
    }
  };

  useEffect(() => {
    getStreamDetail();
  }, []);

  useEffect(() => {
    socket().on(TYPE_SOCKET.ALERT_STREAM, value => {
      handleNewAlert(value, streamDetail);
    });

    void dispatch(getLabelsAction({ data: { terminalId: meInfo?.terminalId } }));
    void dispatch(getSpotsAction({ data: { terminalId: meInfo?.terminalId } }));
    void dispatch(getPlaneNumerAction({ data: { isSort: true } }));

    return () => {
      socket().disconnect();
    };
  }, [streamDetail]);

  const getStreamDetail = (): void => {
    void dispatch(getStreamDetailActions());
  };

  const alertStream = async (id: number, typeAlert: string): Promise<void> => {
    await dispatch(
      alertStreamActions({
        id,
        typeAlert,
        callback: getStreamDetail,
      }),
    );
  };

  const deleteStream = async (id: number): Promise<void> => {
    await dispatch(deleteStreamActions({ id, callback: getStreamDetail }));
  };

  return {
    meInfo,
    optionsSelectLabels,
    optionsSelectSpots,
    optionsSelectAirplane,
    streamDetail,
    isLoading,
    isLoadingDetail,
    alertStatus,
    alertStream,
    deleteStream,
    getStreamDetail,
    setAlertStatus,
  };
};

export default UseInit;
