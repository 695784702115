import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { t } from 'i18next';

import SPOTS_API from './spots.api';
import { MESSAGE_MASTER } from 'src/shared/constants/message';

export const getSpotsAction = createAsyncThunk<any, SpotsStore.RequestData>(
  'sopts',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SPOTS_API.getSpotsAPI({
        page: data?.page,
        size: data?.size,
        terminalId: data?.terminalId,
      });
      callback();
      return { response, isSubmit: data?.isSubmit };
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      rejectWithValue(err?.message);
    }
  },
);

export const createSpotAction = createAsyncThunk<any, SpotsStore.RequestData>(
  'create_spots',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SPOTS_API.createSpotAPI(data);
      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.CREATE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      return rejectWithValue(err?.message);
    }
  },
);

export const updateSpotAction = createAsyncThunk<any, SpotsStore.RequestData>(
  'update_spots',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SPOTS_API.updateSpotsAPI(data);
      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.EDIT_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      return rejectWithValue(err?.message);
    }
  },
);

export const deleteSpotActions = createAsyncThunk<any, SpotsStore.RequestDeleteSpots>(
  'delete_spots',
  async ({ id, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const response = await SPOTS_API.deleteSpotAPI(id);
      callback();

      notification.success({ message: MESSAGE_MASTER.COMMON.DELETE_SUCCESSFULLY });

      return response;
    } catch (err: any) {
      notification.error({ message: t(`errorMsg.${err?.code || 0}`) });

      return rejectWithValue(err?.message);
    }
  },
);
