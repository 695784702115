import { Row } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'src/shared/constants';

export const WrapperStyled = styled(Row)`
  padding: 20px 40px;
  height: 100%;
  justify-content: space-between;

  .col-form {
    background-color: ${COLOR.BACKGROUND_SECONDARY};
    padding: 30px;
    border-radius: 10px;
    height: fit-content;
    width: calc(100% / 2 - 16px);

    .row-title {
      border-bottom: 1px solid #383840;
      margin-bottom: 30px;

      .title {
        color: ${COLOR.WHITE};
        margin-bottom: 30px;
      }
    }

    .row-field-group {
      margin-bottom: 10px;

      .col-text-first {
        margin-bottom: 5px;

        .text-field-group {
          font-size: 15px;
        }
      }

      .text-field-group {
        font-weight: 700;
        font-size: 14px;
        color: ${COLOR.WHITE};
      }
    }

    .row-field {
      margin-top: 25px;
      padding: 0 50px;

      .text-field {
        color: ${COLOR.WHITE};
      }
    }

    .text-auth-key {
      font-weight: 400;
      font-size: 14px;
      color: #adaec1;
    }

    .one-click-field {
      &::after {
        content: 'アクション';
        position: absolute;
        top: -25px;
        color: #adaec1;
      }
    }

    .row-api-key {
      padding-top: 30px;

      .title {
        margin-bottom: 10px;
      }

      .text {
        color: ${COLOR.WHITE};
        font-size: 14px;
        font-weight: 700;
      }

      .input-key {
        margin-top: 20px;
      }
    }

    .row-btn {
      padding-top: 30px;
      border-top: 1px solid #383840;
      margin-top: 6px;

      .col-btn {
        display: flex;
        justify-content: center;

        .ant-btn {
          min-width: 120px;
          padding: 12px 20px;
          height: 48px;
        }
      }
    }

    .col-btn-first {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      .ant-btn {
        min-width: 120px;
        padding: 12px 20px;
        height: 48px;
      }
    }
  }
`;
