/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { Col, Typography } from 'antd';
import Icon from '@ant-design/icons';

import useInit from './hooks/useInit';
import { ModeStandard, Spinning } from 'src/shared/components';
import useCheckStatusAlert from 'src/shared/hooks/useCheckStatusAlert';
import { LogoV2Icon, LogoV2LightIcon } from 'src/assets/icons';
import {
  LOCAL_STORAGE_KEY,
  MAX_CARD_DISPLAY,
  THEME_TYPE,
  TYPE_DISPLAY_SCREEN,
  TYPE_SOCKET,
} from 'src/shared/constants';
import socket from 'src/configs/socketIO/socketIO';
import { DashboardSecondStyled } from './styled';

interface DashboardSecondProps {
  isDashboardList?: boolean;
  startIndex?: number;
  endIndex?: number;
  isMobileListScreen?: boolean;
}

const { Text } = Typography;

const styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const DashboardSecondV2: React.FC<DashboardSecondProps> = ({
  isDashboardList,
  startIndex,
  endIndex,
  isMobileListScreen,
}) => {
  const dataSettingMonitorStorage = localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR);
  const isPlayPauseStorage = JSON?.parse(dataSettingMonitorStorage ?? '')?.isPlayPause;
  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);

  const {
    dataDashboardSecond,
    isLoading,
    dataSetting,
    totalPageSwitch,
    terminalId,
    meInfo,
    totalPin,
    newTypeAlert,
    newAlert,
    onCloseZoom,
    onClickPin,
    onClickAlert,
    fetchDataDashboardSecond,
    setNewAlert,
  } = useInit();

  const { checkStatus } = useCheckStatusAlert();

  const [dataSettingMonitor, setDataSettingMonitor] = useState(JSON?.parse(dataSettingMonitorStorage ?? 'null'));
  const [isPlayPause, setIsPlayPause] = useState(isPlayPauseStorage);
  const [isZoomFull, setIsZoomFull] = useState(false);
  const [typeThemeState, setTypeThemeState] = useState(typeTheme);
  const [pageSwitch, setPageSwitch] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  const renderListItem = newAlert?.slice(startIndex ?? 0, endIndex ?? 2)?.map((item, index) => {
    const status = checkStatus(item?.id === newTypeAlert?.idStream ? newTypeAlert?.alertStatus : item?.alertStatus);

    return (
      <div key={index} className="col-item col-item-alert">
        <ModeStandard
          isPin={item?.isPin}
          id={item?.id}
          isShowMuted={true}
          className="video-detail "
          typeAlert={status}
          onClickAlert={() => {
            onClickAlert(item, status);
          }}
          title={item?.label?.name}
          terminalName={item?.terminal?.name}
          spotName={item?.spot?.name}
          airplaneName={item?.airplane?.name}
          flightNum={item?.flightNum}
          firstVideo={item?.label?.cameraList[0]}
          secondVideo={item?.label?.cameraList[1]}
          cameraList={dataDashboardSecond}
          onCloseZoom={() => {
            onCloseZoom(item);
          }}
          dataVideo={dataDashboardSecond}
          onClickIsPin={() => {
            onClickPin(item);
          }}
          setIsZoomFull={setIsZoomFull}
          onClickAlertModeZoomFull={() => {
            onClickAlert(item, status, true);
          }}
        />
      </div>
    );
  });

  const handleSettingChange = (value: any): void => {
    const convertString = JSON.stringify({ ...value });

    localStorage.setItem(LOCAL_STORAGE_KEY.SETTING_MONITOR, convertString);

    setDataSettingMonitor(value);
  };

  const handleSwitchPlayPause = (value: any): void => {
    const isPlayPause = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '')?.isPlayPause;

    if (value?.id === meInfo?.id) {
      setIsPlayPause(isPlayPause);
    }
  };

  useEffect(() => {
    const quantityItemSwitch = dataDashboardSecond?.slice(0, 4).filter(item => !item.isPin);

    const TIME_OUT_CHANGE_PAGE = Number(dataSetting?.switchZoomSecond) * 1000;

    let intervalSwitch: any;

    const dataSettingMonitorStorage = JSON?.parse(localStorage.getItem(LOCAL_STORAGE_KEY.SETTING_MONITOR) ?? '');

    if (dataSettingMonitorStorage?.isSwitchZoom) {
      if (dataSettingMonitorStorage?.isPlayPause) {
        if (!isZoomFull) {
          intervalSwitch = setInterval(() => {
            if (quantityItemSwitch?.length > 0) {
              setPageSwitch(Number(pageSwitch) + 1);
              setInitialLoad(false);
            }
          }, TIME_OUT_CHANGE_PAGE);
        }
      }
    }

    return () => {
      clearInterval(intervalSwitch);
    };
  }, [dataSetting, dataSettingMonitor, dataDashboardSecond, isZoomFull, isPlayPause]);

  useEffect(() => {
    const quantityItemSwitch = dataDashboardSecond.filter(item => !item.isPin);

    const totalPageHasSwitch = totalPageSwitch / quantityItemSwitch?.length;

    const getListID = quantityItemSwitch?.length > 0 ? JSON.stringify(quantityItemSwitch?.map(item => item?.id)) : '[]';

    if (!initialLoad) {
      if (pageSwitch < totalPageHasSwitch) {
        handleSwitchItem(quantityItemSwitch?.length, getListID, pageSwitch, () => {});
      } else {
        handleSwitchItem(quantityItemSwitch?.length, getListID, 1, () => {
          setPageSwitch(0);
        });
      }
    }
  }, [dataDashboardSecond, pageSwitch, initialLoad, dataSettingMonitor]);

  useEffect(() => {
    setInitialLoad(true);
  }, [dataDashboardSecond]);

  const handleSwitchItem = (
    quantityItemSwitch: number,
    getListId: string,
    newPageStorage: number,
    callback: () => void,
  ): void => {
    setNewAlert(dataDashboardSecond);

    void fetchDataDashboardSecond(
      {
        page: pageSwitch,
        size: quantityItemSwitch === 0 ? null : quantityItemSwitch,
        terminalId: terminalId ?? meInfo?.terminalId,
        listOldItem: getListId,
      },
      () => {
        setInitialLoad(true);

        callback();
      },
    );

    localStorage.setItem(
      LOCAL_STORAGE_KEY.SIZE_PAGE_DASHBOARD2_SWITCH,
      JSON.stringify({ page: newPageStorage, size: quantityItemSwitch }),
    );
  };

  const checkTypeScreenDisplay = (): void => {
    const TYPE_SCREEN_DISPLAY = localStorage.getItem(LOCAL_STORAGE_KEY.TYPE_DISPLAY_SCREEN);

    if (TYPE_SCREEN_DISPLAY === TYPE_DISPLAY_SCREEN.ONE_SCREEN) {
      window.close();
    }
  };

  useEffect(() => {
    changeTheme();
    socket().on(TYPE_SOCKET.SETTING_CHANGE, value => {
      handleSettingChange(value);
    });

    socket().on(TYPE_SOCKET.SWITCH_PLAY_PAUSE, value => {
      handleSwitchPlayPause(value);
    });

    socket().on(TYPE_SOCKET.MONITOR_CLOSE, value => {
      if (value?.id === meInfo?.id) {
        checkTypeScreenDisplay();
      }
    });

    socket().on(TYPE_SOCKET.CHANGE_THEME, value => {
      if (value?.id === meInfo?.id) {
        changeTheme();
      }
    });

    const handleStorageChange = (event): void => {
      if (event.storageArea.is_logout === 'true') {
        location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const changeTheme = (): void => {
    const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);
    setTypeThemeState(typeTheme);

    if (typeTheme === THEME_TYPE.LIGHT) {
      document.body.classList.add('light-theme');
      styleSheetLink.href = '/css/light_theme.css';
    } else if (document.body.classList.contains('light-theme')) {
      document.body.classList.remove('light-theme');
      styleSheetLink.href = '';
    }
  };

  return (
    <React.Fragment>
      <Text className="quantity-wait-diplay">
        {totalPin > MAX_CARD_DISPLAY &&
          !isDashboardList &&
          `表示されていない通知が
          ${totalPin - MAX_CARD_DISPLAY}
          個あります。表示するためには、現在表示されている通知をクローズしてください。`}
      </Text>

      {isLoading ? (
        <Spinning isLoading={isLoading} />
      ) : isDashboardList ? (
        renderListItem
      ) : (
        <DashboardSecondStyled
          className={'dashboard-second-screen'}
          style={{ backgroundColor: `${typeTheme === THEME_TYPE.DARK ? '#0f1013' : '#f5f5f5'}` }}
          isShowTotalPin={totalPin > MAX_CARD_DISPLAY}
        >
          <div className="list-alert">
            {dataDashboardSecond?.length > 0 ? (
              <div className="video-list">{renderListItem}</div>
            ) : (
              <Col span={24} className="col-icon">
                <Icon component={typeThemeState === THEME_TYPE.DARK ? LogoV2Icon : LogoV2LightIcon} />
              </Col>
            )}
          </div>
        </DashboardSecondStyled>
      )}
    </React.Fragment>
  );
};

export default DashboardSecondV2;
