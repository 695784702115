import * as yup from 'yup';

import { ONLY_ALLOW_ENTER_CHARACTERS_NUMBER, NOT_ALLOW_ENTER_CHARACTERS_ALPHABET_FULLSIZE } from 'src/shared/constants';

export const shcemaManeuverSetting = yup.object().shape({
  switchListSecond: yup
    .string()
    .trim()
    .matches(ONLY_ALLOW_ENTER_CHARACTERS_NUMBER, '0以上の半角数字で入力してください')
    .matches(NOT_ALLOW_ENTER_CHARACTERS_ALPHABET_FULLSIZE, '0以上の半角数字で入力してください')
    .nullable()
    .required('切替の秒数を入力してください'),
  switchZoomSecond: yup
    .string()
    .trim()
    .matches(ONLY_ALLOW_ENTER_CHARACTERS_NUMBER, '0以上の半角数字で入力してください')
    .matches(NOT_ALLOW_ENTER_CHARACTERS_ALPHABET_FULLSIZE, '0以上の半角数字で入力してください')
    .nullable()
    .required('切替の秒数を入力してください'),
});
