import React from 'react';

const DownIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 14V4.80645C13 4.32258 12.6 4 12 4C11.4 4 11 4.32258 11 4.80645V14H13Z" fill="white" />
      <path
        d="M5.96561 14.8712C5.62561 14.5645 5.84262 14 6.30056 14H17.6994C18.1574 14 18.3744 14.5645 18.0344 14.8712L12.6125 19.7632C12.2625 20.0789 11.7375 20.0789 11.3875 19.7632L5.96561 14.8712Z"
        fill="white"
      />
    </svg>
  );
};

export default DownIcon;
