import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const DASHBOARD_API = {
  getKeySafieAPI: async (terminalId: number) => {
    const response = await new AxiosClient().get(`/streams/key/${terminalId}`);

    const data = get(response, 'data', null);

    return data;
  },

  settingKeySafieAPI: async (params: SettingStore.keySafie) => {
    const response = await new AxiosClient().post('streams/setting-key', { value: params?.key });

    const data = get(response, 'message', null);

    return data;
  },

  settingSoracomAPI: async (params: SettingStore.DataSettingSoracomTerminal) => {
    const getParams = {
      oneClickAction: params.oneClickAction,
      doubleClickAction: params.doubleClickAction,
      longClickAction: params.longClickAction,
      authKey: params.authKey,
      authKeyId: params.authKeyId,
    };

    const response = await new AxiosClient().patch(`/terminals/${params.terminalId}/setting-button`, getParams);

    const data = get(response, 'message', null);

    return data;
  },

  settingSafieAPI: async (params: SettingStore.DataSettingSafieTerminal) => {
    const getParams = {
      key: params.key,
    };

    const response = await new AxiosClient().patch(`/terminals/${params.terminalId}/setting-camera`, getParams);

    const data = get(response, 'message', null);

    return data;
  },

  getSettingSoracomSystemAPI: async id => {
    const response = await new AxiosClient().get(`/terminals/${id}/setting-button`);

    const data = get(response, 'data', null);

    return data;
  },

  getSettingSafieSystemAPI: async id => {
    const response = await new AxiosClient().get(`/terminals/${id}/setting-camera`);

    const data = get(response, 'data', null);

    return data;
  },
};

export default DASHBOARD_API;
