import { Row } from 'antd';
import styled, { css } from 'styled-components';

import { COLOR } from 'src/shared/constants';
import ResponsiveUI, { SIZEH, SIZEW } from 'src/shared/constants/reponsive';

const reponsiveWrapperStyled = css`
  ${ResponsiveUI.lessThan(1920)} {
    width: 1920px;
    height: 1080px;

    .list-alert {
      .col-item {
        min-height: calc(1080px - 170px);
        min-width: calc(1920px / 2 - 200px);
      }
    }
  }

  ${ResponsiveUI.lessThan(1471)} {
    width: 1470px;
    height: calc(956px - 44px);

    .list-alert {
      .col-item {
        min-height: calc(956px - 170px);
        min-width: calc(1470px / 2 - 100px);
      }
    }
  }

  ${ResponsiveUI.lessThanH(SIZEH.PAD2)} {
    ${ResponsiveUI.lessThan(SIZEW.XL1)} {
      .row-header {
        .col-info {
          .title {
            padding: 6px 14px !important;
          }
        }
      }
    }
  }
`;

export const DashboardSecondStyled = styled(Row)<{ isShowTotalPin: boolean }>`
  height: 100%;
  position: relative;
  background-color: ${COLOR.BACKGROUND_PRIMARY};

  .list-alert {
    display: flex;
    overflow-x: auto;
    width: 100%;
    height: 100%;

    .video-list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      padding: 20px;
    }

    .col-item {
      display: flex;
      justify-content: center;
      min-height: calc(100vh - 150px);
      min-width: ${props => `calc(100vw / 2 - ${props.isShowTotalPin ? '180px' : '150px'})`};
    }
  }

  .col-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinning {
    height: calc(100vh - 44px);
  }

  ${reponsiveWrapperStyled}
`;
