import React from 'react';
import { Row, Col, Typography } from 'antd';
import Icon from '@ant-design/icons';

import { InputField, ButtonField, PasswordField } from 'src/shared/components/form';
import UseFormLogin from './hooks/useForm';
import useWindowSize from 'src/shared/hooks/useWindowSize';
import { LogoIcon, LogoTextBlackIcon, LogoTextIcon } from 'src/assets/icons';
import { THEME_TYPE } from 'src/shared/constants';
import { LoginScreenStyle } from './styled';

const { Title, Text } = Typography;

const LoginScreen: React.FC = () => {
  const { formik } = UseFormLogin();
  const { isMobile } = useWindowSize();

  const { setFieldValue, handleSubmit, getFieldProps, errors, touched } = formik;

  const typeTheme = localStorage.getItem(THEME_TYPE.TYPE);

  return (
    <LoginScreenStyle className="wrapper_screen-login wrapper_screen-auth">
      <Row className="row-wrap">
        {isMobile && (
          <Col span={24} className="header-mobile">
            <Icon component={LogoIcon} className="logo" />
            <Icon component={typeTheme === THEME_TYPE.LIGHT ? LogoTextBlackIcon : LogoTextIcon} />
          </Col>
        )}
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} className="col-left">
          <Row className="row-form">
            <Col span={24}>
              <Title className="login-title">ログイン</Title>
            </Col>
            <Col span={24}>
              <div className="input-group">
                <Text className="input-label required-field">社員番号</Text>
                <InputField
                  field={getFieldProps('staffNum')}
                  setFieldValue={setFieldValue}
                  className="form-control"
                  error={errors.staffNum}
                  touched={touched.staffNum}
                  autoComplete="off"
                />
              </div>
              <div className="input-group">
                <Text className="input-label required-field">パスワード</Text>
                <PasswordField
                  field={getFieldProps('password')}
                  className="form-control"
                  error={errors.password}
                  touched={touched.password}
                />
              </div>
            </Col>
            <Col span={24} className="btn-submit">
              <ButtonField onClick={handleSubmit} type="primary" content="ログイン" color="#3D7EA3" />
            </Col>
          </Row>
          <Text className="text-footer font-helvetica">HANDLING MONITOR CAMERA SYSTEM</Text>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={0} sm={0} xs={0} className="row-right" />
      </Row>
    </LoginScreenStyle>
  );
};

export default LoginScreen;
