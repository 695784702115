import React from 'react';

const UpIconV2: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 9.6001V21.0001C13 21.6001 12.6 22.0001 12 22.0001C11.4 22.0001 11 21.6001 11 21.0001V9.6001H13Z"
        fill="#48BB47"
      />
      <path opacity="0.3" d="M4 9.60002H20L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L4 9.60002Z" fill="#48BB47" />
    </svg>
  );
};

export default UpIconV2;
