import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import Icon from '@ant-design/icons';

import { NoCamera } from 'src/assets/images';
import { ALERT_STATUS } from 'src/shared/constants';
import { CloseAlertIcon, MutedIcon } from 'src/assets/icons';

const { Text } = Typography;

interface IEighteenCard {
  item: DashboardStore.DataVideo;
  status: string;
  isDrap?: boolean;
  clickZoom: (id: number, isZoom: boolean, alertStatus: string) => void;
  showConfirm: (id: number, isZoom: boolean, typeAlert?: string) => void;
}

const EighteenCard: React.FC<IEighteenCard> = ({ item, status, isDrap, clickZoom, showConfirm }) => {
  const headerCard = (item: DashboardStore.DataVideo): React.ReactNode => (
    <Col className="col-title-type-18" span={24}>
      <div className="text-group">
        <Text className="name">スポット</Text>
        <Text className="value font-helvetica">{item?.spot?.name}</Text>
      </div>
      <div className="text-group">
        <Text className="name">便名</Text>
        <Text className="value font-helvetica">JL{item?.flightNum}</Text>
      </div>
      <div className="text-group">
        <Text className="name">機番</Text>
        <Text className="value font-helvetica">{item?.airplane?.name}</Text>
      </div>
    </Col>
  );

  return (
    <Card className="card-type-18">
      <Row>
        <Col span={18}>
          <Row
            className="row-video"
            onClick={() => {
              clickZoom(item?.id, item?.isZoom, item?.alertStatus);
            }}
          >
            <Col span={24} className="col-video">
              <img
                data-name="player"
                className="streaming-player streaming-img"
                id={`video-${String(item?.id)}-1`}
                src={NoCamera}
              />
            </Col>
          </Row>

          <Row
            className="row-video"
            onClick={() => {
              clickZoom(item?.id, item?.isZoom, item?.alertStatus);
            }}
          >
            <Col span={24} className="col-video">
              <img
                data-name="player"
                className="streaming-player streaming-img"
                id={`video-${String(item?.id)}-2`}
                src={NoCamera}
              />
            </Col>
          </Row>
        </Col>

        <Col span={6}>{headerCard(item)}</Col>

        <Col span={24} className="row-footer font-helvetica">
          <Text>{item?.label?.name}</Text>
          {!isDrap && (
            <>
              {(item?.alertStatus === ALERT_STATUS.ALERT || item?.alertStatus === ALERT_STATUS.ALERT_MUTED) && (
                <Icon
                  component={item?.alertStatus === ALERT_STATUS.ALERT ? MutedIcon : CloseAlertIcon}
                  onClick={() => showConfirm(item?.id, item?.isZoom, status)}
                />
              )}

              {item?.alertStatus === ALERT_STATUS.NORMAL && (
                <span className="card-stream-status" id={`card-stream-status-${item?.id}`}></span>
              )}
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EighteenCard;
