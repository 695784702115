/* eslint-disable multiline-ternary */
import { Card, Col, List, Row, Typography } from 'antd';
import React from 'react';
import Icon from '@ant-design/icons';

import EighteenCard from './EighteenCard';
import { ALERT_STATUS, LOCAL_STORAGE_KEY } from 'src/shared/constants';
import { NoCamera } from 'src/assets/images';
import { CloseAlertIcon, MutedIcon } from 'src/assets/icons';
import useCheckStatusAlert from 'src/shared/hooks/useCheckStatusAlert';
import { useAppSelector } from 'src/stores';

interface ListItemProps {
  mergeVideo: DashboardStore.DataVideo[];
  totalItem: number;
  itemOfPage: string | null;
  currentPage: number;
  fetchDataVideo: (value: DashboardStore.ParamsVideo) => Promise<void>;
  order: string | null;
  search: string | null;
  terminalId: string | null;
  clickZoom: (id: number, isZoom: boolean, alertStatus: string) => void;
  showConfirm: (id: number, isZoom: boolean, typeAlert?: string) => void;
  headerCard: (item: any) => React.ReactNode;
}

const { Text } = Typography;

const TenCard: React.FC<ListItemProps> = ({
  mergeVideo,
  totalItem,
  currentPage,
  itemOfPage,
  fetchDataVideo,
  order,
  search,
  terminalId,
  clickZoom,
  headerCard,
  showConfirm,
}) => {
  const { checkStatus } = useCheckStatusAlert();
  const { meInfo } = useAppSelector(state => state.auth);

  return (
    <List
      locale={{
        emptyText: <React.Fragment />,
      }}
      grid={{
        gutter: 12,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 5,
      }}
      pagination={
        mergeVideo?.length > 0
          ? {
              pageSize: Number(itemOfPage),
              total: totalItem,
              current: Number(currentPage),
              onChange: page => {
                const searchStorage = localStorage.getItem(LOCAL_STORAGE_KEY.SEARCH);
                const orderStorage = localStorage.getItem(LOCAL_STORAGE_KEY.ORDER);

                localStorage.setItem(LOCAL_STORAGE_KEY.PAGE_DASHBOARD_LIST, `${page}`);

                void fetchDataVideo({
                  size: Number(itemOfPage),
                  page,
                  order: orderStorage ?? order,
                  search: searchStorage ?? search,
                  terminalId: terminalId ?? meInfo?.terminalId,
                });
              },
            }
          : false
      }
      dataSource={mergeVideo}
      renderItem={item => {
        const status = checkStatus(item?.alertStatus);

        return (
          <List.Item className={`${status}`}>
            {Number(itemOfPage) === 18 ? (
              <EighteenCard clickZoom={clickZoom} item={item} showConfirm={showConfirm} status={status} />
            ) : (
              <Card title={headerCard(item)}>
                <Row
                  className="row-video"
                  onClick={() => {
                    clickZoom(item?.id, item?.isZoom, item?.alertStatus);
                  }}
                >
                  <Col span={24} className="col-video">
                    <img
                      data-name="player"
                      className="streaming-player streaming-img"
                      id={`video-${String(item?.id)}-1`}
                      src={NoCamera}
                    />
                  </Col>
                </Row>

                <Row
                  className="row-video"
                  onClick={() => {
                    clickZoom(item?.id, item?.isZoom, item?.alertStatus);
                  }}
                >
                  <Col span={24} className="col-video">
                    <img
                      data-name="player"
                      className="streaming-player streaming-img"
                      id={`video-${String(item?.id)}-2`}
                      src={NoCamera}
                    />
                  </Col>
                </Row>

                <Row className="row-footer font-helvetica">
                  <Text>{item?.label?.name}</Text>

                  {(item?.alertStatus === ALERT_STATUS.ALERT || item?.alertStatus === ALERT_STATUS.ALERT_MUTED) && (
                    <Icon
                      component={item?.alertStatus === ALERT_STATUS.ALERT ? MutedIcon : CloseAlertIcon}
                      onClick={() => showConfirm(item?.id, item?.isZoom, status)}
                    />
                  )}

                  {item?.alertStatus === ALERT_STATUS.NORMAL && (
                    <span className="card-stream-status" id={`card-stream-status-${item?.id}`}></span>
                  )}
                </Row>
              </Card>
            )}
          </List.Item>
        );
      }}
    />
  );
};

export default TenCard;
