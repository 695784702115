import { Content } from 'antd/lib/layout/layout';
import styled, { css } from 'styled-components';

import { COLOR, SIZEW } from 'src/shared/constants';
import { BackgroundLogin } from 'src/assets/images';
import ResponsiveUI from 'src/shared/constants/reponsive';

const reponsiveLoginScreenStyle = css`
  ${ResponsiveUI.lessThan(SIZEW.SM)} {
    .col-left {
      padding: 0 40px;
    }

    .text-footer {
      font-size: 16px;
    }
  }
`;

export const LoginScreenStyle = styled(Content)`
  .row-wrap {
    height: 100%;

    .header-mobile {
      height: 102px;
      background-color: #15171c;
      position: absolute;
      width: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        margin-right: 15px;

        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .col-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 45px;
  }

  .col-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR.BACKGROUND_PRIMARY};
    padding: 0 20px;
  }

  .row-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${BackgroundLogin});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo-icon svg {
    width: 314px;
    height: 314px;
    flex-shrink: 0;
  }

  .row-form {
    width: 380px;
  }

  .login-title {
    color: ${COLOR.WHITE};
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -0.52px;
  }

  .input-group {
    margin-bottom: 15px;

    .ant-form-item {
      margin: 0;
      width: 100%;
    }

    .input-label {
      display: inline-block;
      color: #adaec1;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .ant-input {
      height: 54px;
    }

    .ant-input-password {
      height: 54px !important;

      .ant-input {
        height: 100%;
      }
    }
  }

  .btn-submit {
    text-align: center;

    button {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-radius: 5px;
      background: ${COLOR.BLUE};
      color: ${COLOR.WHITE};
      font-size: 16px;
      font-weight: 700;
    }
  }

  .text-footer {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: center;
    color: #60646e;
    font-size: 24px;
    font-weight: 700;
  }

  ${reponsiveLoginScreenStyle}
`;
