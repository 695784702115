import * as yup from 'yup';

import { FOUR_NUMBER_CHARACTERS } from 'src/shared/constants';
import { MESSAGE_VALIDATE } from 'src/shared/constants/message';

export const submitStreamSchema = yup.object().shape({
  flightNum: yup
    .string()
    .nullable()
    .matches(FOUR_NUMBER_CHARACTERS, MESSAGE_VALIDATE.FLIGHT_NUM_REQUIRED_4_CHARACTERS_NUMBER)
    .required(MESSAGE_VALIDATE.FLIGHT_NUM_IS_REQUIRED),

  labelId: yup.string().required(MESSAGE_VALIDATE.LABEL_IS_REQUIRED).nullable(),
  spotId: yup.string().required(MESSAGE_VALIDATE.SPOT_IS_REQUIRED).nullable(),
  airplaneId: yup.string().required(MESSAGE_VALIDATE.PLANE_NUM_IS_REQUIRED).nullable(),
});
