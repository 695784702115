import { createSlice } from '@reduxjs/toolkit';

import { deleteStreamActions, getStreamDetailActions, submitStreamAction } from './submitStream.action';

const initialState: SubmitStreamingStore.SubmitStreamReducer = {
  isLoading: false,
  isLoadingDetail: false,
  error: null,
  streamDetail: [],
};

const { actions, reducer } = createSlice({
  name: 'submit_stream_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(submitStreamAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitStreamAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(submitStreamAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getStreamDetailActions.pending, (state, action) => {
        state.isLoadingDetail = true;
      })
      .addCase(getStreamDetailActions.fulfilled, (state, action) => {
        state.isLoadingDetail = false;
        state.streamDetail = action.payload;
      })
      .addCase(getStreamDetailActions.rejected, (state, action) => {
        state.isLoadingDetail = false;
        state.error = action.error;
      })

      .addCase(deleteStreamActions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteStreamActions.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteStreamActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
