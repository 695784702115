import { get } from 'lodash';

import { AxiosClient } from 'src/configs/axios/axios';

const COMMON_API = {
  getLabelsAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/labels', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getSpotsAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/spots', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getAirplaneAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/airplanes', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getCameraAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/cameras', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getOneClickAPI: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/buttons', { params });

    const data = get(response, 'data', null);

    return data;
  },

  getDataVideoZoom: async (params: CommonStore.ParamsGetList) => {
    const response = await new AxiosClient().get('/stream-users', { params });

    const data = get(response, 'data', null);

    return data;
  },

  updateStatusVideoZoom: async (params: CommonStore.RequestUpdateStatusVideoZoom) => {
    const getParams = { isZoom: params.isZoom, isZoomFull: params?.isZoomFull };

    const response = await new AxiosClient().patch(`/stream-users/${params.id}/zoom`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  updateStatusIsPin: async (params: CommonStore.RequestUpdateStatusIsPin) => {
    const getParams = { isPin: params.isPin };

    const response = await new AxiosClient().patch(`/stream-users/${params.id}/pin`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  updateStatusCamera: async (params: CommonStore.RequestUpdateStatusCamera) => {
    const getParams = { status: params.status };

    const response = await new AxiosClient().patch(`/cameras/${params.id}/status`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  createCameraAPI: async (params: CommonStore.InitValueCreateCamera) => {
    const response = await new AxiosClient().post('/cameras', params);

    const data = get(response, 'data', null);

    return data;
  },

  updateCameraAPI: async (params: CommonStore.InitValueUpdateCamera) => {
    const getParams = {
      serialNum: params.serialNum,
      type: params.type,
    };

    const response = await new AxiosClient().patch(`/cameras/${params?.id}`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  deleteCameraAPI: async (params: CommonStore.InitValueDeleteCamera) => {
    const response = await new AxiosClient().delete(`/cameras/${params?.id}`);

    const data = get(response, 'data', null);

    return data;
  },

  createButtonAPI: async (params: CommonStore.InitValueCreateButton) => {
    const response = await new AxiosClient().post('/buttons', params);

    const data = get(response, 'data', null);

    return data;
  },

  updateButtonAPI: async (params: CommonStore.InitValueUpdateButton) => {
    const getParams = {
      terminalId: params.terminalId,
      serialNum: params.serialNum,
    };

    const response = await new AxiosClient().patch(`/buttons/${params?.id}`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  deleteButtonAPI: async (params: CommonStore.InitValueDeleteCamera) => {
    const response = await new AxiosClient().delete(`/buttons/${params?.id}`);

    const data = get(response, 'data', null);

    return data;
  },

  createAirplaneAPI: async (params: CommonStore.InitValueCreateAirPlane) => {
    const response = await new AxiosClient().post('/airplanes', params);

    const data = get(response, 'data', null);

    return data;
  },

  updateAirplaneAPI: async (params: CommonStore.InitValueUpdateAirplane) => {
    const getParams = {
      name: params.name,
    };

    const response = await new AxiosClient().patch(`/airplanes/${params?.id}`, getParams);

    const data = get(response, 'data', null);

    return data;
  },

  deleteAirplaneAPI: async (params: CommonStore.InitValueDeleteAirplane) => {
    const response = await new AxiosClient().delete(`/airplanes/${params?.id}`);

    const data = get(response, 'data', null);

    return data;
  },
};

export default COMMON_API;
