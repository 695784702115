import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { CloseIconV3 } from 'src/assets/icons';
import { InputField, ButtonField } from 'src/shared/components/form';
import Modal from 'src/shared/components/modal/Modal';
import useForm from './hooks/useForm';
import { MAX_LENGTH } from 'src/shared/constants/validate';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { ModalContentWrap } from './styled';

interface Props {
  isOpen: boolean;
  dataEdit: DashboardStore.Airplane | null;
  setIsShowModal: (param: boolean) => void;
}

const { Text } = Typography;

const ModalForm: React.FC<Props> = ({ isOpen, dataEdit, setIsShowModal }) => {
  const { formik, setIsUpdate, setIdUpdate } = useForm({ setIsShowModal });

  const { errors, touched, setFieldValue, getFieldProps, handleSubmit, resetForm } = formik;

  const handlecancel = (): void => {
    setIsShowModal(false);
    resetForm();
  };

  useEffect(() => {
    if (dataEdit) {
      setIsUpdate(true);
      setIdUpdate(dataEdit?.id);
      setFieldValue('name', dataEdit.name);
    } else {
      setIsUpdate(false);
    }
  }, [dataEdit]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={dataEdit ? '機番情報を更新する' : '機番情報を登録する'}
      width={494}
      iconClose={CloseIconV3}
      handleCancel={handlecancel}
      className="modal-create-camera modal-custom-antd modal-set-divice"
      footer={
        <>
          <ButtonField content="閉じる" type="default" onClick={handlecancel} />
          <ButtonField
            content={dataEdit ? MESSAGE_POPUP.EDIT : MESSAGE_POPUP.CREATE}
            type="primary"
            onClick={handleSubmit}
          />
        </>
      }
      content={
        <ModalContentWrap>
          <Col span={24} className="group-field">
            <Row>
              <Col span={24} className="field-title">
                <Text>
                  機番：<span className="required">*</span>
                </Text>
              </Col>
              <Col span={24} className="field-list">
                <div>
                  <InputField
                    setFieldValue={setFieldValue}
                    field={getFieldProps('name')}
                    error={errors.name}
                    touched={touched.name}
                    maxLength={MAX_LENGTH}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </ModalContentWrap>
      }
    />
  );
};

export default ModalForm;
