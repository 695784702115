import { createSlice } from '@reduxjs/toolkit';
import { getListUserAction, updateParamSearchUserActions } from './userManagement.action';

const initialState: UserManagementStore.UserManagementReducer = {
  isLoading: false,
  error: null,
  users: [],
  totalPage: 1,
  terminalId: '',
  roleId: '',
  searchUser: '',
};

const { actions, reducer } = createSlice({
  name: 'user_mangement_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListUserAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload?.rows;
        state.totalPage = action.payload?.total;
      })
      .addCase(getListUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updateParamSearchUserActions.fulfilled, (state, action) => {
        state.terminalId = action.payload?.terminalId;
        state.roleId = action.payload?.roleId;
        state.searchUser = action.payload?.searchUser;
      });
  },
});

export { reducer };
export default actions;
