import React from 'react';

const SearchIcon: React.FC = () => {
  return (
    <svg width={11} height={12} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M6.86189 8.80473C6.60154 8.54438 6.60154 8.12227 6.86189 7.86192C7.12224 7.60157 7.54435 7.60157 7.8047 7.86192L10.4714 10.5286C10.7317 10.7889 10.7317 11.211 10.4714 11.4714C10.211 11.7317 9.7889 11.7317 9.52855 11.4714L6.86189 8.80473Z"
        fill="#7E8299"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.99998C0 7.57731 2.08934 9.66665 4.66667 9.66665C7.244 9.66665 9.33333 7.57731 9.33333 4.99998C9.33333 2.42265 7.244 0.333313 4.66667 0.333313C2.08934 0.333313 0 2.42265 0 4.99998ZM7.99998 4.99998C7.99998 6.84093 6.50759 8.33332 4.66664 8.33332C2.82569 8.33332 1.33331 6.84093 1.33331 4.99998C1.33331 3.15903 2.82569 1.66665 4.66664 1.66665C6.50759 1.66665 7.99998 3.15903 7.99998 4.99998Z"
        fill="#7E8299"
      />
    </svg>
  );
};

export default SearchIcon;
