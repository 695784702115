import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';
import { DefaultOptionType } from 'antd/lib/select';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { deleteSpotActions, getSpotsAction } from 'src/stores/screens/privateScreens/spots/spots.action';
import { Spots } from 'src/interfaces/pages/spots';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';

const { confirm } = Modal;

function useInit(): Spots.UseInitSpots {
  const dispatch = useAppDispatch();

  const [spotSelected, setSpotSelected] = useState(null);
  const [terminalId, setTerminalId] = useState<number | null>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const { spots, totalPageSpots, isUpdated, isLoadingSpots: isLoading } = useAppSelector(state => state.spots);
  const { terminals } = useAppSelector(state => state.terminals);
  const { meInfo } = useAppSelector(state => state.auth);

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: '110px',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: 'スポット名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'アクション',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon component={PencilEditIcon} onClick={() => handleShowModal(true, record)} />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirm(record.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      terminalId: terminalId ?? meInfo?.terminalId,
    },
    onSubmit: () => {},
  });

  const handleShowModal = (isShow: boolean, data: any): void => {
    setIsShowModal(isShow);
    setSpotSelected(data);
  };

  const fetchDataSpots = async (params?: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(
      getSpotsAction({
        data: { size: 10, page: currentPage, terminalId: terminalId ?? meInfo?.terminalId, ...params },
      }),
    );
  };

  const dataSelectTerminals: DefaultOptionType[] = terminals?.map(item => {
    return {
      label: item.name,
      value: Number(item.id),
    };
  });

  useEffect(() => {
    void fetchDataSpots();
    void dispatch(getTerminalAction({ data: {} }));
  }, []);

  useEffect(() => {
    if (isUpdated) {
      void (async () => {
        await fetchDataSpots();
      })();
    }
  }, [isUpdated]);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  const showConfirm = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteSpotActions({
            id,
            callback: () => {
              void setIsShowModal(false);
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.DELETE,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  return {
    columns,
    dataSource: spots,
    totalPageSpots,
    isLoading,
    isShowModal,
    spotSelected,
    currentPage,
    dataSelectTerminals,
    formik,
    terminalId,
    setIsShowModal,
    handleShowModal,
    setTerminalId,
    setCurrentPage,
    fetchDataSpots,
  };
}

export default useInit;
