import React from 'react';

const OneScreenIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.45"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4096 24.7331C30.9454 28.2479 28.2479 30.9454 24.7331 31.4096C22.3167 31.7287 19.294 32 16 32C12.706 32 9.68333 31.7287 7.26694 31.4096C3.75207 30.9454 1.0546 28.2479 0.590388 24.7331C0.271256 22.3167 1.1108e-06 19.294 1.39876e-06 16C1.68673e-06 12.706 0.271257 9.68333 0.59039 7.26694C1.0546 3.75206 3.75207 1.0546 7.26695 0.590385C9.68334 0.271254 12.706 -1.68673e-06 16 -1.39876e-06C19.294 -1.1108e-06 22.3167 0.271255 24.7331 0.590387C28.2479 1.0546 30.9454 3.75207 31.4096 7.26694C31.7287 9.68333 32 12.706 32 16C32 19.294 31.7287 22.3167 31.4096 24.7331Z"
        fill="#2C2F36"
      />
      <path
        opacity="0.3"
        d="M23.0301 12H17.5401C15.8998 12 14.5701 13.3297 14.5701 14.97V23.03C14.5701 24.6703 15.8998 26 17.5401 26H23.0301C24.6704 26 26.0001 24.6703 26.0001 23.03V14.97C26.0001 13.3297 24.6704 12 23.0301 12Z"
        fill="#3699FF"
      />
      <path
        d="M13.07 15C13.0723 13.835 13.5292 12.717 14.3436 11.8839C15.158 11.0509 16.2654 10.5687 17.43 10.54V9C17.43 8.20435 17.1139 7.44129 16.5513 6.87868C15.9887 6.31607 15.2256 6 14.43 6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9V17C6 17.7956 6.31607 18.5587 6.87868 19.1213C7.44129 19.6839 8.20435 20 9 20H13.1L13.07 15Z"
        fill="#3699FF"
      />
      <path
        d="M19.248 17.496C18.5587 17.496 18 16.9373 18 16.248C18 15.5587 18.5587 15 19.248 15H21.1292C21.6477 15 22.068 15.4203 22.068 15.9388V22.374C22.068 23.1461 21.4421 23.772 20.67 23.772C19.8979 23.772 19.272 23.1461 19.272 22.374V17.496H19.248Z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default OneScreenIcon;
