import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { useAppDispatch, useAppSelector } from 'src/stores';
import { deleteLabelActions, getLabelsAction } from 'src/stores/screens/privateScreens/labels/labels.action';
import { Labels } from 'src/interfaces/pages/labels';
import { PencilEditIcon, WarningModalIcon } from 'src/assets/icons';
import DeleteRowTableIcon from 'src/assets/icons/delete-row-table-icon';
import { MESSAGE_POPUP } from 'src/shared/constants/message';
import { getTerminalAction } from 'src/stores/screens/privateScreens/terminals/terminals.action';
import { TYPE_CAMERA } from 'src/shared/constants';

const { confirm } = Modal;

function useInit(): Labels.UseInitLabels {
  const dispatch = useAppDispatch();

  const [labelSelected, setLabelselected] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const pageSize = 10;

  const { labels, totalPageLabels, isUpdated, isLoadingLabels: isLoading } = useAppSelector(state => state.labels);
  const { terminals } = useAppSelector(state => state.terminals);
  const { meInfo } = useAppSelector(state => state.auth);

  const [terminalId, setTerminalId] = useState<any>(meInfo?.terminalId);

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: '110px',
      render: (_, record, index) => {
        const no = startIndex + index < pageSize ? `0${startIndex + index}` : startIndex + index;

        return <>{no}</>;
      },
    },
    {
      title: 'グループ名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '車載カメラ',
      dataIndex: '',
      key: 'camera-car',
      render: (_, record) => {
        const filterCameraCar = record?.cameraList?.filter(item => item?.type === TYPE_CAMERA.CAR.VALUE);

        return <>{filterCameraCar[0]?.serialNum}</>;
      },
    },
    {
      title: '作業員カメラ',
      dataIndex: '',
      key: 'camera-human',
      render: (_, record) => {
        const filterCameraCar = record?.cameraList?.filter(item => item?.type === TYPE_CAMERA.HUMAN.VALUE);

        return <>{filterCameraCar[0]?.serialNum}</>;
      },
    },
    {
      title: 'タグボタン',
      dataIndex: '',
      key: 'tag-button',
      render: (_, record) => <>{record.button?.serialNum}</>,
    },
    {
      title: 'アクション',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <React.Fragment>
          <Icon component={PencilEditIcon} onClick={() => handleShowModal(true, record)} />
          <Icon
            component={DeleteRowTableIcon}
            onClick={() => {
              showConfirm(record.id);
            }}
          />
        </React.Fragment>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      terminalId: terminalId ?? meInfo?.terminalId,
    },
    onSubmit: () => {},
  });

  const handleShowModal = (isShow: boolean, data: any): void => {
    setIsShowModal(isShow);
    setLabelselected(data);
  };

  const fetchDataLabels = async (params?: CommonStore.ParamsGetList): Promise<void> => {
    await dispatch(
      getLabelsAction({
        data: { size: 10, page: currentPage, terminalId: terminalId ?? meInfo?.terminalId, haveLabel: true, ...params },
      }),
    );
  };

  const dataSelectTerminals: DefaultOptionType[] = terminals?.map(item => {
    return {
      label: item.name,
      value: Number(item.id),
    };
  });

  useEffect(() => {
    void fetchDataLabels({});
    void dispatch(getTerminalAction({ data: {} }));
  }, []);

  useEffect(() => {
    if (isUpdated) {
      void (async () => {
        await fetchDataLabels();
      })();
    }
  }, [isUpdated]);

  useEffect(() => {
    const calculateStartIndex = (): void => {
      const newStartIndex = (currentPage - 1) * pageSize + 1;
      setStartIndex(newStartIndex);
    };

    calculateStartIndex();
  }, [currentPage]);

  const showConfirm = (id: number): void => {
    confirm({
      title: MESSAGE_POPUP.ARE_YOU_SURE_DELETE,
      icon: <Icon component={WarningModalIcon} />,
      onOk() {
        void dispatch(
          deleteLabelActions({
            id,
            callback: () => {
              void setIsShowModal(false);
            },
          }),
        );
      },
      onCancel() {},
      okText: MESSAGE_POPUP.DELETE,
      cancelText: MESSAGE_POPUP.CANCEL,
    });
  };

  return {
    columns,
    dataSource: labels,
    totalPageLabels,
    isLoading,
    isShowModal,
    labelSelected,
    currentPage,
    dataSelectTerminals,
    formik,
    terminalId,
    setIsShowModal,
    handleShowModal,
    setTerminalId,
    setCurrentPage,
    fetchDataLabels,
  };
}

export default useInit;
